import { useNavigate } from 'react-router-dom';
import { IndicatorSmallIcon } from 'assets/icons';

interface ReturnButtonProps {
  returnTo: string;
}

function ReturnButton({ returnTo }: ReturnButtonProps) {
  const navigate = useNavigate();

  return (
    <button
      type="button"
      className="text-secondary text-xs font-bold h-[42px] flex flex-row items-center gap-3 cursor-pointer"
      onClick={() => navigate(returnTo)}
    >
      <IndicatorSmallIcon className="!w-[8px] mr-[0px]" />
      VOLTAR
    </button>
  );
}

export default ReturnButton;