/* eslint-disable react-hooks/rules-of-hooks */
import { CloseIcon, ListIcon } from 'assets/icons';
import Modal from 'components/Modal';
import { PAGES_ROUTES } from 'constants/routes';
import { Navigate } from 'react-router-dom';
import useCompanySelectedStore from 'store/client/companySelected/useCompanySelectedStore';
import { selectCompanySelected } from 'store/client/companySelected/selectors';
import { useGetCompanyLogDetails } from 'store/server/company/queries';
import { dateUtils } from 'utils/formatDate';
import { ILog } from 'interfaces/logTypes';
import classNames from 'classnames';
import ToogleListElement from 'components/ToogleListElement';
import ReactJson from '@microlink/react-json-view';
import dayjs from 'dayjs';
import LoadingListSkeleton from './components/LoadingListSkeleton';
import { isReactNode } from 'utils/mask.utils';

const { DATE_TIME_DISPLAY_FORMAT } = dateUtils.constants;

interface IProps {
  setOpenModal: (modalState: { display: boolean; log: ILog | null }) => void;
  logData: { display: boolean; log: ILog | null };
}

function ListPaymentsModal({ setOpenModal, logData }: IProps) {
  const companySelected = useCompanySelectedStore(selectCompanySelected);

  const { log } = logData;

  if (!companySelected || !log || !log.id)
    return <Navigate to={PAGES_ROUTES.authenticated.commonInitialRoute} replace />;

  const {
    data: logListData,
    isLoading: logListIsLoading,
    isFetching: logListIsFetching,
  } = useGetCompanyLogDetails({
    logId: log.id,
  });

  return (
    <Modal
      css={{
        modal: 'max-w-[100vw] !min-w-[calc(100vw-600px)]',
      }}
      content={
        <>
          {logListIsFetching || logListIsLoading ? (
            <LoadingListSkeleton />
          ) : (
            <div className="flex flex-col gap-y-4 max-h-[calc(100vh-100px)]">
              <div className="w-full flex justify-between items-start gap-4">
                <div className="w-full flex justify-between flex-wrap gap-y-2 gap-x-1">
                  <h2 className="text-xl text-[#595959] font-inter font-semibold flex justify-start items-center gap-x-2.5 ">
                    <ListIcon className="w-[22px] h-[24px] !text-secondary min-w-[22px]" />
                    Detalhes
                  </h2>
                </div>
                <button
                  type="button"
                  className="text-black text-sm py-1 font-inter group"
                  onClick={() => setOpenModal({ display: false, log: null })}
                >
                  <CloseIcon className="w-[17px] h-[17px] !text-primary" />
                </button>
              </div>
              <div className="flex flex-col items-start w-full custom-container-scroll overflow-auto">
                <div className="flex flex-col gap-y-[9px] text-xs pl-[22px]">
                  <div className="font-inter font-medium text-gray-500 text-sm">
                    <p>
                      <b className="text-primary">Data: </b>
                      {dayjs(logListData?.data).format(DATE_TIME_DISPLAY_FORMAT)}
                    </p>
                    <p>
                      <b className="text-primary normal-case">Usuário: </b> {logListData?.usuario}
                    </p>
                    <p>
                      <b className="text-primary">Área: </b>
                      {logListData?.area}
                    </p> 
                    <p>
                      <b className="text-primary">Operação: </b>
                      {logListData?.operacao}
                    </p>                    
                  </div>
                </div>
                <ToogleListElement title="Requisição" startOpen={true}>
                  <div>               
                    <div>
                    <ul>
                    {logListData !== undefined && logListData?.requisicao && typeof logListData.requisicao === 'object' && !Array.isArray(logListData.requisicao) ? (
                        Object.entries(logListData.requisicao).map(([key, value]) => (
                          <li key={key}>
                            <strong>{key}:</strong> {value !== null && value !== undefined ? value : '-'}
                          </li>
                        ))
                      ) : (
                        <li>-</li>
                      )}
                    </ul>
                      
                      {/* <ReactJson
                        src={logListData?.requisicao || {}}
                        style={{ width: '100%', marginLeft: '15px' }}
                        enableClipboard={false}
                        displayDataTypes={false}
                        displayObjectSize={false}
                        name={false}
                      /> */}
                    </div> 

                  </div>
                </ToogleListElement>
                <ToogleListElement title="Resposta" startOpen={true}>
                  <div>               
                    <div>
                    {logListData !== undefined && Array.isArray(logListData?.resposta) && logListData.resposta.length > 0 ? (
                        logListData.resposta.map((item, index) => (
                          <div key={index}>
                            <ul>
                              {Object.entries(item).map(([key, value]) => (
                                <li key={key}>
                                  <strong>{key}: {isReactNode(value) && value !== null && value !== undefined ? value : '-'} </strong> 
                                </li>
                              ))}
                            </ul>
                          </div>
                        ))
                      ) : (
                        <div>-</div>
                      )}


                      {/* <ul>
                        {Object.entries(logListData?.resposta).map(([key, value]) => (

                          // <li key={key}>
                          //   <strong>{key}:</strong> {value}
                          // </li>
                        ))}
                      </ul> */}
                      
                      {/* <ReactJson
                        src={logListData?.resposta || {}}
                        style={{ width: '100%', marginLeft: '15px' }}
                        enableClipboard={false}
                        displayDataTypes={false}
                        displayObjectSize={false}
                        name={false}
                      /> */}
                    </div>
                  </div>
                </ToogleListElement>
              </div>
            </div>
          )}
        </>
      }
      key={`display-body-log-${log.id}`}
    />
  );
}

export default ListPaymentsModal;
