// Third-party
import { number, object, string } from 'yup';

// App
import { emailRegex } from 'constants/validations';
import { ICreateClientPayload } from 'interfaces/clientTypes';

const REQUIRED_FIELD_MESSAGE = 'Este campo é obrigatório';

export const formSchema = object<ICreateClientPayload>({
  cpfCnpj: string().min(14).max(18).required(REQUIRED_FIELD_MESSAGE),
  name: string().required(REQUIRED_FIELD_MESSAGE),
  zipCode: string().required(REQUIRED_FIELD_MESSAGE),
  address: string().required(REQUIRED_FIELD_MESSAGE),
  addressNumber: string().required(REQUIRED_FIELD_MESSAGE),
  complement: string(),
  district: string().required(REQUIRED_FIELD_MESSAGE),
  city: string().required(REQUIRED_FIELD_MESSAGE),
  state: string().required(REQUIRED_FIELD_MESSAGE),
  phone: string().required(REQUIRED_FIELD_MESSAGE),
  email: string().matches(emailRegex, 'Formato inválido').required(REQUIRED_FIELD_MESSAGE),
  typeClientId: number().required(REQUIRED_FIELD_MESSAGE),
  comment: string().nullable(),
});
