import classNames from 'classnames';

function CloseIcon({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      className={classNames('text-primary', className)}
    >
      <path
        d="M13.125 1.875L1.875 13.125M1.875 1.875L13.125 13.125L1.875 1.875Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CloseIcon;
