import classNames from 'classnames';

function SaveIcon({ className }: { className?: string }) {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames('text-white', className)}
    >
      <path
        d="M18.6484 4.75781L14.7422 0.85156C14.6692 0.779153 14.5826 0.721868 14.4874 0.682989C14.3922 0.64411 14.2903 0.624403 14.1875 0.624998H1.6875C1.2731 0.624998 0.875671 0.789618 0.582646 1.08264C0.28962 1.37567 0.125 1.7731 0.125 2.1875V17.8125C0.125 18.2269 0.28962 18.6243 0.582646 18.9174C0.875671 19.2104 1.2731 19.375 1.6875 19.375H17.3125C17.7269 19.375 18.1243 19.2104 18.4174 18.9174C18.7104 18.6243 18.875 18.2269 18.875 17.8125V5.3125C18.8756 5.20968 18.8559 5.10776 18.817 5.01257C18.7781 4.91739 18.7208 4.83081 18.6484 4.75781ZM6.375 2.1875H12.625V5.3125H6.375V2.1875ZM12.625 17.8125H6.375V11.5625H12.625V17.8125ZM14.1875 17.8125V11.5625C14.1875 11.1481 14.0229 10.7507 13.7299 10.4576C13.4368 10.1646 13.0394 10 12.625 10H6.375C5.9606 10 5.56317 10.1646 5.27015 10.4576C4.97712 10.7507 4.8125 11.1481 4.8125 11.5625V17.8125H1.6875V2.1875H4.8125V5.3125C4.8125 5.7269 4.97712 6.12433 5.27015 6.41735C5.56317 6.71038 5.9606 6.875 6.375 6.875H12.625C13.0394 6.875 13.4368 6.71038 13.7299 6.41735C14.0229 6.12433 14.1875 5.7269 14.1875 5.3125V2.50781L17.3125 5.63281V17.8125H14.1875Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SaveIcon;
