/* eslint-disable react-hooks/rules-of-hooks */
// Third-party
import { Navigate, useParams } from 'react-router-dom';
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { AxiosError } from 'axios';
// App
import { formSchema } from './schemas';
import { InputText, Select, Toggle } from 'components';
import { AddUserIcon, SimpleSpinnerIcon } from 'assets/icons';
import { cepMask, cnpjMask, phoneNumberMask, removePhoneNumberMask } from 'utils/mask.utils';
import { IUpdateCompanyPayload, companyTypePlanEnum } from 'interfaces/companyTypes';
import useNotificationStore from 'store/client/notification/useNotificationStore';
import { selectAddNotification } from 'store/client/notification/selectors';
import useLoadingStore from 'store/client/loading/useLoadingStore';
import { selectLoading } from 'store/client/loading/selectors';
import { useUpdateCompanyMutation } from 'store/server/company/mutations';
import { useGetCompanyById, useGetCompanyTypes } from 'store/server/company/queries';
import { PAGES_ROUTES } from 'constants/routes';
import LoadingSkeleton from './components/LoadingSkeleton';
import { getAddressByCep } from 'services/api/external/viaCep';
import { IUpdateUnitPayload } from 'interfaces/unitTypes';
import { useUpdateUnitMutation } from 'store/server/unit/mutations';
import Skeleton from 'react-loading-skeleton';
import { handleChangeMonthlyInstallments } from 'utils/handlers';
import { convertToBrazilianCurrencyFormat } from 'utils/currency.utils';
import Datepicker from 'react-tailwindcss-datepicker';
import ReturnButton from 'components/ReturnButton';
import SaveButton from 'components/SaveButton';
import { mainStyles, formStyles } from './styles';
import formProperties from './formProperties';
import InputTextArea from 'components/InputTextArea';
import { dateUtils } from '../../../utils/formatDate';
import { DEFAULT_VALUES } from 'constants/textAreaInput';

const { DATE_DISPLAY_FORMAT } = dateUtils.constants;
const { DEFAULT_MAX_LENGHT } = DEFAULT_VALUES;

type IUpdateCompanyAndUnitPayload = IUpdateCompanyPayload & IUpdateUnitPayload;

function Edit() {
  /*
  **** Component organization ****

   └── Declaration of generic hooks (e.g., useNavigate)
   └── State declaration
   └── Side effects (e.g., useEffect)
   └── Memoization (e.g., useMemo)
   └── Handlers (e.g., useCallback)
   └── JSX
   */

  // └── Declaration of generic hooks (e.g., useNavigate)
  const { companyId } = useParams();

  const {
    register,
    handleSubmit,
    formState: { isValid, dirtyFields },
    reset,
    watch,
    setValue,
    control,
  } = useForm<IUpdateCompanyAndUnitPayload>({
    resolver: yupResolver(formSchema),
  });
  const isLoading = useLoadingStore(selectLoading);
  const addNotification = useNotificationStore(selectAddNotification);

  if (!companyId || isNaN(Number(companyId)))
    return <Navigate to={PAGES_ROUTES.authenticated.company.list} replace />;

  const { data: company, isLoading: isLoadingCompany } = useGetCompanyById(Number(companyId));
  const updateCompanyMutation = useUpdateCompanyMutation(Number(companyId));
  const updateUnitMutation = useUpdateUnitMutation(Number(company?.units[0]?.id));
  const { data: companyTypes, isLoading: companyTypesIsLoading } = useGetCompanyTypes();

  // └── State declaration
  const [addressByCepIsLoading, setAddressByCepIsLoading] = useState<boolean>(false);
  const watchCompanyType = watch('companyType');
  const watchCEP = watch('zipCode');
  const watchTypePlan = watch('typePlan');
  const watchStatus = watch('status');

  const ACTIVED = 'ACTIVED';
  const DESACTIVED = 'DESACTIVED';
  const isCompanyActive = watchStatus === ACTIVED;

  // └── Side effects (e.g., useEffect)
  useEffect(() => {
    if (company) {
      reset({
        name: company.name,
        fantasyName: company.fantasyName,
        status: company.status,
        address: company?.units?.[0]?.address ?? '',
        addressNumber: company?.units?.[0]?.addressNumber ?? '',
        city: company?.units?.[0]?.city ?? '',
        complement: company?.units?.[0]?.complement ?? '',
        district: company?.units?.[0]?.district ?? '',
        state: company?.units?.[0]?.state ?? '',
        zipCode: (company?.units?.[0]?.zipCode && cepMask(company?.units?.[0]?.zipCode)) ?? '',
        phone: (company?.units?.[0]?.phone && phoneNumberMask(company?.units?.[0]?.phone)) ?? '',
        companyType: company?.companyType.id,
        monthlyInstallments: convertToBrazilianCurrencyFormat(company?.monthlyInstallments || ''),
        typePlan: company?.typePlan,
        dateRegister: company?.dateRegister,
        monthlyFeeDay: company?.monthlyFeeDay,
        comment: company?.comment ?? '',
      });
    }
  }, [company, reset]);

  useEffect(() => {
    if (watchTypePlan === company?.typePlan) {
      setValue('monthlyFeeDay', company?.monthlyFeeDay);
      return;
    }
    setValue('monthlyFeeDay', '');
  }, [watchTypePlan, setValue]);

  useEffect(() => {
    (async () => {
      if (dirtyFields.zipCode && watchCEP?.length === 9) {
        try {
          setAddressByCepIsLoading(true);
          const address = await getAddressByCep(watchCEP.replace('-', ''));

          address.logradouro && setValue('address', address.logradouro, { shouldDirty: true });
          address.bairro && setValue('district', address.bairro, { shouldDirty: true });
          address.localidade && setValue('city', address.localidade, { shouldDirty: true });
          address.uf && setValue('state', address.uf, { shouldDirty: true });
        } catch (error) {
          addNotification({
            type: 'error',
            message: 'Erro ao consultar endereço. Verifique o CEP inserido e tente novamente.',
          });
        } finally {
          setAddressByCepIsLoading(false);
        }
      }
    })();
  }, [addNotification, dirtyFields.zipCode, setValue, watchCEP]);

  // └── Handlers (e.g., useCallback)
  const onSubmit = async (data: IUpdateCompanyAndUnitPayload) => {
    const {
      fantasyName,
      name,
      address,
      addressNumber,
      city,
      complement,
      district,
      phone,
      state,
      zipCode,
      status,
      companyType,
      monthlyInstallments,
      typePlan,
      dateRegister,
      monthlyFeeDay,
      comment,
    }: Partial<IUpdateCompanyAndUnitPayload> = Object.keys(dirtyFields).reduce(
      (fields, fieldName) => {
        const key = fieldName as keyof IUpdateCompanyAndUnitPayload;
        if (key === 'companyType') {
          fields[key] = watchCompanyType;
        } else {
          fields[key] = data[key];
        }
        return fields;
      },
      {} as Partial<IUpdateCompanyAndUnitPayload>
    );
    try {
      if (
        fantasyName ||
        name ||
        status ||
        companyType ||
        monthlyInstallments ||
        typePlan ||
        dateRegister ||
        monthlyFeeDay ||
        comment
      ) {
        await updateCompanyMutation.mutateAsync({
          fantasyName,
          name,
          status,
          companyType,
          monthlyInstallments:
            monthlyInstallments &&
            parseFloat(monthlyInstallments?.replace(/R\$\s/g, '').replace(',', '.')).toString(),
          typePlan,
          dateRegister,
          monthlyFeeDay,
          comment,
        });
      }

      if (address || addressNumber || city || complement || district || phone || state || zipCode) {
        await updateUnitMutation.mutateAsync({
          address,
          addressNumber,
          city,
          complement,
          district,
          phone: phone && removePhoneNumberMask(phone),
          state,
          zipCode: zipCode && removePhoneNumberMask(zipCode),
        });
      }
      addNotification({
        type: 'success',
        message: 'Empresa atualizada com sucesso.',
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        if (
          error.response?.status &&
          error.response?.status >= 400 &&
          error.response?.status < 500
        ) {
          addNotification({
            type: 'error',
            message: error.response?.data.message,
          });
          return;
        }

        if (
          ['Email already exists', 'User already exists'].includes(error.response?.data.message)
        ) {
          addNotification({
            type: 'error',
            message: 'Usuário já cadastrado no sistema.',
          });
          return;
        }

        addNotification({
          type: 'error',
          message: 'Erro com o serviço. Tente novamente mais tarde.',
        });
      }
    }
  };

  const handleMaskValue = useCallback(
    <TFieldName extends keyof IUpdateCompanyAndUnitPayload>(
      field: ControllerRenderProps<IUpdateCompanyAndUnitPayload, TFieldName>,
      maskCallback: (value: string) => string
    ) => {
      return (event: ChangeEvent<HTMLInputElement>) => {
        event.target.value = maskCallback(event.target.value);
        field.onChange(event);
      };
    },
    []
  );

  const handleStatusChange = (
    event: ChangeEvent<HTMLInputElement>,
    field: ControllerRenderProps<IUpdateCompanyAndUnitPayload, 'status'>
  ) => {
    setValue('status', event.target.checked ? ACTIVED : DESACTIVED);
    field.onChange(event.target.checked ? ACTIVED : DESACTIVED);
  };

  return (
    <>
      {/* MAIN CONTAINER */}
      <div className={mainStyles.mainContainer}>
        {/* SUBHEADER CONTAINER */}
        <div className={mainStyles.subHeaderContainer}>
          {/* ADD USER ICON - COMPANY DETAILS HEADER */}
          <h1 className={mainStyles.addUserIconContainerH1}>
            <AddUserIcon className={mainStyles.addUserIconStyles} /> Detalhes da Empresa
          </h1>
          {/* REQUIRED FIELDS TEXT */}
          <h3 className={mainStyles.requiredFieldsH3}>Campos obrigatórios *</h3>
        </div>
        {/* FORM CONTAINER */}
        {company && !isLoadingCompany ? (
          <form onSubmit={handleSubmit(onSubmit)} noValidate className={mainStyles.formContainer}>
            {/* FORM QUESTIONS SECTION */}
            <div className={mainStyles.formQuestionsSection}>
              {/* FORM TITLE */}
              <h3 className={mainStyles.formTitle}>Dados da Empresa</h3>
              {/* Company inputs */}
              {/* CNPJ INPUT */}
              <InputText
                labelText={formProperties.cnpjInput.labelText}
                value={cnpjMask(company.cnpj)}
                cssClasses={{
                  label: formStyles.cnpjInput.labelStyle,
                }}
                type="text"
                placeholder={formProperties.cnpjInput.placeHolderText}
                disabled
              />
              {/* STATUS INPUT */}
              <Controller
                name="status"
                control={control}
                render={({ field }) => (
                  <Toggle
                    labelText={formProperties.statusInput.labelText(isCompanyActive)}
                    cssClasses={{ label: formStyles.statusInput.labelStyle }}
                    checked={isCompanyActive}
                    onChange={(event) => {
                      handleStatusChange(event, field);
                    }}
                  />
                )}
              />
              {/* FANTASY NAME INPUT */}
              <InputText
                labelText={formProperties.fanstasyNameInput.labelText}
                cssClasses={{ label: formStyles.fanstasyNameInput.labelStyle }}
                {...register('fantasyName')}
              />
              {/* NAME INPUT */}
              <InputText
                labelText={formProperties.nameInput.labelText}
                cssClasses={{ label: formStyles.nameInput.labelStyle }}
                {...register('name')}
              />
              {company.units && company.units.length > 0 && (
                <>
                  {/* Address inputs */}
                  {/* ZIPCODE INPUT */}
                  <Controller
                    name="zipCode"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <div className={formStyles.zipCodeInput.mainContainerStyle}>
                        {addressByCepIsLoading && (
                          <SimpleSpinnerIcon className={formStyles.zipCodeInput.spinnerIconStyle} />
                        )}
                        <InputText
                          {...field}
                          labelText={formProperties.zipCodeInput.labelText}
                          cssClasses={{
                            label: formStyles.zipCodeInput.inputText.labelStyle,
                          }}
                          type="text"
                          placeholder={formProperties.zipCodeInput.placeHolderText}
                          onChange={handleMaskValue<'zipCode'>(field, cepMask)}
                          disabled={addressByCepIsLoading}
                        />
                      </div>
                    )}
                  />
                  {/* ADDRESS INPUT */}
                  <InputText
                    labelText={formProperties.addressInput.labelText}
                    cssClasses={{ label: formStyles.addressInput.labelStyle }}
                    {...register('address')}
                    disabled={addressByCepIsLoading}
                  />
                  {/* ADDRESSNUMBER INPUT */}
                  <InputText
                    labelText={formProperties.addressNumber.labelText}
                    cssClasses={{
                      label: formStyles.addressNumber.labelStyle,
                    }}
                    {...register('addressNumber')}
                    disabled={addressByCepIsLoading}
                  />
                  {/* COMPLEMENT INPUT */}
                  <InputText
                    labelText={formProperties.complementInput.labelText}
                    cssClasses={{
                      label: formStyles.complementInput.labelStyle,
                    }}
                    {...register('complement')}
                    disabled={addressByCepIsLoading}
                  />
                  {/* DISTRICT INPUT */}
                  <InputText
                    labelText={formProperties.districtInput.labelText}
                    cssClasses={{ label: formStyles.districtInput.labelStyle }}
                    {...register('district')}
                    disabled={addressByCepIsLoading}
                  />
                  {/* CITY INPUT */}
                  <InputText
                    labelText={formProperties.cityInput.labelText}
                    cssClasses={{ label: formStyles.cityInput.labelStyle }}
                    {...register('city')}
                    disabled={addressByCepIsLoading}
                  />
                  {/* STATE INPUT */}
                  <InputText
                    labelText={formProperties.stateInput.labelText}
                    cssClasses={{
                      label: formStyles.stateInput.labelStyle,
                    }}
                    {...register('state')}
                    disabled={addressByCepIsLoading}
                  />
                  {/* PHONE INPUT */}
                  <Controller
                    name="phone"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <InputText
                        {...field}
                        labelText={formProperties.phoneInput.labelText}
                        cssClasses={{ label: formStyles.phoneInput.labelStyle }}
                        type="text"
                        placeholder={formProperties.phoneInput.placeHolderText}
                        onChange={handleMaskValue<'phone'>(field, phoneNumberMask)}
                      />
                    )}
                  />
                </>
              )}
              {/* Company manager inputs */}
              {/* COMPANY TYPE SKELETON*/}
              {companyTypesIsLoading ? (
                <Skeleton
                  height={formStyles.companyTipeInput.skeleton.heightSkeleton}
                  baseColor={formStyles.companyTipeInput.skeleton.baseColor}
                  highlightColor={formStyles.companyTipeInput.skeleton.highlightColor}
                  containerClassName={formStyles.companyTipeInput.skeleton.containerClassName}
                  className={formStyles.companyTipeInput.skeleton.classNameSkeleton}
                />
              ) : (
                //COMPANYTYPE INPUT
                <Controller
                  name="companyType"
                  control={control}
                  defaultValue={company.companyType ? company.companyType.id : -1}
                  render={({ field }) => (
                    <Select
                      labelText={formProperties.companyTipeInput.labelText}
                      cssClasses={{
                        label: formStyles.companyTipeInput.selectInput.labelStyle,
                        select: formStyles.companyTipeInput.selectInput.selectStyle(field),
                      }}
                      placeholder={formProperties.companyTipeInput.placeHolderText}
                      disabled={addressByCepIsLoading}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      value={companyTypes?.data.find((e) => e.id == field.value)?.id || -1}
                      name={field.name}
                    >
                      <option
                        value={-1}
                        className={formStyles.companyTipeInput.selectInput.defaultOptionStyle}
                      >
                        Selecione um tipo
                      </option>
                      {companyTypes?.data.map((companyType, index) => (
                        <option
                          value={companyType.id}
                          className={formStyles.companyTipeInput.selectInput.optionsStyle}
                          key={`company-type-${companyType.id}-${index}`}
                        >
                          {companyType.description}
                        </option>
                      ))}
                    </Select>
                  )}
                />
              )}
              {/* PLANTYPE INPUT */}
              <Select
                labelText={formProperties.selectPlanTypeInput.labelText}
                cssClasses={{
                  label: formStyles.selectPlanTypeInput.labelStyle,
                  select: formStyles.selectPlanTypeInput.selectStyle(watchTypePlan),
                }}
                {...register('typePlan')}
                placeholder={formProperties.selectPlanTypeInput.placeHolderText}
                defaultValue={''}
              >
                <option
                  defaultChecked={true}
                  value={''}
                  className={formStyles.selectPlanTypeInput.defaultOptionStyle}
                >
                  Selecione um plano
                </option>
                <option value={'mensal'} className={formStyles.selectPlanTypeInput.optionsStyle}>
                  Plano mensal
                </option>
                <option value={'anual'} className={formStyles.selectPlanTypeInput.optionsStyle}>
                  Plano anual
                </option>
              </Select>
              {/* DATEREGISTER INPUT*/}
              <Controller
                name="dateRegister"
                control={control}
                render={({ field }) => {
                  return (
                    <label
                      htmlFor="dateRegister"
                      className={formStyles.dateRegisterInput.labelStyleContainer}
                    >
                      {formProperties.dateRegisterInput.labelText}
                      <Datepicker
                        inputName="dateRegister"
                        {...field}
                        asSingle={true}
                        primaryColor="indigo"
                        i18n={'pt-br'}
                        dateLooking="middle"
                        displayFormat={DATE_DISPLAY_FORMAT}
                        placeholder="dd/mm/yyyy"
                        popoverDirection="down"
                        containerClassName={formStyles.dateRegisterInput.datePickerStyleContainer}
                        inputClassName={formStyles.dateRegisterInput.datePickerStyleInput}
                        onChange={(event) => field.onChange(event?.startDate ?? '')}
                        value={{ startDate: field.value || null, endDate: field.value || null }}
                      />
                    </label>
                  );
                }}
              />
              {/* MONTHLYFEEDAY INPUT */}
              {watchTypePlan !== companyTypePlanEnum.YEARLY ? (
                <Controller
                  name="monthlyFeeDay"
                  control={control}
                  render={({ field }) => (
                    <InputText
                      labelText={formProperties.monthlyFeeDayInput.labelText(watchTypePlan || '')}
                      placeholder={formProperties.monthlyFeeDayInput.placeHolderText(
                        watchTypePlan || ''
                      )}
                      cssClasses={{ label: formStyles.monthlyFeeDayInput.textInputStyleLabel }}
                      type="number"
                      {...field}
                      onChange={(e) => {
                        const value = Math.max(1, Math.min(31, Number(e.target.value)));
                        field.onChange(value.toString());
                      }}
                      disabled={watchTypePlan !== companyTypePlanEnum.MONTHLY}
                      value={field.value || ''}
                    />
                  )}
                />
              ) : (
                <Controller
                  name="monthlyFeeDay"
                  control={control}
                  render={({ field }) => (
                    <label
                      htmlFor="monthlyFeeDay"
                      className={formStyles.monthlyFeeDayInput.datePickerStyleContainerLabel}
                    >
                      {formProperties.monthlyFeeDayInput.labelText(watchTypePlan)}
                      <Datepicker
                        inputName="monthlyFeeDay"
                        {...field}
                        asSingle={true}
                        primaryColor="indigo"
                        i18n={'pt-br'}
                        dateLooking="middle"
                        displayFormat={DATE_DISPLAY_FORMAT}
                        placeholder="dd/mm/yyyy"
                        popoverDirection="down"
                        containerClassName={
                          formStyles.monthlyFeeDayInput.datePickerStyleInputContainer
                        }
                        inputClassName={formStyles.monthlyFeeDayInput.inputDatePickerStyle}
                        onChange={(event) => field.onChange(event?.startDate ?? '')}
                        value={{ startDate: field.value || null, endDate: field.value || null }}
                      />
                    </label>
                  )}
                />
              )}
              {/* MONTHLYINSTALLMENTS INPUT */}
              <Controller
                name="monthlyInstallments"
                control={control}
                defaultValue="R$ 0,00"
                render={({ field }) => (
                  <InputText
                    {...field}
                    labelText={formProperties.monthlyInstallments.labelText(watchTypePlan || '')}
                    cssClasses={{ label: formStyles.monthlyInstallments.labelStyle }}
                    type="text"
                    onChange={handleChangeMonthlyInstallments(field.onChange)}
                  />
                )}
              />
              {/* COMMENT INPUT */}
              <Controller
                name="comment"
                control={control}
                defaultValue={''}
                render={({ field }) => (
                  <InputTextArea
                    {...field}
                    labelText={formProperties.commentInput.labelText}
                    cssClasses={{
                      label: formStyles.comments.labelStyle,
                      textarea: formStyles.comments.textarea,
                    }}
                    maxLength={DEFAULT_MAX_LENGHT}
                  />
                )}
              />
            </div>
            {/* FORM BUTTONS SECTION */}
            <div className={mainStyles.formButtonSection}>
              {/* RETURN BUTTON */}
              <ReturnButton returnTo={PAGES_ROUTES.authenticated.company.list} />
              {/* SAVE BUTTON */}
              <SaveButton
                text="SALVAR"
                isLoading={isLoading || isLoadingCompany}
                isDisabled={
                  !isValid ||
                  isLoading ||
                  isLoadingCompany ||
                  Object.keys(dirtyFields).length === 0 ||
                  updateCompanyMutation.isPending ||
                  updateUnitMutation.isPending
                }
                showSpinner={
                  updateCompanyMutation.isPending ||
                  updateUnitMutation.isPending ||
                  isLoadingCompany
                }
              />
            </div>
          </form>
        ) : (
          <LoadingSkeleton />
        )}
      </div>
    </>
  );
}

export default Edit;
