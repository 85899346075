// Third-party
import { useQuery } from '@tanstack/react-query';

// App
import queriesKeys from './queriesKeys';
import { IGroup } from 'interfaces/menuTypes';
import { listGroupOptions } from 'services/api/menu';
import { IResponseWithDataListPaginated } from 'interfaces/apiTypes';

export const useMenuGroupList = () =>
  useQuery<IResponseWithDataListPaginated<IGroup>['response']>({
    queryKey: [queriesKeys.groupList],
    queryFn: async () => {
      const groupList = await listGroupOptions();

      return groupList;
    },
    staleTime: Infinity,
  });
