import { StoreApi } from 'zustand';
import { IDashboardState } from './interfaces';

export const setSelectedMonth =
  (set: StoreApi<IDashboardState>['setState']): IDashboardState['setSelectedMonth'] =>
  (value) =>
    set({
      selectedMonth: value,
    });

export const setSelectedYear =
  (set: StoreApi<IDashboardState>['setState']): IDashboardState['setSelectedYear'] =>
  (value) =>
    set({
      selectedYear: value,
    });

export const setSelectedClient =
  (set: StoreApi<IDashboardState>['setState']): IDashboardState['setSelectedClient'] =>
  (value) =>
    set({
      selectedClient: value,
    });
