interface TableCellProps<T> {
  keyIndex: string;
  content: T;
  renderContent: (content: T) => JSX.Element | string; 
  className?: string;
}

function CustomTableCell<T>({
  keyIndex,
  content,
  renderContent,
  className,
}: TableCellProps<T>) {
  return (
    <td 
      key={keyIndex}
      className={`px-6 py-4 whitespace-nowrap w-fit ${className}`}>
      {renderContent(content)}
    </td>
  );
}

export default CustomTableCell;