import ServicesPerClientsList from '../../ServicesPerClientsList';
import SectionContainer from '../SectionContainer';

interface IServicesSectionProps {
  toExport?: boolean;
}

function ServicesSection({ toExport }: IServicesSectionProps) {
  return (
    <SectionContainer
      title="Serviços"
      cssClasses={{
        titleContainerStyle: 'bg-[#376091]',
        container: toExport ? 'max-h-[1280px] overflow-hidden' : undefined,
      }}
      toExport={toExport}
    >
      <ServicesPerClientsList toExport={toExport} />
    </SectionContainer>
  );
}

export default ServicesSection;
