import { ChangeEvent } from 'react';
import dayjs from 'dayjs';
import { Select } from 'components';
import { useFilterBarContext } from '../../context/FilterBarContext';
import { DateHelper } from 'helpers/dateHelpers';

interface IMonthYearSelectorBarProps {
  disabled?: boolean;
}

const FilterBar = ({ disabled }: IMonthYearSelectorBarProps) => {
  const { selectedMonth, selectedYear, setSelectedMonth, setSelectedYear } = useFilterBarContext();

  const years = Array.from(new Array(20), (val, index) => dayjs().year() - 10 + index);
  const months = Array.from(new Array(12), (val, index) => index);
  const dateUtils = new DateHelper(); 

  const handleYearChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedYear(Number(event.target.value));
  };

  const handleMonthChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedMonth(Number(event.target.value));
  };

  return (
    <div className="flex gap-x-2">
      <Select
        value={selectedMonth}
        onChange={handleMonthChange}
        cssClasses={{ label: 'w-full min-w-[120px]' }}
        disabled={disabled}
      >
        {months.map((month, index) => {
          const monthName = dateUtils.getMonthName(month);
          return (
            <option key={index} value={month + 1}>
              {monthName.charAt(0).toUpperCase() + monthName.slice(1)}
            </option>
          );
        })}
      </Select>
      <Select value={selectedYear} onChange={handleYearChange} disabled={disabled}>
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </Select>
    </div>
  );
};

export default FilterBar;
