import classNames from 'classnames';

function AddUserIcon({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1024 1024"
      version="1.1"
      className={classNames('!text-buttontextcolor', className)}
      width="20px"
      height="20px"
    >
      <path
        fill="currentColor"
        d="M866.324275 126.976c-5.632-48.128-42.496-84.992-90.624-90.624-306.688-24.576-321.024-10.24-334.336 3.072L25.108275 453.632c-16.384 16.384-25.6 38.912-25.088 61.44 0.512 22.016 9.728 41.984 25.088 56.32l306.176 306.176c15.872 15.872 37.888 25.088 58.88 25.088 22.528 0 41.984-8.704 58.88-25.088l414.208-414.208c15.872-15.36 28.16-27.648 3.072-336.384z m-56.832 292.352l-409.6 409.6c-5.632 5.632-13.824 5.632-19.456 0L74.260275 522.24c-2.56-2.56-4.096-6.144-4.096-9.728s1.536-7.168 4.096-9.728l409.6-409.6c15.872-2.56 75.776-3.584 285.184 12.8 14.336 1.536 25.088 12.288 25.088 24.064v1.024c19.968 216.576 17.92 273.408 15.36 288.256zM538.644275 862.208c-13.824-10.752-36.864-10.24-48.128 1.024-7.168 7.168-10.24 14.848-10.24 24.576s3.072 17.408 10.24 24.576 17.408 10.24 24.576 10.24 17.408-3.072 24.576-10.24 10.24-14.848 10.24-24.576-3.072-17.408-10.24-24.576l-1.024-1.024z"
      />
      <path
        fill="currentColor"
        d="M944.148275 206.336c-9.216-15.36-27.136-20.48-41.472-11.776-14.848 9.216-19.968 27.136-11.776 41.472 26.624 46.592 60.416 109.568 72.704 146.944L768.020275 927.232c-2.048 4.096-6.144 8.192-10.24 12.288-3.584 1.536-9.216 1.536-14.848-0.512l-149.504-53.76c-7.168-3.072-15.36-2.56-23.04 1.024-7.168 3.584-12.8 9.216-15.872 16.384-3.072 7.168-2.56 15.36 1.024 23.04 3.584 7.168 9.216 12.8 16.896 15.872l149.504 53.76c4.608 1.536 16.384 5.12 26.112 5.12 11.264 0 24.064-3.072 33.792-7.68 19.968-8.704 34.816-25.088 39.936-45.056l198.144-550.912c10.24-16.896 5.632-40.96-75.776-190.464zM645.140275 164.352c-54.272 0-98.304 44.032-98.304 98.304s44.032 98.304 98.304 98.304 98.304-44.032 98.304-98.304-44.032-98.304-98.304-98.304z m38.912 95.744c0 21.504-17.408 38.912-38.912 38.912-21.504 0-38.912-17.408-38.912-38.912 0-21.504 17.408-38.912 38.912-38.912 21.504 0 38.912 17.408 38.912 38.912z"
      />
    </svg>
  );
}

export default AddUserIcon;
