// Third-party
import { useMutation } from '@tanstack/react-query';

// App
import { ICreateClientPayload, IEditClientPayload, IEditClientStatusPayload } from 'interfaces/clientTypes';
import { createClient, editClient } from 'services/api/client';
import queryClient from '../queryClient';
import queriesKeys from './queriesKeys';
import { ClientStatusPayloadEnum } from 'interfaces/companyTypes';

export const useCreateClientMutation = (companyId: number) =>
  useMutation({
    mutationFn: async ({
      cpfCnpj,
      name,
      comercialName,
      zipCode,
      address,
      addressNumber,
      complement,
      district,
      city,
      state,
      phone,
      email,
      typeClientId,
      comment
    }: ICreateClientPayload) => {
      await createClient(companyId, {
        cpfCnpj,
        name,
        comercialName,
        zipCode,
        address,
        addressNumber,
        complement,
        district,
        city,
        state,
        phone,
        email,
        typeClientId,
        comment
      });
    },
  });

  export const useEditClientMutation = (companyId: number, clientId: number) =>
  useMutation({
    mutationFn: async ({      
      name,
      comercialName,
      zipCode,
      address,
      addressNumber,
      complement,
      district,
      city,
      state,
      phone,
      email,
      typeClientId,
      status,
      comment
    }: IEditClientPayload) => {
      await editClient(companyId, clientId, {        
        name,
        comercialName,
        zipCode,
        address,
        addressNumber,
        complement,
        district,
        city,
        state,
        phone,
        email,
        typeClientId,
        status,
        comment
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queriesKeys.clientForEdit],
      });
      queryClient.invalidateQueries({
        queryKey: [queriesKeys.clientList],
      });
    },
  });
  
  export const useEditClientStatusMutation = (companyId: number) =>
  useMutation({
    mutationFn: async ({      
      status,
      clientId
    }: IEditClientStatusPayload) => {
      await editClient(companyId, Number(clientId), {        
        status: status ? ClientStatusPayloadEnum.ACTIVED : ClientStatusPayloadEnum.DESACTIVED
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queriesKeys.clientList],
      });
    },
  });

