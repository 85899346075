// Third parties
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

// App

function SideMenuSkeletonLoading() {
  /*
  **** Component organization ****

   └── Declaration of generic hooks (e.g., useNavigate)
   └── State declaration
   └── Side effects (e.g., useEffect)
   └── Memoization (e.g., useMemo)
   └── Handlers (e.g., useCallback)
   └── JSX
   */

  return (
    <SkeletonTheme>
      <div className="text-white bg-[#F0F0F0] h-screen">
        {/* ACCORDION BUTTON */}
        <Skeleton height={71} className="p-4 bg-white" />

        {/* UNIT'S BRANDS */}
        <ul className="list-disc p-0 pt-2">
          <li className="px-6 flex gap-2 items-center  h-[40px]">
            <Skeleton width={180} height={25} />
          </li>
          <li className="px-6 flex gap-2 items-center  h-[40px]">
            <Skeleton width={180} height={25} />
          </li>
          <li className="px-6 flex gap-2 items-center  h-[40px]">
            <Skeleton width={180} height={25} />
          </li>
          <li className="px-6 flex gap-2 items-center  h-[40px]">
            <Skeleton width={180} height={25} />
          </li>
          <li className="px-6 flex gap-2 items-center  h-[40px]">
            <Skeleton width={180} height={25} />
          </li>
          <li className="px-6 flex gap-2 items-center  h-[40px]">
            <Skeleton width={180} height={25} />
          </li>
          <li className="px-6 flex gap-2 items-center  h-[40px]">
            <Skeleton width={180} height={25} />
          </li>
          <li className="px-6 flex gap-2 items-center  h-[40px]">
            <Skeleton width={180} height={25} />
          </li>
          <li className="px-6 flex gap-2 items-center  h-[40px]">
            <Skeleton width={180} height={25} />
          </li>
        </ul>
      </div>
    </SkeletonTheme>
  );
}

export default SideMenuSkeletonLoading;
