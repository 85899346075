// excelExportUtils.ts
import { QueryObserverResult } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { MESSAGES } from 'constants/messages';
import { AxiosError } from 'axios';

interface IExportExcelParams {
    refetch: () => Promise<QueryObserverResult<string, Error>>;
    setLoading: (loading: boolean) => void;
    addNotification: (notification: INotificationType) => void;
    filenamePrefix: string;    
}

interface INotificationType {
    type: 'error' | 'success';
    message: string;
}

export async function exportExcel({
    refetch,
    setLoading,
    addNotification,
    filenamePrefix,    
}: IExportExcelParams): Promise<void> {
    setLoading(true);
    try {
        const response = await refetch();        
        if(response.isError && !response.data){ 
            addNotification({
                type: 'error',
                message: MESSAGES.NO_FILES_ERROR_MESSAGE,
            });
            return
        }
        if (response.isSuccess && response.data) {
            const url = `data:application/octet-stream;base64,${response.data}`;
            const a = document.createElement('a');
            a.href = url;
            a.download = `ECVFinancy_${filenamePrefix}_${dayjs().format('DD-MM-YYYY')}_${dayjs().format('HH')}h${dayjs().format('mm')}m${dayjs().format('ss')}s.xlsx`;
            document.body.appendChild(a);
            a.click();
            a.remove(); 
        }
    } catch (error: any) {
        if(error instanceof AxiosError) {
            console.error("Error fetching Excel data:", error);
            addNotification({
                type: 'error',
                message: error.message || MESSAGES.DEFAULT_FAILURE_EXPORT_MESSAGE,
            });            
        }
    } finally {
        setLoading(false);
    }
}
