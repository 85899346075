/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
// Third-party
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

// App
import {
  ArrowIcon,
  BuildingIcon,
  DropDownArrowIcon,
  LockPasswordIcon,
  MenuIcon,
  ProfileIcon,
} from 'assets/icons';
import Skeleton from 'react-loading-skeleton';
import { PAGES_ROUTES } from 'constants/routes';
import { useGetUser } from 'store/server/user/queries';
import useGlobalParamsStore from 'store/client/globalParams/useGlobalParamsStore';
import { selectFirstRender } from 'store/client/globalParams/selectors';
import { cnpjMask } from 'utils/mask.utils';
import useCompanySelectedStore from 'store/client/companySelected/useCompanySelectedStore';
import { selectCompanySelected } from 'store/client/companySelected/selectors';
import { isValidCNPJ } from 'utils/cnpj.utils';

interface IHeaderProps {
  setShowMenu: Dispatch<SetStateAction<boolean>>;
  showMenu: boolean;
}

function Header({ setShowMenu, showMenu }: IHeaderProps) {
  /*
  **** Component organization ****

   └── Declaration of generic hooks (e.g., useNavigate)
   └── State declaration
   └── Side effects (e.g., useEffect)
   └── Memoization (e.g., useMemo)
   └── Handlers (e.g., useCallback)
   └── JSX
   */
  // └── Declaration of generic hooks (e.g., useNavigate)
  const userInfosDropDownMenu = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const companySelected = useCompanySelectedStore(selectCompanySelected);
  const navigate = useNavigate();

  // └── State declaration
  //   └── Global
  const userFull = useGetUser();
  const { data: user } = userFull;
  const firstRender = useGlobalParamsStore(selectFirstRender);
  //   └── Internal
  const [isUserDropDownColliding, setIsUserDropDownColliding] = useState(false);
  const [isOpenUserTitleMenu, setIsOpenUserTitleMenu] = useState<boolean>(false);
  const [windowSize, setWindowSize] = useState<number>(window.innerWidth);


  // └── Side effects (e.g., useEffect)
  useEffect(() => {
    window.addEventListener('resize', adjustPosition);

    return () => {
      window.removeEventListener('resize', adjustPosition);
    };
  }, []);

  useEffect(() => {
    setIsOpenUserTitleMenu(false);
  }, [location]);

  // └── Handlers (e.g., useCallback)
  const adjustPosition = () => {
    const movingRect = userInfosDropDownMenu?.current?.getBoundingClientRect();

    setIsUserDropDownColliding((movingRect?.right || -1) >= window.innerWidth - 10);
    setWindowSize(window.innerWidth)
  };

  const toggleUserTitleMenu = () => {
    setIsOpenUserTitleMenu(!isOpenUserTitleMenu);
    adjustPosition();
  };

  return (
    <div
      className={classNames('w-full z-[99999] bg-white', {
        fixed: windowSize < 768,
        flex: windowSize >= 768,
      })}
    >
      {/* HEADER */}
      <header
        className={classNames(
          ' transition-all duration-500 ease-in-out w-full  h-[71px] flex max-md:gap-4 px-4 items-center justify-between md:justify-end z-30 md:px-8 relative max-md:shadow-[0px_4px_4px_0px_rgba(0,_0,_0,_0.05)]',
          {
            'md:ml-[228px] md:w-[calc(100%_-_228px)]': showMenu,
            'md:ml-[40px]': !showMenu,
          }
        )}
      >
        {/* MOBILE HAMBURGER MENU */}
        <button onClick={() => setShowMenu(!showMenu)} type="button">
          <div className="md:hidden">
            <MenuIcon />
          </div>
        </button>
        {/* OLÁ, USERNAME */}
        {firstRender || (!userFull.data && userFull.isLoading) ? (
          <div className="flex justify-end items-center gap-4 overflow-hidden w-fit h-fit">
            <Skeleton baseColor="white" highlightColor="#f3f3f3" circle width={40} height={40} />
            <Skeleton baseColor="white" highlightColor="#f3f3f3" width={185} height={20} />
          </div>
        ) : (
          <div className="flex flex-row w-full justify-between items-center h-[56px]">
            <div
              className="flex justify-center items-center gap-x-2 cursor-pointer"
              onClick={() => navigate(PAGES_ROUTES.authenticated.company.listToSelect)}
            >
              <BuildingIcon className="w-[25px] min-w-[25px]" />
              {companySelected && (
                <div className="flex w-full flex-col lg:flex-row gap-x-2 md:text-lg text-sm ">
                  <b className="font-semibold truncate">{companySelected?.name}</b>{' '}
                  <span className="hidden lg:inline-block">-</span>                  
                  <span className="truncate">
                    {companySelected && isValidCNPJ(companySelected.cnpj)
                      ? cnpjMask(companySelected.cnpj)
                      : 'CNPJ não disponível'}
                  </span>
                </div>
              )}
              {[1].includes(userFull.data?.accessLevel || 0) && windowSize > 600 && (
                <span className="text-secondary font-bold lg:whitespace-nowrap md:text-lg text-sm">
                  {companySelected ? `Trocar Empresa` : 'Selecione uma empresa'}
                </span>
              )}
            </div>
            <div
              className={classNames(
                'w-fit overflow-hidden h-[100px] mt-4 self-start px-1 lg:min-w-[85px]'
              )}
              style={{
                height: `calc(40px + ${
                  isOpenUserTitleMenu
                    ? (userInfosDropDownMenu.current?.getBoundingClientRect().height || 0) + 30
                    : 0
                }px)`,
              }}
            >
              <div className="flex justify-end items-align overflow-hidden w-full">
                <button
                  onClick={toggleUserTitleMenu}
                  className="overflow-hidden w-fit max-w-[280px] gap-2 text-sm text-[#1F2937] font-medium flex justify-between items-center whitespace-nowrap"
                >
                  <div className="bg-gray-200 rounded-full flex justify-center items-center min-w-[40px] h-[40px]">
                    <ProfileIcon className="text-[#1F2937]" />
                  </div>
                  {windowSize > 1300 && (
                    <>
                      <p
                        className="w-full max-w-[200px] truncate text-primary font-semibold"
                        title={`${user?.givenName} ${user?.familyName}`}
                      >
                        Olá, {user?.givenName} {user?.familyName.split(' ').slice(-1)[0]}
                      </p>
                      <DropDownArrowIcon
                        className={classNames('!text-secondary ml-4 min-w-[14px] h-2', {
                          'transform rotate-180': isOpenUserTitleMenu,
                        })}
                      />
                    </>
                  )}
                </button>
              </div>
              {
                <div
                  className={classNames(
                    'mt-2 w-56 shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-[2000] absolute origin-center right-0 lg:right-[80px]',
                    {
                      hidden: !isOpenUserTitleMenu,
                    }
                  )}
                  ref={userInfosDropDownMenu}
                >
                  <div
                    className="py-1 overflow-hidden"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                  >
                    <NavLink
                      to={PAGES_ROUTES.authenticated.user.edit.replace(
                        ':userId',
                        (user?.id ?? -1).toString()
                      )}
                      className={classNames(
                        'pl-5 pr-4 cursor-pointer hover:bg-[#F9FAFB] font-inter text-sm text-[#595959] font-medium flex items-center justify-start gap-2 h-[40px] group'
                      )}
                    >
                      <ProfileIcon className="!text-secondary" /> Meu Perfil
                    </NavLink>
                    <NavLink
                      to={PAGES_ROUTES.authenticated.user.changePassword}
                      className={classNames(
                        'pl-5 pr-4 cursor-pointer hover:bg-[#F9FAFB] font-inter text-sm text-[#595959] font-medium flex items-center justify-start gap-2 h-[40px] group'
                      )}
                    >
                      <LockPasswordIcon className="!text-secondary" /> Trocar Minha Senha
                    </NavLink>
                  </div>
                </div>
              }
            </div>
          </div>
        )}
      </header>
    </div>
  );
}

export default Header;
