import { useCallback, useState } from 'react';

function usePersistedState<T>(
  key: string,
  initialValue: T
): [T, (value: T | ((val: T) => T)) => void] {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const setValue: (value: T | ((val: T) => T)) => void = useCallback(
    (value) => {
      setStoredValue((prevValueToStore) => {
        const valueToStore = value instanceof Function ? value(prevValueToStore) : value;
        window.localStorage.setItem(key, JSON.stringify(valueToStore));

        return valueToStore;
      });
    },
    [key]
  );

  return [storedValue, setValue];
}

export default usePersistedState;
