import classNames from 'classnames';

function PenIcon({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 18 18"
      fill="none"
      className={classNames('text-secondary', className)}
    >
      <path
        d="M16.6551 4.19872L16.655 4.19877L16.6594 4.20295C17.0007 4.52931 17.1994 4.97833 17.2115 5.4517C17.2237 5.92396 17.0492 6.38167 16.7266 6.72504L7.2105 16.2755C7.2104 16.2756 7.2103 16.2757 7.21019 16.2758C6.90636 16.578 6.50842 16.7662 6.08309 16.8089C6.08278 16.8089 6.08247 16.809 6.08216 16.809L1.685 17.2111L1.60121 17.2111L1.59976 17.2111C1.49359 17.2118 1.38832 17.1913 1.28998 17.151C1.19168 17.1107 1.10221 17.0514 1.02672 16.9762C0.942652 16.8921 0.87803 16.7903 0.837513 16.6781C0.797093 16.5662 0.78167 16.4468 0.792349 16.3282L1.24701 11.9031L1.2471 11.9022C1.28957 11.4743 1.47737 11.0743 1.77878 10.7691C1.77887 10.769 1.77896 10.7689 1.77905 10.7688L11.29 1.2235C11.6384 0.931338 12.081 0.776749 12.5348 0.788734C12.9907 0.800776 13.4265 0.980124 13.7598 1.293L16.6551 4.19872ZM5.93996 15.1836L6.0297 15.1752L6.09342 15.1115L12.0377 9.16696L12.2142 8.99051L12.038 8.81373L9.18224 5.94762L9.00624 5.77099L8.82915 5.94652L2.85312 11.8698L2.78827 11.9341L2.78011 12.025L2.49453 15.2096L2.46769 15.5089L2.76685 15.4809L5.93996 15.1836ZM13.0589 7.74421L13.2337 7.91971L13.4108 7.74643L15.5262 5.67646L15.7065 5.50003L15.5285 5.32132L12.6409 2.42336L12.4616 2.24336L12.2845 2.42562L10.222 4.54866L10.0506 4.72509L10.2242 4.89932L13.0589 7.74421Z"
        fill="currentColor"
        stroke="white"
        strokeWidth="0.5"
      />
    </svg>
  );
}

export default PenIcon;
