/* eslint-disable react-hooks/rules-of-hooks */
// Third-party
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Navigate } from 'react-router-dom';

// App
import useCompanySelectedStore from 'store/client/companySelected/useCompanySelectedStore';
import { selectCompanySelected } from 'store/client/companySelected/selectors';
import { PAGES_ROUTES } from 'constants/routes';
import { useGetSummaryData } from 'store/server/company/queries';
import getMonthStartAndEndUtil from 'utils/getMonthStartAndEnd.util';
import { useFilterBarContext } from '../../context/FilterBarContext';
import { convertToBrazilianCurrencyFormat } from 'utils/currency.utils';
import classNames from 'classnames';
import { splitArray } from 'utils/splitArray.utils';
import SectionContainer from '../sections/SectionContainer';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

interface ISalesPerPayList {
  toExport?: boolean;
}

const MAX_ITEMS_TO_EXPORT = 34;

function SalesPerPayList({ toExport }: ISalesPerPayList) {
  const companySelected = useCompanySelectedStore(selectCompanySelected);

  if (!companySelected) {
    return <Navigate to={PAGES_ROUTES.authenticated.commonInitialRoute} replace />;
  }

  const { selectedMonth, selectedYear } = useFilterBarContext();

  const { data: dashboardData } = useGetSummaryData(
    companySelected.id,
    {
      ...getMonthStartAndEndUtil(Number(selectedYear), Number(selectedMonth)),
    },
    { requestIdEnabled: false }
  );

  const length = dashboardData?.listSalesPay?.length || 0;
  const itemsToExportLength = toExport ? MAX_ITEMS_TO_EXPORT : length || MAX_ITEMS_TO_EXPORT;

  return (
    <>
      {(length === 0 ? [[]] : splitArray(dashboardData?.listSalesPay ?? [], itemsToExportLength)).map(
        (listSalesBatch, listSalesBatchIndex) => {
          const table = (
            <div
              className={classNames('w-full h-fit overflow-hidden', {
                'min-h-[10000px]':
                  toExport &&
                  listSalesBatchIndex === 0 &&
                  listSalesBatch.length === MAX_ITEMS_TO_EXPORT,
              })}
              key={`sales-list-${listSalesBatchIndex}`}
            >
              <div className="overflow-auto">
                <table className={classNames('w-full text-lg text-left text-black')}>
                  <thead
                    className={classNames(
                      'font-inter text-[14px] text-primary font-bold uppercase bg-gray-100 w-full'
                    )}
                  >
                    <tr>
                      <th className="py-[4px] pl-[10px]">Forma de Recebimento Real</th>
                      <th className="py-[4px] whitespace-nowrap text-right pr-2">Recebido</th>
                      
                    </tr>
                  </thead>
                  <tbody>
                    {listSalesBatch.map((sale, saleIndex) => (
                      <tr
                        key={`sales-list-${saleIndex}`}
                        className={classNames(
                          'bg-white border-b hover:bg-gray-100 w-full text-base h-[25px]'
                        )}
                      >
                        <td className="pl-[10px] align-middle">{sale.paymethod}</td>
                        <td className="text-right align-middle pr-2">
                          {convertToBrazilianCurrencyFormat(sale.totalpay ?? 0)}
                        </td>
                        
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr
                      className={classNames(
                        'font-inter text-[14px] text-primary font-bold uppercase bg-gray-100 w-full'
                      )}
                    >
                      <th className="py-[4px] pl-[10px] pr-[8px] whitespace-nowrap"></th>
                      <th className="py-[4px] text-right whitespace-nowrap pr-2">
                        Total recebido:{' '}
                        {convertToBrazilianCurrencyFormat(
                          `${dashboardData?.listSalesPay.reduce((totalSaleAcc, sale) => {
                            totalSaleAcc += Number(sale.totalpay);

                            return totalSaleAcc;
                          }, 0)}`
                        )}
                      </th>
                      
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          );

          if (toExport && listSalesBatchIndex > 0) {
            return (
              <SectionContainer key={`sales-list-container-${listSalesBatchIndex}`}>
                {table}
              </SectionContainer>
            );
          }

          return table;
        }
      )}
    </>
  );
}

export default SalesPerPayList;
