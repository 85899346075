// Third parties
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

// App

function RegisterOptionSkeletonLoading() {
  /*
  **** Component organization ****

   └── Declaration of generic hooks (e.g., useNavigate)
   └── State declaration
   └── Side effects (e.g., useEffect)
   └── Memoization (e.g., useMemo)
   └── Handlers (e.g., useCallback)
   └── JSX
   */

  return (
    <SkeletonTheme>
      <div className="text-white mt-[15px]">
        <Skeleton width={180} height={25} className="mx-6 flex gap-2 items-center  h-[40px]" />
        <ul className="list-disc p-0 pt-2 ml-8">
          <li className="px-6 flex gap-2 items-center  h-[40px]">
            <Skeleton width={160} height={25} />
          </li>
          <li className="px-6 flex gap-2 items-center  h-[40px]">
            <Skeleton width={160} height={25} />
          </li>
          <li className="px-6 flex gap-2 items-center  h-[40px]">
            <Skeleton width={160} height={25} />
          </li>
          <li className="px-6 flex gap-2 items-center  h-[40px]">
            <Skeleton width={160} height={25} />
          </li>
          <li className="px-6 flex gap-2 items-center  h-[40px]">
            <Skeleton width={160} height={25} />
          </li>
          <li className="px-6 flex gap-2 items-center  h-[40px]">
            <Skeleton width={160} height={25} />
          </li>
        </ul>
      </div>
    </SkeletonTheme>
  );
}

export default RegisterOptionSkeletonLoading;
