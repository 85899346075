import classNames from 'classnames';

function FileXLSIcon({ className }: { className?: string }) {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames('text-white', className)}
    >
      <path
        d="M11 1V5C11 5.26522 11.1054 5.51957 11.2929 5.70711C11.4804 5.89464 11.7348 6 12 6H16"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 10V3C2 2.46957 2.21071 1.96086 2.58579 1.58579C2.96086 1.21071 3.46957 1 4 1H11L16 6V10M1 13L5 19M1 19L5 13M14 18.25C14 18.664 14.336 19 14.75 19H16C16.2652 19 16.5196 18.8946 16.7071 18.7071C16.8946 18.5196 17 18.2652 17 18V17C17 16.7348 16.8946 16.4804 16.7071 16.2929C16.5196 16.1054 16.2652 16 16 16H15C14.7348 16 14.4804 15.8946 14.2929 15.7071C14.1054 15.5196 14 15.2652 14 15V14C14 13.7348 14.1054 13.4804 14.2929 13.2929C14.4804 13.1054 14.7348 13 15 13H16.25C16.4489 13 16.6397 13.079 16.7803 13.2197C16.921 13.3603 17 13.5511 17 13.75M8 13V19H11"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default FileXLSIcon;
