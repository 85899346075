import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';

class DateHelper {
  private locale: string;

  constructor(locale = 'pt-br') {
    this.locale = locale;   
  }

  getMonthName(month: number): string {   
    return dayjs().month(month).locale(this.locale).format('MMMM');
  }

  setLocale(newLocale: string): void {
    this.locale = newLocale;
   }
}

export { DateHelper };