/* eslint-disable react-hooks/rules-of-hooks */
// Third-party
import { Navigate } from 'react-router-dom';
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// App
import { formSchema } from './schemas';
import { InputText, Select } from 'components';
import { AddUserIcon, CheapIcon } from 'assets/icons';
import { ICreateSalePayload, saleTypeEnum } from 'interfaces/saleTypes';
import useNotificationStore from 'store/client/notification/useNotificationStore';
import { selectAddNotification } from 'store/client/notification/selectors';
import useLoadingStore from 'store/client/loading/useLoadingStore';
import { selectLoading, selectSetLoading } from 'store/client/loading/selectors';
import { PAGES_ROUTES } from 'constants/routes';
import { useGetCompanyGroupList } from 'store/server/company/queries';
import classNames from 'classnames';
import Skeleton from 'react-loading-skeleton';
import useCompanySelectedStore from 'store/client/companySelected/useCompanySelectedStore';
import { selectCompanySelected } from 'store/client/companySelected/selectors';
import InputTextArea from 'components/InputTextArea';
import { useGetClientList } from 'store/server/client/queries';
import { cpfCnpjMask, monetaryMask, phoneNumberMask, removeMonetaryMask } from 'utils/mask.utils';

import { useCreateSaleMutation } from 'store/server/company/mutations';
import normalizeJsonStringUtil from 'utils/normalizeJsonString.util';
import { IComplementService } from 'interfaces/companyTypes';
import TextAreaWithMask from './components/TextAreaWithMask';
import ReturnButton from 'components/ReturnButton';
import { dateUtils } from 'utils/formatDate';
import InputSearch, { IInputSearchProps } from 'components/InputSearch';
import { IClient } from 'interfaces/clientTypes';
import { ListItemType } from 'types/listItemType';
import {  ORDER_ATTRIBUTTES } from 'constants/orderAttributtes';
import { DEFAULT_VALUES } from 'constants/textAreaInput';
import { convertToCurrencyFormat } from 'utils/currency.utils';
import { handleChangeMonthlyInstallments } from 'utils/handlers';


const { ASC } = ORDER_ATTRIBUTTES;

const { DATE_TIME_DISPLAY_FORMAT } = dateUtils.constants;

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/Sao_Paulo');

interface ICreateSaleComplementTyped
  extends Omit<ICreateSalePayload<{ [key: string]: string }>, 'amount' | 'clientId'> {
  amount: string;
  clientId?: number;
}

const parameterError: { [key: string]: string } = {
  clientId: 'Cliente',
  paymentMethodId: 'Condição de Recebimento',  
  serviceId: 'Serviço',
  description: 'Descrição',
  amount: 'Valor Unitário',
  quantity: 'Quantidade',
  totalAmount: 'Valor Total',
  dataEvent: 'Data',
  complement: 'Campos complementares',
};

function Create() {
  /*
  **** Component organization ****

   └── Declaration of generic hooks (e.g., useNavigate)
   └── State declaration
   └── Side effects (e.g., useEffect)
   └── Memoization (e.g., useMemo)
   └── Handlers (e.g., useCallback)
   └── JSX
   */

  // └── Declaration of generic hooks (e.g., useNavigate)
  const {
    register,
    handleSubmit,
    formState: { isValid },
    reset,
    watch,
    control,
    setValue,
  } = useForm<ICreateSaleComplementTyped>({
    resolver: yupResolver(formSchema),
  });

  const isLoading = useLoadingStore(selectLoading);
  const setLoading = useLoadingStore(selectSetLoading);
  const addNotification = useNotificationStore(selectAddNotification);

  const companySelected = useCompanySelectedStore(selectCompanySelected);

  // Validates whether a company is currently selected
  if (!companySelected) return <Navigate to={'/'} replace />;

  const [clientNameToSearchSubmitted, setClientNameToSearchSubmitted] = useState<string>('');

  const {
    data: clientListData,
    isLoading: clientListIsLoading,
    isRefetching: clientListIsRefetching,
  } = useGetClientList(companySelected.id, {
    start: 0,
    size: 1000,
    field: 'client.name',
    order: ASC,
    filter: clientNameToSearchSubmitted ? clientNameToSearchSubmitted.trim() : null,
    status: 'ACTIVED',
  });

  // Company types list
  const { data: serviceList, isLoading: serviceListIsLoading } = useGetCompanyGroupList({
    companyId: companySelected.id,
    groupId: '5',
    status: false,
    field: 'description',
    order: ASC,
  });

  const { data: paymentMethodList, isLoading: paymentMethodListIsLoading } = useGetCompanyGroupList(
    {
      companyId: companySelected.id,
      groupId: '7',
      status: true,
      field: 'description',
      order: ASC,
    }
  );
  
  const createSaleMutation = useCreateSaleMutation(companySelected.id);
  // └── State declaration
  const [totalAmount, setTotalAmount] = useState('');

  const watchServiceId = watch('serviceId');
  const watchPaymentMethodId = watch('paymentMethodId');  

  const watchAmount = watch('amount');
  const watchQuantity = watch('quantity');
  const watchSaleType = watch('saleType');

  // └── Side effects (e.g., useEffect)
  useEffect(() => {
    if (watchAmount && removeMonetaryMask(watchAmount) > 0 && watchQuantity > 0) {
      setTotalAmount( monetaryMask(removeMonetaryMask(watchAmount) * watchQuantity));
    } else {
      setTotalAmount('R$ 0,00');
    }
  }, [watchAmount, watchQuantity]);

  const handleChangeMonetaryValue =
    (onChange: (value: string) => void) => (event: ChangeEvent<HTMLInputElement>) => {
      onChange(monetaryMask(removeMonetaryMask(event.target.value)));
    };

  const cleanClientSeach = useCallback(() => {
    setClientNameToSearchSubmitted('');
  }, []);

  // └── Handlers (e.g., useCallback)
  const onSubmit = useCallback(
    async ({
      clientId,
      paymentMethodId,      
      serviceId,
      description,
      amount,
      quantity,
      dataEvent,
      complement,
      saleType,
      name,
      cpf,
      phone,
    }: ICreateSaleComplementTyped) => {
      setLoading(true);

      try {
        console.log('data', dataEvent);
        console.log('data ISO', dayjs(dataEvent).format());
        
        
        await createSaleMutation.mutateAsync({
          clientId: clientSale ? Number(clientId) : 0,
          paymentMethodId: Number(paymentMethodId),
          serviceId: Number(serviceId),
          description,
          amount: removeMonetaryMask(convertToCurrencyFormat(amount).toString()),
          quantity: Number(quantity),
          totalAmount: removeMonetaryMask(convertToCurrencyFormat(totalAmount)),
          dataEvent: dayjs(dataEvent).format(),
          complement: JSON.stringify(complement),
          saleType: saleType,
          name: clientSale ? '' : name,
          cpf: clientSale ? '' : cpf,
          phone: clientSale ? '' : phone,
        });

        addNotification({
          type: 'success',
          message: 'Venda registrada com sucesso!',
        });
        reset();
        cleanClientSeach();
      } catch (error) {
        if (error instanceof AxiosError) {
          if (
            error.response?.status &&
            error.response?.status >= 400 &&
            error.response?.status < 500 &&
            error.response.data.message &&
            error.response.data.message.includes('Mandatory parameters: ')
          ) {
            addNotification({
              type: 'error',
              message: `Campo ${
                parameterError[error.response.data.message.replace('Mandatory parameters: ', '')]
              } faltando.`,
            });
            return;
          }

          addNotification({
            type: 'error',
            message: 'Erro ao registrar venda. Tente novamente mais tarde.',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [setLoading, createSaleMutation, totalAmount, addNotification, reset, cleanClientSeach]
  );

  const handleMaskValue = useCallback(
    <TFieldName extends keyof ICreateSaleComplementTyped>(
      field: ControllerRenderProps<ICreateSaleComplementTyped, TFieldName>,
      maskCallback: (value: string) => string
    ) => {
      return (event: ChangeEvent<HTMLInputElement>) => {
        event.target.value = maskCallback(event.target.value);
        field.onChange(event);
      };
    },
    []
  );

  const handleOnSearchClient = useCallback((searchText: string) => {
    if (!clientNameToSearchSubmitted || clientNameToSearchSubmitted !== searchText) {
      setClientNameToSearchSubmitted(searchText);
    }
  }, []);

  const handleOnSelectedClient = useCallback(
    (selectedClient: ListItemType<IInputSearchProps<IClient>['listToSelect']> | undefined) => {
      if (selectedClient) {
        return setValue('clientId', selectedClient.id, { shouldValidate: true });
      }
      setValue('clientId', undefined, { shouldValidate: true });
    },
    [setValue]
  );

  const clientSale = watchSaleType === saleTypeEnum.CLIENT;
  return (
    <div className="w-full min-h-screen h-full flex flex-col justify-start gap-y-[19px] items-start pt-[23px] md:pt-[42px] p-[27px] md:p-8">
      <div className="flex flex-col gap-2">
        <h1 className="flex items-center gap-2.5 text-2xl font-semibold text-[#595959]">
          <AddUserIcon className="w-[22px] h-[24px] !text-secondary" /> Registar venda
        </h1>
        <h3 className="text-xs text-[#374151]">Campos obrigatórios *</h3>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        className="self-center w-full max-w-[800px] gap-x-[10px] xl:gap-x-[20px] gap-y-10 flex flex-col justify-center"
      >
        <div className="md:grid md:grid-cols-12 gap-x-[10px] xl:gap-x-[20px] gap-y-2">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Controller
              name="dataEvent"
              control={control}
              defaultValue={dayjs()}
              render={({ field }) => {
                return (
                  <label
                    htmlFor="dataEvent"
                    className={classNames(
                      'font-inter text-[#374151] text-sm font-medium align-middle flex flex-col justify-start w-full mt-[10px] truncate !overflow-visible relative cursor-pointer gap-y-1  md:max-w-[250px] row-start-1 row-end-2 col-start-1 col-end-5'
                    )}
                  >
                    Data da Venda*
                    <DateTimePicker
                      ampm={false}
                      format={DATE_TIME_DISPLAY_FORMAT}
                      onChange={field.onChange}
                      value={dayjs(field.value) || dayjs()}
                      slotProps={{
                        textField: {
                          size: 'small',
                        },
                      }}
                    />
                  </label>
                );
              }}
            />
          </LocalizationProvider>
          <Select
            labelText="Tipo de Venda*"
            cssClasses={{
              label: 'row-start-1 row-end-2 col-start-7 col-end-13 mt-[10px]',
              select: classNames({
                '!text-[#a9b1bc] h-[40px]': !watchSaleType,
              }),
            }}
            {...register('saleType')}
            placeholder="Selecione um Serviço"
            defaultValue=""
          >
            <option defaultChecked={true} value={saleTypeEnum.CLIENT} className="text-textcolor">
              {saleTypeEnum.CLIENT}
            </option>
            <option value={saleTypeEnum.PRIVATE} className="text-textcolor">
              {saleTypeEnum.PRIVATE}
            </option>
          </Select>

          {clientSale ? (
            clientListIsLoading ? (
              <Skeleton
                height={44}
                baseColor="#f3f3f3"
                highlightColor="white"
                containerClassName="flex h-[42px] mt-[26px] row-start-2 row-end- col-start-1 col-end-7"
                className="!rounded-lg"
              />
            ) : (
              <div className="row-start-2 row-end-3 col-start-1 col-end-7 ">
                <InputSearch<IClient>
                  listToSelect={
                    clientListData?.data.map((client) => ({
                      title: client.name,
                      subtitle: client.email,
                      ...client,
                    })) ?? []
                  }
                  emptyListMessage="Nenhum cliente encontrado."
                  isLoading={clientListIsLoading || clientListIsRefetching}
                  value={clientNameToSearchSubmitted}
                  onSearch={handleOnSearchClient}
                  onSelect={handleOnSelectedClient}
                  inputOptions={{
                    labelText: 'Cliente*',
                    placeholderText: 'Selecione um cliente',
                  }}
                />
              </div>
            )
          ) : (
            <>
              <InputText
                labelText="Nome do Cliente*"
                cssClasses={{ label: `row-start-2 row-end-3 col-start-1 col-end-7 mt-[10px]` }}
                type="text"
                {...register('name')}
              />
              <Controller
                name="cpf"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <InputText
                    labelText="CPF"
                    cssClasses={{ label: `row-start-2 row-end-3 col-start-7 col-end-13 mt-[10px]` }}
                    {...field}
                    type="text"
                    placeholder="111.222.333-44"
                    onChange={handleMaskValue<'cpf'>(field, cpfCnpjMask)}
                    maxLength={14}
                    inputMode="numeric"
                  />
                )}
              ></Controller>
              <Controller
                name="phone"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <InputText
                    {...field}
                    labelText="Telefone*"
                    cssClasses={{ label: `row-start-3 row-end-4 col-start-1 col-end-7 mt-[10px]` }}
                    type="text"
                    placeholder="(99) 99999-9999"
                    onChange={handleMaskValue<'phone'>(field, phoneNumberMask)}
                  />
                )}
              />
            </>
          )}
          {serviceListIsLoading ? (
            <Skeleton
              height={44}
              baseColor="#f3f3f3"
              highlightColor="white"
              containerClassName="flex h-[42px] mt-[26px] row-start-2 row-end-3 col-start-7 col-end-13"
              className="!rounded-lg"
            />
          ) : (
            <Select
              labelText="Serviço*"
              cssClasses={{
                label: ` ${
                  clientSale ? 'row-start-2 row-end-3 ' : 'row-start-3 row-end-4'
                } col-start-7 col-end-13`,
                select: classNames({
                  '!text-[#a9b1bc] h-[44px]': !watchServiceId,
                }),
              }}
              {...register('serviceId')}
              placeholder="Selecione um Serviço"
              defaultValue=""
            >
              <option
                defaultChecked={true}
                value=""
                className="font-normal font-inter text-sm leading-5 !text-[#a9b1bc]"
              >
                Selecione um Serviço
              </option>
              {serviceList?.data.map((service, serviceIndex) => (
                <option
                  value={service.id}
                  className="text-textcolor"
                  key={`service-${service.description}-${service.id}-option-${serviceIndex}`}
                >
                  {service.description ?? '-'}
                </option>
              ))}
            </Select>
          )}
          {paymentMethodListIsLoading ? (
            <Skeleton
              height={44}
              baseColor="#f3f3f3"
              highlightColor="white"
              containerClassName={`flex h-[42px] mt-[26px] ${
                clientSale ? 'row-start-3 row-end-4' : 'row-start-4 row-end-5'
              } col-start-1 col-end-7`}
              className="!rounded-lg"
            />
          ) : (
            <Select
              labelText="Condição de Recebimento*"
              cssClasses={{
                label: `${
                  clientSale ? 'row-start-3 row-end-4' : 'row-start-4 row-end-5'
                } col-start-1 col-end-7 mt-[10px]`,
                select: classNames({
                  '!text-[#a9b1bc] h-[44px]': !watchPaymentMethodId,
                }),
              }}
              {...register('paymentMethodId')}
              placeholder="Selecione uma Condição de recebimento"
              defaultValue=""
            >
              <option
                defaultChecked={true}
                value=""
                className="font-normal font-inter text-sm leading-5 !text-[#a9b1bc]"
              >
                Selecione uma Condição de recebimento
              </option>
              {paymentMethodList?.data.map((paymentMethod, paymentMethodIndex) => (
                <option
                  value={paymentMethod.id}
                  className="text-textcolor"
                  key={`payment-method-${paymentMethod.description}-${paymentMethod.id}-option-${paymentMethodIndex}`}
                >
                  {paymentMethod.description ?? '-'}
                </option>
              ))}
            </Select>
          )}
          <Controller
            name="amount"
            control={control}
            defaultValue="R$ 0,00"
            render={({ field }) => (
              <InputText
                {...field}
                labelText="Valor Unitário*"
                cssClasses={{
                  label: ` ${
                    clientSale ? 'row-start-4 row-end-5' : 'row-start-5 row-end-6'
                  } col-start-1 col-end-5 mt-[10px]`,
                }}
                type="text"
                onChange={handleChangeMonthlyInstallments(field.onChange)}
                inputMode="numeric"
              />
            )}
          />
          <InputText
            labelText="Quantidade*"
            cssClasses={{
              label: `${
                clientSale ? 'row-start-4 row-end-5' : 'row-start-5 row-end-6'
              } col-start-5 col-end-9 mt-[10px]`,
            }}
            type="number"
            {...register('quantity')}
            min="1"
            defaultValue={1}
            inputMode="numeric"
          />
          <InputText
            labelText="Valor total*"
            cssClasses={{
              label: `${
                clientSale ? 'row-start-4 row-end-5' : 'row-start-5 row-end-6'
              } col-start-9 col-end-13 mt-[10px]`,
              input: '!outline-none read-only:!border-[#D1D5DB] read-only:!text-[#37415]',
            }}
            type="text"
            readOnly
            value={totalAmount}
          />
          <Controller
            name='description'
            control={control}
            defaultValue={""}
            render={({field}) => (
              <InputTextArea
                {...field}
                labelText="Descrição"
                cssClasses={{
                  label: `${
                    clientSale ? 'row-start-5 row-end-6' : 'row-start-6 row-end-7'
                  } col-start-1 col-end-13 mt-[10px]`,
                }}
                maxLength={DEFAULT_VALUES.MAX_250_CHAR}    
              />
            )}
          />
          <div
            className={`${
              clientSale ? 'row-start-6 row-end-7' : 'row-start-7 row-end-8'
            } col-start-1 col-end-13 flex flex-row flex-wrap justify-between align-baseline mt-[10px]`}
          >
            {companySelected?.companyType?.complementService &&
              JSON.parse(
                normalizeJsonStringUtil(companySelected?.companyType?.complementService)
              ).map((complement: IComplementService, complementIndex: number) => (
                <Controller
                  key={`complement-text-area-${complement.name}-${complementIndex}`}
                  name={`complement.${complement.name}`}
                  control={control}
                  render={({ field }) => (
                    <TextAreaWithMask
                      mask={complement.mask}
                      labelText={complement.label}
                      maxLength={complement.size}
                      {...field}
                    />
                  )}
                />
              ))}
          </div>
        </div>
        <div className="flex justify-between md:justify-end items-center md:gap-x-7 lg:gap-x-11 md:row-start-[9] !w-full md:col-start-8 md:col-end-13 mt-[11px] md:mt-[30px]">
          <ReturnButton returnTo={PAGES_ROUTES.authenticated.transactions.sale.list} />
          <button
            type="submit"
            className="bg-primary text-xs font-bold leading-4 disabled:bg-[#D1D5DB] text-buttontextcolor py-2 pl-4 pr-[18px] rounded-lg flex justify-center items-center h-[42px] max-w-[147px] tracking-[0.6px] gap-x-2"
            disabled={
              !isValid ||
              isLoading ||
              clientListIsLoading ||
              serviceListIsLoading ||
              paymentMethodListIsLoading 
            }
          >
            CADASTRAR
            <CheapIcon className="!text-buttontextcolor" />
          </button>
        </div>
      </form>
    </div>
  );
}

export default Create;
