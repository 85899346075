import classNames from 'classnames';

function ClosedEyeIcon({ className }: { className?: string }) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames('text-secondary', className)}
    >
      <path
        d="M2.5 10C2.5 10 6 14 12.5 14C19 14 22.5 10 22.5 10M4.5 11.645L2.5 14M22.5 14L20.504 11.648M9.414 13.68L8.5 16.5M15.563 13.688L16.5 16.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ClosedEyeIcon;
