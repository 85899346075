//Thrid-pary
// App
import {
  ICompany,
  ICompanyGroup,
  ICompanyGroupListParams,
  ICompanyListParams,
  ICompanyPendingSalesListParams,
  ICompanySalesListParams,
  ICompanyType,
  ICreateCompanyGroupPayload,
  ICreateCompanyPayload,
  ICreateExpensePayload,
  ICreateManyWriteOff,
  ICreatePaymentPayload,
  ICreateWriteOffPaymentPayload,
  IExpense,
  IExpenseListParams,
  IExpenseResponseData,
  IListElementExpense,
  IPaymentData,
  IUpdateCompanyGroupTypePayload,
  IUpdateCompanyPayload,
} from 'interfaces/companyTypes';
import api from '../api';
import { routes } from '../../../constants/api';
import {
  IResponseWithData,
  IResponseWithDataListPaginated,
  IResponseWithDataListPaginatedAndResume,
} from 'interfaces/apiTypes';
import { ICreateSalePayload } from 'interfaces/saleTypes';
import { IGetDashboardData } from 'interfaces/dashboardType';
import { IGetSummaryData } from 'interfaces/summaryType';
import { AxiosRequestConfig } from 'axios';
import { ILog, ILogDetailsParams, ILogListParams, ILogRecord } from 'interfaces/logTypes';

export async function createCompany(data: ICreateCompanyPayload): Promise<any> {
  const result = await api.auth.post<{ message: string }>(routes.v1.company.create, {
    ...data,
    accessLevel: 2,
  });

  return result.data;
}

export async function getCompanyById(companyId: number): Promise<ICompany> {
  const { data } = await api.auth.get<IResponseWithData<ICompany>>(routes.v1.company.getById, {
    params: {
      companyId: companyId.toString(),
    },
  });

  return data.response;
}

export async function updateCompany(
  companyId: number,
  data: IUpdateCompanyPayload
  //TODO: tipar ICreateCompanyPayload valor de retorno da função
): Promise<IUpdateCompanyPayload> {
  const result = await api.auth.patch<IResponseWithData<IUpdateCompanyPayload>>(
    routes.v1.company.updateById,
    data,
    {
      params: {
        companyId: companyId.toString(),
      },
    }
  );

  return result.data.response;
}

export async function listCompany({
  start,
  size,
  order,
  field,
  filter,
}: ICompanyListParams): Promise<IResponseWithDataListPaginated<ICompany>['response']> {
  const { data, status } = await api.auth.get<IResponseWithDataListPaginated<ICompany>>(
    routes.v1.company.list,
    {
      params: {
        start,
        size,
        order,
        field,
        filter,
      },
    }
  );

  if (status === 204) {
    return {
      data: [],
      pageNumber: 0,
      nextPage: 0,
      lastPage: 0,
      totalPages: 0,
      total: 0,
      pageSize: 0,
    };
  }

  return data.response;
}

export async function listGroup({
  companyId,
  groupId,
  status,
  order,
  field,
}: ICompanyGroupListParams): Promise<IResponseWithDataListPaginated<ICompanyGroup>['response']> {
  const { data, status: responseStatus } = await api.auth.get<
    IResponseWithDataListPaginated<ICompanyGroup>
  >(
    routes.v1.company.listGroup
      .replace(':groupId', groupId.toString())
      .replace(':companyId', companyId.toString()),
    {
      params: {
        size: 100,
        status,
        order,
        field,
      },
    }
  );

  if (responseStatus === 204) {
    return {
      data: [],
      pageNumber: 0,
      nextPage: 0,
      lastPage: 0,
      totalPages: 0,
      total: 0,
      pageSize: 0,
    };
  }

  return data.response;
}

export async function createCompanyGroup(
  companyId: number,
  typeId: string,
  data: ICreateCompanyGroupPayload
): Promise<void> {
  await api.auth.post(
    routes.v1.company.group.create
      .replace(':companyId', companyId.toString())
      .replace(':typeId', typeId),
    {
      ...data,
    }
  );
}

export async function updateCompanyGroupType(
  groupId: string,
  companyId: number,
  typeGroupId: number,
  data: IUpdateCompanyGroupTypePayload
): Promise<void> {
  await api.auth.patch(
    routes.v1.company.group.updateType
      .replace(':groupId', groupId.toString())
      .replace(':companyId', companyId.toString())
      .replace(':typeGroupId', typeGroupId.toString()),
    {
      ...data,
    }
  );
}

export async function listTypes(): Promise<
  IResponseWithDataListPaginated<ICompanyType>['response']
> {
  const { data, status } = await api.auth.get<IResponseWithDataListPaginated<[ICompanyType]>>(
    routes.v1.company.type.list
  );

  if (status === 204) {
    return {
      data: [],
      pageNumber: 0,
      nextPage: 0,
      lastPage: 0,
      totalPages: 0,
      total: 0,
      pageSize: 0,
    };
  }

  return { ...data.response, data: data.response.data[0] };
}

export async function listExpensesTypes(
  companyId: number,
  groupId: string
): Promise<IListElementExpense[] | null> {
  try {
    const { data, status } = await api.auth.get<IResponseWithData<IExpenseResponseData>>(
      routes.v1.company.group.getCategoryList
        .replace(':companyId', companyId.toString())
        .replace(':groupId', groupId.toString()),
      {
        params: {
          status: true,
          size:1000
        },
      }
    );

    if (status === 204) {
      return null;
    }

    return data.response.data;
  } catch (error) {
    return null;
  }
}

export async function listCompayLogs({
  start,
  size,
  order,
  field,
  filter,
  dateInit,
  dateFinal,
  companyId,
}: ILogListParams): Promise<IResponseWithDataListPaginated<ILog>['response']> {
  const params = {
    start,
    size,
    order,
    ...(field ? { field } : {}),
    ...(filter ? { searchBy: filter } : {}),
    ...(dateInit ? { dateInit: dateInit } : {}),
    ...(dateFinal ? { dateFinal: dateFinal } : {}),
  };

  const { data } = await api.auth.get<IResponseWithDataListPaginated<ILog>>(
    routes.v1.company.logList.replace(':companyId', companyId.toString()),
    { params }
  );

  return data.response;
}

export async function listLogsDetails({
  logId,
}: ILogDetailsParams): Promise<IResponseWithData<ILogRecord>['response']> {
  const { data } = await api.auth.get<IResponseWithData<ILogRecord>>(
    routes.v1.company.logDetails.replace(':logId', logId.toString())
  );

  return data.response;
}

export async function listCompanyExpenses({
  start,
  size,
  order,
  field,
  description,
  dateInit,
  dateFinal,
  companyId,
}: IExpenseListParams): Promise<IResponseWithDataListPaginatedAndResume<IExpense>['response']> {
  const params = {
    start,
    size,
    order,
    field,
    ...(field ? { field } : {}),
    ...(description ? { filter: description } : {}),
    ...(dateInit ? { dateInit: dateInit } : {}),
    ...(dateFinal ? { dateFinal: dateFinal } : {}),
  };

  const { data } = await api.auth.get<IResponseWithDataListPaginatedAndResume<IExpense>>(
    routes.v1.company.expense.list.replace(':companyId', companyId.toString()),
    { params }
  );

  return data.response;
}
export async function createWriteOffPayment(
  companyId: number | string,
  expenseId: number | string,
  data: ICreateWriteOffPaymentPayload
): Promise<any> {
  const response = await api.auth.post<IResponseWithData<any>>(
    routes.v1.company.expense.writeOff
      .replace(':companyId', companyId.toString())
      .replace(':expenseId', expenseId.toString()),
    data
  );
  const { data: responseData, status } = response;

  if (status !== 204) {
    throw Error('Erro ao cadastrar uma baixa');
  }

  return responseData;
}

export async function createExpense(
  companyId: number | string,
  data: ICreateExpensePayload
): Promise<any> {
  const response = await api.auth.post<IResponseWithData<any>>(
    routes.v1.company.expense.create.replace(':companyId', companyId.toString()),
    data
  );
  const { data: responseData, status } = response;

  if (status !== 204) {
    throw Error('Erro ao cadastrar uma despesa');
  }

  return responseData;
}

export async function removeExpense(
  companyId: number | string,
  expenseId: number | string,
  comment?: string
): Promise<any> {
  const config: AxiosRequestConfig = {
    data: { comment },
  };

  const { data: responseData, status } = await api.auth.delete<IResponseWithData<any>>(
    routes.v1.company.expense.remove
      .replace(':companyId', companyId.toString())
      .replace(':expenseId', expenseId.toString()),
    config
  );

  if (status !== 204) {
    throw Error('Erro ao excluir uma despesa');
  }
  return responseData;
}

export async function createSale(
  companyId: number | string,
  data: ICreateSalePayload
): Promise<any> {
  const response = await api.auth.post<IResponseWithData<any>>(
    routes.v1.company.sale.create.replace(':companyId', companyId.toString()),
    data
  );
  const { data: responseData, status } = response;

  if (status !== 204) {
    throw Error('Erro ao cadastrar uma despesa');
  }

  return responseData;
}

export async function removeSale(
  companyId: number | string,
  saleId: number | string,
  comment: string
): Promise<any> {
  const config: AxiosRequestConfig = {
    data: { comment },
  };
  const { data: responseData, status } = await api.auth.delete<IResponseWithData<any>>(
    routes.v1.company.sale.remove
      .replace(':companyId', companyId.toString())
      .replace(':saleId', saleId.toString()),
    config
  );

  return responseData;
}

export async function listCompanyPendingSales({
  start,
  size,
  order,
  field,
  filter,
  companyId,
  clientId = 0,
  dateInit,
  dateFinal,
}: ICompanyPendingSalesListParams): Promise<
  IResponseWithDataListPaginatedAndResume<any>['response']
> {
  const { data, status } = await api.auth.get<IResponseWithDataListPaginatedAndResume<any>>(
    routes.v1.company.pendingSales.list
      .replace(':companyId', companyId.toString())
      .replace(':clientId', (clientId ?? 0).toString()),
    {
      params: {
        start,
        size,
        order,
        field,
        filter,
        ...(dateInit ? { dateInit: dateInit } : {}),
        ...(dateFinal ? { dateFinal: dateFinal } : {}),
      },
    }
  );

  if (status === 204) {
    return {
      result: {
        data: [],
        pageNumber: 0,
        nextPage: 0,
        lastPage: 0,
        totalPages: 0,
        total: 0,
        pageSize: 0,
      },
    };
  }
  return data.response;
}

export async function listCompanySales({
  start,
  size,
  order,
  field,
  filter,
  companyId,
  dateInit,
  dateFinal,
}: ICompanySalesListParams): Promise<IResponseWithDataListPaginatedAndResume<any>['response']> {
  const { data, status } = await api.auth.get<IResponseWithDataListPaginatedAndResume<any>>(
    routes.v1.company.sale.list.replace(':companyId', companyId.toString()),
    {
      params: {
        start,
        size,
        order,
        field,
        filter,
        ...(dateInit ? { dateInit: dateInit } : {}),
        ...(dateFinal ? { dateFinal: dateFinal } : {}),
      },
    }
  );

  if (status === 204) {
    return {
      result: {
        data: [],
        pageNumber: 0,
        nextPage: 0,
        lastPage: 0,
        totalPages: 0,
        total: 0,
        pageSize: 0,
      },
    };
  }
  return data.response;
}

export async function listCompanyPayments({
  start,
  size,
  order,
  field,
  filter,
  companyId,
  dateInit,
  dateFinal,
}: ICompanySalesListParams): Promise<IResponseWithDataListPaginated<IPaymentData>['response']> {
  const { data, status } = await api.auth.get<IResponseWithDataListPaginated<IPaymentData>>(
    routes.v1.company.payments.list.replace(':companyId', companyId.toString()),
    {
      params: {
        start,
        size,
        order,
        field,
        filter,
        ...(dateInit ? { dateInit: dateInit } : {}),
        ...(dateFinal ? { dateFinal: dateFinal } : {}),
      },
    }
  );

  if (status === 204) {
    return {
      data: [],
      pageNumber: 0,
      nextPage: 0,
      lastPage: 0,
      totalPages: 0,
      total: 0,
      pageSize: 0,
    };
  }
  return data.response;
}

export async function createPayment(
  companyId: number | string,
  saleId: number | string,
  data: ICreatePaymentPayload
): Promise<any> {
  const response = await api.auth.post<IResponseWithData<any>>(
    routes.v1.company.sales.createPayment
      .replace(':companyId', companyId.toString())
      .replace(':saleId', saleId.toString()),
    data
  );
  const { data: responseData, status } = response;

  if (status !== 204) {
    throw Error('Erro ao cadastrar um pagamento');
  }

  return responseData;
}

export async function createManyWriteOff(
  companyId: number | string,
  data: ICreateManyWriteOff
): Promise<any> {
  const response = await api.auth.post<IResponseWithData<any>>(
    routes.v1.company.pendingSales.writeOff.replace(':companyId', companyId.toString()),
    data
  );
  const { data: responseData, status } = response;

  if (status !== 204) {
    throw Error('Erro ao cadastrar baixas');
  }

  return responseData;
}

export async function getSaleById(
  companyId: number | string,
  saleId: number | string
): Promise<any> {
  const response = await api.auth.get<IResponseWithData<any>>(
    routes.v1.company.sales.getByID
      .replace(':companyId', companyId.toString())
      .replace(':saleId', saleId.toString())
  );
  const { data: responseData } = response;

  return responseData.response;
}
export async function getExpenseById(
  companyId: number | string,
  expenseId: number | string
): Promise<any> {
  const response = await api.auth.get<IResponseWithData<any>>(
    routes.v1.company.expense.getById
      .replace(':companyId', companyId.toString())
      .replace(':expenseId', expenseId.toString())
  );
  const { data: responseData } = response;

  return responseData.response;
}

export async function getDashboardData(
  companyId: number | string,
  params: { initialDate: string; finalDate: string; clientId?: number }
): Promise<IGetDashboardData> {
  const response = await api.auth.get<IResponseWithData<IGetDashboardData>>(
    routes.v1.company.dashboardData.replace(':companyId', companyId.toString()),
    {
      params: {
        init: params.initialDate,
        final: params.finalDate,
        clientId: params.clientId,
      },
    }
  );
  const { data: responseData } = response;

  return responseData.response;
}

export async function getSummaryData(
  companyId: number | string,
  params: { initialDate: string; finalDate: string }
): Promise<IGetSummaryData> {
  const response = await api.auth.get<IResponseWithData<IGetSummaryData>>(
    routes.v1.company.summaryData.replace(':companyId', companyId.toString()),
    {
      params: {
        init: params.initialDate,
        final: params.finalDate,
      },
    }
  );
  const { data: responseData } = response;

  return responseData.response;
}
