import { CheapIcon, LoadingSpinIcon, SearchIcon } from 'assets/icons';
import classNames from 'classnames';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

interface IRegisterDataButton {
  handleClick: () => void;
  showSkeletonLoading?: boolean;
}

function RegisterDataButton({
  handleClick,
  showSkeletonLoading = false,
}: IRegisterDataButton) {
  return (
    <>
      {showSkeletonLoading ? (
        <Skeleton
          width={147}
          height={42}
          baseColor="white"
          highlightColor="#f3f3f3"
          containerClassName="flex h-[42px] w-full"
          className="!rounded-lg"
        />
      ) : (
        <button
          type="button"
          className="bg-primary text-xs font-bold leading-4 disabled:bg-[#D1D5DB] text-buttontextcolor py-2 pl-4 pr-[18px] rounded-lg flex justify-center items-center h-[42px] w-full xl:max-w-[147px] tracking-[0.6px]"
          onClick={handleClick}
        >
          CADASTRAR
          <CheapIcon className="ml-4 w-[19px] h-[22px] !text-buttontextcolor" />
        </button>
      )}
    </>
  );
}

export default RegisterDataButton;
