// Third-party

// App
import { IResponseWithData } from 'interfaces/apiTypes';
import api from '../api';
import { ISigninResult } from './types';
import { routes } from '../../../constants/api';
import { IGetUserStatus } from 'interfaces/userTypes';

export async function signin(authenticator: string): Promise<ISigninResult | null> {
  const formData = new FormData();
  formData.append('authenticator', authenticator);

  const { data, status } = await api.post<IResponseWithData<ISigninResult>>(
    routes.v1.user.auth.signin,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );

  if (status === 204) {
    return null;
  }
  return data.response;
}

export async function postRefreshToken(
  refreshToken: string
): Promise<IResponseWithData<ISigninResult>> {
  const result = await api.post<IResponseWithData<ISigninResult>>(
    routes.v1.user.auth.refreshToken,
    {
      refreshToken: refreshToken,
    }
  );

  return result.data;
}

export async function getUserStatus(email: string): Promise<IGetUserStatus | null> {
  const { data, status } = await api.get<IResponseWithData<IGetUserStatus>>(
    routes.v1.user.auth.checkStatus.replace(':email', email)
  );

  if (status === 204) {
    return null;
  }

  return data.response;
}
