// Third-party
import { AxiosError } from 'axios';

// App
import api from '../api';
import {
  ICreateUserRequest,
  IChangePasswordRequest,
  IUser,
  IUserListParams,
  IUpdateUserPayload,
} from 'interfaces/userTypes';
import { IResponseWithData, IResponseWithDataListPaginated } from 'interfaces/apiTypes';
import { routes } from '../../../constants/api';

const userAcessLevelLabels = { 1: 'Administrador', 2: 'Unidade' };
const statusLabels = { 1: 'Inativo', 2: 'Aguardando', 3: 'Ativo' };

export async function createUser(data: ICreateUserRequest): Promise<{ message: string }> {
  const result = await api.auth.post<{ message: string }>(routes.v1.user.create, data);

  return result.data;
}

/**
 * Change the user password
 * @param {IChangePasswordRequest} data
 * @returns {Promise<{ message: string }>}
 */
export async function changePassword({
  email,
  currentPassword,
  newPassword,
}: IChangePasswordRequest): Promise<{ message: string }> {
  // Call the API change password endpoint
  const result = await api.auth.post(routes.v1.user.changePassword, {
    email,
    currentPassword,
    newPassword,
  });

  // Return the message from the API
  return result.data;
}

/**
 *  Send a request to the API with user email to reset the password
 * @param {{ email: string; }} data
 * @returns { message:string }
 */
export async function ForgotPasswordRequestChangePassword({
  email,
}: {
  email: string;
}): Promise<{ message: string }> {
  // Send a request to the API with user email to reset the password
  const result = await api.post(routes.v1.user.forgotPassword.requestChange, { email });

  if (result.status === 204) {
    throw new AxiosError('User not found', '204');
  }

  // Return the message from the API
  return result.data;
}

/**
 *  Send a request to the API with user email to reset the password
 * @param {{ email: string; token: string; newPassword: string; }} data
 * @returns { message:string }
 */
export async function confirmForgotPassword({
  email,
  token,
  newPassword,
}: {
  email: string;
  token: string;
  newPassword: string;
}): Promise<{ message: string }> {
  // Send a request to the API with user email to reset the password
  const result = await api.post(routes.v1.user.forgotPassword.confirmChangePassword, {
    email,
    token,
    password: newPassword,
  });

  // Return the message from the API
  return result.data;
}

/**
 * Get user data by id
 * @param {string} userId
 * @returns {Promise<IUser>}
 */
// eslint-disable-next-line import/no-unused-modules
export async function getUserById(userId: string): Promise<IUser | null> {
  // Call the API to get user data by id
  const {
    data: { response: user },
    status,
  } = await api.auth.get<IResponseWithData<IUser>>(
    routes.v1.user.getById.replace(':userId', userId)
  );

  if (status === 204) return null;

  // Return the user data
  return {
    ...user,
    accessLevelLabel: userAcessLevelLabels[user.accessLevel],
  };
}

export async function getUserByEmail(userEmail: string): Promise<IUser | null> {
  // Call the API to get user data by id
  const {
    data: { response: user },
    status,
  } = await api.auth.get<IResponseWithData<IUser>>(
    routes.v1.user.getByEmail.replace(':userEmail', userEmail)
  );

  if (status === 204) return null;

  // Return the user data
  return {
    ...user,
    accessLevelLabel: userAcessLevelLabels[user.accessLevel],
  };
}

/**
 * Update user data
 * @param userId
 * @param data
 * @returns {Promise<{ message: string }>}
 */
export async function updateUser(
  userId: number,
  data: IUpdateUserPayload
): Promise<{ message: string }> {
  // Call the API to update user data
  const { data: message } = await api.auth.patch(
    routes.v1.user.update.replace(':userId', userId.toString()),
    data
  );

  // Return the message from the API
  return message;
}

/**
 * Get users list
 * @param {string} userId
 * @returns {Promise<IUser>}
 */
export async function getUsersList({
  start,
  size,
  order,
  field,
  filter,
}: IUserListParams): Promise<IResponseWithDataListPaginated<IUser>['response']> {
  let searchParams;

  if (filter) {
    searchParams = {
      filter: filter
    };
  }

  // Call the API to get user data by id
  const { data, status } = await api.auth.get<IResponseWithDataListPaginated<IUser>>(
    routes.v1.user.getList,
    {
      params: { size, start, order, field, filter, ...searchParams },
    }
  );

  if (status === 204) {
    return {
      data: [],
      pageNumber: 0,
      nextPage: 0,
      lastPage: 0,
      totalPages: 0,
      total: 0,
      pageSize: 0,
    };
  }
  // Return the user data
  //1=inactive,2=registered, 3=active

  return data?.response;
}

/**
 * Delete user by id
 * @param {string} parameter.requesterEmail Email from the administrator sent the user deletion request
 * @param {string} parameter.userId ID of the user to be deleted
 * @returns {Promise<AxiosResponse>}
 */
export async function deleteUser({
  requesterEmail,
  userId,
}: {
  requesterEmail: string;
  userId: number;
}): Promise<{ message: string }> {
  // Call the API to update user data
  const { data: message } = await api.auth.delete(
    routes.v1.user.delete.replace(':userId', userId.toString()),
    {
      data: {
        email: requesterEmail,
      },
    }
  );

  // Return the message from the API
  return message;
}

/**
 *  Send a request to the API to resend the invitation email
 * @param {number} userId
 * @param {IUser} data
 * @returns { message:string }
 */
export async function resendInvite(userId: number): Promise<{ message: string }> {
  // Send a request to the API with user email to reset the password
  const result = await api.auth.get(
    routes.v1.user.resendInvite.replace(':userId', userId.toString())
  );

  // Return the message from the API
  return result.data;
}

/**
 * Set up new user`s first password
 * @param {IChangePasswordRequest} data
 * @returns {Promise<{ message: string }>}
 */
export async function firstPassword({
  email,
  currentPassword,
  newPassword,
}: IChangePasswordRequest): Promise<{ message: string }> {
  const result = await api.post(routes.v1.user.firstPassword, {
    email,
    currentPassword,
    newPassword,
  });

  // Return the message from the API
  return result.data;
}

export default {
  createUser,
  ForgotPasswordRequestChangePassword,
  changePassword,
  getUserById,
  updateUser,
  deleteUser,
  resendInvite,
  firstPassword,
};
