import classNames from 'classnames';
import { FileXLSIcon, LoadingSpinIcon } from 'assets/icons';
import Skeleton from 'react-loading-skeleton';

interface IExportButtonProps {
    loading?: boolean;
    showSkeleton?: boolean;
    onClick?: () => void;
    buttonText?: string;
    buttonClassName?: string;
    buttonDisabled?: boolean;
    icon?: JSX.Element;
    showSkeletonLoading?: boolean;
  }

function ExportButton({ 
  loading = false, 
  showSkeleton = false,
  onClick, 
  buttonText = 'Exportar Excel', 
  buttonClassName,
  buttonDisabled = false,
  showSkeletonLoading= false,
  icon,
}: IExportButtonProps) {
  
  return (
    <>
      {showSkeletonLoading ? (
        <Skeleton
        width={147}
        height={42}
        baseColor="white"
        highlightColor="#f3f3f3"
        containerClassName="flex h-[42px] w-full"
        className="!rounded-lg"
      />
      ) : (
        <button
          type="button"
          className={classNames(
            'w-full xl:max-w-[190px] h-[42px] text-xs font-bold leading-4 tracking-[0.6px] uppercase whitespace-nowrap !text-buttontextcolor disabled:bg-[#D1D5DB] bg-primary flex justify-center items-center gap-x-[13px] py-2 pl-4 pr-[14px] rounded-lg',
            {
              'flex items-center': loading,
            },
            buttonClassName
          )}
          onClick={onClick}
          disabled={buttonDisabled || loading}
        >
          {loading ? (
            <LoadingSpinIcon className=" w-6 !text-buttontextcolor " />
          ) : (
            <>
              <p className="w-fit truncate">{buttonText}</p>
              {icon ? icon : <FileXLSIcon className="!text-buttontextcolor w-[19px] h-[22px]" />}
            </>
          )}
        </button>
      )}
    </>
  );
}

export default ExportButton;


