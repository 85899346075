import { LoadingSpinIcon, SearchIcon } from 'assets/icons';
import classNames from 'classnames';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

interface ISearchButton {
  handleClick: () => void;
  disabled?: boolean;
  isLoading?: boolean;
  isFetching?: boolean;
  hasFilterStatesToSubmit?: boolean;
  showSkeletonLoading?: boolean;
}

function SearchButton({
  handleClick,
  disabled,
  isLoading,
  isFetching,
  hasFilterStatesToSubmit,
  showSkeletonLoading = false,
}: ISearchButton) {
  return (
    <>
      {showSkeletonLoading ? (
        <Skeleton
          height={42}
          baseColor="#f3f3f3"
          highlightColor="white"
          containerClassName="flex h-[42px] w-[50px]"
          className="!rounded-lg"
        />
      ) : (
        <button
          className={classNames(
            ' bg-primary disabled:bg-[#D1D5DB] text-white py-2 px-4 rounded-lg flex justify-center items-center w-full h-[42px] xl:max-w-[56px]'
          )}
          onClick={handleClick}
          disabled={!hasFilterStatesToSubmit || isLoading || isFetching || disabled}
        >
          {isLoading || isFetching ? (
            <LoadingSpinIcon className=" w-5 !text-primary md:!text-buttontextcolor " />
          ) : (
            <SearchIcon className="!text-buttontextcolor" />
          )}
        </button>
      )}
    </>
  );
}

export default SearchButton;
