import classNames from 'classnames';

function DropDownArrowIcon({ className }: { className?: string | Record<string, unknown> }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="14"
      viewBox="0 0 24 14"
      fill="none"
      className={classNames('text-primary', className)}
    >
      <path
        d="M0.75 8.25H3.25V5.75H0.75V8.25ZM0.75 13.25H3.25V10.75H0.75V13.25ZM0.75 3.25H3.25V0.75H0.75V3.25ZM5.75 8.25H23.25V5.75H5.75V8.25ZM5.75 13.25H23.25V10.75H5.75V13.25ZM5.75 0.75V3.25H23.25V0.75H5.75ZM0.75 8.25H3.25V5.75H0.75V8.25ZM0.75 13.25H3.25V10.75H0.75V13.25ZM0.75 3.25H3.25V0.75H0.75V3.25ZM5.75 8.25H23.25V5.75H5.75V8.25ZM5.75 13.25H23.25V10.75H5.75V13.25ZM5.75 0.75V3.25H23.25V0.75H5.75Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default DropDownArrowIcon;
