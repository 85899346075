import classNames from "classnames"
import { ICreateCompanyPayload, IUpdateCompanyPayload } from "interfaces/companyTypes";
import { IUpdateUnitPayload } from "interfaces/unitTypes";
import { ControllerRenderProps } from "react-hook-form"

type IUpdateCompanyAndUnitPayload = IUpdateCompanyPayload & IUpdateUnitPayload;

const mainStyles= {
    mainContainer : 'w-full min-h-screen h-full flex flex-col justify-start gap-y-[19px] items-start pt-[23px] md:pt-[42px] p-[27px] md:p-8',
    subHeaderContainer: 'flex flex-col gap-2',
    addUserIconContainerH1: 'flex items-center gap-2.5 text-2xl font-semibold text-[#595959]',
    addUserIconStyles: 'w-[22px] h-[24px] !text-secondary',
    requiredFieldsH3: 'text-xs text-[#374151]',
    formContainer : 'self-center w-full max-w-[800px] gap-x-[10px] xl:gap-x-[20px] gap-y-10 flex flex-col justify-center',
    formQuestionsSection: 'flex flex-col justify-start md:grid md:grid-cols-12 gap-x-[10px] xl:gap-x-[20px] gap-y-2',
    formTitle: 'row-start-1 row-end-1 col-start-1 col-end-5 font-inter font-semibold',
    formButtonSection: 'flex justify-between md:justify-end items-center md:gap-x-7 lg:gap-x-11 md:row-start-[9] !w-full md:col-start-8 md:col-end-13 mt-[11px] md:mt-[30px]',
    userInputSection: 'md:grid md:grid-cols-12 gap-x-[10px] xl:gap-x-[20px] gap-y-2  font-inter font-semibold',
    userTitleH3: 'row-start-1 row-end-1 col-start-1 col-end-5',    
    submitButtonsSection: 'flex justify-between md:justify-end items-center md:gap-x-7 lg:gap-x-11 md:row-start-[9] !w-full md:col-start-8 md:col-end-13 mt-[11px] md:mt-[30px]',
    submitButtonStyle: 'bg-primary text-xs font-bold leading-4 disabled:bg-[#D1D5DB] text-buttontextcolor py-2 pl-4 pr-[18px] rounded-lg flex justify-center items-center h-[42px] max-w-[147px] tracking-[0.6px]',
    submitButtonIconStyle: 'ml-4 !text-buttontextcolor'
  }

  const formStyles = {
    cnpjInput : {
      labelStyle: 'row-start-2 row-end-3 col-start-1 col-end-5 md:w-[170px]',
    },
    statusInput: {
      labelStyle: 'self-start row-start-2 row-end-3 md:col-start-7 md:col-end-8',
    },
    fanstasyNameInput: {      
      labelStyle: 'row-start-3 row-end-4 col-start-1 col-end-7',
    },
    nameInput: {      
      labelStyle : 'row-start-3 row-end-4 col-start-7 col-end-13',
    },
    zipCodeInput: {
      mainContainerStyle: 'relative row-start-4 row-end-5 col-start-1 col-end-5 w-fit',
      spinnerIconStyle: 'absolute right-3 bottom-3.5 z-10',
      inputText: {
        labelStyle:'row-start-4 row-end-5 col-start-1 col-end-5 md:w-[170px]',
      }
    },
    addressInput: {
      labelStyle: 'row-start-5 row-end-6 col-start-1 col-end-7',

    },
    addressNumber : {
      labelStyle: 'row-start-5 row-end-6 col-start-7 col-end-9 md:min-w-[80px]',
    },
    complementInput : {
      labelStyle: 'row-start-5 row-end-6 col-start-9 col-end-[13] md:min-w-[80px]',
    },
    districtInput : {
      labelStyle: 'row-start-6 row-end-7 col-start-1 col-end-6' ,

    },
    cityInput : {
      labelStyle: 'row-start-6 row-end-7 col-start-6 col-end-10',

    },
    stateInput: {
      labelStyle: 'row-start-6 row-end-7 col-start-10 col-end-12 md:max-w-[100px]',
    },
    phoneInput : {
      labelStyle: 'row-start-7 row-end-8 col-start-1 col-end-5',
    },
    companyTipeInput : {
      skeleton: {
        heightSkeleton: 44,
        baseColor:'#f3f3f3',
        highlightColor:'white',
        containerClassName:'flex h-[42px] mt-[24px] row-start-7 row-end-8 col-start-5 col-end-9',
        classNameSkeleton:'!rounded-lg',
      },
      selectInput: {
        labelStyle: 'row-start-7 row-end-8 col-start-5 col-end-9',
        selectStyle: (field :number|string | undefined ) =>  classNames({
          '!text-[#a9b1bc] h-[44px]': Number(field) === -1|| !field,
        }),
        defaultOptionStyle: 'font-normal font-inter text-sm leading-5 !text-[#a9b1bc]',
        optionsStyle : 'font-normal font-inter text-sm leading-5 text-textcolor'
      },
    },
    selectPlanTypeInput: {
      labelStyle: 'row-start-7 row-end-8 col-start-9 col-end-13',
      selectStyle: (watchTypePlan: string | null | undefined) =>  classNames({
        '!text-[#a9b1bc] h-[44px]': watchTypePlan == '' || !watchTypePlan,
      }),
      defaultOptionStyle: 'font-normal font-inter text-sm leading-5 !text-[#a9b1bc]',
      optionsStyle : 'font-normal font-inter text-sm leading-5 text-textcolor'
    },
    dateRegisterInput : {
      labelStyleContainer: 'font-inter text-[#374151] text-sm font-medium row-start-[8] row-end-[9] col-start-1 col-end-5',
      datePickerStyleContainer: 'datepicker bg-[#FBFCFD] focus-within:outline outline-1 outline-secondary w-full lg:max-w-[250px] lg:min-w-[150px] relative h-[42px] text-gray-700 border border-gray-300 rounded-lg text-xs',
      datePickerStyleInput: 'p-3 text-primary text-medium text-[14px]' ,
    },
    monthlyFeeDayInput: {
      textInputStyleLabel: 'row-start-[8] row-end-[9] col-start-5 col-end-9',
      datePickerStyleContainerLabel: 'font-inter text-[#374151] text-sm font-medium row-start-[8] row-end-[9] col-start-5 col-end-9',
      datePickerStyleInputContainer : 'datepicker bg-[#FBFCFD] focus-within:outline outline-1 outline-secondary w-full lg:max-w-[250px] lg:min-w-[150px] relative h-[42px] text-gray-700 border border-gray-300 rounded-lg text-xs',
      inputDatePickerStyle: 'p-3 text-primary text-medium text-[14px]',
    },
    monthlyInstallments: { 
      labelStyle: 'row-start-[8] row-end-[9] col-start-9 col-end-13',
    },
    comments: { 
      labelStyle: 'row-start-[9] row-end-[10] col-start-1 col-end-13',
      textarea: 'h-full min-h-[100px] max-h-[250px]',
    },
    
  }
  const userForm = {
    nameInput : {
      labelStyle: 'row-start-2 row-end-3 col-start-1 col-end-7',
    },
    surnameInput : {
      labelStyle: 'row-start-2 row-end-3 col-start-7 col-end-13',
    },
    emailInput : {
      labelStyle: 'row-start-3 row-end-4 col-start-1 col-end-7 md:min-w-[400px]',
    },

  }


export  { 
    mainStyles,
    formStyles,
    userForm
 }