/* eslint-disable import/no-unused-modules */
// Importações consolidadas com renomeação para evitar conflitos
import Expenses from './Expenses';
import Sales from './Sales';
import PendingSales from './PendingSales'
import Payments from './Payments'


export default {
  Expenses,
  Sales,
  PendingSales,
  Payments
};
