// App
import { IAuthState } from './interfaces';

// IsRefreshToken
export const selectIsRefreshToken = (state: IAuthState) => {
  return state.isRefreshToken > 0;
};

export const selectSetIsRefreshToken = (state: IAuthState) => {
  return state.setIsRefreshToken;
};

// tokenTimeoutId
export const selectTokenTimeoutId = (state: IAuthState) => {
  return state.tokenTimeoutId;
};

export const selectSetTokenTimeoutId = (state: IAuthState) => {
  return state.setTokenTimeoutId;
};

export const selectCleanTokenTimeoutId = (state: IAuthState) => {
  return state.cleanTokenTimeoutId;
};
