/* eslint-disable react-hooks/rules-of-hooks */
// Third-party
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { AxiosError } from 'axios';

// App
import { formSchema } from './schemas';
import { Input, InputText, Select } from 'components';
import { resendInvite } from 'services/api/user';
import {
  SendIcon,
  IndicatorSmallIcon,
  SaveIcon,
  ProfileIcon,
  SimpleSpinnerIcon,
} from 'assets/icons';
import { AccessLevel, IUser } from 'interfaces/userTypes';
import EditUserSkeleton from './components/EditUserSkeleton';
import { PAGES_ROUTES } from 'constants/routes';
import useLoadingStore from 'store/client/loading/useLoadingStore';
import { selectLoading, selectSetLoading } from 'store/client/loading/selectors';
import useNotificationStore from 'store/client/notification/useNotificationStore';
import { selectAddNotification } from 'store/client/notification/selectors';
import { useGetUser, useGetUserById } from 'store/server/user/queries';
import { useUpdateUserMutation } from 'store/server/user/mutations';
import { cnpjMask } from 'utils/mask.utils';
import ReturnButton from 'components/ReturnButton';

type IUpdateUserPayload = Pick<IUser, 'givenName' | 'familyName'>;

function EditUser() {
  /*
  **** Component organization ****

   └── Declaration of generic hooks (e.g., useNavigate)
   └── State declaration
   └── Side effects (e.g., useEffect)
   └── Memoization (e.g., useMemo)
   └── Handlers (e.g., useCallback)
   └── JSX
   */

  // └── Declaration of generic hooks (e.g., useNavigate)
  const { userId } = useParams();
  const { data: requesterUser } = useGetUser();
  const navigate = useNavigate();
  const addNotification = useNotificationStore(selectAddNotification);

  const userIsHimself = useMemo(
    () => requesterUser?.id === Number(userId),
    [requesterUser, userId]
  );
  const isAdmin = useMemo(
    () => requesterUser && [1].includes(requesterUser.accessLevel),
    [requesterUser]
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, dirtyFields },
    reset,
  } = useForm<IUpdateUserPayload>({
    resolver: yupResolver(formSchema),
  });
  const isLoading = useLoadingStore(selectLoading);
  const setLoading = useLoadingStore(selectSetLoading);

  if (!userId || isNaN(Number(userId)))
    return <Navigate to={PAGES_ROUTES.authenticated.user.list} replace />;

  // └── State declaration
  const { data: user, isLoading: isLoadingUser } = useGetUserById(Number(userId));
  const updateUserMutation = useUpdateUserMutation(Number(userId));

  useEffect(() => {
    if (user) {
      reset(user);
    }
  }, [reset, user]);

  // └── Handlers (e.g., useCallback)
  const onSubmit = async (data: IUpdateUserPayload) => {
    const { givenName, familyName } = Object.keys(dirtyFields).reduce((fields, fieldName) => {
      fields[fieldName as keyof IUpdateUserPayload] = data[fieldName as keyof IUpdateUserPayload];
      return fields;
    }, {} as { [key in keyof IUpdateUserPayload]: string | undefined });

    try {
      await updateUserMutation.mutateAsync({
        givenName: givenName as string,
        familyName: familyName as string,
      });

      addNotification({ type: 'success', message: 'Usuário atualizado com sucesso!' });
    } catch (error) {
      const defaultErrorMessage = () => {
        addNotification({
          message: `Erro ao tentar atualizar informações do usuário ID <b>${userId}</b>.<br/> Tente novamente mais tarde.`,
          type: 'error',
        });
      };
      let errorMessageDispatcher = defaultErrorMessage;

      if (error instanceof AxiosError) {
        const errors: Record<string | number, Record<string, () => void>> = {
          404: {
            'User not found': () => {
              addNotification({
                message: `Usuário ID <b>${userId}</b> não encontrado.<br/> Tente novamente mais tarde.`,
                type: 'error',
              });

              navigate(PAGES_ROUTES.authenticated.user.list);
            },
          },
        };
        errorMessageDispatcher =
          errors[error.response?.status ?? 0]?.[error.response?.data?.message] ??
          defaultErrorMessage;
      }
      errorMessageDispatcher();
    }
  };

  const handleResendInvite = async () => {
    if (user) {
      setLoading(true);
      try {
        await resendInvite(user.id);
        addNotification({
          message: 'O reenvio do convite foi efetuado com sucesso.',
          type: 'success',
        });

        setTimeout(() => {
          navigate(PAGES_ROUTES.authenticated.user.list);
        }, 2000);
      } catch (error) {
        const defaultErrorMessage = () => {
          addNotification({
            message: `Houve um problema ao tentar reenviar o convite ao usuário <b>${user.givenName} ${user.familyName}</b>.<br/> Tente novamente mais tarde.`,
            type: 'error',
          });
        };

        let errorMessageDispatcher = defaultErrorMessage;

        if (error instanceof AxiosError) {
          const errors: Record<string | number, Record<string, () => void>> = {
            400: {
              'User status is not 2': () => {
                addNotification({
                  message: `O usuário <b>${user.givenName} ${user.familyName}</b> já está <b>ativo</b>.`,
                  type: 'error',
                });
              },
            },
          };

          errorMessageDispatcher =
            errors[error.response?.status ?? 0]?.[error.response?.data?.message] ??
            defaultErrorMessage;
        }

        errorMessageDispatcher();
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      {requesterUser && user ? (
        <div className="w-full min-h-screen h-full flex flex-col justify-start gap-y-[19px] items-start pt-[23px] md:pt-[42px] p-[27px] md:p-8">
          <div className="flex flex-col gap-2">
            <h1 className="flex items-center gap-2.5 text-2xl font-semibold text-[#595959]">
              <ProfileIcon className="w-[22px] h-[24px] !text-secondary" />{' '}
              {userIsHimself ? 'Meus dados' : 'Perfil de usuário'}
            </h1>
            <h3 className="text-xs text-[#374151]">Campos obrigatórios *</h3>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            className="self-center flex flex-col items-center md:grid md:gap-x-6 gap-y-[14px] md:gap-y-5 md:grid-cols-4 w-full min-w-0 max-w-[100%] md:max-w-[755px]"
          >            
            <InputText
              labelText="Nome *"
              type="text"
              {...register('givenName')}              
              cssClasses={{
                label: classNames("md:col-start-1 md:col-end-3"),
              }}
            />
            <InputText
              labelText="Sobrenome *"              
              type="text"           
              {...register('familyName')}
              cssClasses={{
                label: classNames("md:col-start-3 md:col-end-5"),
              }}
            />
            <InputText
              labelText="E-mail"
              name="email"
              type="email"
              value={user.email}
              cssClasses={{
                label: classNames('md:col-start-1 md:col-end-3', {
                  'md:!col-end-4': isAdmin,
                }),
              }}
              disabled
              readOnly
            />
            {isAdmin && (
              <Select
                labelText="Nivel de Acesso"
                value={user.accessLevel}
                cssClasses={{
                  label: 'md:col-start-4 md:col-end-5',                  
                }}
                disabled
              >
                <option value="1">Administrador</option>
                <option value="2">Unidade</option>
              </Select>
            )}                
            {user.company && user.accessLevel !== AccessLevel.ADMIN  && (
              <>
                <h3 className="row-start-3 col-start-1 col-end-5 font-inter font-semibold">
                  Dados da Empresa
                </h3>
                <>
                  <InputText
                    labelText="Nome"
                    name="company"
                    type="text"
                    value={user.company?.name}
                    cssClasses={{
                      label: classNames('md:col-start-1 md:col-end-3'),
                    }}
                    disabled
                    readOnly
                  />
                  <InputText
                    labelText="CNPJ"
                    name="company"
                    type="text"
                    value={cnpjMask(user.company?.cnpj)}
                    cssClasses={{
                      label: classNames('md:col-start-3 md:col-end-5'),
                    }}
                    disabled
                    readOnly
                  />
                </>
              </>
            )}          
            {user?.status === 'PENDING' && (
              <button
                type="button"
                className="text-secondary font-semibold text-xs w-fit h-fit flex flex-row items-center self-start gap-2 group md:col-start-1 md:col-end-3 mt-2"
                onClick={handleResendInvite}
              >
                <SendIcon className="!text-secondary" /> REENVIAR E-MAIL DE CONVITE
              </button>
            )}
            <div className="w-full flex flex-col items-end gap-y-5 md:row-start-8 md:col-start-3 md:col-end-5 md:mt-5">
              <div className="flex justify-between md:justify-end items-center md:gap-x-7 lg:gap-x-11 !w-full">
                <ReturnButton 
                  returnTo={PAGES_ROUTES.authenticated.user.list}
                />
                <button
                  type="submit"
                  className="bg-primary text-xs font-bold leading-4 disabled:bg-[#D1D5DB] text-buttontextcolor py-2 pl-4 pr-[18px] rounded-lg flex justify-center items-center h-[42px] max-w-[147px] tracking-[0.6px]"
                  disabled={
                    !isValid ||
                    isLoading ||
                    Object.keys(dirtyFields).length === 0 ||
                    updateUserMutation.isPending ||
                    isLoadingUser
                  }
                >
                  SALVAR
                  {updateUserMutation.isPending || isLoadingUser ? (
                    <SimpleSpinnerIcon className="ml-[18px] z-10" />
                  ) : (
                    <SaveIcon className="ml-4 !text-buttontextcolor" />
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <EditUserSkeleton />
      )}
    </>
  );
}

export default EditUser;
