// Third-party
import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';

//App
import { InputText } from 'components';
import {
  AddUserIcon,
  ArrowIcon,
  DeleteIcon,
  LoadingSpinIcon,
  PenIcon,
  ProfileIcon,
  SearchIcon,
} from 'assets/icons';
import LoadingListSkeleton from './components/LoadingListSkeleton';
import Skeleton from 'react-loading-skeleton';
import { PAGES_ROUTES } from 'constants/routes';
import { useGetUser } from 'store/server/user/queries';
import { useGetCompanyList } from 'store/server/company/queries';
import { CompanyStatusEnum, ICompanyListOrderAttribute } from 'interfaces/companyTypes';
import { DateType } from 'react-tailwindcss-datepicker';
import { cnpjMask } from 'utils/mask.utils';
import usePersistedState from 'hooks/usePersistedState';
import PageHeader from 'components/PageHeader/components/PageHeader';
import TableContainer from 'components/TableContainer';
import NavigationSelector from 'components/NavigationSelector';
import { screenConfig } from 'utils/screenConfig.util';
import { ORDER_ATTRIBUTTES, ascDescType } from 'constants/orderAttributtes';

const { ASC, DESC } = ORDER_ATTRIBUTTES;
const { MEDIUM_SCREEN_SIZE } = screenConfig.constants

function List() {
  /*
  **** Component organization ****

   └── Declaration of generic hooks (e.g., useNavigate)
   └── State declaration
   └── Side effects (e.g., useEffect)
   └── Memoization (e.g., useMemo)
   └── Handlers (e.g., useCallback)
   └── JSX
   */

  // └── Declaration of generic hooks (e.g., useNavigate)
  const navigate = useNavigate();

  // └── State declaration
  // Selected table row
  const [selectedRowId, setSelectedRowId] = useState<null|number>(null);


  // Pagination states
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = usePersistedState<number>('companyList.pageSize', 10);

  // Sort states
  const [orderAttribute, setOrderAttribute] = useState<ICompanyListOrderAttribute>('id');
  const [order, setOrder] = useState<ascDescType>(ASC);

  // └── State declaration
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);
  const [selectedCompanyStatusFilter, setSelectedCompanyStatusFilter] = useState<
    keyof CompanyStatusEnum | null
  >(null);
  const [selectedInitialDateFilter, setSelectedInitialDateFilter] = useState<DateType>(null);
  const [selectedEndDateFilter, setSelectedEndDateFilter] = useState<DateType>(null);

  const [submitedFilters, setSubmitedFilters] = useState<{
    filter: string | null;
    companyStatus: keyof CompanyStatusEnum | null;
    initialDate: DateType;
    endDate: DateType;
  }>({
    filter: selectedFilter,
    companyStatus: selectedCompanyStatusFilter,
    initialDate: selectedInitialDateFilter,
    endDate: selectedEndDateFilter,
  });

  const { isLoading: userLoggedIsLoading } = useGetUser();
  const {
    data: companyListData,
    isLoading: companyListIsLoading,
    isFetching: companyListIsFetching,
  } = useGetCompanyList({
    start: page,
    size: pageSize,
    field: orderAttribute,
    order,
    filter: submitedFilters.filter,
  });

  // └── Side effects (e.g., useEffect)

  // └── Handlers (e.g., useCallback)

  const handleSortByColumn = useCallback(
    (columnName: ICompanyListOrderAttribute, orderSelected?: ascDescType) => () => {
      if (orderAttribute !== columnName) {
        setOrder(ASC); // If the sort field is different from the clicked column, set the order to ASC
      } else {
        setOrder(orderSelected ? orderSelected : order === ASC ? DESC : ASC); // If the sort field is the same as the clicked column, toggle between ASC and DESC order
      }
      setOrderAttribute(columnName); // Set the sort field to the clicked column
    },
    [orderAttribute, order]
  );

  const checkIfShowCards = useCallback(() => {
    if (window.innerWidth < MEDIUM_SCREEN_SIZE) {
      orderAttribute !== 'id' && setOrderAttribute('id');
      order !== ASC && setOrder(ASC);
    }
  }, [order, orderAttribute]);

  useEffect(() => {
    window.removeEventListener('resize', checkIfShowCards);
    window.addEventListener('resize', checkIfShowCards);

    return () => {
      window.removeEventListener('resize', checkIfShowCards);
    };
  }, [checkIfShowCards]);

  /**
   * Returns a memoized arrow component with the correct direction based on the 'order' prop.
   *
   * @param {string} order - The order prop indicating the direction (ASC or DESC).
   * @returns {React.Component} - The memoized Arrow component.
   */
  const ArrowWithCorrectDirection = useMemo(
    () =>
      function Arrow({ className }: { className?: string | Record<string, unknown> }) {
        return (
          <ArrowIcon
            className={classNames(
              {
                'rotate-180': order === ASC, // Adds the 'rotate-180' class if the 'order' prop is ASC
              },
              className
            )}
          />
        );
      },
    [order] // Dependency for useMemo, the component will be recomputed when the 'order' prop changes
  );

  const showSkeletonLoading = useMemo(
    () => (!companyListData?.data && companyListIsLoading) || userLoggedIsLoading,
    [userLoggedIsLoading, companyListData, companyListIsLoading]
  );

  const showClearFiltersButton = useMemo(
    () =>
      submitedFilters.filter ||
      submitedFilters.companyStatus ||
      submitedFilters.initialDate ||
      submitedFilters.endDate,
    [submitedFilters]
  );

  const handleClearFilters = useCallback(() => {
    setSelectedFilter(null);
    setSelectedCompanyStatusFilter(null);
    setSelectedInitialDateFilter(null);
    setSelectedEndDateFilter(null);

    if (
      submitedFilters.filter ||
      submitedFilters.companyStatus ||
      submitedFilters.initialDate ||
      submitedFilters.endDate
    ) {
      setPage(0);
      setSubmitedFilters({
        filter: null,
        companyStatus: null,
        initialDate: null,
        endDate: null,
      });
    }
  }, [
    submitedFilters.endDate,
    submitedFilters.initialDate,
    submitedFilters.companyStatus,
    submitedFilters.filter,
  ]);

  const hasFiltersToSubmit = useMemo(
    () =>
      submitedFilters.filter !== selectedFilter ||
      submitedFilters.companyStatus !== selectedCompanyStatusFilter ||
      submitedFilters.initialDate !== selectedInitialDateFilter ||
      submitedFilters.endDate !== selectedEndDateFilter,
    [
      selectedEndDateFilter,
      selectedFilter,
      selectedInitialDateFilter,
      selectedCompanyStatusFilter,
      submitedFilters.endDate,
      submitedFilters.filter,
      submitedFilters.initialDate,
      submitedFilters.companyStatus,
    ]
  );

  const handleRowClick = (clientId:number) => {    
    setSelectedRowId(clientId === selectedRowId ? null : clientId);
  };
 

  return (
    <div
      className={classNames('h-full', {
        'overflow-hidden max-h-[calc(100vh-64px)] md:max-h-[calc(100vh-71px)]': showSkeletonLoading,
      })}
    >
      <div
        className={classNames(
          'pt-5 md:pt-[42px] p-[27px] md:p-8 w-full min-h-[calc(100vh-64px)] md:min-h-[calc(100vh-71px)]',
          {
            'overflow-hidden !h-screen': showSkeletonLoading,
          }
        )}
      >
        <PageHeader 
            iconTextTitleProps={{
              iconProps:{
                iconJSX: <ProfileIcon className="w-[22px] h-[24px] !text-secondary" />                 
              },
              textProps:{
                content: 'Lista de Empresas'
              },
            }
          }
          /> 
        <div className="w-full flex flex-col md:flex-row justify-between mb-4 gap-3">
          <div
            className={classNames(
              'row-start-1 row-end-2 md:row-start-2 md:row-end-2 col-start-1 col-end-4 md:col-end-3 flex flex-col md:flex-row justify-start items-start gap-x-2.5 gap-y-2.5 w-full h-fit'
            )}
          >
            {/* Filter */}
            {showSkeletonLoading ? (
              <Skeleton
                height={42}
                baseColor="#f3f3f3"
                highlightColor="white"
                containerClassName="flex w-full md:max-w-[300px] h-[42px] md:row-start-1 md:row-end-2"
                className="!rounded-lg"
              />
            ) : (
              <InputText
                name="filter"
                type="text"
                placeholder="Busque por CNPJ ou por nome fantasia"
                onChange={async (event: { target: { value: string } }) =>
                  setSelectedFilter(event.target.value)
                }
                value={selectedFilter ?? ''}
                title={selectedFilter ?? ''}
                cssClasses={{
                  label:
                    'md:max-w-[300px] !flex-1 md:row-start-1 md:row-end-2 max-md:col-start-1 max-md:col-end-4',
                  input:
                    '!mt-0 w-full !h-[42px] !text-[#374151] !font-normal text-xs placeholder:font-normal placeholder:text-xs disabled:placeholder:!text-[#D1D5DB] truncate disabled:bg-gray-100',
                }}
                min={'18'}
                max={'18'}
                disabled={companyListIsLoading || companyListIsFetching}
              />
            )}

            {/* Button to filter */}
            {showSkeletonLoading ? (
              <Skeleton
                height={42}
                baseColor="#f3f3f3"
                highlightColor="white"
                containerClassName="flex w-full md:max-w-[56px] h-[42px] row-start-3 md:row-start-1 row-end-4 md:row-end-2 col-start-1 col-end-4 md:col-start-3 md:col-end-4"
                className="!rounded-lg"
              />
            ) : (
              <>
                <button
                  className={classNames(
                    ' bg-primary disabled:bg-[#D1D5DB] text-white py-2 px-4 rounded-lg flex justify-center items-center w-full h-[42px] md:max-w-[56px]',
                    {
                      '!col-end-3': showClearFiltersButton,
                    }
                  )}
                  onClick={() => {
                    if (
                      submitedFilters.filter !== selectedFilter ||
                      submitedFilters.companyStatus !== selectedCompanyStatusFilter ||
                      submitedFilters.initialDate !== selectedInitialDateFilter ||
                      submitedFilters.endDate !== selectedEndDateFilter
                    ) {
                      setPage(0);
                      setSubmitedFilters({
                        filter: selectedFilter,
                        companyStatus: selectedCompanyStatusFilter,
                        initialDate: selectedInitialDateFilter,
                        endDate: selectedEndDateFilter,
                      });
                    }
                  }}
                  disabled={!hasFiltersToSubmit || companyListIsLoading || companyListIsFetching}
                >
                  {companyListIsLoading || companyListIsFetching ? (
                    <LoadingSpinIcon className=" w-5 !text-primary md:!text-buttontextcolor " />
                  ) : (
                    <SearchIcon className="!text-buttontextcolor" />
                  )}
                </button>
                {showClearFiltersButton && (
                  <button
                    className="bg-primary text-xs font-bold leading-4 tracking-[0.6px] text-white flex justify-center items-center gap-x-3  w-full md:w-fit h-[42px] py-2 px-4 rounded-lg disabled:bg-[#D1D5DB]"
                    onClick={handleClearFilters}
                    disabled={companyListIsLoading || companyListIsFetching}
                  >
                    <span className="hidden min-[1060px]:inline-block">LIMPAR</span>
                    <DeleteIcon />
                  </button>
                )}
              </>
            )}
          </div>

          <div className="w-full flex md:max-w-fit justify-between items-center">
            {showSkeletonLoading ? (
              <Skeleton
                width={147}
                height={42}
                baseColor="white"
                highlightColor="#f3f3f3"
                containerClassName="flex h-[42px]"
                className="!rounded-lg"
              />
            ) : (
              <button
                type="button"
                className="bg-primary text-xs font-bold leading-4 disabled:bg-[#D1D5DB] text-buttontextcolor py-2 pl-4 pr-[18px] rounded-lg flex justify-center items-center h-[42px] w-full md:max-w-[147px] tracking-[0.6px]"
                onClick={() => navigate(PAGES_ROUTES.authenticated.company.create)}
              >
                CADASTRAR
                <AddUserIcon className="ml-4 w-[19px] h-[22px] !text-buttontextcolor" />
              </button>
            )}
          </div>
        </div>

        {showSkeletonLoading ? (
          <LoadingListSkeleton />
        ) : companyListData?.data && companyListData?.data?.length <= 0 ? (
          <p className="w-full text-center text-[#595959] font-semibold">
            Nenhuma empresa encontrada!
          </p>
        ) : (
          <TableContainer>          
            {/* Desktop */}
            <table className="w-full text-sm text-left text-gray-500 hidden md:table">
              <thead className="font-inter text-[10px] text-primary font-bold uppercase bg-gray-50 w-full">
                <tr>
                  <th
                    scope="col"
                    className="h-full px-6 py-3 cursor-pointer hover:bg-gray-100 w-fit"
                    onClick={handleSortByColumn('id')}
                  >
                    <span className="flex flex-row items-center gap-2 w-full truncate">
                      ID
                      <span className={classNames('min-w-[12px]')}>
                        {orderAttribute === 'id' && <ArrowWithCorrectDirection />}
                      </span>
                    </span>
                  </th>
                  <th
                    scope="col"
                    className="h-full px-6 py-3 cursor-pointer hover:bg-gray-100 w-fit"
                    onClick={handleSortByColumn('cnpj')}
                  >
                    <span className="flex flex-row items-center gap-2 truncate">
                      CNPJ
                      <span className={classNames('min-w-[12px]')}>
                        {orderAttribute === 'cnpj' && <ArrowWithCorrectDirection />}
                      </span>
                    </span>
                  </th>
                  <th
                    scope="col"
                    className="h-full px-6 py-3 cursor-pointer hover:bg-gray-100 w-fit"
                    onClick={handleSortByColumn('fantasyName')}
                  >
                    <span className="flex flex-row items-center gap-2 truncate">
                      NOME
                      <span className={classNames('min-w-[12px]')}>
                        {orderAttribute === 'fantasyName' && <ArrowWithCorrectDirection />}
                      </span>
                    </span>
                  </th>
                  <th
                    scope="col"
                    className="h-full px-6 py-3 cursor-pointer hover:bg-gray-100 w-fit"
                    onClick={handleSortByColumn('status')}
                  >
                    <span className="flex flex-row items-center gap-2 truncate">
                      STATUS
                      <span className={classNames('min-w-[12px]')}>
                        {orderAttribute === 'status' && <ArrowWithCorrectDirection />}
                      </span>
                    </span>
                  </th>
                  <th
                    scope="col"
                    className="h-full px-6 py-3 cursor-pointer hover:bg-gray-100 w-fit"
                    onClick={handleSortByColumn('createdAt')}
                  >
                    <span className="flex flex-row items-center gap-2 truncate">
                      Data Cadastro
                      <span className={classNames('min-w-[12px]')}>
                        {orderAttribute === 'createdAt' && <ArrowWithCorrectDirection />}
                      </span>
                    </span>
                  </th>
                  <th scope="col" className="h-full px-6 py-3 w-fit">
                    <span className="flex flex-row items-center gap-2 truncate">AÇÕES</span>
                  </th>
                </tr>
              </thead>
              <tbody className="w-full">
                {companyListData?.data.map((companyForListing, companyForListingIndex) => (
                  <tr
                    className={classNames(
                    "bg-white border-b w-full h-[60px] text-xs text-black",
                    {
                      '!bg-white hover:!bg-gray-100 ': selectedRowId !== companyForListing.id,                      
                      '!bg-gray-300': selectedRowId === companyForListing.id,    
                    }

                    )}
                    key={`company-${companyForListing.id}-row-${companyForListingIndex}`}
                    onClick={() => handleRowClick(companyForListing.id)}
                  >
                    <td className="px-6 py-4 font-normal whitespace-nowrap w-fit">
                      {companyForListing.id}
                    </td>
                    <td className="px-6 py-4 font-normal whitespace-nowrap w-fit">
                      {cnpjMask(companyForListing.cnpj)}
                    </td>
                    <td className="px-6 py-4 font-normal whitespace-nowrap w-fit">
                      {companyForListing.fantasyName}
                    </td>
                    <td className={classNames("px-6 py-4 whitespace-nowrap w-fit text-red-500" , 
                      {
                        '!text-green-500' : String(CompanyStatusEnum[companyForListing.status]) === CompanyStatusEnum.ACTIVED
                      }
                    )}>
                      {companyForListing.status &&
                        String(CompanyStatusEnum[companyForListing.status]).toUpperCase() }
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap w-fit">
                      {companyForListing.createdAt &&                        
                        dayjs(companyForListing.createdAt).format('DD/MM/YYYY - HH:mm:ss')
                      }
                    </td>
                    <td className="px-6 py-4 w-fit max-w-[106px] flex justify-between font-medium whitespace-nowrap">
                      <button
                        onClick={() =>
                          navigate(
                            PAGES_ROUTES.authenticated.company.edit.replace(
                              ':companyId',
                              (companyForListing.id ?? -1).toString()
                            )
                          )
                        }
                        className="mr-3 group"
                      >
                        <PenIcon className="" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* Mobile */}
                <div className="flex flex-col gap-y-[18px] md:hidden w-full">
                  {companyListData?.data.map((companyForListing, companyForListingIndex) => (
                    <div
                      className="bg-white px-[20px] py-[16px] overflow-hidden shadow-container rounded-[5px] w-full"
                      key={`company-card-${companyForListing.id}-row-${companyForListingIndex}-mobile`}
                    >
                      <div className="w-full flex flex-col gap-y-[10px]">
                        
                        <div className="flex flex-row justify-between gap-x-2.5 h-fit">
                        <p className="font-inter font-medium text-gray-500 text-xs/[20px] self-start">
                            <b className="text-primary normal-case">ID: </b>{' '}
                            {companyForListing.id ?? '-'}
                        </p>
                        <div className='flex flex-row gap-x-2.5' >
                          <span className={classNames('text-xs  text-red-500 pt-1',
                            {
                              '!text-green-500': String(CompanyStatusEnum[companyForListing.status]) === CompanyStatusEnum.ACTIVED
                            }
                          )}>
                            {companyForListing.status &&
                              String(CompanyStatusEnum[companyForListing.status]).toUpperCase()}
                          </span>
                          <button
                            onClick={() =>
                              navigate(
                                PAGES_ROUTES.authenticated.company.edit.replace(
                                  ':companyId',
                                  (companyForListing.id ?? -1).toString()
                                )
                              )
                            }
                            className={classNames({
                              group: true,
                            })}
                          >
                            <PenIcon className="group-enabled:hover:text-secondary" />
                          </button>                        
                        </div>
                        </div>
                      </div>
                      <div className="flex flex-row w-full font-inter font-medium text-gray-500 text-xs/[20px]">
                        <span className="uppercase w-full">
                          
                          <p>
                            <b className="text-primary">CNPJ: </b>
                            {cnpjMask(companyForListing.cnpj) ?? '-'}
                          </p>
                          <p>
                            <b className="text-primary">Nome Fantasia: </b>
                            {companyForListing.fantasyName || '-'}
                          </p>
                          <p>
                            <b className="text-primary">Data Cadastro: </b>
                            {companyForListing.createdAt &&
                              dayjs(companyForListing.createdAt).format('DD/MM/YYYY - HH:mm:ss')
                            }
                          </p>
                        </span>
                      </div>
                    </div>
                  ))}
                </div>   
          </TableContainer>
        )}
        {!showSkeletonLoading && (
          <NavigationSelector
            totalResults={companyListData?.total || 0}
            pageSize={pageSize}
            currentPage={page}
            onChangePageSize={setPageSize}
            onPageChange={setPage}
            isLoading={companyListIsLoading || companyListIsFetching} />        
        )}
      </div>
    </div>
  );
}

export default List;
