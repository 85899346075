/* eslint-disable react-hooks/rules-of-hooks */
// Third-party
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Navigate } from 'react-router-dom';

// App
import useCompanySelectedStore from 'store/client/companySelected/useCompanySelectedStore';
import { selectCompanySelected } from 'store/client/companySelected/selectors';
import { PAGES_ROUTES } from 'constants/routes';
import { useGetSummaryData } from 'store/server/company/queries';
import getMonthStartAndEndUtil from 'utils/getMonthStartAndEnd.util';
import { useFilterBarContext } from '../../context/FilterBarContext';
import { convertToBrazilianCurrencyFormat } from 'utils/currency.utils';
import { ExpenseTypesCompare } from 'interfaces/expenseTypes';
import classNames from 'classnames';
import { splitArray } from 'utils/splitArray.utils';
import SectionContainer from '../sections/SectionContainer';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

interface IExpensesList {
  toExport?: boolean;
}

const MAX_ITEMS_TO_EXPORT = 23;

function ExpensesList({ toExport }: IExpensesList) {
  const companySelected = useCompanySelectedStore(selectCompanySelected);

  if (!companySelected) {
    return <Navigate to={PAGES_ROUTES.authenticated.commonInitialRoute} replace />;
  }

  const { selectedMonth, selectedYear } = useFilterBarContext();

  const { data: dashboardData } = useGetSummaryData(
    companySelected.id,
    {
      ...getMonthStartAndEndUtil(Number(selectedYear), Number(selectedMonth)),
    },
    { requestIdEnabled: false }
  );

  const length = dashboardData?.listExpenses?.length || 0;
  const itemsToExportLength = toExport ? MAX_ITEMS_TO_EXPORT : length || MAX_ITEMS_TO_EXPORT;

  return (
    <>
      {(length === 0
        ? [[]]
        : splitArray(dashboardData?.listExpenses ?? [], itemsToExportLength)
      ).map((expensesBatch, expensesBatchIndex) => {
        const table = (
          <div
            className={classNames('w-full h-fit overflow-auto', {
              'min-h-[100000px]':
                toExport &&
                expensesBatchIndex === 0 &&
                expensesBatch.length === MAX_ITEMS_TO_EXPORT,
            })}
            key={
              toExport
                ? `expensesBatch-toExport-${expensesBatchIndex}`
                : `expensesBatch-${expensesBatchIndex}`
            }
          >
            <div className={classNames('overflow-auto')}>
              <table className={classNames('w-full text-lg text-left text-black')}>
                <thead
                  className={classNames(
                    'font-inter text-[14px] text-primary font-bold uppercase bg-gray-100 w-full'
                  )}
                >
                  <tr>
                    <th className="py-[4px] pl-[10px] w-1/4">Categoria</th>
                    <th className="py-[4px] whitespace-nowrap text-right pr-2">
                      Quantidade de Despesa
                    </th>
                    <th className="py-[4px] whitespace-nowrap text-right pr-2">Pago</th>
                    <th className="py-[4px] whitespace-nowrap text-right pr-2">Despesa Total</th>
                    <th className="py-[4px] whitespace-nowrap text-right pr-2">Restante a pagar</th>
                  </tr>
                </thead>
                <tbody>
                  {expensesBatch.map((expense, expenseIndex) => (
                    <tr
                      key={`expense-${expense.category}-${expenseIndex}`}
                      className={classNames(
                        'bg-white border-b hover:bg-gray-100 w-full text-base h-[30px]'
                      )}
                    >
                      <td className="align-middle pl-[10px] py-[2px]">{expense.category}</td>
                      <td className="text-black text-right align-middle pr-2">
                        {(Number(expense.expensecount) || 0)}
                      </td>
                      <td className="text-black text-right align-middle pr-2">
                        {convertToBrazilianCurrencyFormat(expense.amounttotalpay ?? '0')}
                      </td>
                      <td className="text-black text-right align-middle pr-2">
                        {convertToBrazilianCurrencyFormat(
                          (
                            Number(expense.amounttotal || 0) 
                          ).toString() ?? '0'
                        )}
                      </td>
                      <td className="text-black text-right align-middle pr-2">
                        {convertToBrazilianCurrencyFormat(expense.amounttotalpending ?? '0')}
                      </td>
                      
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr
                    className={classNames(
                      'font-inter text-[14px] text-primary font-bold uppercase bg-gray-100 w-full'
                    )}
                  >
                    <th className="py-[4px] pl-[10px]"></th>
                    <th className="py-[4px] text-right whitespace-nowrap pl-[16px] pr-2">
                      Quantidade total:{' '}
                      {Array.isArray(dashboardData?.listExpenses)
                        ? dashboardData?.listExpenses?.reduce((totalAcc, expense) => {
                            totalAcc +=
                              (Number(expense.expensecount) || 0) +
                              (Number(expense.expensecountpending) || 0);
                            return totalAcc;
                          }, 0) ?? 0
                        : 0}
                    </th>
                    <th className="py-[4px] text-right whitespace-nowrap pl-[16px] pr-2">
                      Total Pago:{' '}
                      {convertToBrazilianCurrencyFormat(
                        `${
                          dashboardData?.listExpenses.reduce((totalAcc, expense) => {
                            totalAcc += Number(expense.amounttotalpay);
                            return totalAcc;
                          }, 0) ?? 0
                        }`
                      )}
                    </th>
                    <th className="py-[4px] text-right whitespace-nowrap pl-[16px] pr-2">
                      Total de Despesa:{' '}
                      {convertToBrazilianCurrencyFormat(
                        `${
                          dashboardData?.listExpenses.reduce((totalAcc, expense) => {
                            totalAcc +=
                              Number(expense.amounttotal);
                            return totalAcc;
                          }, 0) ?? 0
                        }`
                      )}
                    </th>
                    <th className="py-[4px] text-right whitespace-nowrap pl-[16px] pr-2">
                      Total à Pagar:{' '}
                      {convertToBrazilianCurrencyFormat(
                        `${
                          dashboardData?.listExpenses.reduce((totalAcc, expense) => {
                            totalAcc += Number(expense.amounttotalpending);
                            return totalAcc;
                          }, 0) ?? 0
                        }`
                      )}
                    </th>
                    
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        );

        if (toExport && expensesBatchIndex > 0) {
          return (
            <SectionContainer key={`expenses-list-container-${expensesBatchIndex}`}>
              {table}
            </SectionContainer>
          );
        }

        return <>{table}</>;
      })}
    </>
  );
}

export default ExpensesList;
