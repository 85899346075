// import dayjs from 'dayjs';

// export default function getMonthStartAndEndUtil(year?: number, month?: number) {
//   const date = dayjs(year && month ? `${year}-${month}-01` : undefined);

//   const initialDate = date.startOf('month').format('YYYY-MM-DD');
//   const finalDate = date.endOf('month').format('YYYY-MM-DD');

//   return { initialDate, finalDate };
// }
export default function getMonthStartAndEndUtil(year?: number, month?: number) {
  // Se ano e mês são fornecidos, cria uma data no primeiro dia desse mês. 
  // Se não, usa a data atual.
  let date = new Date();
  if (typeof year === 'number' && typeof month === 'number') {
    // Ajusta o mês para base-0 (janeiro é 0, dezembro é 11)
    date = new Date(year, month - 1);
  }

  // Encontra o primeiro dia do mês
  const initialDate = new Date(date.getFullYear(), date.getMonth(), 1);
  // Encontra o último dia do mês
  const finalDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  // Formata as datas para 'YYYY-MM-DD'
  const format = (d: Date) => d.toISOString().slice(0, 10);

  return {
    initialDate: format(initialDate),
    finalDate: format(finalDate),
    unformatted: {
      initialDate: initialDate,
      finalDate: finalDate,
    }
  };
}
