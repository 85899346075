/* eslint-disable react-hooks/rules-of-hooks */
// Third-party
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Navigate } from 'react-router-dom';
import { useMemo } from 'react';

// App
import useCompanySelectedStore from 'store/client/companySelected/useCompanySelectedStore';
import { selectCompanySelected } from 'store/client/companySelected/selectors';
import { PAGES_ROUTES } from 'constants/routes';
import { useGetSummaryData } from 'store/server/company/queries';
import getMonthStartAndEndUtil from 'utils/getMonthStartAndEnd.util';
import { useFilterBarContext } from '../../context/FilterBarContext';
import { titleBackgroundPlugin } from '../../chartsPlugins';
import { convertToBrazilianCurrencyFormat } from 'utils/currency.utils';
import classNames from 'classnames';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

interface IExpensesChart {
  toExport?: boolean;
}

function ExpensesChart({ toExport }: IExpensesChart) {
  const companySelected = useCompanySelectedStore(selectCompanySelected);

  if (!companySelected) {
    return <Navigate to={PAGES_ROUTES.authenticated.commonInitialRoute} replace />;
  }

  const { selectedMonth, selectedYear } = useFilterBarContext();

  const { data: dashboardData } = useGetSummaryData(
    companySelected.id,
    {
      ...getMonthStartAndEndUtil(Number(selectedYear), Number(selectedMonth)),
    },
    { requestIdEnabled: false }
  );

  const periodReceivablesOptions = useMemo<ChartOptions<'bar'>>(
    () => ({
      indexAxis: 'y',
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          bottom: 22,
          right: 10,
        },
      },
      scales: {
        y: {
          ticks: {
            color: '#000000',
            font: {
              size: 16,
            },
          },
        },
        x: {
          display: false,
          ticks: {
            stepSize: 1,
          },
        },
      },
      plugins: {
        tooltip: {
          callbacks: {
            title: (context) => context[0].label.split('R$')[0],
            label: (context) => {
              const value = context.parsed.x;
              const label = convertToBrazilianCurrencyFormat(`${value}`);
              return label;
            },
          },
        },
        legend: {
          position: 'top' as const,
          display: false,
        },
        title: {
          display: false,
        },
        datalabels: {
          display: false,
          color: 'rgb(255, 255, 255)',
          formatter: (value) => `R$ ${value.toFixed(2).replace('.', ',')}`,
        },
      },
    }),
    []
  );

  const periodReceivablesData = useMemo<ChartData<'bar'>>(
    () => ({
      labels: [
        `Restante\na pagar ${convertToBrazilianCurrencyFormat(
          `${dashboardData?.expenses.pending ?? 0}`
        )}`,
        `Total Pago ${convertToBrazilianCurrencyFormat(`${dashboardData?.expenses.paid ?? 0}`)}`,
      ],

      datasets: [
        {
          label: 'R$',

          data: [
            Number(dashboardData?.expenses.pending ?? 0),
            Number(dashboardData?.expenses.paid ?? 0),
            Math.max(
              Number(dashboardData?.expenses.pending ?? 0),
              Number(dashboardData?.expenses.paid ?? 0)
            ) + 1,
          ],
          backgroundColor: ['#fc9692', '#ff0000'],
          categoryPercentage: 0.8,
          barPercentage: 1,
        },
      ],
    }),
    [dashboardData?.expenses]
  );

  return (
    <div
      className={classNames('w-full mr-[10px]', {
        '!h-[calc(100%/2-20px)]': toExport,
      })}
    >
      <Bar
        data={periodReceivablesData}
        options={periodReceivablesOptions}
        plugins={[titleBackgroundPlugin]}
        height={150}
      />
    </div>
  );
}

export default ExpensesChart;
