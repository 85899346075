// Third-party
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

// App
import { INotification } from 'interfaces/notificationTypes';
import CloseIcon from './icons/CloseIcon';
import CancelIcon from './icons/CancelIcon';
import CheckCircleIcon from './icons/CheckCircleIcon';
import useNotificationStore from 'store/client/notification/useNotificationStore';
import { selectRemoveNotification } from 'store/client/notification/selectors';

const typesProperties = {
  success: {
    colors: {
      cardBg: 'bg-green-100',
      text: 'text-green-700',
      closeIcon: 'text-green-400',
    },
  },
  error: {
    colors: {
      cardBg: 'bg-red-100',
      text: 'text-red-700',
      closeIcon: 'text-red-400',
    },
  },
};

function NotificationCard({ id, message, type, timeout = 6000}: INotification) {
  /*
  **** Component organization ****

   └── Declaration of generic hooks (e.g., useNavigate)
   └── State declaration
   └── Side effects (e.g., useEffect)
   └── Memoization (e.g., useMemo)
   └── Handlers (e.g., useCallback)
   └── JSX
   */

  // └── Declaration of generic hooks (e.g., useNavigate)
  const removeNotification = useNotificationStore(selectRemoveNotification);
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const timerRef = useRef<NodeJS.Timeout>(setTimeout(() => {}, 0)); // Store timeout id

  // └── State declaration
  const [className, setClassName] = useState<string>('animate-slide-left');

  // └── Handlers (e.g., useCallback)
  const handleClose = useCallback(
    (notificationId: string, timeoutValue?: number) => {
      // Set animation class to remove notification from screen
      setClassName(`animate-slide-right`);
      // Timeout to synchronize with css animation
      setTimeout(() => {
        removeNotification(notificationId); // Remove notification from store
      }, timeoutValue || 300);
    },
    []
  );

  const startCountdown = useCallback(() => {
    // Store timeout id to clear it later
    timerRef.current = setTimeout(() => {
      handleClose(id);
    }, timeout);
  }, [timeout, handleClose, id]);

  const handleMouseEnter = () => {
    // When mouse hovers over the notification card it stops counting for removal
    clearTimeout(timerRef.current);
  };

  const handleMouseLeave = () => {
    // When mouse moves away from the notification card, it restarts counting for removal of the notification
    startCountdown();
  };

  useEffect(() => {
    startCountdown(); // Start counting for removal of the notification

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      clearTimeout(timerRef.current);
    };
  }, [startCountdown]);

  // bg-${typesProperties[type].mainColor}-100
  return (
    <div
      key={id}
      className={`w-full relative mt-5 z-20 p-4 rounded bg-green-100 text-green-700 flex justify-between items-center ${className} ${typesProperties[type].colors.cardBg} ${typesProperties[type].colors.text}`}
      role="alert"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="min-w-[22px]">
        {type === 'success' ? <CheckCircleIcon /> : <CancelIcon />}
      </div>
      <div className="px-4" dangerouslySetInnerHTML={{ __html: message }} />
      <button onClick={() => handleClose(id, 10)} className="pointer-events-auto">
        <CloseIcon color={typesProperties[type].colors.closeIcon} />
      </button>
    </div>
  );
}

export default NotificationCard;
