export default {
  create: '/v1/companies/main',
  getById: '/v1/companies/main',
  updateById: '/v1/companies/main',
  list: '/v1/companies/main',
  listGroup: '/v1/types/group/:groupId/company/:companyId',
  dashboardData: '/v1/companies/:companyId/dashboard',
  summaryData: '/v1/companies/:companyId/resume',
  logList: '/v1/companies/:companyId/logs',
  logDetails: '/v1/companies/logs/:logId',
  type: {
    list: '/v1/company/types',
  },
  group: {
    create: '/v1/company/:companyId/type/:typeId',
    updateType: '/v1/types/group/:groupId/company/:companyId/:typeGroupId',
    getCategoryList: '/v1/types/group/:groupId/company/:companyId',
  },
  expense: {
    create: '/v1/companies/:companyId/expense',
    remove: '/v1/companies/:companyId/expenses/:expenseId',
    get: '/v1/companies/:companyId/expenses',
    getById: '/v1/companies/:companyId/expenses/:expenseId',
    list: '/v1/companies/:companyId/expenses',
    writeOff: '/v1/companies/:companyId/expense/:expenseId'
  },
  sale: {
    create: '/v1/companies/:companyId/sale',
    remove: '/v1/companies/:companyId/sales/:saleId',
    list: '/v1/companies/:companyId/sales',
  },
  sales: {
    list: '/v1/companies/:companyId/sales',
    getByID: '/v1/companies/:companyId/sales/:saleId',
    createPayment: '/v1/companies/:companyId/sales/:saleId/payment',
  },
  pendingSales: {
    writeOff: '/v1/companies/:companyId/sales/payment/batch',    
    list: '/v1/companies/:companyId/salespending/:clientId'    
  },
  payments:{
    list: '/v1/companies/:companyId/payments'
  },
  exportExcelPdf:'/v1/companies/:companyId/export'
} as const;
