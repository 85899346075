
import {
  CloseIcon,
  DropDownArrowIcon,
} from '../assets/icons';
import classNames from "classnames";
import { DateType, DateValueType, DatepickerType } from 'react-tailwindcss-datepicker';
import { dateUtils } from './formatDate';
import dayjs from 'dayjs';
import { ReactNode } from 'react';
const { DATE_DISPLAY_FORMAT } = dateUtils.constants;


function getDatepickerProps(  
  startDate: DateType,
  endDate: DateType,
  handleChangeDateFilter: (value: DateValueType, e?: HTMLInputElement | null | undefined) => void,
  isLoading: boolean
): DatepickerType {

  const returnIcon = (prop: boolean): ReactNode => { 
    if (prop) return <DropDownArrowIcon className="text-primary" />;
    return <CloseIcon className="text-primary w-2.5" />;
  }

  return {
    primaryColor: "indigo",
    separator: "-",
    showShortcuts: true,
    showFooter: true,
    configs: {
      shortcuts: {
        today: 'Hoje',
        yesterday: 'Ontem',
        past: (period: number) => `${period} dias`,
        lastYear: {
          text: '1 ano',
          period: {
            start: dayjs().subtract(1, 'year').toDate(),
            end: dayjs().toDate(),
          },
        },
      },
      footer: {
        cancel: 'Cancelar',
        apply: 'Aplicar',
      },
    },
    i18n: "pt-br",
    value: {
      startDate: startDate,
      endDate: endDate,
    },
    onChange: handleChangeDateFilter,
    dateLooking: "middle",
    displayFormat: DATE_DISPLAY_FORMAT,
    placeholder: "dd/mm/yyyy - dd/mm/yyyy",
    toggleIcon: (props: boolean) => returnIcon(props),
    popoverDirection: "down",
    containerClassName: classNames(
      'datepicker z-[40] md:z-50 bg-[#FBFCFD] focus-within:outline outline-1 outline-secondary w-full xl:max-w-[250px] xl:min-w-[150px] relative h-[42px] text-gray-700 border border-gray-300 rounded-lg text-xs grow-[0.35]',
      {
        'border-[#EEE] bg-gray-100': isLoading,
      }
    ),
    classNames:{
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      input: (input: HTMLInputElement) => {
        if (input && startDate && endDate) {
          input.title =
            dayjs(startDate).format(DATE_DISPLAY_FORMAT) +
            ' - ' +
            dayjs(endDate).format(DATE_DISPLAY_FORMAT);
        }

        return 'w-[calc(100%_-_25px)] h-full text-xs pl-3 truncate text-textcolor disabled:placeholder:!text-[#D1D5DB] disabled:!text-[#D1D5DB]';
      },
    },
    disabled: isLoading
  };
}

export { getDatepickerProps };
