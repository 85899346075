import { date, number, object, string } from 'yup';

// App
import { ICreatePaymentPayload } from 'interfaces/companyTypes';
import { dateUtils } from 'utils/formatDate';

const { validateDateStringDayjsToDate } = dateUtils.validate
const REQUIRED_FIELD_MESSAGE = 'Este campo é obrigatório';
const PAYTIPE_FIELD_MESSAGE = 'Selecione um tipo de pagamento válido.';
const AMOUNT_GREATER_THAN_ZERO_MESSAGE = 'O valor deve ser maior que zero.';
const PAYTYPEID_GREATER_THAN_ZERO_MESSAGE = 'O tipo de pagamento deve ser válido e maior que zero.';

export const formSchema = object<ICreatePaymentPayload>({  
  amount: string()
    .required(REQUIRED_FIELD_MESSAGE)
    .test('is-greater-than-zero', AMOUNT_GREATER_THAN_ZERO_MESSAGE, value =>    
      parseFloat(value.replace(/R\$\s/g, '').replace(',', '.')) > 0
    ),  
  paymentMethodId: number()
    .required(PAYTIPE_FIELD_MESSAGE)
    .moreThan(0, PAYTYPEID_GREATER_THAN_ZERO_MESSAGE),
  dataEvent: date()
  .transform(validateDateStringDayjsToDate)
  .required(REQUIRED_FIELD_MESSAGE) 
});