// Third-party
import { object, string } from 'yup';

// App
import { password } from 'schemas';

export const formSchema = object({
  currentPassword: string().required('Este campo é obrigatório'),
  newPassword: password,
  repeatNewPassword: password.test(
    'isEqual',
    'area_to must be larger than area_from',
    (value, testContext) => {
      if (testContext.parent.newPassword === value) return true;
    }
  ),
});
