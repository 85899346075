// Third-party
import { object, string } from 'yup';

// eslint-disable-next-line no-useless-escape
const emailRegex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])+)+$/;

export const formSchema = object({
  email: string()
    .matches(emailRegex, 'Formato inválido')
    .required('Este campo é obrigatório'),
  password: string().required('Este campo é obrigatório'),
});
