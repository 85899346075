// App
import { IGlobalParamsState } from './interfaces';

export const selectCompanySelected = (state: IGlobalParamsState) => {
  return state.companySelected;
};

export const selectSetCompanySelected = (state: IGlobalParamsState) => {
  return state.setCompanySelected;
};
