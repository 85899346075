import classNames from 'classnames';

function LibraryBuilding({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      className={classNames('text-white', className)}
    >
      <path
        d="M10.6459 0.616943L0.6875 5.41174V7.90625H21.3125V5.39851L10.6459 0.616943ZM19.9375 6.53125H2.0625V6.27576L10.6666 2.13305L19.9375 6.28899V6.53125ZM0.6875 21.3125H21.3125V16.8437H0.6875V21.3125ZM2.0625 18.2187H19.9375V19.9375H2.0625V18.2187ZM3.09375 8.9375H4.46875V15.8125H3.09375V8.9375ZM17.5312 8.9375H18.9062V15.8125H17.5312V8.9375ZM7.90625 8.9375H9.28125V15.8125H7.90625V8.9375ZM12.7188 8.9375H14.0938V15.8125H12.7188V8.9375Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default LibraryBuilding;
