import classNames from 'classnames';

function SearchIcon({ className }: { className?: string }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
    className={classNames('text-white', className)}>
<path d="M10 18C11.775 17.9996 13.4988 17.4054 14.897 16.312L19.293 20.708L20.707 19.294L16.311 14.898C17.405 13.4997 17.9996 11.7754 18 10C18 5.589 14.411 2 10 2C5.589 2 2 5.589 2 10C2 14.411 5.589 18 10 18ZM10 4C13.309 4 16 6.691 16 10C16 13.309 13.309 16 10 16C6.691 16 4 13.309 4 10C4 6.691 6.691 4 10 4Z" fill="currentColor"/>
<path d="M11.4121 8.58603C11.7911 8.96603 12.0001 9.46802 12.0001 10H14.0001C14.001 9.47445 13.8977 8.95392 13.6961 8.46851C13.4946 7.9831 13.1989 7.54245 12.8261 7.17202C11.3121 5.66002 8.68707 5.66002 7.17407 7.17202L8.58607 8.58803C9.34607 7.83003 10.6561 7.83203 11.4121 8.58603Z" fill="currentColor"/>
</svg>

  );
}

export default SearchIcon;
