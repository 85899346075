import Skeleton from 'react-loading-skeleton';

function LoadingSkeleton() {
  return (
    <div className="p-10 overflow-hidden h-[calc(100vh-183px)] flex flex-col gap-y-[20px]">
      <div className="p-10 flex flex-col gap-y-10 border border-gray-200 rounded-xl">
        <Skeleton
          height={55}
          className="inline-block w-full"
          containerClassName="inline-block w-full max-w-[calc(100%/1.7)]"
        />
        <Skeleton
          height={55}
          className="inline-block w-full"
          containerClassName="inline-block w-full max-w-[calc(100%/1.1)]"
        />
        <Skeleton
          height={55}
          className="inline-block w-full"
          containerClassName="inline-block w-full max-w-[calc(100%/4)]"
        />
        <Skeleton
          height={55}
          className="inline-block w-full"
          containerClassName="inline-block w-full max-w-[calc(100%/2.2)]"
        />
      </div>
      <div className="p-10 flex flex-row justify-between items-end border border-gray-200 rounded-xl">
        <Skeleton
          width={55}
          height={200}
          className="inline-block"
          containerClassName="inline-block"
        />
        <Skeleton
          width={55}
          height={250}
          className="inline-block"
          containerClassName="inline-block"
        />
        <Skeleton
          width={55}
          height={100}
          className="inline-block"
          containerClassName="inline-block"
        />
        <Skeleton
          width={55}
          height={300}
          className="inline-block"
          containerClassName="inline-block"
        />
        <Skeleton
          width={55}
          height={220}
          className="inline-block"
          containerClassName="inline-block"
        />
      </div>
    </div>
  );
}

export default LoadingSkeleton;
