//Third-party
import { create } from 'zustand';
import { v4 as uuidv4 } from 'uuid';

// App
import { INotificationState } from './interfaces';
import { INotification } from 'interfaces/notificationTypes';

const useNotificationStore = create<INotificationState>((set) => ({
  notifications: [],
  addNotification: (notification) => set((state) => {
    const stateCopy = [ ...state.notifications ];
    stateCopy.push({ id: uuidv4(), ...notification });

    return { notifications: stateCopy }
  }),
  removeNotification: (notificationId) => set((state) => {
    const filteredNotifications = state.notifications.filter(
      (notification) => notification.id !== notificationId
    );
    return { notifications: filteredNotifications }
  }),
}))

export default useNotificationStore;