// Third-party
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AxiosError, isAxiosError } from 'axios';

// App
import useLoadingStore from 'store/client/loading/useLoadingStore';
import { getUserStatus, signin } from 'services/api/user/auth';
import { PAGES_ROUTES } from 'constants/routes';
import { selectSetLoading } from 'store/client/loading/selectors';
import { selectAddNotification } from 'store/client/notification/selectors';
import useNotificationStore from 'store/client/notification/useNotificationStore';
import { useRefreshToken } from 'store/server/user/mutations';
import useAuthStore from 'store/client/auth/useAuthStore';
import { useGetUser } from 'store/server/user/queries';
import queryClient from 'store/server/queryClient';
import userQueriesKeys from 'store/server/user/queriesKeys';
import useGlobalParamsStore from 'store/client/globalParams/useGlobalParamsStore';
import {
  selectSetIsCheckingToken,
  selectSetLockComponentsRender,
} from 'store/client/globalParams/selectors';
import useCompanySelectedStore from 'store/client/companySelected/useCompanySelectedStore';
import { selectSetCompanySelected } from 'store/client/companySelected/selectors';

function useAuth() {
  /*
  **** Component organization ****

   └── Declaration of generic hooks (e.g., useNavigate)
   └── State declaration
   └── S̶i̶d̶e̶ ̶e̶f̶f̶e̶c̶t̶s̶ ̶(̶e̶.̶g̶.̶,̶ ̶u̶s̶e̶E̶f̶f̶e̶c̶t̶)̶
   └── Memoization (e.g., useMemo)
   └── Handlers (e.g., useCallback)
   └── JSX
  */

  /**
  **** ATTENTION ****

  * The idea of this hook is reuse,
  * so the use of a useEffect in here is inappropriate,
  * as it can cause unnecessary rederizations, infinite loops and other problems.
  */

  const navigate = useNavigate();
  const { data: user } = useGetUser();
  const { tokenTimeoutId } = useAuthStore();
  const setLoading = useLoadingStore(selectSetLoading);
  const refreshUserToken = useRefreshToken();
  const setIsCheckingToken = useGlobalParamsStore(selectSetIsCheckingToken);
  const setLockComponentsRender = useGlobalParamsStore(selectSetLockComponentsRender);
  const setCompanySelected = useCompanySelectedStore(selectSetCompanySelected);

  // └── State declaration
  const [internalIsLoading, setInternalIsLoading] = useState<boolean>(false);
  const addNotification = useNotificationStore(selectAddNotification);
  /**
   * Performs user authentication with the user's email and password
   * @param email
   * @param password
   * @returns void

   */

  /**
   * Disconnects the user, clearing all user-related information, such as tokens, account information, etc.
   * And finally redirects him to the route "/"
   * @returns void
   */
  const signOut = useCallback(
    (disableRedirect = false) => {
      tokenTimeoutId && clearTimeout(tokenTimeoutId);
      localStorage.clear();
      queryClient.removeQueries({ queryKey: [userQueriesKeys.getUser] });
      setCompanySelected(null);

      if (!disableRedirect) {
        navigate(PAGES_ROUTES.unauthenticated.initialRoute);
      }
    },
    [navigate, setCompanySelected, tokenTimeoutId]
  );

  const signIn = useCallback(
    async (email: string, password: string, options?: { firstLogin: boolean }) => {
      setInternalIsLoading(true);
      setLoading(true);
      try {
        const combinedString = JSON.stringify({ username: email, password });

        const encodedAuthenticator = btoa(combinedString);

        const authKeys = await signin(encodedAuthenticator);

        if (authKeys === null) {
          return addNotification({
            message: 'Usuário ou senha incorretos.',
            type: 'error',            
          });
        }
        localStorage.setItem('access_token', authKeys.AuthenticationResult.IdToken);
        localStorage.setItem('refresh_token', authKeys.AuthenticationResult.RefreshToken);

        navigate('/');
      } catch (error: any) {
        localStorage.clear();

        if (isAxiosError(error)) {
          const errors: Record<string | number, Record<string, () => void>> = {
            400: {
              'Username and password must be provided': () => {
                addNotification({
                  message: 'E-mail e senha devem ser informados.',
                  type: 'error',                  

                });
              },
              'Invalid request body': () => {
                addNotification({
                  message: 'Informações incorretas.',
                  type: 'error',                  

                });
              },
              'New password required': async () => {
                navigate(PAGES_ROUTES.unauthenticated.newPassword, {
                  state: {
                    event: 'newPasswordRequired',
                    email: email,
                    currentPassword: password,
                  },
                });

                setIsCheckingToken(false);
                setLockComponentsRender(false);
              },
            },
            401: {
              'Incorrect username or password.': () => {
                if (options?.firstLogin) {
                  addNotification({
                    message: 'Convite expirado. Contate um administrador da plataforma.',
                    type: 'error',                    

                  });
                  return;
                }
                addNotification({ 
                  message: 'E-mail ou senha incorretos.', 
                  type: 'error',                  
                });
              },
            },
            403: {
              Unauthorized: () => {
                addNotification({ 
                  message: 'Usuário ou senha incorretos.', 
                  type: 'error',                  
                });
              },
            },
            404: {
              'User does not exist.': () => {
                addNotification({ 
                  message: 'Usuário não encontrado.', 
                  type: 'error',                  
                });
              },
            },
          };

          (
            errors[error.response?.status ?? 0]?.[error.response?.data.message] ??
            (() => {
              addNotification({ message: error.response?.data.message, type: 'error' });
              setIsCheckingToken(false);
              navigate(PAGES_ROUTES.unauthenticated.initialRoute);
            })
          )();
        }
      } finally {
        setInternalIsLoading(false);
        setLoading(false);
      }
    },
    [addNotification, navigate, setIsCheckingToken, setLoading, setLockComponentsRender]
  );

  /**
   * Search email and password in the url and try to sign in with them
   * @returns void
   */
  const forgotPasswordFinalStep = useCallback(async () => {
    try {
      signOut(true);
      const searchParams = window.location.toString().split('?')[1];
      if (searchParams) {
        // Sets the search params to a string
        const tokenPattern = /token=([^&]+)/;
        const tokenMatch = searchParams.match(tokenPattern);

        if (tokenMatch?.[1] && tokenMatch?.[1] != 'undefined') {
          const token = tokenMatch[1]; // e.g: "email:password"
          // Check if the code exists

          const decodedToken = JSON.parse(atob(token.split('.')[1]));

          navigate(PAGES_ROUTES.unauthenticated.newPassword, {
            state: {
              event: 'forgotPassword',
              token,
              email: decodedToken.email,
            },
          });
        }
      }
    } catch (error: any) {
      if (isAxiosError(error)) {
        if (error.response?.status === 403) {
          addNotification({
            type: 'error',
            message: 'Tempo para troca de senha expirado. Faça um novo pedido de troca de senha.',            
          });

          navigate(PAGES_ROUTES.unauthenticated.forgotPassword);
          return;
        }

        addNotification({
          type: 'error',
          message: 'Sessão para troca de senha inválida. Faça um novo pedido de troca de senha.',          

        });
        navigate(PAGES_ROUTES.unauthenticated.forgotPassword);
      }
    }
  }, [addNotification, navigate, signOut]);

  /**
   * Checks if the user is logged in and returns a boolean value
   * @returns "true" if the user is authenticated or "false" if not
   */
  const isAuthenticated: boolean = useMemo(() => {
    return !!user;
  }, [user]);

  const refreshToken = useCallback(async () => {
    try {
      await refreshUserToken.mutateAsync(false);
    } catch (error) {
      signOut();
    }
  }, [refreshUserToken, signOut]);

  const checkUserStatus = useCallback(
    async (email: string, password: string) => {
      try {
        await getUserStatus(email);
        navigate(PAGES_ROUTES.unauthenticated.newPassword, {
          state: {
            event: 'newPasswordRequired',
            email: email,
            currentPassword: password,
          },
        });
      } catch (error) {
        if (error instanceof AxiosError) {
          if (error.response?.status === 400) {
            addNotification({
              type: 'error',
              message: error.response.data.message,              
            });
            navigate(PAGES_ROUTES.unauthenticated.initialRoute);
            return;
          }
        }

        addNotification({
          type: 'error',
          message: 'Não é possível verificar o usuário para registro de primeira senha.',          
        });
        navigate(PAGES_ROUTES.unauthenticated.initialRoute);
      }
    },
    [addNotification, navigate]
  );

  return {
    signIn,
    signOut,
    isAuthenticated,
    isLoading: internalIsLoading,
    forgotPasswordFinalStep,
    refreshToken,
    checkUserStatus,
  };
}

export default useAuth;
