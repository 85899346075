// Third-party
import { object, string } from 'yup';

// App
import { ICreateCompanyPayload } from 'interfaces/companyTypes';

const REQUIRED_FIELD_MESSAGE = 'Este campo é obrigatório';

export const formSchema = object<ICreateCompanyPayload>({
  description: string().required(REQUIRED_FIELD_MESSAGE),
});
