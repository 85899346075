// Third-party
import { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

// App
import { useGetUser } from 'store/server/user/queries';
import useAuth from 'hooks/useAuth';
import { PAGES_ROUTES } from 'constants/routes';
import useGlobalParamsStore from 'store/client/globalParams/useGlobalParamsStore';
import {
  selectIsCheckingToken,
  selectSetIsCheckingToken,
} from 'store/client/globalParams/selectors';
import useLoadingStore from 'store/client/loading/useLoadingStore';
import { selectSetLoading } from 'store/client/loading/selectors';

function OpenRoute() {
  /*
  **** Component organization ****

   └── Declaration of generic hooks (e.g., useNavigate)
   └── State declaration
   └── Side effects (e.g., useEffect)
   └── Memoization (e.g., useMemo)
   └── Handlers (e.g., useCallback)
   └── JSX
   */

  // └── Declaration of generic hooks (e.g., useNavigate)
  const { data: user, status: getUserStatus } = useGetUser();
  const isCheckingToken = useGlobalParamsStore(selectIsCheckingToken);
  const setIsCheckingToken = useGlobalParamsStore(selectSetIsCheckingToken);
  const { signIn, signOut, checkUserStatus } = useAuth();
  const setLoading = useLoadingStore(selectSetLoading);

  useEffect(() => {
    const checkIfHasFirstLoginToken = async () => {
      try {
        setIsCheckingToken(true);
        const searchParams = window.location.toString().split('?')[1];

        if (
          window.location.toString().includes(PAGES_ROUTES.unauthenticated.signin) &&
          searchParams
        ) {
          // Sets the search params to a string
          const tokenPattern = /token=([^&]+)/;

          // Check for base64 possible characters
          const base64Regex = /^[A-Za-z0-9+/=]+$/;

          // Extract the token
          const tokenMatch = searchParams.match(tokenPattern);
          if (base64Regex.test(tokenMatch?.[1] || '') && tokenMatch?.[1] != 'undefined') {
            const decodedToken = tokenMatch ? atob(tokenMatch[1]) : ''; // e.g: "email:password"
            const email = decodedToken.split(':')[0];
            const password = decodedToken.split(':')[1];

            // If both email and password are present, sign in
            if (email && password) {
              signOut(true);
              await checkUserStatus(email, password);
              return;
            }
          }
        }
      } finally {
        setLoading(false);
        setIsCheckingToken(false);
      }
    };

    checkIfHasFirstLoginToken();
  }, [checkUserStatus, setIsCheckingToken, setLoading, signIn, signOut]);

  if (user) {
    return <Navigate to={user.initialRoute} replace />;
  }

  if (isCheckingToken || (!user && ['loading', 'pending'].includes(getUserStatus))) return null;

  return <Outlet />;
}

export default OpenRoute;
