// Third-party
import { date, object, string } from 'yup';

// App
import { removeMonetaryMask } from 'utils/mask.utils';
import { dateUtils } from 'utils/formatDate';

const { validateDateStringDayjsToDate } = dateUtils.validate

const REQUIRED_FIELD_MESSAGE = 'Este campo é obrigatório';
const PAYTIPE_FIELD_MESSAGE = 'Selecione um tipo de pagamento válido.';

export const formSchema = object({
  dataEvent: date()
  .transform(validateDateStringDayjsToDate)
  .required(REQUIRED_FIELD_MESSAGE),
  description: string().nullable(),
  amount: string()
    .required(REQUIRED_FIELD_MESSAGE)
    .test((value) => removeMonetaryMask(value) > 0),
  paymentMethodId: string()
    .required(PAYTIPE_FIELD_MESSAGE)
    .test(
      'is-greater-than-zero',
      PAYTIPE_FIELD_MESSAGE,
      value => parseInt(value, 10) > 0
    ),
  categoryId: string()
    .required(PAYTIPE_FIELD_MESSAGE)
    .test(
      'is-greater-than-zero',
      PAYTIPE_FIELD_MESSAGE,
      value => parseInt(value, 10) > 0
    ),
});
