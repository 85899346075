import * as yup from 'yup';

const schema = yup.object().shape({
  PRIVATE_API_URL: yup.string().url().required(),
  PUBLIC_API_URL: yup.string().url().required(),
});

export type Config = yup.InferType<typeof schema>;

const config: Config = {
  PRIVATE_API_URL: import.meta.env.VITE_PRIVATE_API_URL,
  PUBLIC_API_URL: import.meta.env.VITE_PUBLIC_API_URL,
};

export const validateEnvs = async () => {
  try {
    await schema.validate(config);
  } catch (err) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    console.error('Missing environment variable', err.message);
  }
};

validateEnvs();

export default config;
