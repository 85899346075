// Third-party
import { create } from 'zustand';

// App
import { ILoadingState } from './interfaces';

const useLoadingStore = create<ILoadingState>((set, get) => ({
  loadingCount: 0,
  setLoading: (value) => set((state) => {
    if (value) {
      return { loadingCount: state.loadingCount + 1 }
    }

    if (state.loadingCount > 0) return { loadingCount: state.loadingCount - 1 }

    return { loadingCount: 0 }
  })
}));

export default useLoadingStore;