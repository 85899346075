/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react-hooks/rules-of-hooks */
// Third-party
import React, { useCallback, useRef, useState } from 'react';
import { Navigate } from 'react-router-dom';
// eslint-disable-next-line import/no-named-as-default
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

// App
import { useGetDashboardData } from 'store/server/company/queries';
import useCompanySelectedStore from 'store/client/companySelected/useCompanySelectedStore';
import { selectCompanySelected } from 'store/client/companySelected/selectors';
import { PAGES_ROUTES } from 'constants/routes';
import {
  ServicesByClientChart,
  ReceiptsInPeriod,
  ServicesChart,
  ExpensesChart,
  LoadingSkeleton,
  PaymentExpenses,
  LastMonthReceipts,
} from './components';
import { GraphIcon, PDFIcon } from 'assets/icons';
import MonthYearSelectorBar from './components/MonthYearSelector';
import getMonthStartAndEndUtil from 'utils/getMonthStartAndEnd.util';
import {
  selectSelectedClient as selectSelectedClient,
  selectSelectedMonth,
  selectSelectedYear,
  selectSetSelectedClient as selectSetSelectedClient,
} from 'store/client/dashboard/selectors';
import useDashboardStore from 'store/client/dashboard/useDashboardStore';
import { cnpjMask } from 'utils/mask.utils';
import ExportButton from 'components/ExportButton ';
import { setPageCount, setPageCountWithTotal, setPdfHeader } from 'utils/pdf.utils';
import Skeleton from 'react-loading-skeleton';
import { useGetClientList } from 'store/server/client/queries';
import InputSearch, { IInputSearchProps } from 'components/InputSearch';
import { IClient } from 'interfaces/clientTypes';
import { ListItemType } from 'types/listItemType';
import { ORDER_ATTRIBUTTES } from 'constants/orderAttributtes';
import PageHeader from 'components/PageHeader/components/PageHeader';
import { dateUtils } from 'utils/formatDate';

const { DATE_DISPLAY_FORMAT } = dateUtils.constants;

const { ASC } = ORDER_ATTRIBUTTES;
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/Sao_Paulo');

const Dashboard: React.FC = () => {
  const dashboardContainerRef = useRef<HTMLDivElement>(null);
  const companySelected = useCompanySelectedStore(selectCompanySelected);

  const selectedMonth = useDashboardStore(selectSelectedMonth);
  const selectedYear = useDashboardStore(selectSelectedYear);
  const selectedClient = useDashboardStore(selectSelectedClient);
  const setSelectedClient = useDashboardStore(selectSetSelectedClient);

  const [isGeneratingPdf, setIsGeneratingPdf] = useState<boolean>(false);

  if (!companySelected) {
    return <Navigate to={PAGES_ROUTES.authenticated.commonInitialRoute} replace />;
  }

  const { isLoading: dashboardDataIsLoading, isRefetching: dashboardDataIsRefetching } =
    useGetDashboardData(companySelected.id, {
      ...getMonthStartAndEndUtil(Number(selectedYear), Number(selectedMonth)),
      clientId: selectedClient?.id,
    });

  const handlePrint = async () => {
    setIsGeneratingPdf(true);
    try {
      const pdf = new jsPDF('p', 'pt', 'a4');
      const pageWidth = 595.28;
      const marginX = 20;
      const marginY = 20;

      const chartDivs = dashboardContainerRef?.current?.querySelectorAll('.graph') ?? [];

      const period = getMonthStartAndEndUtil(selectedYear, selectedMonth);

      let position = marginY;
      setPdfHeader(pdf, {
        pageWidth,
        companyName: companySelected.name,
        companyCnpj: cnpjMask(companySelected.cnpj),
        selectedMonth,
        selectedYear,
        headerTitle: 'Gráficos Financeiros',
        headerSubtitle: selectedClient ? `Cliente: ${selectedClient.name}` : undefined,
        pdfPeriod: `${dayjs(period.initialDate).format(DATE_DISPLAY_FORMAT)} - ${dayjs(
          period.finalDate
        ).format(DATE_DISPLAY_FORMAT)}`,
      });

      for (let index = 0; index < chartDivs.length; index++) {
        const chartDiv = chartDivs[index];

        if (index !== 0) {
          pdf.addPage();
          pdf.addImage('/logo_objetiva.png', 'PNG', 20, 20, 87, 60);
          setPdfHeader(pdf, {
            pageWidth,
            companyName: companySelected.name,
            companyCnpj: cnpjMask(companySelected.cnpj),
            selectedMonth,
            selectedYear,
            headerTitle: 'Gráficos Financeiros',
            headerSubtitle: selectedClient ? `Cliente: ${selectedClient.name}` : undefined,
            pdfPeriod: `${dayjs(period.initialDate).format(DATE_DISPLAY_FORMAT)} - ${dayjs(
              period.finalDate
            ).format(DATE_DISPLAY_FORMAT)}`,
          });
          position = marginY + 100;
        }

        await html2canvas(chartDiv as HTMLElement).then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const imgWidth = pageWidth - marginX * 2;
          const imgHeight = (canvas.height / canvas.width) * imgWidth;

          const imgX = marginX;
          const imgY = pdf.getNumberOfPages() === 1 ? position + 120 : position;

          pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth, imgHeight);

          position += imgHeight + marginY;
        });
      }

      setPageCountWithTotal(pdf, { pageWidth });

      pdf.save(`ECVFinancy_Graficos_${dayjs().format('DD_MM_YYYY_hh_mm_ss')}.pdf`);
    } finally {
      setIsGeneratingPdf(false);
    }
  };

  const [clientNameToSearchSubmitted, setClientNameToSearchSubmitted] = useState<
    string | undefined
  >(undefined);

  const {
    data: clientListData,
    isLoading: clientListIsLoading,
    isRefetching: clientListIsRefetching,
  } = useGetClientList(companySelected.id, {
    start: 0,
    size: 1000,
    field: 'client.name',
    order: ASC,
    filter: clientNameToSearchSubmitted ? clientNameToSearchSubmitted.trim() : null,
    status: 'ACTIVED',
  });

  const handleOnSearchClient = useCallback((searchText: string) => {
    setClientNameToSearchSubmitted(searchText);
  }, []);

  const handleOnSelectedClient = useCallback(
    (selectedClient: ListItemType<IInputSearchProps<IClient>['listToSelect']> | undefined) => {
      if (selectedClient) {
        return setSelectedClient(selectedClient);
      }
      setSelectedClient(undefined);
    },
    [setSelectedClient]
  );

  return (
    <div className="flex flex-col px-[32px] py-[10px] mt-[20px] md:mt-[30px]">
      <PageHeader
        iconTextTitleProps={{
          iconProps: {
            iconJSX: <GraphIcon className="!text-secondary w-[22px] h-[22px]" />,
          },
          textProps: {
            content: 'Gráficos',
          },
        }}
      />
      <div className="w-full flex max-md:flex-col max-md:gap-y-[12px] justify-between ">
        {/* HEADER */}
        <div className="flex max-md:flex-col max-md:gap-y-[12px] gap-x-[10px]">
          <MonthYearSelectorBar disabled={dashboardDataIsLoading || dashboardDataIsRefetching} />
          {clientListIsLoading ? (
            <Skeleton
              width={217}
              height={44}
              baseColor="#f3f3f3"
              highlightColor="white"
              containerClassName="flex h-[42px]"
              className="!rounded-lg"
            />
          ) : (
            <InputSearch<IClient>
              listToSelect={
                clientListData?.data.map((client) => ({
                  title: client.name,
                  subtitle: client.email,
                  ...client,
                })) ?? []
              }
              emptyListMessage="Nenhum cliente encontrado."
              isLoading={clientListIsLoading || clientListIsRefetching}
              onSearch={handleOnSearchClient}
              onSelect={handleOnSelectedClient}
              inputOptions={{
                placeholderText: 'Selecione um cliente',
                disabled: dashboardDataIsLoading || dashboardDataIsRefetching,
              }}
            />
          )}
        </div>
        <ExportButton
          loading={dashboardDataIsLoading || dashboardDataIsRefetching || isGeneratingPdf}
          onClick={() => {
            handlePrint();
          }}
          icon={<PDFIcon className="ml-4 !text-buttontextcolor" />}
          buttonClassName="md:!max-w-[150px] !gap-x-0"
          buttonText="Exportar"
          buttonDisabled={dashboardDataIsLoading || dashboardDataIsRefetching}
        />
      </div>
      {dashboardDataIsLoading ? (
        <LoadingSkeleton />
      ) : (
        <div className="w-full max-w-[1424px] self-center flex flex-col gap-y-[60px] mt-[20px] mb-[80px] ">
          <ServicesChart />
          <ServicesByClientChart />
          <ExpensesChart />
          <PaymentExpenses />
          <ReceiptsInPeriod />
          <LastMonthReceipts />
        </div>
      )}
      {dashboardDataIsLoading ? (
        <LoadingSkeleton />
      ) : (
        <div style={{ position: 'absolute', width: '0', height: '0', overflow: 'hidden' }}>
          <div
            ref={dashboardContainerRef}
            className="!w-[1424px] h-fit self-center px-[24px] flex flex-col gap-y-[100px]"
          >
            <ServicesChart toExport />
            <ServicesByClientChart toExport />
            <ExpensesChart toExport />
            <PaymentExpenses toExport />
            <ReceiptsInPeriod toExport />
            <LastMonthReceipts toExport />
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
