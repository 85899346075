// Third-party
import classNames from 'classnames';
import { ForwardedRef, InputHTMLAttributes, ReactNode, forwardRef } from 'react';

type ToggleProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  labelText?: string; // Label text
  textLeft?: boolean;
  displayWarning?: boolean; // Flag to display or hide the warning
  warningMessage?: string; // Displayed warning message
  placeholder?: string; // Placeholder text
  disabled?: boolean;
  /*
   **** Flags to show or hide error message and outline ****
   */
  showError?: boolean; // Flag to show or hide error message and outline
  showErrorMessage?: boolean; // Flag to show or hide error message
  showErrorOutline?: boolean; // Flag to show or hide error outline
  className?: string | Record<string, unknown>; // CSS classes for styling
  labelClassName?: string | Record<string, unknown>; // CSS classes for styling
  toggleClassName?: string | Record<string, unknown>; // CSS classes for styling
};

interface IError {
  showOutline: boolean;
}

interface ICssClasses {
  toggle: string; // CSS classes
  bg: string; // CSS classes
  label: string; // CSS classes
  textLabel: string; // CSS classes
  icon: string; // CSS classes
}

interface IToggleProps {
  cssClasses: Partial<ICssClasses>;
  labelText: ReactNode | string; // Label text
  error: Partial<IError>;
}

const Toggle = forwardRef(
  (
    {
      labelText,
      cssClasses,
      error,
      ...toggleHtmlProps
    }: Partial<IToggleProps> & InputHTMLAttributes<HTMLInputElement>,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <label
        className={classNames(
          'relative flex flex-col justify-center items-center cursor-pointer text-sm font-medium text-textcolor gap-x-2.5',
          cssClasses?.label
        )}
      >
      {labelText}
        <div className="relative cursor-pointer">
          <input
            type="checkbox"
            className={classNames(
              'sr-only peer',
              {
                '!outline-0': error?.showOutline,
              },
              cssClasses?.toggle
            )}
            ref={ref}
            {...toggleHtmlProps}
          />
          <div
            className={classNames(
              "w-11 h-6 bg-gray-400 hover:opacity-95 duration-200 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-disabled:bg-gray-200 peer-disabled:cursor-default after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-buttontextcolor after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-primary after:!border-none",
              {
                'shadow-red-400 shadow-[0_0_0_2px] border-opacity-0 !outline-0 !ring-0':
                  error?.showOutline,
              },
              cssClasses?.bg
            )}
          ></div>
        </div>
      </label>
    );
  }
);

Toggle.displayName = 'ToggleRf';

export default Toggle;
