// Third-party
import { useMutation } from '@tanstack/react-query';

// App
import queryClient from '../queryClient';
import queriesKeys from '../company/queriesKeys';
import { IUpdateUnitPayload } from 'interfaces/unitTypes';
import { updateUnit } from 'services/api/unit';

export const useUpdateUnitMutation = (unitId: number) =>
  useMutation({
    mutationFn: async (data: IUpdateUnitPayload) => {
      await updateUnit(unitId, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queriesKeys.companyDetails] });
    },
  });
