// Third-party
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

// App
import useAuth from 'hooks/useAuth';
import { useGetUser } from 'store/server/user/queries';
import useAuthStore from 'store/client/auth/useAuthStore';
import {
  selectCleanTokenTimeoutId,
  selectSetTokenTimeoutId,
  selectTokenTimeoutId,
} from 'store/client/auth/selectors';
import queryClient from 'store/server/queryClient';
import userQueriesKeys from 'store/server/user/queriesKeys';
import useGlobalParamsStore from 'store/client/globalParams/useGlobalParamsStore';
import { selectSetFirstRendered } from 'store/client/globalParams/selectors';

/**
 * A component that is responsible for executing the logic for checking the existence, expiration and renewal of the access token.
 * When entering a nested route within RefreshTokenProvider, all the logic is triggered.
 *
 * @props allowedAccessLevels - An array of access levels that are allowed to access the protected route.
 * @returns {Outlet} Component Outlet from React Router Dom -> https://reactrouter.com/en/main/components/outlet
 *
 */
function RefreshTokenProvider() {
  /*
  **** Component organization ****

   └── Declaration of generic hooks (e.g., useNavigate)
   └── State declaration
   └── Side effects (e.g., useEffect)
   └── Memoization (e.g., useMemo)
   └── Handlers (e.g., useCallback)
   └── JSX
   */

  // └── Declaration of generic hooks (e.g., useNavigate)
  const { refreshToken } = useAuth();
  const setFirstRendered = useGlobalParamsStore(selectSetFirstRendered);

  // └── State declaration
  //   └── Global
  const { data: user } = useGetUser(true);
  const timeoutId = useAuthStore(selectTokenTimeoutId);
  const setTokenTimeoutId = useAuthStore(selectSetTokenTimeoutId);
  const cleanTokenTimeoutId = useAuthStore(selectCleanTokenTimeoutId);
  //   └── Internal

  // └── Side effects (e.g., useEffect)
  useEffect(() => {
    setFirstRendered();
  }, [setFirstRendered]);

  useEffect(() => {
    const searchParams = window.location.toString().split('?')[1];
    const tokenPattern = /token=([^&]+)/;

    if (!searchParams?.match(tokenPattern)) {
      if (timeoutId) {
        clearTimeout(timeoutId);
        cleanTokenTimeoutId();
      }

      const token = localStorage.getItem('access_token');
      let tokenExpiration: number;

      if (token) {
        // Decode token to get expiration time
        const decodedToken = JSON.parse(atob(token.split('.')[1]));
        // Check if token is expired or within the refresh threshold (e.g., 5 minutes before expiration)
        tokenExpiration = Math.floor(decodedToken.exp * 1000) - Date.now() - 300000;
        if (tokenExpiration > 0) {
          const startTimeout = () => {
            const newTimeout = setTimeout(() => {
              refreshToken();
              timeoutId && clearTimeout(timeoutId);
            }, tokenExpiration);
            setTokenTimeoutId(newTimeout);
          };

          // Start the initial timeout
          startTimeout();
          if (!user) {
            queryClient.invalidateQueries({ queryKey: [userQueriesKeys.getUser] });
          }
        } else {
          refreshToken();
        }
      } else {
        localStorage.clear();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, localStorage.getItem('access_token')]);

  return <Outlet />;
}

export default RefreshTokenProvider;
