// Third-party
import { ForwardedRef, ReactNode, SelectHTMLAttributes, forwardRef, useState } from 'react';
import classNames from 'classnames';
import { DropDownArrowIcon } from 'assets/icons';

// App

interface IError {
  showOutline: boolean;
}

interface ICssClasses {
  select: string; // CSS classes
  label: string; // CSS classes
  textLabel: string; // CSS classes
  icon: string; // CSS classes
}

interface ISelectProps {
  cssClasses: Partial<ICssClasses>;
  labelText: ReactNode | string; // Label text
  error: Partial<IError>;
}

const Select = forwardRef(
  (
    {
      labelText,
      cssClasses,
      error,
      children,
      ...selectHtmlProps
    }: Partial<ISelectProps> & SelectHTMLAttributes<HTMLSelectElement>,
    ref: ForwardedRef<HTMLSelectElement>
  ) => {
    /*
  **** Component organization ****

   └── Declaration of generic hooks (e.g., useNavigate)
   └── State declaration
   └── Side effects (e.g., useEffect)
   └── Memoization (e.g., useMemo)
   └── Handlers (e.g., useCallback)
   └── JSX
   */
    const [thisInFocus, setThisInFocus] = useState(false);

    // └── State declaration

    return (
      <label
        className={classNames(
          'font-inter text-[#374151] text-sm font-medium flex flex-col justify-start w-full m-0 truncate !overflow-visible relative cursor-pointer',
          cssClasses?.label,
          {
            'gap-y-1': labelText,
          }
        )}
        htmlFor={selectHtmlProps.name}
      >
        <span className={classNames('truncate w-fit', cssClasses?.textLabel)}>{labelText}</span>
        <div className="relative w-full h-fit">
          <select
            id={selectHtmlProps.name}
            name={selectHtmlProps.name}
            onClick={() => setThisInFocus(!thisInFocus)}
            onBlur={() => setThisInFocus(false)}
            className={classNames(
              'bg-[#FBFCFD] border border-[#D1D5DB] text-primary text-sm font-normal rounded-lg focus:outline outline-1 focus:outline-secondary block w-full p-2.5 disabled:!text-black disabled:border-[#EEE]  disabled:bg-gray-100  read-only:bg-gray-10 appearance-none truncate overflow-visible pr-8',
              {
                'shadow-red-400 shadow-[0_0_0_2px] border-opacity-0 !outline-0': error?.showOutline,
              },
              cssClasses?.select
            )} // CSS classes for styling
            ref={ref}
            {...selectHtmlProps}
          >
            {children}
          </select>
          {!selectHtmlProps.disabled && (
            <button className="absolute top-0 right-0 h-full px-3 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed pointer-events-none">
              <DropDownArrowIcon
                className={classNames('transition-transform', cssClasses?.icon, {
                  'transform rotate-180': thisInFocus,
                })}
              />
            </button>
          )}
        </div>
      </label>
    );
  }
);

Select.displayName = 'Select';

export default Select;
