export interface IIconTextTitleProps {
    containerProps?:{
        containerClassName?: string;
    }
    iconProps: {
        iconJSX: JSX.Element;        
    },
    textProps : {
        content: string;
        contentClassName?: string;
    }

}

function IconTextTitle({iconProps, textProps, containerProps}: IIconTextTitleProps) {
                    
    return (
        <h1 className={containerProps?.containerClassName || 'expense-sale-page-icon-text w-fit'}>
            {iconProps?.iconJSX}
            <span className={`${textProps?.contentClassName || ''} md:whitespace-nowrap break-all `}>
                {textProps.content}
            </span>
        </h1>
    );
}

export default IconTextTitle;