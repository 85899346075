// App
import {
  IClient,
  IClientListParams,
  ICreateClientPayload,
  IEditClientPayload,
} from 'interfaces/clientTypes';
import api from '../api';
import { routes } from 'constants/api';
import { IResponseWithData, IResponseWithDataListPaginated } from 'interfaces/apiTypes';

export async function createClient(companyId: number, data: ICreateClientPayload): Promise<void> {
  await api.auth.post<{ message: string }>(
    routes.v1.client.create.replace(':companyId', companyId.toString()),
    data
  );
}
export async function editClient(
  companyId: number,
  clientId: number,
  data: IEditClientPayload
): Promise<void> {
  await api.auth.patch<{ message: string }>(
    routes.v1.client.edit
      .replace(':companyId', companyId.toString())
      .replace(':clientId', clientId.toString()),
    data
  );
}

export async function getClientById(companyId: number, clientId: number): Promise<IClient | null> {
  const { data, status } = await api.auth.get<IResponseWithData<IClient>>(
    routes.v1.client.getClientById
      .replace(':companyId', companyId.toString())
      .replace(':clientId', clientId.toString())
  );
  if (status === 204) return null;

  return data.response;
}

export async function listClient(
  companyId: number,
  { start, size, filter, field, order, status }: IClientListParams
): Promise<IResponseWithDataListPaginated<IClient>['response']> {
  const { data, status: requestStatus } = await api.auth.get<
    IResponseWithDataListPaginated<IClient>
  >(routes.v1.client.list.replace(':companyId', companyId.toString()), {
    params: {
      start,
      size,
      filter,
      field,
      order,
      status,
    },
  });

  if (requestStatus === 204) {
    return {
      data: [],
      pageNumber: 0,
      nextPage: 0,
      lastPage: 0,
      totalPages: 0,
      total: 0,
      pageSize: 0,
    };
  }

  return data.response;
}
