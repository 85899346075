// Third-party
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

// App
import { IGlobalParamsState } from './interfaces';
import { setCompanySelected } from './actions';

const useCompanySelectedStore = create(
  persist<IGlobalParamsState>(
    (set) => ({
      companySelected: null,
      setCompanySelected: setCompanySelected(set),
    }),
    {
      name: 'companySelected',
    }
  )
);

export default useCompanySelectedStore;
