import { DeleteIcon } from 'assets/icons';
import classNames from 'classnames';

interface ICleanFilterButton { 
    handleClick: () => void ;
    disabled?: boolean;    
}

function CleanFilterButton({
    handleClick,
    disabled,    
}: ICleanFilterButton) {
  return (
    <button
    className={classNames(
      'xl:max-w-fit  bg-primary disabled:bg-[#D1D5DB] text-white py-2 px-4 rounded-lg flex justify-center items-center w-full h-[42px] '
    )}                 
      onClick={handleClick}
      disabled={disabled}
    >
      <DeleteIcon />
    </button>
  );
}

export default CleanFilterButton;
