/* eslint-disable @typescript-eslint/ban-ts-comment */
// Third-party
import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import dayjs from 'dayjs';

//App
import { IUnit } from 'interfaces/unitTypes';
import { InputText } from 'components';
import {
  AddUserIcon,
  ArrowIcon,
  CloseIcon,
  DeleteIcon,
  DropDownArrowIcon,
  PenIcon,
  ProfileIcon,
} from 'assets/icons';
import ListSkeleton from './components/ListSkeleton';
import Skeleton from 'react-loading-skeleton';
import { PAGES_ROUTES } from 'constants/routes';
import Datepicker, { DateValueType } from 'react-tailwindcss-datepicker';
import isBetween from 'dayjs/plugin/isBetween';
import { useGetUser } from 'store/server/user/queries';
import { dateUtils } from '../../../utils/formatDate';
import { screenConfig } from 'utils/screenConfig.util';
import {  ORDER_ATTRIBUTTES, ascDescType } from 'constants/orderAttributtes';


const { ASC, DESC } = ORDER_ATTRIBUTTES;
const { DATE_DISPLAY_FORMAT } = dateUtils.constants;
const { MEDIUM_SCREEN_SIZE } = screenConfig.constants
dayjs.extend(isBetween);

function List() {
  /*
  **** Component organization ****

   └── Declaration of generic hooks (e.g., useNavigate)
   └── State declaration
   └── Side effects (e.g., useEffect)
   └── Memoization (e.g., useMemo)
   └── Handlers (e.g., useCallback)
   └── JSX
   */

  // └── Declaration of generic hooks (e.g., useNavigate)
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // └── State declaration
  const { data: userLogged, isLoading: userLoggedIsLoading } = useGetUser();
  const [unitList, setUnitList] = useState<IUnit[]>([
    {
      id: '1',
      city: 'Campinas',
      cnpj: '55.555.555/0001-05',
      createdAt: '2022-10-31T12:30:23.823Z',
      name: 'Troca óleo LTDA',
      status: 'Ativo',
      uf: 'SP',
      userName: 'teste@teste.com',
    },
    {
      id: '2',
      city: 'Curitiba',
      cnpj: '33.333.333/0001-03',
      createdAt: '2006-06-13T12:30:23.823Z',
      name: 'Troca pneu LTDA',
      status: 'Ativo',
      uf: 'PR',
      userName: 'teste@teste.com',
    },
    {
      id: '3',
      city: 'Maceió',
      cnpj: '11.111.111/0001-01',
      createdAt: '2020-08-21T12:30:23.823Z',
      name: 'Alpha vistoria veículos leves LTDA',
      status: 'Ativo',
      uf: 'AL',
      userName: 'teste@teste.com',
    },
    {
      id: '4',
      city: 'Fortaleza',
      cnpj: '22.222.222/0001-02',
      createdAt: '2010-03-11T12:30:23.823Z',
      name: 'Vistoria LTDA',
      status: 'Ativo',
      uf: 'CE',
      userName: 'teste@teste.com',
    },
  ]);
  const [unitListIsLoading, setUnitListIsLoading] = useState<boolean>(false);
  const [sortField, setSortField] = useState<keyof IUnit>('name');
  const [order, setOrder] = useState<ascDescType>(ASC);
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [dateFilter, setDateFilter] = useState<DateValueType>({
    startDate: null,
    endDate: null,
  });
  const [unitListFiltered, setUnitListFiltered] = useState<IUnit[]>([]);

  // TODO: Integrar com API
  // └── Side effects (e.g., useEffect)
  // useEffect(() => {
  //   const getUsers = async () => {
  //     try {
  //       dispatch(setLoading(true));
  //       setUsersListIsLoading(true);
  //       const result = await getUsersList();

  //       setUsers(result);
  //     } catch (error) {
  //       if (error instanceof AxiosError) {
  //         if (error.response?.status === 404) {
  //           dispatch(
  //             addNotification({
  //               type: "success",
  //               message: "Nenhum usuário foi encontrado.",
  //             })
  //           );
  //           return;
  //         }
  //       }
  //       dispatch(
  //         addNotification({
  //           type: "error",
  //           message:
  //             "Erro ao tentar listar usuários. Tente novamente mais tarde.",
  //         })
  //       );
  //     } finally {
  //       dispatch(setLoading(false));
  //       setUsersListIsLoading(false);
  //     }
  //   };

  //   getUsers();
  // }, [dispatch]);

  const handleSortByColumn = useCallback(
    (columnName: keyof IUnit, orderSelected?: ascDescType) => () => {
      if (sortField !== columnName) {
        setOrder(ASC); // If the sort field is different from the clicked column, set the order to ASC
      } else {
        setOrder(orderSelected ? orderSelected : order === ASC ? DESC : ASC); // If the sort field is the same as the clicked column, toggle between ASC and 'desc' order
      }
      setSortField(columnName); // Set the sort field to the clicked column
    },
    [sortField, order]
  );

  const filterUnitList = useCallback(
    (searchFilterParam: string = searchFilter) => {
      const unitsIndex: Record<number, number> = {};
      const unitListFiltered = unitList.filter((unitToFilter, index) => {
        unitsIndex[Number(unitToFilter.id)] = index;
        const searchFilterWithoutCase = searchFilterParam.toLowerCase(); // Converts the value of "searchFilter" to lowercase, to perform case-insensitive comparisons.

        if (searchFilter) {
          return (
            unitToFilter.name.toLowerCase().includes(searchFilterWithoutCase) ||
            unitToFilter.city.toLowerCase().includes(searchFilterWithoutCase) ||
            unitToFilter.status.toLowerCase().includes(searchFilterWithoutCase) ||
            unitToFilter.cnpj.toLowerCase().includes(searchFilterWithoutCase) ||
            unitToFilter.uf.toLowerCase().includes(searchFilterWithoutCase) ||
            unitToFilter.userName.toLowerCase().includes(searchFilterWithoutCase)
          );
        }

        if (dateFilter?.startDate && dateFilter.endDate) {
          return dayjs(unitToFilter.createdAt).isBetween(
            dateFilter.startDate,
            dateFilter.endDate,
            'day',
            '[]'
          );
        }

        return true;
      });

      setUnitListFiltered(unitListFiltered);
    },
    [searchFilter, unitList, dateFilter]
  );

  const checkIfShowCards = useCallback(() => {
    if (window.innerWidth < MEDIUM_SCREEN_SIZE) {
      sortField !== 'name' && setSortField('name');
      order !== ASC && setOrder(ASC);
      if (searchFilter) {
        setSearchFilter('');
        filterUnitList('');
      }
    }
  }, [filterUnitList, order, searchFilter, sortField]);

  useEffect(() => {
    window.removeEventListener('resize', checkIfShowCards);
    window.addEventListener('resize', checkIfShowCards);

    return () => {
      window.removeEventListener('resize', checkIfShowCards);
    };
  }, [checkIfShowCards]);

  useEffect(() => {
    filterUnitList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitList, searchFilter, dateFilter]);

  const unitListSorted = useMemo(() => {
    return [...unitListFiltered].sort((unitA, unitB) => {
      const { sortField: field, order: sortOrder } = { sortField, order };

      let firstUnit = unitA;
      let secondUnit = unitB;

      if (sortOrder === DESC) {
        [firstUnit, secondUnit] = [secondUnit, firstUnit];
      }

      switch (field) {
        case 'createdAt':
          return dayjs(firstUnit.createdAt).unix() - dayjs(secondUnit.createdAt).unix();
        default:
          // eslint-disable-next-line no-case-declarations
          const compareResult = firstUnit[field]?.localeCompare(secondUnit[field] || '');
          if (compareResult === 0) {
            return dayjs(unitB.createdAt).unix() - dayjs(unitA.createdAt).unix();
          }
          return compareResult;
      }
    });
  }, [sortField, order, unitListFiltered]);

  /**
   * Returns a memoized arrow component with the correct direction based on the 'order' prop.
   *
   * @param {string} order - The order prop indicating the direction (ASC or 'desc').
   * @returns {React.Component} - The memoized Arrow component.
   */
  const ArrowWithCorrectDirection = useMemo(
    () =>
      function Arrow({ className }: { className?: string | Record<string, unknown> }) {
        return (
          <ArrowIcon
            className={classNames(
              {
                'rotate-180': order === ASC, // Adds the 'rotate-180' class if the 'order' prop is ASC
              },
              className
            )}
          />
        );
      },
    [order] // Dependency for useMemo, the component will be recomputed when the 'order' prop changes
  );

  const showSkeletonLoading = useMemo(
    () => unitListIsLoading || userLoggedIsLoading,
    [userLoggedIsLoading, unitListIsLoading]
  );

  const showClearFiltersButton = useMemo(
    () => searchFilter || (dateFilter?.startDate && dateFilter.endDate),
    [searchFilter, dateFilter]
  );

  const handleClearFilters = useCallback(() => {
    setDateFilter({ startDate: null, endDate: null });
    setSearchFilter('');
    // setDateFilterSubmitted(null);
    // setSearchFilterSubmitted("");
    filterUnitList();
  }, [dateFilter, searchFilter]);

  return (
    <div
      className={classNames('h-full', {
        'overflow-hidden max-h-[calc(100vh-64px)] md:max-h-[calc(100vh-71px)]': showSkeletonLoading,
      })}
    >
      <div
        className={classNames(
          'pt-5 md:pt-[42px] p-[27px] md:p-8 w-full min-h-[calc(100vh-64px)] md:min-h-[calc(100vh-71px)]',
          {
            'overflow-hidden !h-screen': showSkeletonLoading,
          }
        )}
      >
        <h1 className="flex items-center gap-2.5 text-2xl font-semibold text-[#595959] mb-3 md:mb-[27px]">
          <ProfileIcon className="w-[22px] h-[24px] !text-secondary" /> Lista de Unidades
        </h1>
        <div className="w-full flex flex-col md:flex-row-reverse justify-between mb-4 gap-3">
          <div className="w-full flex md:max-w-fit justify-between items-center">
            {showSkeletonLoading ? (
              <Skeleton
                width={147}
                height={42}
                baseColor="white"
                highlightColor="#f3f3f3"
                containerClassName="flex h-[42px]"
                className="!rounded-lg"
              />
            ) : (
              <button
                type="button"
                className="bg-primary text-xs font-bold leading-4 disabled:bg-[#D1D5DB] text-buttontextcolor py-2 pl-4 pr-[18px] rounded-lg flex justify-center items-center h-[42px] max-w-[147px] tracking-[0.6px]"
                onClick={() => navigate(PAGES_ROUTES.authenticated.unit.create)}
              >
                CADASTRAR
                <AddUserIcon className="ml-4 w-[19px] h-[22px] !text-buttontextcolor" />
              </button>
            )}
          </div>

          <div
            className={classNames(
              'w-full max-w-[452px] hidden md:flex justify-between items-center gap-3',
              {
                'max-w-[578px]': showClearFiltersButton,
              }
            )}
          >
            {showSkeletonLoading ? (
              <>
                <Skeleton
                  height={42}
                  baseColor="white"
                  highlightColor="#f3f3f3"
                  className="w-full max-w-[234px] !rounded-lg"
                  containerClassName="w-full max-w-[234px]"
                />
                <Skeleton
                  height={42}
                  baseColor="white"
                  highlightColor="#f3f3f3"
                  className="w-full max-w-[56px] !rounded-lg"
                  containerClassName="w-full max-w-[56px]"
                />
              </>
            ) : (
              <>
                <InputText
                  name="searchFilter"
                  type="text"
                  placeholder="Buscar unidade"
                  onChange={async (event: { target: { value: string } }) => {
                    setSearchFilter(event.target.value);
                    if (!event.target.value) {
                      filterUnitList(event.target.value);
                    }
                  }}
                  value={searchFilter}
                  cssClasses={{
                    input: '!mt-0 max-w-[234px]',
                  }}
                />
                <Datepicker
                  primaryColor="indigo"
                  separator="-"
                  showShortcuts
                  showFooter
                  configs={{
                    shortcuts: {
                      today: 'Hoje',
                      yesterday: 'Ontem',
                      past: (period) => `${period} dias`,
                      lastYear: {
                        text: '1 ano',
                        period: {
                          start: dayjs().subtract(1, 'year').toDate(),
                          end: dayjs().toDate(),
                        },
                      },
                    },
                    footer: {
                      cancel: 'Cancelar',
                      apply: 'Aplicar',
                    },
                  }}
                  i18n={'pt-br'}
                  value={dateFilter}
                  onChange={(value: DateValueType) => {
                    setDateFilter(value);
                  }}
                  dateLooking="middle"
                  displayFormat={DATE_DISPLAY_FORMAT}
                  placeholder="dd/mm/yyyy - dd/mm/yyyy"
                  toggleIcon={(props) => {
                    if (props) return <DropDownArrowIcon className="text-primary" />;
                    return <CloseIcon className="text-primary w-2.5" />;
                  }}
                  popoverDirection="down"
                  containerClassName="datepicker z-[40] bg-[#FBFCFD] focus-within:outline outline-1 outline-secondary row-start-3 row-end-4 md:row-start-1 md:row-end-2 col-start-2 col-end-5 md:col-start-5 md:col-end-6 relative h-[42px] text-gray-700 border border-gray-300 rounded-lg text-xs grow-[0.35] w-full"
                  classNames={{
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    input: (input: HTMLInputElement) => {
                      if (input && dateFilter) {
                        input.title =
                          dayjs(dateFilter.startDate).format(DATE_DISPLAY_FORMAT) +
                          ' - ' +
                          dayjs(dateFilter.endDate).format(DATE_DISPLAY_FORMAT);
                      }

                      return 'h-full text-xs pl-3 truncate text-textcolor';
                    },
                  }}
                />
                {showClearFiltersButton && (
                  <button
                    className="row-start-4 md:row-start-1 md:row-end-2 col-start-4 col-end-5 md:col-start-7 md:col-end-8 justify-self-end bg-primary text-xs font-bold leading-4 tracking-[0.6px] text-white flex justify-center items-center gap-x-3 w-fit h-[42px] py-2 px-4 rounded-lg"
                    onClick={handleClearFilters}
                  >
                    <span className="hidden min-[1060px]:inline-block">LIMPAR</span>
                    <DeleteIcon />
                  </button>
                )}
              </>
            )}
          </div>
        </div>

        {showSkeletonLoading ? (
          <ListSkeleton />
        ) : unitListSorted.length <= 0 ? (
          <p className="w-full text-center text-[#595959] font-semibold">
            Nenhuma unidade encontrada!
          </p>
        ) : (
          <div className="relative overflow-visible md:overflow-x-auto md:shadow-container md:rounded-lg md:border md:border-gray-200 w-full flex flex-col gap-y-5">
            {/* Desktop */}
            <table className="w-full text-sm text-left text-gray-500 hidden md:table">
              <thead className="font-inter text-[10px] text-primary font-bold uppercase bg-gray-50 w-full">
                <tr>
                  <th
                    scope="col"
                    className="h-full px-6 py-3 cursor-pointer hover:bg-gray-100 w-fit"
                    onClick={handleSortByColumn('name')}
                  >
                    <span className="flex flex-row items-center gap-2 w-full truncate">
                      NOME
                      <span className={classNames('min-w-[12px]')}>
                        {sortField === 'name' && <ArrowWithCorrectDirection />}
                      </span>
                    </span>
                  </th>
                  <th
                    scope="col"
                    className="h-full px-6 py-3 cursor-pointer hover:bg-gray-100 w-fit"
                    onClick={handleSortByColumn('cnpj')}
                  >
                    <span className="flex flex-row items-center gap-2 truncate">
                      CNPJ
                      <span className={classNames('min-w-[12px]')}>
                        {sortField === 'cnpj' && <ArrowWithCorrectDirection />}
                      </span>
                    </span>
                  </th>
                  <th
                    scope="col"
                    className="h-full px-6 py-3 cursor-pointer hover:bg-gray-100 w-fit"
                    onClick={handleSortByColumn('city')}
                  >
                    <span className="flex flex-row items-center gap-2 truncate">
                      CIDADE
                      <span className={classNames('min-w-[12px]')}>
                        {sortField === 'city' && <ArrowWithCorrectDirection />}
                      </span>
                    </span>
                  </th>
                  <th
                    scope="col"
                    className="h-full px-6 py-3 cursor-pointer hover:bg-gray-100 w-fit"
                    onClick={handleSortByColumn('uf')}
                  >
                    <span className="flex flex-row items-center gap-2 truncate">
                      UF
                      <span className={classNames('min-w-[12px]')}>
                        {sortField === 'uf' && <ArrowWithCorrectDirection />}
                      </span>
                    </span>
                  </th>
                  <th
                    scope="col"
                    className="h-full px-6 py-3 cursor-pointer hover:bg-gray-100 w-fit"
                    onClick={handleSortByColumn('userName')}
                  >
                    <span className="flex flex-row items-center gap-2 truncate">
                      USUÁRIO
                      <span className={classNames('min-w-[12px]')}>
                        {sortField === 'userName' && <ArrowWithCorrectDirection />}
                      </span>
                    </span>
                  </th>
                  <th
                    scope="col"
                    className="h-full px-6 py-3 cursor-pointer hover:bg-gray-100 w-fit"
                    onClick={handleSortByColumn('createdAt')}
                  >
                    <span className="flex flex-row items-center gap-2 truncate">
                      DATA CRIAÇÃO
                      <span className={classNames('min-w-[12px]')}>
                        {sortField === 'createdAt' && <ArrowWithCorrectDirection />}
                      </span>
                    </span>
                  </th>
                  <th
                    scope="col"
                    className="h-full px-6 py-3 cursor-pointer hover:bg-gray-100 w-fit"
                    onClick={handleSortByColumn('status')}
                  >
                    <span className="flex flex-row items-center gap-2 truncate">
                      STATUS
                      <span className={classNames('min-w-[12px]')}>
                        {sortField === 'status' && <ArrowWithCorrectDirection />}
                      </span>
                    </span>
                  </th>
                  <th scope="col" className="h-full px-6 py-3 w-fit">
                    <span className="flex flex-row items-center gap-2 truncate">AÇÕES</span>
                  </th>
                </tr>
              </thead>
              <tbody className="w-full">
                {unitListSorted.map((unitForListing, unitForListingIndex) => (
                  <tr
                    className="bg-white border-b hover:bg-gray-100 w-full h-[60px] text-xs"
                    key={`unit-${unitForListing.id}-row-${unitForListingIndex}`}
                  >
                    <td className="px-6 py-4 font-normal whitespace-nowrap w-fit">
                      {unitForListing.name}
                    </td>
                    <td className="px-6 py-4 font-normal whitespace-nowrap w-fit">
                      {unitForListing.cnpj}
                    </td>
                    <td className="px-6 py-4 font-normal whitespace-nowrap w-fit">
                      {unitForListing.city}
                    </td>
                    <td className="px-6 py-4 font-normal whitespace-nowrap w-fit">
                      {unitForListing.uf}
                    </td>
                    <td className="px-6 py-4 font-normal whitespace-nowrap w-fit">
                      {unitForListing.userName}
                    </td>
                    <td className="px-6 py-4 font-normal whitespace-nowrap w-fit">
                      {dayjs(unitForListing.createdAt).format(DATE_DISPLAY_FORMAT)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap w-fit">
                      {String(unitForListing.status).toUpperCase()}
                    </td>
                    <td className="px-6 py-4 w-fit max-w-[106px] flex justify-between font-medium whitespace-nowrap">
                      <button
                        onClick={() =>
                          navigate(
                            PAGES_ROUTES.authenticated.unit.edit.replace(
                              ':unitId',
                              (unitForListing.id ?? -1).toString()
                            )
                          )
                        }
                        className="mr-3 group"
                      >
                        <PenIcon className="" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Mobile */}
            <div className="flex flex-col gap-y-[18px] md:hidden">
              {unitListSorted.map((unitForListing) => (
                <div
                  className="bg-white p-[18px] overflow-hidden flex flex-row justify-between shadow-container"
                  key={`unit-list-card-${unitForListing.id}`}
                >
                  <div className="flex flex-col pb-4 gap-y-[9px]">
                    <div>
                      <p className=" text-black text-xs/[20px] font-normal">
                        {unitForListing.name}
                      </p>
                      <p className=" text-black text-xs/[20px] font-normal">
                        {unitForListing.cnpj}
                      </p>
                      <p className="text-xs/[20px] text-gray-500">
                        {unitForListing.city} - {unitForListing.uf}
                      </p>
                      <p className="text-xs/[20px] text-gray-500">{unitForListing.createdAt}</p>
                      <p className="text-xs/[20px] text-gray-500">{unitForListing.userName}</p>
                    </div>
                  </div>

                  <div className="flex flex-col justify-between items-end w-fit min-h-full">
                    <div className="text-xs">{String(unitForListing.status).toUpperCase()}</div>

                    <div className="w-full max-w-[54px] flex justify-between">
                      <button
                        onClick={() =>
                          navigate(
                            PAGES_ROUTES.authenticated.unit.edit.replace(
                              ':unitId',
                              (unitForListing.id ?? -1).toString()
                            )
                          )
                        }
                        className={classNames({
                          group: true,
                        })}
                      >
                        <PenIcon className="group-enabled:hover:text-secondary" />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default List;
