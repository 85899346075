// Third-party
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import { useDispatch } from 'react-redux';

// App
import Input from 'components/Input';
import { formSchema } from './schemas';
import useAuth from 'hooks/useAuth';
import { ForgotPasswordRequestChangePassword } from 'services/api/user';
import { MarkEmailReadIcon } from 'assets/icons';
import { PAGES_ROUTES } from 'constants/routes';
import useLoadingStore from 'store/client/loading/useLoadingStore';
import { selectLoading, selectSetLoading } from 'store/client/loading/selectors';
import useNotificationStore from 'store/client/notification/useNotificationStore';
import { selectAddNotification } from 'store/client/notification/selectors';

type FormValues = {
  email: string;
};

function ForgotPassword() {
  /*
  **** Component organization ****

   └── Declaration of generic hooks (e.g., useNavigate)
   └── State declaration
   └── Side effects (e.g., useEffect)
   └── Memoization (e.g., useMemo)
   └── Handlers (e.g., useCallback)
   └── JSX
   */

  // └── Declaration of generic hooks (e.g., useNavigate)
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    resolver: yupResolver(formSchema),
  });
  const { forgotPasswordFinalStep } = useAuth();
  const isLoading = useLoadingStore(selectLoading);
  const setLoading = useLoadingStore(selectSetLoading);
  const addNotification = useNotificationStore(selectAddNotification);

  // └── State declaration
  const [forgotPasswordWasRequested, setForgotPasswordWasRequested] = useState<boolean>(false);

  // └── Side effects (e.g., useEffect)
  useEffect(() => {
    forgotPasswordFinalStep();
  }, [forgotPasswordFinalStep]);

  // └── Handlers (e.g., useCallback)
  const onSubmit = async ({ email }: FormValues) => {
    setLoading(true);
    try {
      await ForgotPasswordRequestChangePassword({ email });

      setForgotPasswordWasRequested(true);
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error?.response?.status == 404 || error?.response?.status === 204) {
          addNotification({
            message: 'Usuário não encontrado',
            type: 'error',
          });
        } else {
          addNotification({
            message: 'Erro com o serviço. Tente novamente mais tarde.',
            type: 'error',
          });
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {forgotPasswordWasRequested ? (
        <div
          data-testid="forgot-password-id"
          className="flex flex-col items-center md:items-start font-inter"
        >
          <MarkEmailReadIcon className="w-[100px] md:w-[131px] mb-[18px]" />
          <h1 className="text-left text-[22px] text-primary font-bold mb-3">
            E-mail enviado com sucesso!
          </h1>
          <p className="text-left text-sm leading-4 font-medium text-primary mb-[26px] w-full grow-[1]">
            Você recebeu um e-mail com um link para que você possa criar a sua nova senha. Não
            esqueça de dar uma olhadinha na caixa de SPAM, caso não encontre o e-mail na caixa de
            entrada.
          </p>
          <button
            type="button"
            className="bg-primary disabled:bg-[#D1D5DB] text-sm text-buttontextcolor text-center py-[11px] px-4 rounded-[5px] transition duration-200 w-full"
            onClick={() => navigate(PAGES_ROUTES.unauthenticated.initialRoute)}
          >
            Login
          </button>
        </div>
      ) : (
        <div data-testid="forgot-password-id">
          <h1
            className="font-inter text-primary font-medium text-lg mb-6 leading-5 tracking-wide"
            data-testid="forgot-password-title"
          >
            Receba em seu e-mail <span className="text-secondary">um link</span> para recriar uma
            nova senha.
          </h1>
          <form onSubmit={handleSubmit(onSubmit)} noValidate className="flex flex-col gap-y-6">
            <Input
              labelText="Digite seu e-mail"
              name="email"
              type="email"
              labelClassName="!text-primary"
              register={register}
              displayWarning={!!errors.email}
              warningMessage={errors.email?.message}
            />
            <div className="flex flex-col justify-between items-center gap-y-6">
              <button
                type="submit"
                className="bg-primary disabled:bg-[#D1D5DB] text-sm text-buttontextcolor text-center py-[11px] px-4 rounded-[5px] transition duration-200 w-full"
                disabled={!isValid || isLoading}
              >
                Recuperar Senha
              </button>
              <button
                type="button"
                className="font-inter text-secondary hover:text-orange font-medium text-sm transition duration-200"
                onClick={() => navigate(PAGES_ROUTES.unauthenticated.initialRoute)}
                disabled={isLoading}
              >
                Lembrei minha senha
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default ForgotPassword;
