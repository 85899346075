import NotFound from './NotFound';
import Unavailable from './Unavailable';

export { NotFound };
export { Unavailable };

export default {
  NotFound,
  Unavailable,
};
