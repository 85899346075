// Third-party
import { createSlice } from '@reduxjs/toolkit';

// App
import { RootState } from 'store';

interface IFileInfosState {
  fileUrl: string | null;
  fileMimeType: string | null;
  fileName: string;
}

const initialState: IFileInfosState = {
  fileUrl: null,
  fileMimeType: null,
  fileName: '',
};

const fileModalSlice = createSlice({
  name: 'fileModal',
  initialState,
  reducers: {
    addFileToDisplay: (state, action: { payload: IFileInfosState }) => {
      state.fileMimeType = action.payload.fileMimeType;
      state.fileUrl = action.payload.fileUrl;
      state.fileName = action.payload.fileName;
    },
    removeDisplayedFile: (state) => {
      state.fileMimeType = null;
      state.fileUrl = null;
      state.fileName = '';
    },
  },
});

export const { addFileToDisplay, removeDisplayedFile } = fileModalSlice.actions;

export const selectFileToDisplay = (state: RootState) => state.fileModal;

export default fileModalSlice.reducer;
