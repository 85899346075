/* eslint-disable react-hooks/rules-of-hooks */
// Third-party
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, Tooltip, Legend, ArcElement, ChartOptions } from 'chart.js';
import { useEffect, useMemo, useState } from 'react';
import { Navigate } from 'react-router-dom';

// App
import useDashboardStore from 'store/client/dashboard/useDashboardStore';
import {
  selectSelectedClient,
  selectSelectedMonth,
  selectSelectedYear,
} from 'store/client/dashboard/selectors';
import useCompanySelectedStore from 'store/client/companySelected/useCompanySelectedStore';
import { selectCompanySelected } from 'store/client/companySelected/selectors';
import { PAGES_ROUTES } from 'constants/routes';
import { useGetDashboardData } from 'store/server/company/queries';
import getMonthStartAndEndUtil from 'utils/getMonthStartAndEnd.util';

import { colors } from 'constants/dashboard';
import 'chartjs-plugin-datalabels';
import classNames from 'classnames';
import useWindowSize from 'hooks/useWindowSize';
import { convertToBrazilianCurrencyFormat } from 'utils/currency.utils';

ChartJS.register(ArcElement, Tooltip, Legend);

interface IExpensesChartProps {
  toExport?: boolean;
}

function ExpensesChart({ toExport }: IExpensesChartProps) {
  const companySelected = useCompanySelectedStore(selectCompanySelected);
  const { width } = useWindowSize();

  if (!companySelected) {
    return <Navigate to={PAGES_ROUTES.authenticated.commonInitialRoute} replace />;
  }

  const selectedMonth = useDashboardStore(selectSelectedMonth);
  const selectedYear = useDashboardStore(selectSelectedYear);
  const selectedClient = useDashboardStore(selectSelectedClient);

  const { data: dashboardData } = useGetDashboardData(
    companySelected.id,
    {
      ...getMonthStartAndEndUtil(Number(selectedYear), Number(selectedMonth)),
      clientId: selectedClient?.id,
    },
    { requestIdEnabled: false }
  );

  const [expensesChartKey, setExpensesChartKey] = useState<string>();

  const expensesChartData = useMemo(
    () => ({
      labels: (dashboardData?.expensesByCategoryTotal ?? []).length
        ? dashboardData?.expensesByCategoryTotal.map(
            (expenseByCategory) => expenseByCategory.category
          )
        : ['sem registros'],
      datasets: [
        {
          label: 'R$',
          data: dashboardData?.expensesByCategoryTotal.map(
            (expenseByCategory) => expenseByCategory.expenseTotal
          ),

          backgroundColor:
            (dashboardData?.expensesByCategoryTotal?.length ?? 0) > 0
              ? colors.backgroundColors
              : '#eeeeee',
        },
      ],
    }),
    [dashboardData?.expensesByCategoryTotal]
  );

  const expensesChartOptions = useMemo<ChartOptions<'pie'>>(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      radius: width > 768 ? '70%' : '100%',
      plugins: {
        tooltip: {
          callbacks: {
            label: function (context) {
              let label = context.dataset.label || '';
              if (label) {
                label += ': ';
              }
              const value = context.parsed;
              label += convertToBrazilianCurrencyFormat(`${value}`);
              return label;
            },
          },
        },
        legend: {
          title: {
            text: 'Despesas',
            display: true,
            position: 'start',
            color: '#000',
            font: {
              size: 18,
              weight: 'bold',
            },
            padding: {
              top: 80,
            },
          },
          position: width < 980 ? 'bottom' : 'right',
          align: width < 980 ? 'end' : 'center',
          labels: {
            padding: 15,
            usePointStyle: true,
            pointStyle: 'circle',
            color: '#000',
            font: {
              size: 16,
            },
            generateLabels: function (chart) {
              const originalLabels =
                ChartJS.overrides.pie.plugins.legend.labels.generateLabels(chart);
              return originalLabels.map((label) => {
                const maxLength = width / 60;
                label.text =
                  label.text.length > maxLength
                    ? label.text.substring(0, maxLength) + '…'
                    : label.text;
                return label;
              });
            },
          },
        },
        title: {
          display: true,
          text: 'Despesas do Período',
          font: {
            size: toExport ? 24 : 20,
            weight: 'bold',
          },
          align: 'center',
          position: 'top',
          padding: {
            bottom: 80,
          },
        },
        datalabels: {
          display: false,
          color: '#000000',
          textAlign: 'center',
          font: {
            weight: 'bold',
            size: 16,
          },
          formatter: (value) => `R$ ${convertToBrazilianCurrencyFormat(value)}`,
          anchor: 'end',
          align: 'end',
          offset: 0,
          rotation: -25,
          clamp: true,
          padding: {
            left: 10,
            right: 10,
            bottom: 10,
            top: 10,
          },
        },
      },
      layout: {
        padding: {
          bottom: 20,
          left: width > 768 ? 50 : 5,
          right: width > 768 ? 50 : 5,
        },
      },
      elements: {
        arc: {
          borderWidth: 0,
        },
      },
    }),
    [toExport, width]
  );

  const circlePlugin = useMemo(
    () => (width: number) => ({
      id: 'circleBackground',
      beforeDraw: (chart: ChartJS<'pie', number[], unknown>) => {
        const {
          ctx,
          chartArea: { width: widthInt, height },
        } = chart;
        ctx.save();
        ctx.fillStyle = '#eeeeee';
        ctx.beginPath();

        const radius = Math.min(widthInt, height) / 2 - (width > 768 ? 110 : 0);
        ctx.arc(
          widthInt / 2 + (width > 768 ? 50 : 5),
          height / 2 + 105,
          radius > 0 ? radius : 0,
          0,
          2 * Math.PI
        );
        ctx.fill();
        ctx.restore();
      },
    }),
    []
  );

  const customDatalabels = useMemo(
    () => () => ({
      id: 'customDatalabels',

      afterDatasetsDraw: (chart: ChartJS<'pie'>) => {
        const {
          ctx,
          data,
          chartArea: { top, left, width, height },
        } = chart;
        let lastYPosition: number | null = null;

        ctx.save();
        const halfWidth = width / 2 + left;
        const halfHeight = height / 2 + top;
        const radius = Math.min(width, height) / 2;

        data.datasets[0].data.forEach((datapoint, index) => {
          const meta = chart.getDatasetMeta(0);
          const dataPoint = meta.data[index];

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          const startAngle = dataPoint.startAngle;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          const endAngle = dataPoint.endAngle;
          const midAngle = (startAngle + endAngle) / 2;

          const offsetStart = radius * (width > 768 ? 0.6 : 0.7);
          const startX = halfWidth + Math.cos(midAngle) * offsetStart;
          const startY = halfHeight + Math.sin(midAngle) * offsetStart;

          const offsetEnd = radius * (width > 768 ? 0.85 : 0.9);
          const xLine = halfWidth + Math.cos(midAngle) * offsetEnd;
          const yLine = halfHeight + Math.sin(midAngle) * offsetEnd;

          const extraLine = xLine > halfWidth ? 5 : -5;

          let adjustedYLine = yLine;
          if (lastYPosition !== null && Math.abs(lastYPosition - yLine) < 20) {
            adjustedYLine += lastYPosition > yLine ? -20 : 20;
          }
          lastYPosition = adjustedYLine;

          ctx.beginPath();
          ctx.moveTo(startX, startY);
          ctx.lineTo(xLine, adjustedYLine);
          ctx.lineTo(xLine + extraLine, adjustedYLine);
          ctx.stroke();

          const textX = xLine + extraLine;
          ctx.font = 'bold 12px sans-serif';
          ctx.textAlign = xLine > halfWidth ? 'left' : 'right';
          ctx.textBaseline = 'middle';
          // ctx.fillText(
          //   `  R$ ${Number(datapoint ?? 0).toLocaleString('pt-BR', {
          //     minimumFractionDigits: 2,
          //     maximumFractionDigits: 2,
          //     useGrouping: false,
          //   })}  `,
          //   textX,
          //   adjustedYLine
          // );
          ctx.fillText(
            `  ${convertToBrazilianCurrencyFormat(`${Number(datapoint ?? 0)}`)}  `,
            textX,
            adjustedYLine
          );
        });

        ctx.restore();
      },
    }),
    []
  );

  useEffect(() => {
    setExpensesChartKey(
      `expenses-chart-${dashboardData?.expensesByCategoryTotal.length ?? 0}-${width}`
    );
  }, [dashboardData?.expensesByCategoryTotal, width]);

  return (
    <div
      className={classNames(
        'graph h-[600px] md:h-[900px] relative bg-[#f6f9fd] rounded-2xl px-[20px] md:px-[0px] py-[50px]',
        {
          '!h-[900px]': toExport,
          '!bg-white': toExport,
          '!pr-[115px]': (dashboardData?.expensesByCategoryTotal?.length ?? 0) <= 0,
        }
      )}
    >
      <Pie
        key={expensesChartKey}
        data={expensesChartData}
        options={expensesChartOptions}
        plugins={[circlePlugin(width), customDatalabels()]}
      />
    </div>
  );
}

export default ExpensesChart;
