import React, { useState, useEffect, useMemo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { DropDownArrowIcon } from 'assets/icons'; // Ensure the path is correct
import Skeleton from 'react-loading-skeleton';
import { IMenuSection } from '../..';

interface NavLinkComponentProps extends IMenuSection {
  toggleMobileMenu: (event: React.MouseEvent<HTMLElement>) => void;
  menuNavLinksIndex: number;
}

const NavLinkComponent: React.FC<NavLinkComponentProps> = ({
    navLinkTitle,
    to,
    icon,
    badge,
    children,
    options,
    toggleMobileMenu,
    menuNavLinksIndex,
  }) => {
    const location = useLocation();
    const [shouldOpenChildren, setShouldOpenChildren] = useState(options?.openChildren);
    const [renderChildren, setRenderChildren] = useState(shouldOpenChildren);
  
    useEffect(() => {
      if (shouldOpenChildren) {
        setRenderChildren(true);
      }
    }, [shouldOpenChildren]);
  
    const handleTransitionEnd = () => {
      if (!shouldOpenChildren) {
        setRenderChildren(false);
      }
    };
  
    const childrenClasses = classNames(
      'transition-all transform duration-500 ease-out origin-top',
      {
        'scale-y-0 opacity-0': !shouldOpenChildren,
        'scale-y-100 opacity-100': shouldOpenChildren,
      }
    );
    useMemo(() =>   setShouldOpenChildren(options?.isSelected), [options?.isSelected])
    useMemo(() =>   setShouldOpenChildren(options?.openChildren), [options?.openChildren])

    return (
      <>
        <NavLink
          key={`${navLinkTitle}-${menuNavLinksIndex}`}
          to={to}
          onClick={toggleMobileMenu}
          className={classNames(
            'purple-500 flex justify-start items-center gap-2.5 text-[#595959] hover:text-orange text-sm font-inter font-semibold pl-[28px] cursor-pointer py-[8px] leading-4 hamburger-menu',
            options?.style,
            {
              [options?.styleWhenSelected || '!bg-[#F4F4F4]']: options?.isSelected === undefined ? location.pathname.includes(to.split('/')[1]) : options?.isSelected,
            }
          )}
        >
          {icon}
          {navLinkTitle}
          { children && 
            <button onClick={() => setShouldOpenChildren(!shouldOpenChildren)} className="focus:outline-none">
            <DropDownArrowIcon className={classNames(
              'md:block text-grey-500 transition-transform duration-500 ease-in-out h-[12px] w-[12px] mt-1',
              {
                'rotate-0': !shouldOpenChildren,
                'rotate-180': shouldOpenChildren,
              }
            )}/>
          </button>
          }
          {badge && (badge.isLoading ? (
            <Skeleton width={20} height={20} className="!rounded-full" baseColor="#d9d9d9" highlightColor="#f2f2f2"/>
          ) : (
            <span className="inline-block !bg-warningcolor !text-white text-[10px] !leading-[20px] text-center w-fit min-w-[20px] h-fit min-h-[20px] max-h-[20px] px-[4.44px] rounded-full">
              {badge.value}
            </span>
          ))}
        </NavLink>
        <div className={childrenClasses} onTransitionEnd={handleTransitionEnd} style={{ overflow: 'hidden' }}>
          {renderChildren && children}
        </div>
      </>
    );
  };
  
  export default NavLinkComponent;