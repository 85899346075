import { CloseIcon, SimpleSpinnerIcon } from 'assets/icons'
import GenericTable, { GenericTableProps } from 'components/GenericTable';
import Modal from 'components/Modal'



interface InlineDataItem {
  name: string;
  value: any;
  formatter?: (value: any) => string;
}


interface IConfirmationModalProps {    
    title: string;
    description: string;
    onConfirm: () => void;
    onClose: () => void;
    isProcessing?: boolean;
    useTable?: boolean;
    tableData?: GenericTableProps
    useInlineData?: boolean;
    inlineData?: InlineDataItem[];
  }
  
  function ConfirmationModal({ title, description, onConfirm, onClose, isProcessing, useTable = false, tableData, useInlineData=false, inlineData }:IConfirmationModalProps) {
    return (
      <Modal
        content={
          <div className="flex flex-col gap-y-4">
            <label 
              className='flex items-end justify-end'
              htmlFor='closeIcon'
              >
              <button
                name='closeIcon'
                className=""
                disabled={isProcessing}
                onClick={() => onClose()}
              >
                <CloseIcon className="!text-primary h-[15px] w-[15px]" />
              </button>
            </label>
            <div className="w-full flex flex-col gap-4">
              <h2 className="text-2xl text-black font-inter text-center font-bold">
                {title}
              </h2>
            </div>
            <div className="text-black font-normal text-center text-sm">
              {description}     
            </div>
            {useTable && tableData &&
              <div>
                <GenericTable
                  columns={tableData.columns}
                  data={tableData.data}
                  formatters={tableData.formatters}
                />
              </div>
            }
            {
              useInlineData && inlineData &&
              <div
                className="bg-white px-[20px] py-[16px] overflow-hidden flex flex-col justify-between gap-y-[9px] shadow-container rounded-[5px]"
              >
                <div className="flex flex-row justify-between w-full">
                  <div className="font-inter font-medium text-gray-500 text-xs/[20px]">
                    <span className="uppercase">
                      {inlineData.map((e, i) => {
                        return (
                          <p key={`${e.name}+${i}`}>
                            <b className="text-primary">{e.name}: </b>
                            {e.formatter ? e.formatter(e.value) : e.value }
                          </p>
                        )
                      })}
                    </span>
                  </div>
                </div>
              </div>

            }        
            <div className="flex flex-col items-center gap-5 w-full">
              <button
                type="button"
                onClick={onConfirm}
                className="px-4 py-2 text-buttontextcolor bg-primary rounded-md shadow-md duration-200 font-medium text-center w-full disabled:bg-gray-300 flex justify-center h-[40px]"
                disabled={isProcessing}
              >
                {!isProcessing ? (
                  'Confirmar'
                ) : (
                  <SimpleSpinnerIcon className="w-[24px] h-[24px]" />
                )}
              </button>
            </div>
          </div>
        }
        onClose={onClose}
        css={{        
          modal: 'relative md:max-h-[850px] max-w-[600px] w-fit my-auto  max-h-[600px] overflow-y-auto mx-4 md:mx-0' ,
        }}
      />
    )
  }
  
  export default ConfirmationModal

