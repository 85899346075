import { removeCpfCnpjMask } from "./mask.utils";

// Utility object to check if a parameter contains only numbers
const numberUtils = {
    // Function to test if the given string parameter consists only of digits
    onlyNumber(param: string) {
      return /^\d+$/.test(param); // Regular expression to match a string containing only numbers
    },
  };
  
// Modified original function for use in this context
export const isNotEmailOrName = (param: string) => {
    // Checks if the parameter is an email
    if (param.indexOf("@") >= 0) return false; // If '@' is found, it's likely an email, so return false
  
    // Checks if the parameter is only numbers (CPF/CNPJ)    
    // making them plain numbers before checking with `onlyNumber`
    return numberUtils.onlyNumber(removeCpfCnpjMask(param));
  };