import classNames from 'classnames';

function OpenEyeIcon({ className }: { className?: string }) {
  return (
    <svg
      width="23"
      height="16"
      viewBox="0 0 23 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames('text-primary', className)}
    >
      <path
        d="M20.757 6.962C21.231 7.582 21.231 8.419 20.757 9.038C19.264 10.987 15.682 15 11.5 15C7.31801 15 3.73601 10.987 2.24301 9.038C2.01239 8.74113 1.88721 8.37592 1.88721 8C1.88721 7.62408 2.01239 7.25887 2.24301 6.962C3.73601 5.013 7.31801 1 11.5 1C15.682 1 19.264 5.013 20.757 6.962V6.962Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 11C13.1569 11 14.5 9.65685 14.5 8C14.5 6.34315 13.1569 5 11.5 5C9.84315 5 8.5 6.34315 8.5 8C8.5 9.65685 9.84315 11 11.5 11Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default OpenEyeIcon;
