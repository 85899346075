import classNames from 'classnames';

function ArrowIcon({ className }: { className?: string }) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames('text-primary', className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.99999 0.75C6.09944 0.75 6.19482 0.789509 6.26515 0.859835C6.33548 0.930161 6.37499 1.02554 6.37499 1.125V9.96975L8.73449 7.6095C8.8049 7.53908 8.9004 7.49953 8.99999 7.49953C9.09957 7.49953 9.19507 7.53908 9.26549 7.6095C9.3359 7.67991 9.37546 7.77542 9.37546 7.875C9.37546 7.97458 9.3359 8.07009 9.26549 8.1405L6.26549 11.1405C6.23065 11.1754 6.18927 11.2031 6.14371 11.222C6.09815 11.2409 6.04931 11.2507 5.99999 11.2507C5.95066 11.2507 5.90182 11.2409 5.85626 11.222C5.8107 11.2031 5.76932 11.1754 5.73449 11.1405L2.73449 8.1405C2.66407 8.07009 2.62451 7.97458 2.62451 7.875C2.62451 7.77542 2.66407 7.67991 2.73449 7.6095C2.8049 7.53908 2.9004 7.49953 2.99999 7.49953C3.09957 7.49953 3.19507 7.53908 3.26549 7.6095L5.62499 9.96975V1.125C5.62499 1.02554 5.66449 0.930161 5.73482 0.859835C5.80515 0.789509 5.90053 0.75 5.99999 0.75Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default ArrowIcon;
