import { ISummarySection } from 'interfaces/summaryType';
import {
  ExpensesSection,
  SalesControlSection,
  SalesSection,
  ServicesSection,
  SummarySection,
} from 'pages/company/Summary/components/sections';

export const SECTIONS_LIST: ISummarySection[] = [
  { title: 'Resumo', component: SummarySection },
  { title: 'Despesas', component: ExpensesSection },
  { title: 'Vendas', component: SalesSection },
  { title: 'Controle de venda', component: SalesControlSection },
  { title: 'Serviços', component: ServicesSection },
];
