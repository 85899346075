import classNames from 'classnames';

function UploadIcon({ className }: { className?: string | Record<string, unknown> }) {
  return (
    <svg
      width="26"
      height="28"
      viewBox="0 0 26 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames('bg-none', className)}
    >
      <path
        d="M1.3335 20.2916V23.2083C1.3335 23.9819 1.64079 24.7237 2.18777 25.2707C2.73475 25.8177 3.47661 26.125 4.25016 26.125H21.7502C22.5237 26.125 23.2656 25.8177 23.8126 25.2707C24.3595 24.7237 24.6668 23.9819 24.6668 23.2083V20.2916M5.7085 8.62498L13.0002 1.33331M13.0002 1.33331L20.2918 8.62498M13.0002 1.33331V18.8333"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default UploadIcon;
