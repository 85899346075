import { DropDownArrowIcon } from 'assets/icons';
import classNames from 'classnames';

interface IPaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  disabled?: boolean;
}

const Pagination = ({ currentPage, totalPages, onPageChange, disabled }: IPaginationProps) => {
  const generatePagination = () => {
    const maxDisplayed = 3;

    if (totalPages <= maxDisplayed) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    } else {
      let pages = [];
      let firstPages = [1, 2, 3];
      if (currentPage === 3) {
        firstPages = [currentPage - 1, currentPage, currentPage + 1];
      }

      let lastPages = [totalPages - 2, totalPages - 1, totalPages];
      if (currentPage === totalPages - 2) {
        lastPages = [currentPage - 1, currentPage, currentPage + 1];
      }

      if (totalPages >= 10) {
        if (
          currentPage <= firstPages[firstPages.length - 1] ||
          currentPage >= totalPages - lastPages.length + 1
        ) {
          pages = [...firstPages, '...', ...lastPages];
        } else {
          pages = [1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages];
        }
      } else {
        pages = Array.from({ length: totalPages }, (_, i) => i + 1);
      }

      return pages;
    }
  };

  const handlePageChange = (page: number | string) => {
    if (page === '...') return;
    onPageChange(page as number);
  };

  return (
    <div className="flex justify-center text-sm font-medium h-[38px] font-inter">
      <button
        className={classNames(
          'flex justify-center items-center text-center w-[38px] h-full border border-gray-300 rounded-tl-md rounded-bl-md disabled:!bg-gray-100 disabled:!text-[#D1D5DB] group',
          { '': disabled }
        )}
        onClick={() => {
          if (currentPage > 1) handlePageChange(currentPage - 1);
        }}
        disabled={disabled || currentPage === 1}
      >
        <DropDownArrowIcon className="transform rotate-90 !m-0 group-disabled:!text-[#D1D5DB]" />
      </button>
      {generatePagination().map((page, index) => (
        <button
          key={index}
          className={classNames(
            `text-center w-[38px] h-full border border-gray-300 px-2 py-1 cursor-pointer text-gray-500`,
            {
              'bg-primary bg-opacity-20 text-primary border-primary':
                page === currentPage && !disabled,
              '!bg-gray-100 !text-[#D1D5DB]': disabled,
            }
          )}
          onClick={() => handlePageChange(page)}
          disabled={page === currentPage || page === '...' || disabled}
        >
          {page}
        </button>
      ))}
      <button
        className={classNames(
          'flex justify-center items-center text-center w-[38px] h-full border border-gray-300 rounded-tr-md rounded-br-md disabled:!bg-gray-100 disabled:!text-[#D1D5DB] group'
        )}
        onClick={() => {
          if (currentPage < totalPages) handlePageChange(currentPage + 1);
        }}
        disabled={disabled || currentPage + 1 > totalPages}
      >
        <DropDownArrowIcon
          className={classNames('transform -rotate-90 group-disabled:!text-[#D1D5DB]')}
        />
      </button>
    </div>
  );
};

export default Pagination;
