// Third-party
import { useEffect, useState } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

// App

function ListSkeleton() {
  /*
  **** Component organization ****

   └── Declaration of generic hooks (e.g., useNavigate)
   └── State declaration
   └── Side effects (e.g., useEffect)
   └── Memoization (e.g., useMemo)
   └── Handlers (e.g., useCallback)
   └── JSX
   */

  // └── State declaration
  const [windowInnerHeight, setWindowInnerHeight] = useState<number>(window.innerHeight);

  // └── Side effects (e.g., useEffect)
  useEffect(() => {
    const getWindowInnerHeight = () => {
      setWindowInnerHeight(window.innerHeight);
    };

    window.addEventListener('resize', getWindowInnerHeight);

    return () => window.removeEventListener('resize', getWindowInnerHeight);
  }, []);

  return (
    <>
      <SkeletonTheme baseColor="#f3f3f3" highlightColor="white">
        <div className="w-full hidden md:block">
          <div className="flex flex-row w-full">
            <Skeleton
              height={40.5}
              className="inline-block w-full"
              containerClassName="inline-block w-full"
            />
            <Skeleton
              height={40.5}
              className="inline-block w-full"
              containerClassName="inline-block w-full"
            />
            <Skeleton
              height={40.5}
              className="inline-block w-full"
              containerClassName="inline-block w-full"
            />
            <Skeleton
              height={40.5}
              className="inline-block w-full"
              containerClassName="inline-block w-full"
            />
            <Skeleton
              height={40.5}
              className="inline-block w-full"
              containerClassName="inline-block w-full"
            />
          </div>
          {[...Array(Math.round(windowInnerHeight / 53)).keys()].map((element) => (
            <Skeleton width={'100%'} height={52.5} key={`user-table-row-skeleton-${element}`} />
          ))}
        </div>
      </SkeletonTheme>
      <div className="flex flex-col gap-y-[18px] md:hidden">
        {[...Array(Math.round((windowInnerHeight - 150) / 141)).keys()].map((element, index) => (
          <div
            className="bg-white px-[18px] py-[17px] overflow-hidden flex flex-row justify-between shadow-container"
            key={`user-card-mobile-${index}`}
          >
            <div className="flex flex-col pb-4 gap-y-[12px]">
              <Skeleton
                width={102}
                height={20}
                containerClassName="flex h-[20px]"
                className="!rounded-[10px]"
              />
              <div>
                <Skeleton width={100} height={15} containerClassName="flex h-[15px] mb-[7px]" />
                <Skeleton width={150} height={12} containerClassName="flex h-[12px] mb-[6px]" />
              </div>
            </div>

            <div className="flex flex-col justify-between items-end w-fit min-h-full">
              <Skeleton
                width={44}
                height={24}
                containerClassName="flex h-[24px]"
                className="!rounded-[12px]"
              />

              <Skeleton
                width={54}
                height={26}
                containerClassName="flex h-[26px]"
                className="w-full max-w-[54px]"
              />
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default ListSkeleton;
