/* eslint-disable react-hooks/rules-of-hooks */
// Third-party
import { NavLink, Navigate, useNavigate, useParams } from 'react-router-dom';
import { MouseEvent, useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';

//App
import { InputText, Modal, Select, Toggle } from 'components';
import {
  AddUserIcon,
  ArrowIcon,
  DeleteIcon,
  LoadingSpinIcon,
  PenIcon,
  RegistrationIcon,
  SearchIcon,
  SimpleSpinnerIcon,
} from 'assets/icons';
import LoadingListSkeleton from './components/LoadingListSkeleton';
import Skeleton from 'react-loading-skeleton';
import { PAGES_ROUTES } from 'constants/routes';
import { useGetUser } from 'store/server/user/queries';
import { useGetCompanyGroupList } from 'store/server/company/queries';
import removeAccentsUtils from 'utils/removeAccents.utils';
import { ICompanyGroup } from 'interfaces/companyTypes';
import { useUpdateCompanyGroupTypeStatusMutation } from 'store/server/company/mutations';
import useCompanySelectedStore from 'store/client/companySelected/useCompanySelectedStore';
import { selectCompanySelected } from 'store/client/companySelected/selectors';
import { useMenuGroupList } from 'store/server/menu/queries';
import PageHeader from 'components/PageHeader/components/PageHeader';
import TableContainer from 'components/TableContainer';
import { ORDER_ATTRIBUTTES, ascDescType } from 'constants/orderAttributtes';
import RegisterDataButton from 'components/RegisterDataButton';

const { ASC, DESC } = ORDER_ATTRIBUTTES;

const StatusLabel: Record<string, boolean> = {
  ATIVO: true,
  INATIVO: false,
};

type IOrderAttributes = 'description' | 'status'
function ListGroup() {
  /*
  **** Component organization ****

   └── Declaration of generic hooks (e.g., useNavigate)
   └── State declaration
   └── Side effects (e.g., useEffect)
   └── Memoization (e.g., useMemo)
   └── Handlers (e.g., useCallback)
   └── JSX
   */

  // └── Declaration of generic hooks (e.g., useNavigate)
  const { groupId } = useParams();
  const navigate = useNavigate();
  
  // └── State declaration
  // Sort states
  const [orderAttribute, setOrderAttribute] = useState<IOrderAttributes>('description');
  const [order, setOrder] = useState<ascDescType>(ASC);
  // Selected table row
  const [selectedRowId, setSelectedRowId] = useState<null|number>(null);
  // └── State declaration
  const [selectedDescriptionFilter, setSelectedDescriptionFilter] = useState<string | null>(null);
  const [selectedStatusFilter, setSelectedStatusFilter] = useState<string>('');
  const [typeToChangeStatusModal, setTypeToChangeStatusModal] = useState<ICompanyGroup | null>(
    null
  );

  const [submitedFilters, setSubmitedFilters] = useState<{
    description: string | null;
    status: string;
  }>({
    description: selectedDescriptionFilter,
    status: selectedStatusFilter,
  });

  const companySelected = useCompanySelectedStore(selectCompanySelected);

  const { isLoading: userLoggedIsLoading, data: user } = useGetUser();
  if (!companySelected || !groupId)
    return (
      <Navigate to={user?.initialRoute ?? PAGES_ROUTES.authenticated.adminInitialRoute} replace />
    );

  const {
    data: companyGroupListData,
    isLoading: companyListIsLoading,
    isFetching: companyListIsFetching,
  } = useGetCompanyGroupList({
    companyId: companySelected.id,
    groupId,
    order,
    field: orderAttribute
  });

  const updateCompanyGroupTypeStatusMutation = useUpdateCompanyGroupTypeStatusMutation(
    groupId,
    companySelected.id
  );

  const { data: menuGroupList, isLoading: menuGroupListIsLoading } = useMenuGroupList();
  const groupTypeSelected = useMemo(
    () => menuGroupList?.data.find((typeGroup) => typeGroup.id === Number(groupId)),
    [groupId, menuGroupList?.data]
  );

  // └── Side effects (e.g., useEffect)
   

   const handleSortByColumn = useCallback(
    (columnName: IOrderAttributes, orderSelected?: ascDescType) => () => {
      if (orderAttribute !== columnName) {
        setOrder(ASC); // If the sort field is different from the clicked column, set the order to ASC
      } else {
        setOrder(orderSelected ? orderSelected : order === ASC ? DESC : ASC); // If the sort field is the same as the clicked column, toggle between ASC andDESC order
      }
      setOrderAttribute(columnName); // Set the sort field to the clicked column
    },
    [orderAttribute, order]
  );

  const showSkeletonLoading = useMemo(
    () =>
      (!companyGroupListData?.data && companyListIsLoading) ||
      userLoggedIsLoading ||
      menuGroupListIsLoading,
    [companyGroupListData?.data, companyListIsLoading, userLoggedIsLoading, menuGroupListIsLoading]
  );

  const hasFiltersToSubmit = useMemo(
    () =>
      submitedFilters.description !== selectedDescriptionFilter ||
      submitedFilters.status !== selectedStatusFilter,
    [
      selectedDescriptionFilter,
      selectedStatusFilter,
      submitedFilters.description,
      submitedFilters.status,
    ]
  );

  const showClearFiltersButton = useMemo(
    () => submitedFilters.description || submitedFilters.status,
    [submitedFilters.description, submitedFilters.status]
  );

  // └── Handlers (e.g., useCallback)
  const handleChangeDescriptionFilter = useCallback((event: { target: { value: string } }) => {
    setSelectedDescriptionFilter(event.target.value);
  }, []);

  const handleChangeStatusFilter = useCallback((event: { target: { value: string } }) => {
    setSelectedStatusFilter(event.target.value);
  }, []);

  const handleSubmitFilters = useCallback(() => {
    setSubmitedFilters({
      description: selectedDescriptionFilter,
      status: selectedStatusFilter,
    });
  }, [selectedDescriptionFilter, selectedStatusFilter]);

  const handleClearFilters = useCallback(() => {
    setSelectedDescriptionFilter(null);
    setSelectedStatusFilter('');
    setSubmitedFilters({
      description: null,
      status: '',
    });
  }, []);

  const handleClickToggleStatus = useCallback(
    (GroupType: ICompanyGroup) => setTypeToChangeStatusModal(GroupType),
    []
  );

  const handleUpdateStatus = useCallback(
    (typeToChangeStatus: ICompanyGroup) => async () => {
      await updateCompanyGroupTypeStatusMutation.mutateAsync({
        typeId: typeToChangeStatus.id,
        params: {
          status: !typeToChangeStatus.status,
          description: typeToChangeStatus.description,
        },
      });

      setTypeToChangeStatusModal(null);
    },
    [updateCompanyGroupTypeStatusMutation]
  );
    /**
   * Returns a memoized arrow component with the correct direction based on the 'order' prop.
   *
   * @param {string} order - The order prop indicating the direction (ASC orDESC).
   * @returns {React.Component} - The memoized Arrow component.
   */
    const ArrowWithCorrectDirection = useMemo(
      () =>
        function Arrow({ className }: { className?: string | Record<string, unknown> }) {
          return (
            <ArrowIcon
              className={classNames(
                {
                  'rotate-180': order === ASC, // Adds the 'rotate-180' class if the 'order' prop is ASC
                },
                className
              )}
            />
          );
        },
      [order] // Dependency for useMemo, the component will be recomputed when the 'order' prop changes
    );

  const companyGroupListFiltered = useMemo(
    () =>
      companyGroupListData?.data.filter((companyGroup) => {
        if (submitedFilters.description) {
          return removeAccentsUtils(companyGroup.description)
            .toLowerCase()
            .trim()
            .includes(removeAccentsUtils(submitedFilters.description).toLowerCase().trim());
        }

        if (submitedFilters.status) {
          return StatusLabel[submitedFilters.status] === Boolean(companyGroup.status);
        }

        return true;
      }),
    [companyGroupListData?.data, submitedFilters.description, submitedFilters.status]
  );   
  const handleRowClick = (clientId:number) => {    
    setSelectedRowId(clientId === selectedRowId ? null : clientId);
  };
    
  return (
    <>
      {typeToChangeStatusModal && (
        <Modal
          content={
            <div className="flex flex-col gap-y-4">
              <div className="w-full flex justify-between items-start gap-4">
                <h2 className="text-2xl text-black font-inter font-bold">
                  {typeToChangeStatusModal.status
                    ? `Desativar ${typeToChangeStatusModal.description}`
                    : `Ativar ${typeToChangeStatusModal.description}`}
                </h2>
              </div>
              <div className="text-black font-normal text-sm">
                {typeToChangeStatusModal.status
                  ? `Você deseja Desativar esse registro?`
                  : `Você deseja Ativar esse registro?`}
              </div>
              <div className="flex flex-col items-center gap-5 w-full">
                <button
                  type="button"
                  onClick={handleUpdateStatus(typeToChangeStatusModal)}
                  className="px-4 py-2 text-buttontextcolor bg-primary rounded-md shadow-md duration-200 font-medium text-center w-full disabled:bg-gray-300 flex justify-center h-[40px]"
                  disabled={updateCompanyGroupTypeStatusMutation.isPending}
                >
                  {!updateCompanyGroupTypeStatusMutation.isPending ? (
                    'Confirmar'
                  ) : (
                    <SimpleSpinnerIcon className="w-[24px] h-[24px]" />
                  )}
                </button>
              </div>
            </div>
          }
          onClose={() =>
            !updateCompanyGroupTypeStatusMutation.isPending && setTypeToChangeStatusModal(null)
          }
        />
      )}
      <div
        className={classNames('h-full', {
          'overflow-hidden max-h-[calc(100vh-64px)] md:max-h-[calc(100vh-71px)]':
            showSkeletonLoading,
        })}
      >
        <div
          className={classNames(
            'pt-5 md:pt-[42px] p-[27px] md:p-8 w-full min-h-[calc(100vh-64px)] md:min-h-[calc(100vh-71px)]',
            {
              'overflow-hidden !h-screen': showSkeletonLoading,
            }
          )}
        >
           {/* HEADER */}
           <PageHeader 
            iconTextTitleProps={{
              iconProps:{
                iconJSX: <RegistrationIcon className="w-[24px] h-[24px] !text-secondary" />                
              },
              textProps:{
                content: groupTypeSelected?.description || ''
              },
            }}
          /> 
          <div className="w-full flex flex-col md:flex-row justify-between mb-4 gap-3">
            {/* Filters */}
            <div
              className={classNames(
                'flex flex-col md:flex-row justify-start items-start gap-x-2.5 gap-y-2.5 w-full h-fit'
              )}
            >
              {/* Description filter */}
              {showSkeletonLoading ? (
                <Skeleton
                  height={42}
                  baseColor="#f3f3f3"
                  highlightColor="white"
                  containerClassName="flex h-[42px] md:row-start-1 md:row-end-2"
                  className="!rounded-lg"
                />
              ) : (
                <InputText
                  name="descriptionFilter"
                  type="text"
                  placeholder="Descrição"
                  onChange={handleChangeDescriptionFilter}
                  value={selectedDescriptionFilter ?? ''}
                  title={selectedDescriptionFilter ?? ''}
                  cssClasses={{
                    label: '!flex-1 w-full md:max-w-[234px]',
                    input:
                      '!mt-0 w-full md:max-w-[234px] !text-[#374151] !h-[42px] !font-normal text-xs placeholder:font-normal placeholder:text-xs disabled:placeholder:!text-[#D1D5DB] truncate disabled:bg-gray-100',
                  }}
                  min={'18'}
                  max={'18'}
                  disabled={companyListIsLoading || companyListIsFetching}
                />
              )}
              {/* Description filter */}
              {showSkeletonLoading ? (
                <Skeleton
                  height={42}
                  baseColor="#f3f3f3"
                  highlightColor="white"
                  containerClassName="flex w-full md:max-w-[56px] h-[42px] row-start-3 md:row-start-1 row-end-4 md:row-end-2 col-start-1 col-end-4 md:col-start-3 md:col-end-4"
                  className="!rounded-lg"
                />
              ) : (
                <Select
                  name="statusFilter"
                  placeholder="STATUS"
                  onChange={handleChangeStatusFilter}
                  value={selectedStatusFilter ?? ''}
                  title={selectedStatusFilter ?? ''}
                  cssClasses={{
                    label: '!flex-1 w-full md:max-w-[234px]',
                    select: classNames(
                      'disabled:placeholder:!text-[#D1D5DB] disabled:bg-gray-100',
                      {
                        '!text-[#a9b1bc]': !selectedStatusFilter,
                        '!text-[#374151]': selectedStatusFilter,
                      }
                    ),
                  }}
                  disabled={companyListIsLoading || companyListIsFetching}
                >
                  <option
                    defaultChecked={true}
                    value=""
                    className="font-normal font-inter text-sm leading-5 !text-[#a9b1bc]"
                  >
                    STATUS
                  </option>
                  <option className="text-textcolor" value={'ATIVO'}>
                    ATIVO
                  </option>
                  <option className="text-textcolor" value={'INATIVO'}>
                    INATIVO
                  </option>
                </Select>
              )}
              {/* Button to filter */}
              {showSkeletonLoading ? (
                <Skeleton
                  height={42}
                  baseColor="#f3f3f3"
                  highlightColor="white"
                  containerClassName="flex h-[42px] row-start-3 md:row-start-1 row-end-4 md:row-end-2 col-start-1 col-end-4 md:col-start-3 md:col-end-4"
                  className="!rounded-lg"
                />
              ) : (
                <>
                  <button
                    className={classNames(
                      'row-start-3 md:row-start-1 row-end-4 md:row-end-2 col-start-1 col-end-4 md:col-start-3 md:!col-end-4 bg-primary disabled:bg-[#D1D5DB] text-white py-2 px-4 rounded-lg flex justify-center items-center w-full h-[42px] md:max-w-[56px]',
                      {
                        '!col-end-3': showClearFiltersButton,
                      }
                    )}
                    onClick={handleSubmitFilters}
                    disabled={!hasFiltersToSubmit || companyListIsLoading || companyListIsFetching}
                  >
                    {companyListIsLoading || companyListIsFetching ? (
                      <LoadingSpinIcon className=" w-5 !text-primary md:!text-buttontextcolor " />
                    ) : (
                      <SearchIcon className="!text-buttontextcolor" />
                    )}
                  </button>
                  {showClearFiltersButton && (
                    <button
                      className="bg-primary text-xs font-bold leading-4 tracking-[0.6px] text-white flex justify-center items-center gap-x-3  w-full md:w-fit h-[42px] py-2 px-4 rounded-lg disabled:bg-[#D1D5DB]"
                      onClick={handleClearFilters}
                      disabled={companyListIsLoading || companyListIsFetching}
                    >
                      <span className="hidden min-[1060px]:inline-block">LIMPAR</span>
                      <DeleteIcon />
                    </button>
                  )}
                </>
              )}
            </div>
            <div className="w-full flex md:max-w-fit justify-between items-center">
              <RegisterDataButton 
              showSkeletonLoading={showSkeletonLoading}
              handleClick={()=> navigate(PAGES_ROUTES.authenticated.company.group.create.replace(':groupId', groupId))}
            />
            </div>
          </div>

          {showSkeletonLoading ? (
            <LoadingListSkeleton />
          ) : companyGroupListFiltered && companyGroupListFiltered?.length <= 0 ? (
            <p className="w-full text-center text-[#595959] font-semibold">
              Nenhuma registro encontrado!
            </p>
          ) : (
            <TableContainer >            
              {/* Desktop */}
              <table className="w-full text-sm text-left text-gray-500">
                <thead className="font-inter text-[10px] text-primary font-bold uppercase bg-gray-50 w-full">
                  <tr>
                    <th
                      scope="col"
                      className="h-full px-6 py-3 cursor-pointer hover:bg-gray-100 w-fit"
                      onClick={handleSortByColumn('description')}

                    >
                      <span className="flex flex-row items-center gap-2 truncate">
                        DESCRIÇÃO
                        <span className={classNames('min-w-[12px]')}>
                        {orderAttribute === 'description' && <ArrowWithCorrectDirection />}
                        </span>
                      </span>
                    </th>
                    <th
                      scope="col"
                      className="h-full px-6 py-3 cursor-pointer hover:bg-gray-100 w-fit"
                      onClick={handleSortByColumn('status')}
                    >
                      <span className="flex flex-row items-center gap-2 truncate">
                        STATUS
                        <span className={classNames('min-w-[12px]')}>
                        {orderAttribute === 'status' && <ArrowWithCorrectDirection />}
                      </span>
                      </span>
                    </th>
                    <th scope="col" className="h-full px-6 py-3 w-fit">
                      <span className="flex flex-row items-center gap-2 truncate">AÇÕES</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="w-full">
                  {companyGroupListFiltered?.map(
                    (companyGroupForListing, companyGroupForListingIndex) => (
                      <tr
                      className={classNames(
                        "bg-white border-b w-full h-[60px] text-xs text-black",
                        {
                          '!bg-white hover:!bg-gray-100 ': selectedRowId !== companyGroupForListing.id,                      
                          '!bg-gray-300': selectedRowId === companyGroupForListing.id,    
                        }

                      )}
                        onClick={() => handleRowClick(companyGroupForListing.id)}
                        key={`company-${companyGroupForListing.id}-row-${companyGroupForListingIndex}`}
                      >
                        <td className="px-6 py-4 font-normal break-all w-fit">
                          {companyGroupForListing.description}
                        </td>
                        <td className={classNames("px-6 py-4 font-normal whitespace-nowrap w-fit text-red-500", 
                        {                        
                          '!text-green-500':
                          companyGroupForListing.status,
                        })}>
                          {companyGroupForListing.status ? 'ATIVO' : 'INATIVO'}
                        </td>
                        <td className="px-6 py-4 w-fit max-w-[106px] flex justify-between font-medium gap-x-3 whitespace-nowrap">
                          <Toggle
                            name="changeStatus"
                            onClick={(event: MouseEvent<HTMLInputElement>) =>
                              event.preventDefault()
                            }
                            onChange={() => {
                              handleClickToggleStatus(companyGroupForListing);
                            }}
                            checked={companyGroupForListing.status}
                          />
                          <NavLink
                            to={PAGES_ROUTES.authenticated.company.group.edit
                              .replace(':groupId', (groupId ?? -1).toString())
                              .replace(':typeId', (companyGroupForListing.id ?? -1).toString())}
                            state={{ type: companyGroupForListing }}
                            className="mr-3 group cursor-pointer"
                          >
                            <PenIcon className="" />
                          </NavLink>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            

            </TableContainer>
          )}
        </div>
      </div>
    </>
  );
}

export default ListGroup;
