import { ReactNode } from "react";

export const cnpjMask = (value: string) =>
  value
    .replace(/\D+/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');

export const removeCnpjMask = (value: string) => value.replace(/[./-]/g, '');

export const cepMask = (value: string) =>
  value
    .replace(/\D/g, '')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{3})\d+?$/, '$1');

export const removeCepMask = (value: string) => value.replace('-', '');

export const phoneNumberMask = (value: string) => {
  
  const numericValue = value.replace(/\D/g, '').substring(0, 11);

  if (numericValue.length <= 10) {
    // Formats as landline phone (XX) XXXX-XXXX
    return numericValue
      .replace(/(\d{2})(\d)/, '($1) $2') // Adds parentheses around the area code
      .replace(/(\d{4})(\d{1,4})/, '$1-$2'); // Inserts hyphen after the first 4 digits
  } else {
    // Formats as mobile phone (XX) XXXXX-XXXX
    return numericValue
      .replace(/(\d{2})(\d)/, '($1) $2') // Adds parentheses around the area code
      .replace(/(\d{5})(\d{1,4})/, '$1-$2'); // Inserts hyphen after the first 5 digits
  }
};
export const removePhoneNumberMask = (value: string) => value.replace(/[()-]/g, '');

export const insertCpfMask = (value: string) => {
  return value
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1-$2');
};

export const cpfCnpjMask = (value: string) => {
  const digits = value.replace(/\D+/g, '');

  if (digits.length <= 11) {
    return insertCpfMask(digits);
  } else if (digits.length === 14) {
    return digits
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2');
  }

  return value;
};

export const removeCpfCnpjMask = (value: string) => {
  return value.replace(/\D+/g, '');
};

export const monetaryMask = (value: number, prefix = 'R$') => {
  const valueString = `${value.toFixed(2).replace('.', ',')}`
  let inputValue = Number(valueString.replace(/\D/g, '')) / 100;
    
  const formattedValue = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(inputValue);

  return formattedValue;
};

export const removeMonetaryMask = (value: string): number => Number(value.replace(/\D/g, '')) / 100;

export const isReactNode = (value: any): value is ReactNode => {
  return (
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value === 'boolean' ||
    typeof value === 'undefined' ||
    value === null ||
    Array.isArray(value) && value.every(isReactNode) ||
    (typeof value === 'object' && 'type' in value && 'props' in value) 
  );
}