import Create from './Create';
import List from './List';
import Edit from './Edit';

export { Create };
export { List };
export { Edit };


export default {
  Create,
  List,
  Edit,
};
