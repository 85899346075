/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import classNames from 'classnames';
import { DotIcon, DropDownArrowIcon } from 'assets/icons'; // Ensure icons are correctly imported

interface IMenuToggleButtonProps {
  showMenu: boolean; // Indicates whether the menu is currently shown
  toggleMobileMenu: (e: React.MouseEvent<HTMLElement>, forceChange?: boolean) => void; // Function to toggle the mobile menu
  toggleVisibility?: (isVisible: boolean) => void; // Function to toggle the visibility of the button
}

const MenuToggleButton: React.FC<IMenuToggleButtonProps> = ({
  showMenu,
  toggleMobileMenu,
  toggleVisibility,
}) => {
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation(); // Prevents the event from propagating to the parent elements
    toggleMobileMenu(e, true);
    toggleVisibility && toggleVisibility(true); // Ensuring toggleVisibility is called if provided
  };

  return (
    <label
    htmlFor='toggleMenu'
      onClick={handleClick}
      id="toggleButton"
      className={classNames(
        'absolute hover:opacity-100 arrow-icon md:flex w-[25px] h-[25px] items-center justify-center bg-secondary hover:bg-primary rounded-full transition-all duration-500 ease-in-out z-[300] mt-[75px] rotate-90 cursor-pointer',
        {
          'ml-[232px]': showMenu,
          'ml-[26px]': !showMenu,
          hidden: showMenu, // Initially hide if menu is shown
          'opacity-100 ': !showMenu, // Always visible and fully opaque when menu is not shown
        }
      )}
    >
       <DropDownArrowIcon
          className={classNames(
            'md:block h-[10px] w-[10px] text-white transition-transform duration-500 ease-in-out',
            {
              'rotate-0 arrow-icon': showMenu,
              'rotate-180 arrow-icon': !showMenu,
            }
          )}
        /> 
    </label>
  );
};

export default MenuToggleButton;
