import { routes } from 'constants/api';
import api from '../api';
import { IUpdateUnitPayload } from 'interfaces/unitTypes';
import { IResponseWithData } from 'interfaces/apiTypes';

export async function updateUnit(
  unitId: number,
  data: IUpdateUnitPayload
  //TODO: tipar ICreateCompanyPayload valor de retorno da função
): Promise<IUpdateUnitPayload> {
  const result = await api.auth.patch<IResponseWithData<IUpdateUnitPayload>>(
    routes.v1.unit.updateById.replace(':unitId', unitId.toString()),
    data
  );

  return result.data.response;
}
