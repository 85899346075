// Third-party
import { object, string } from 'yup';

// App
const REQUIRED_FIELD_MESSAGE = 'Este campo é obrigatório';

export const formSchema = object({
  givenName: string().required(REQUIRED_FIELD_MESSAGE),
  familyName: string().required(REQUIRED_FIELD_MESSAGE),
});
