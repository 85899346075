/* eslint-disable react-hooks/rules-of-hooks */
// Third-party
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { AxiosError } from 'axios';

// App
import { formSchema } from './schemas';
import { InputText, Select } from 'components';
import { AddUserIcon, SaveIcon, SimpleSpinnerIcon } from 'assets/icons';
import { getAddressByCep } from 'services/api/external/viaCep';
import { cepMask, cpfCnpjMask, phoneNumberMask  } from 'utils/mask.utils';
import useNotificationStore from 'store/client/notification/useNotificationStore';
import { selectAddNotification } from 'store/client/notification/selectors';
import useLoadingStore from 'store/client/loading/useLoadingStore';
import { selectLoading, selectSetLoading } from 'store/client/loading/selectors';
import { PAGES_ROUTES } from 'constants/routes';
import classNames from 'classnames';
import Skeleton from 'react-loading-skeleton';
import { IEditClientPayload } from 'interfaces/clientTypes';
import { useEditClientMutation } from 'store/server/client/mutations';
import { useGetCompanyGroupList } from 'store/server/company/queries';
import { useGetClientById } from 'store/server/client/queries';
import LoadingSkeleton from 'pages/client/Edit/components/LoadingSkeleton';
import useCompanySelectedStore from 'store/client/companySelected/useCompanySelectedStore';
import { selectCompanySelected } from 'store/client/companySelected/selectors';
import ReturnButton from 'components/ReturnButton';
import InputTextArea from 'components/InputTextArea';
import { ORDER_ATTRIBUTTES } from 'constants/orderAttributtes';
import { DEFAULT_VALUES } from 'constants/textAreaInput';

const { ASC } = ORDER_ATTRIBUTTES;
const { DEFAULT_MAX_LENGHT} = DEFAULT_VALUES

function Edit() {
  /*
  **** Component organization ****

   └── Declaration of generic hooks (e.g., useNavigate)
   └── State declaration
   └── Side effects (e.g., useEffect)
   └── Memoization (e.g., useMemo)
   └── Handlers (e.g., useCallback)
   └── JSX
   */

  // └── Declaration of generic hooks (e.g., useNavigate)
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { isValid, dirtyFields },
    reset,
    watch,
    setValue,
    control,
  } = useForm<IEditClientPayload>({
    resolver: yupResolver(formSchema),
  });

  // └── State declaration
  //   └── Global
  const isLoading = useLoadingStore(selectLoading);
  const setLoading = useLoadingStore(selectSetLoading);
  const addNotification = useNotificationStore(selectAddNotification);
  const companySelected = useCompanySelectedStore(selectCompanySelected);

  // Validates whether a company is currently selected
  if (!companySelected) return <Navigate to={'/'} replace />;

  // Extracts the client Id from url params
  const { clientId } = useParams();
  if (!clientId) return <Navigate to={'/'} replace />;

  const editClientMutation = useEditClientMutation(companySelected.id, Number(clientId));

  const { data: client, isLoading: isLoadingClient } = useGetClientById(
    Number(companySelected.id),
    Number(clientId)
  );

  const { data: clientTypeListData, isLoading: clientTypeListIsLoading } = useGetCompanyGroupList({
    companyId: companySelected.id,
    groupId: '6',
    status: true,
    field: 'description',
    order: ASC,
  });

  // └── Side effects (e.g., useEffect)
  useEffect(() => {
    if (client) {
      reset({
        name: client.name,
        comercialName: client.comercialName ?? '',
        email: client.email,
        address: client.address ?? '',
        addressNumber: client.addressNumber ?? '',
        city: client.city ?? '',
        complement: client.complement ?? '',
        district: client.district ?? '',
        state: client.state ?? '',
        zipCode: (client.zipCode && cepMask(client.zipCode)) ?? '',
        phone: (client.phone && phoneNumberMask(client.phone)) ?? '',
        typeClientId: client.type.id,
        comment: client.comment ?? ''
      });
    }
  }, [client, reset]);

  // └── State declaration
  //   └── Local
  const [addressByCepIsLoading, setAddressByCepIsLoading] = useState<boolean>(false);

  const watchCEP = watch('zipCode');
  const watchTypeClientId = watch('typeClientId');
  const docTypeLenght = client?.cpfCnpj ? client?.cpfCnpj?.length : 0;

  // └── Side effects (e.g., useEffect)
  // Use Effect that listen to the zip code field and validates it
  useEffect(() => {
    (async () => {
      if (dirtyFields.zipCode && watchCEP?.length === 9) {
        try {
          setAddressByCepIsLoading(true);
          const address = await getAddressByCep(watchCEP.replace('-', ''));
          address.logradouro && setValue('address', address.logradouro, { shouldDirty: true });
          address.bairro && setValue('district', address.bairro, { shouldDirty: true });
          address.localidade && setValue('city', address.localidade, { shouldDirty: true });
          address.uf && setValue('state', address.uf, { shouldDirty: true });
        } catch (error) {
          addNotification({
            type: 'error',
            message: 'Erro ao consultar endereço. Verifique o CEP inserido e tente novamente.',
          });
        } finally {
          setAddressByCepIsLoading(false);
        }
      }
    })();
  }, [addNotification, dirtyFields.zipCode, setValue, watchCEP]);

  // └── Handlers (e.g., useCallback)
  const onSubmit = async (data: Partial<IEditClientPayload>) => {
    setLoading(true);
    try {
      const {
        name,
        comercialName,
        zipCode,
        address,
        addressNumber,
        complement,
        district,
        city,
        state,
        phone,
        email,
        typeClientId,
        comment
      } = Object.keys(dirtyFields).reduce(
        (
          fields: Record<string, Partial<IEditClientPayload[keyof IEditClientPayload]>>,
          fieldName: string
        ) => {
          fields[fieldName] = data[fieldName as keyof Partial<IEditClientPayload>];

          return fields;
        },
        {}
      ) as Partial<IEditClientPayload>;

      await editClientMutation.mutateAsync({
        name,
        comercialName,
        zipCode,
        address,
        addressNumber,
        complement,
        district,
        city,
        state,
        phone,
        email,
        typeClientId,
        comment
      });

      addNotification({
        type: 'success',
        message: 'Cliente atualizado com sucesso!',
      });
      reset();
    } catch (error) {
      if (error instanceof AxiosError) {
        if (
          error.response?.status &&
          error.response?.status >= 400 &&
          error.response?.status < 500
        ) {
          addNotification({
            type: 'error',
            message: error.response?.data.message,
          });
          return;
        }
        addNotification({
          type: 'error',
          message: 'Erro com o serviço. Tente novamente mais tarde.',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleMaskValue = useCallback(
    <TFieldName extends keyof IEditClientPayload>(
      field: ControllerRenderProps<IEditClientPayload, TFieldName>,
      maskCallback: (value: string) => string
    ) => {
      return (event: ChangeEvent<HTMLInputElement>) => {
        event.target.value = maskCallback(event.target.value);
        field.onChange(event);
      };
    },
    []
  );
  return (
    <div className="w-full min-h-screen h-full flex flex-col justify-start gap-y-[19px] items-start pt-[23px] md:pt-[42px] p-[27px] md:p-8">
      <div className="flex flex-col gap-2">
        <h1 className="flex items-center gap-2.5 text-2xl font-semibold text-[#595959]">
          <AddUserIcon className="w-[22px] h-[24px] !text-secondary" /> Editar Cliente
        </h1>
        <h3 className="text-xs text-[#374151]">Campos obrigatórios *</h3>
      </div>
      {client && !isLoadingClient ? (
        <form
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          className="self-center w-full max-w-[800px] gap-x-[10px] xl:gap-x-[20px] gap-y-10 flex flex-col justify-center"
        >
          <div className="md:grid md:grid-cols-12 gap-x-[10px] xl:gap-x-[20px] gap-y-2">
            <InputText
              defaultValue={cpfCnpjMask(client.cpfCnpj)}
              labelText="CPF/CNPJ*"
              cssClasses={{ label: 'row-start-1 row-end-2 col-start-1 col-end-4' }}
              type="text"
              disabled
            />
            <InputText
              labelText={client?.cpfCnpj?.length < 14 ? 'Nome*' : 'Nome Fantasia*'}
              cssClasses={{ label: 'row-start-2 row-end-3 col-start-1 col-end-7' }}
              {...register('name')}
            />
            {docTypeLenght >= 14 && (
              <InputText
                labelText={'Razão Social*'}
                cssClasses={{ label: 'row-start-2 row-end-3 col-start-7 col-end-13' }}
                {...register('comercialName')}
              />
            )}
            <InputText
              labelText="Email*"
              placeholder="email@email.com"
              cssClasses={{
                label:
                  docTypeLenght < 14
                    ? 'row-start-2 row-end-3 col-start-7 col-end-13 '
                    : 'row-start-3 row-end-4 col-start-7 col-end-13 ',
              }}
              {...register('email')}
            />
            <Controller
              name="phone"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <InputText
                  {...field}
                  labelText="Telefone*"
                  cssClasses={{ label: 'row-start-3 row-end-4 col-start-1 col-end-7' }}
                  type="text"
                  placeholder="(99) 99999-9999"
                  onChange={handleMaskValue<'phone'>(field, phoneNumberMask)}
                />
              )}
            />
            {clientTypeListIsLoading ? (
              <Skeleton
                height={44}
                baseColor="#f3f3f3"
                highlightColor="white"
                containerClassName="row-start-1 row-end-2 col-start-4 col-end-10 mt-[20px]"
                className="!rounded-lg"
              />
            ) : (
              <Select
                labelText="Tipo do Cliente*"
                cssClasses={{
                  label: 'row-start-1 row-end-2 col-start-4 col-end-10',
                  select: classNames({
                    '!text-[#a9b1bc] h-[44px]': watchTypeClientId == -1 || !watchTypeClientId,
                  }),
                }}
                {...register('typeClientId')}
                placeholder="Selecione um tipo"
                defaultValue={-1}
                disabled={addressByCepIsLoading}
              >
                <option
                  defaultChecked={true}
                  value={-1}
                  className="font-normal font-inter text-sm leading-5 !text-[#a9b1bc]"
                  title="Selecione um tipo"
                >
                  Selecione um tipo
                </option>
                {clientTypeListData?.data.map(
                  (clientType, clientTypeIndex) =>
                    clientType.status && (
                      <option
                        value={clientType.id}
                        key={`client-type-${clientType.id}-${clientType.description}-${clientTypeIndex}`}
                        className="text-textcolor"
                        title={clientType.description}
                      >
                        {clientType.description}
                      </option>
                    )
                )}
              </Select>
            )}
            <Controller
            name='comment'
            control={control}
            defaultValue={""}
            render={({field}) => (
              <InputTextArea
                {...field}
                labelText="Observaçōes"
                cssClasses={{
                  label: 'row-start-[4] row-end-[5] col-start-1 col-end-13',
                  textarea: 'h-full min-h-[100px] max-h-[250px]',
                }}
                maxLength={DEFAULT_MAX_LENGHT}
              />              
          )}          
          />          

            {/* Address inputs */}
            <Controller
              name="zipCode"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <div className="relative row-start-5 row-end-6 col-start-1 col-end-5 w-fit">
                  {addressByCepIsLoading && (
                    <SimpleSpinnerIcon className="absolute right-3 bottom-3.5 z-10" />
                  )}
                  <InputText
                    {...field}
                    labelText="CEP*"
                    cssClasses={{
                      label: 'row-start-4 row-end-5 col-start-1 col-end-5 md:w-[170px]',
                    }}
                    type="text"
                    placeholder="00000-000"
                    onChange={handleMaskValue<'zipCode'>(field, cepMask)}
                    disabled={addressByCepIsLoading}
                  />
                </div>
              )}
            />
            <InputText
              labelText="Endereço*"
              cssClasses={{ label: 'row-start-6 row-end-7 col-start-1 col-end-7' }}
              {...register('address')}
              disabled={addressByCepIsLoading}
            />
            <InputText
              labelText="Número*"
              cssClasses={{ label: 'row-start-6 row-end-7 col-start-7 col-end-9 md:min-w-[80px]' }}
              {...register('addressNumber')}
              disabled={addressByCepIsLoading}
            />
            <InputText
              labelText="Complemento"
              cssClasses={{
                label: 'row-start-6 row-end-7 col-start-9 col-end-[13] md:min-w-[80px]',
              }}
              {...register('complement')}
              disabled={addressByCepIsLoading}
            />
            <InputText
              labelText="Bairro*"
              cssClasses={{ label: 'row-start-7 row-end-8 col-start-1 col-end-6' }}
              {...register('district')}
              disabled={addressByCepIsLoading}
            />
            <InputText
              labelText="Cidade*"
              cssClasses={{ label: 'row-start-7 row-end-8 col-start-6 col-end-10' }}
              {...register('city')}
              disabled={addressByCepIsLoading}
            />
            <InputText
              labelText="UF*"
              cssClasses={{
                label: 'row-start-7 row-end-8 col-start-10 col-end-12 md:max-w-[100px]',
              }}
              {...register('state')}
              disabled={addressByCepIsLoading}
            />
          </div>
          <div className="flex justify-between md:justify-end items-center md:gap-x-7 lg:gap-x-11 md:row-start-[9] !w-full md:col-start-8 md:col-end-13 mt-[11px] md:mt-[30px]">            
            <ReturnButton 
              returnTo={PAGES_ROUTES.authenticated.client.list}
             />
            <button
              type="submit"
              className="bg-primary text-xs font-bold leading-4 disabled:bg-[#D1D5DB] text-buttontextcolor py-2 pl-4 pr-[18px] rounded-lg flex justify-center items-center h-[42px] max-w-[147px] tracking-[0.6px]"
              disabled={!isValid || isLoading || watchTypeClientId == -1 || clientTypeListIsLoading}
            >
              SALVAR
              <SaveIcon className="ml-4 !text-buttontextcolor" />
            </button>
          </div>
        </form>
      ) : (
        <LoadingSkeleton />
      )}
    </div>
  );
}

export default Edit;
