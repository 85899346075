/* eslint-disable @typescript-eslint/ban-ts-comment */
// Third-party
import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

//App
import { UserStatusEnum } from 'interfaces/userTypes';
import { InputText } from 'components';
import {
  ArrowIcon,
  DeleteIcon,
  LoadingSpinIcon,
  PenIcon,
  ProfileIcon,
  SearchIcon,
} from 'assets/icons';
import ListSkeleton from './components/ListSkeleton';
import Skeleton from 'react-loading-skeleton';
import { PAGES_ROUTES } from 'constants/routes';
import { useGetUser, useGetUserList } from 'store/server/user/queries';
import { cnpjMask } from 'utils/mask.utils';
import usePersistedState from 'hooks/usePersistedState';
import PageHeader from 'components/PageHeader/components/PageHeader';
import TableContainer from 'components/TableContainer';
import NavigationSelector from 'components/NavigationSelector';
import { screenConfig } from 'utils/screenConfig.util';
import {  ORDER_ATTRIBUTTES, ascDescType } from 'constants/orderAttributtes';


const { ASC, DESC } = ORDER_ATTRIBUTTES;
const { MEDIUM_SCREEN_SIZE } = screenConfig.constants;

const accessLevelsParams = {
  1: {
    label: 'Administrador',
    color: 'bg-green-100',
  },
  2: {
    label: 'Unidade',
    color: 'bg-purple-100',
  },
};

function UserList() {
  /*
  **** Component organization ****

   └── Declaration of generic hooks (e.g., useNavigate)
   └── State declaration
   └── Side effects (e.g., useEffect)
   └── Memoization (e.g., useMemo)
   └── Handlers (e.g., useCallback)
   └── JSX
   */

  // └── Declaration of generic hooks (e.g., useNavigate)
  const navigate = useNavigate();

  // └── State declaration
  type OrderAttributeType =
    | 'user.id'
    | 'user.givenName'
    | 'user.email'
    | 'user.status'
    | 'user.accessLevel'
    | 'company.fantasyName'
    | 'company.cnpj'
    | 'company.status';
  const [orderAttribute, setOrderAttribute] = useState<OrderAttributeType>('user.givenName');
  const [order, setOrder] = useState<ascDescType>(ASC);
  const [searchFilter, setSearchFilter] = useState<string | null>(null);
  const [searchFilterSubmitted, setSearchFilterSubmitted] = useState<string | null>(null);

  // Selected table row
  const [selectedRowId, setSelectedRowId] = useState<null|number>(null);

  // Pagination states
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = usePersistedState<number>('userList.pageSize', 10);

  // └── State declaration
  const { isLoading: userLoggedIsLoading, data: userLogged } = useGetUser();
  const {
    data: userListData,
    isLoading: IsLoadingUserList,
    isFetching: IsFetchingUserList,
  } = useGetUserList({
    start: page,
    size: pageSize,
    field: orderAttribute,
    order,
    filter: searchFilterSubmitted,
  });

  // └── Side effects (e.g., useEffect)

  const handleSortByColumn = useCallback(
    (columnName: OrderAttributeType, orderSelected?: ascDescType) => () => {
      if (orderAttribute !== columnName) {
        setOrder(ASC); // If the sort field is different from the clicked column, set the order to ASC
      } else {
        setOrder(orderSelected ? orderSelected : order === ASC ? DESC : ASC); // If the sort field is the same as the clicked column, toggle between ASC and DESC order
      }
      setOrderAttribute(columnName); // Set the sort field to the clicked column
    },
    [orderAttribute, order]
  );

  const checkIfShowCards = useCallback(() => {
    if (window.innerWidth < MEDIUM_SCREEN_SIZE) {
      orderAttribute !== 'user.givenName' && setOrderAttribute('user.givenName');
      order !== ASC && setOrder(ASC);
      if (searchFilter) {
        setSearchFilter(null);
        setSearchFilterSubmitted(null);
      }
    }
  }, [order, searchFilter, orderAttribute]);

  useEffect(() => {
    window.removeEventListener('resize', checkIfShowCards);
    window.addEventListener('resize', checkIfShowCards);

    return () => {
      window.removeEventListener('resize', checkIfShowCards);
    };
  }, [checkIfShowCards]);

  /**
   * Returns a memoized arrow component with the correct direction based on the 'order' prop.
   *
   * @param {string} order - The order prop indicating the direction ('asc' or DESC).
   * @returns {React.Component} - The memoized Arrow component.
   */
  const ArrowWithCorrectDirection = useMemo(
    () =>
      function Arrow({ className }: { className?: string | Record<string, unknown> }) {
        return (
          <ArrowIcon
            className={classNames(
              {
                'rotate-180': order === ASC, // Adds the 'rotate-180' class if the 'order' prop is 'asc'
              },
              className
            )}
          />
        );
      },
    [order] // Dependency for useMemo, the component will be recomputed when the 'order' prop changes
  );

  const showSkeletonLoading = useMemo(
    () => (!userListData?.data && IsLoadingUserList) || userLoggedIsLoading,
    [userLoggedIsLoading, userListData, IsLoadingUserList]
  );

  const handleChangeSearchInput = useCallback((event: { target: { value: string } }) => {
    setSearchFilter(event.target.value);

    if (event.target.value === null) {
      setSearchFilterSubmitted(null);
    }
  }, []);

  const handleSubmitSearch = useCallback(() => {
    setPage(0);
    setSearchFilterSubmitted(searchFilter);
  }, [searchFilter]);

  const handleClearFilters = useCallback(() => {
    setPage(0);
    setSearchFilter(null);
    setSearchFilterSubmitted(null);
  }, []);

  const handleRowClick = (clientId:number) => {    
    setSelectedRowId(clientId === selectedRowId ? null : clientId);
  };
 
  
  return (
    <div
      className={classNames('h-full', {
        'overflow-hidden max-h-[calc(100vh-64px)] md:max-h-[calc(100vh-71px)]': showSkeletonLoading,
      })}
    >
      <div
        className={classNames(
          'pt-5 md:pt-[42px] p-[27px] md:p-8 w-full min-h-[calc(100vh-64px)] md:min-h-[calc(100vh-71px)]',
          {
            'overflow-hidden !h-screen': showSkeletonLoading,
          }
        )}
      >
        {/* HEADER */}
        <PageHeader
          iconTextTitleProps={{
            iconProps: {
              iconJSX: <ProfileIcon className="w-[22px] h-[24px] !text-secondary" />,
            },
            textProps: {
              content: 'Lista de Usuários',
            },
          }}
        />
        <div className="w-full flex flex-col md:flex-row justify-between mb-4 gap-3">
          <div className="flex flex-row gap-x-[12px] w-full">
            <div className="w-full md:max-w-[302px] flex flex-col md:flex-row justify-between items-center gap-x-3 gap-y-3">
              {showSkeletonLoading ? (
                <>
                  <Skeleton
                    height={42}
                    baseColor="white"
                    highlightColor="#f3f3f3"
                    className="!w-full md:max-w-[234px] !rounded-lg"
                    containerClassName="w-full md:max-w-[234px]"
                  />
                  <Skeleton
                    height={42}
                    baseColor="white"
                    highlightColor="#f3f3f3"
                    className="w-full md:max-w-[56px] !rounded-lg"
                    containerClassName="w-full md:max-w-[56px]"
                  />
                </>
              ) : (
                <>
                  <InputText
                    name="searchFilter"
                    type="text"
                    placeholder="Buscar usuário"
                    onChange={handleChangeSearchInput}
                    value={searchFilter ?? ''}
                    cssClasses={{
                      label:
                        'w-full lg:max-w-[350px] lg:min-w-[150px] !flex-1 md:row-start-1 md:row-end-2 max-md:col-start-1 max-md:col-end-4',
                      input:
                        '!mt-0 w-full !h-[42px] !text-[#374151] !font-normal text-xs placeholder:font-normal placeholder:text-xs disabled:placeholder:!text-[#D1D5DB] truncate disabled:bg-gray-100',
                    }}
                    min={'18'}
                    max={'18'}
                  />
                  <button
                    className={classNames(
                      ' bg-primary disabled:bg-[#D1D5DB] text-white py-2 px-4 rounded-lg flex justify-center items-center w-full h-[42px] md:max-w-[56px]',
                      {
                        '!col-end-3': IsLoadingUserList || IsFetchingUserList,
                      }
                    )}
                    onClick={handleSubmitSearch}
                    disabled={
                      !searchFilter ||
                      searchFilter === searchFilterSubmitted ||
                      IsLoadingUserList ||
                      IsFetchingUserList
                    }
                  >
                    {IsLoadingUserList || IsFetchingUserList ? (
                      <LoadingSpinIcon className=" w-5 !text-primary md:!text-buttontextcolor mx-[2px]" />
                    ) : (
                      <SearchIcon className="!text-buttontextcolor" />
                    )}
                  </button>
                </>
              )}
              {searchFilterSubmitted && (
                <button
                  className="bg-primary text-xs font-bold leading-4 tracking-[0.6px] text-white flex justify-center items-center gap-x-3  w-full md:w-fit h-[42px] py-2 px-4 rounded-lg disabled:bg-[#D1D5DB]"
                  onClick={handleClearFilters}
                  disabled={IsLoadingUserList || IsFetchingUserList}
                >
                  <span className="hidden min-[1060px]:inline-block">LIMPAR</span>
                  <DeleteIcon />
                </button>
              )}
            </div>
          </div>
        </div>

        {showSkeletonLoading ? (
          <ListSkeleton />
        ) : userListData?.data && userListData?.data.length <= 0 ? (
          <p className="w-full text-center text-[#595959] font-semibold">
            Nenhum usuário encontrado!
          </p>
        ) : (
          <TableContainer>
            {/* Desktop */}
            <table className="w-full text-sm text-left text-gray-500 hidden md:table">
              <thead className="font-inter text-[10px] text-primary font-bold uppercase bg-gray-50 w-full">
                <tr>
                  <th
                    scope="col"
                    className="h-full px-6 py-3 cursor-pointer hover:bg-gray-100 w-fit"
                    onClick={handleSortByColumn('user.id')}
                  >
                    <span className="flex flex-row items-center gap-2 w-full truncate">
                      ID
                      <span className={classNames('min-w-[12px]')}>
                        {orderAttribute === 'user.id' && <ArrowWithCorrectDirection />}
                      </span>
                    </span>
                  </th>
                  <th
                    scope="col"
                    className="h-full px-6 py-3 cursor-pointer hover:bg-gray-100 w-fit"
                    onClick={handleSortByColumn('user.givenName')}
                  >
                    <span className="flex flex-row items-center gap-2 truncate">
                      NOME
                      <span className={classNames('min-w-[12px]')}>
                        {orderAttribute === 'user.givenName' && <ArrowWithCorrectDirection />}
                      </span>
                    </span>
                  </th>
                  <th
                    scope="col"
                    className="h-full px-6 py-3 cursor-pointer hover:bg-gray-100 w-fit"
                    onClick={handleSortByColumn('user.email')}
                  >
                    <span className="flex flex-row items-center gap-2 truncate">
                      E-MAIL
                      <span className={classNames('min-w-[12px]')}>
                        {orderAttribute === 'user.email' && <ArrowWithCorrectDirection />}
                      </span>
                    </span>
                  </th>
                  <th
                    scope="col"
                    className="h-full px-6 py-3 cursor-pointer hover:bg-gray-100 w-fit"
                    onClick={handleSortByColumn('company.fantasyName')}
                  >
                    <span className="flex flex-row items-center gap-2 truncate">
                      EMPRESA
                      <span className={classNames('min-w-[12px]')}>
                        {orderAttribute === 'company.fantasyName' && <ArrowWithCorrectDirection />}
                      </span>
                    </span>
                  </th>
                  <th
                    scope="col"
                    className="h-full px-6 py-3 w-fit cursor-default"
                    onClick={handleSortByColumn('company.cnpj')}
                  >
                    <span className="flex flex-row items-center gap-2 truncate">
                      CNPJ DA EMPRESA
                      <span className={classNames('min-w-[12px]')}>
                        {orderAttribute === 'company.cnpj' && <ArrowWithCorrectDirection />}
                      </span>
                    </span>
                  </th>
                  <th
                    scope="col"
                    className="h-full px-6 py-3 w-fit cursor-default"
                    onClick={handleSortByColumn('user.accessLevel')}
                  >
                    <span className="flex flex-row items-center gap-2 truncate">
                      NÍVEL DE ACESSO
                      <span className={classNames('min-w-[12px]')}>
                        {orderAttribute === 'user.accessLevel' && <ArrowWithCorrectDirection />}
                      </span>
                    </span>
                  </th>
                  <th
                    scope="col"
                    className="h-full px-6 py-3  w-fit cursor-default"
                    onClick={handleSortByColumn('user.status')}
                  >
                    <span className="flex flex-row items-center gap-2 truncate">
                      STATUS
                      <span className={classNames('min-w-[12px]')}>
                        {orderAttribute === 'user.status' && <ArrowWithCorrectDirection />}
                      </span>
                    </span>
                  </th>
                  <th scope="col" className="h-full px-6 py-3 w-fit cursor-default">
                    <span className="flex flex-row items-center gap-2 truncate">AÇÕES</span>
                  </th>
                </tr>
              </thead>
              <tbody className="w-full">
                {userListData?.data?.map((userForListing, userForListingIndex) => (
                  <tr
                  className={classNames(
                    "bg-white border-b w-full h-[60px] text-xs text-black",
                    {
                      '!bg-white hover:!bg-gray-100 ': selectedRowId !== userForListing.id,                      
                      '!bg-gray-300': selectedRowId === userForListing.id,    
                    }

                  )}
                    key={`user-${userForListing.id}-row-${userForListingIndex}`}
                    onClick={() => handleRowClick(userForListing.id)}

                  >
                    <td className="px-6 py-4 font-normal whitespace-nowrap w-fit">
                      {userForListing.id}
                    </td>
                    <td className="px-6 py-4 font-normal whitespace-nowrap w-fit">
                      {userForListing.givenName} {userForListing.familyName}
                    </td>
                    <td className="px-6 py-4 font-normal whitespace-nowrap w-fit">
                      {userForListing.email}
                    </td>
                    <td className="px-6 py-4 font-normal whitespace-nowrap w-fit">
                      {userForListing.company?.fantasyName}
                    </td>
                    <td className="px-6 py-4 font-normal whitespace-nowrap w-fit">
                      {userForListing.company?.cnpj && cnpjMask(userForListing.company.cnpj)}
                    </td>
                    <td
                      className={classNames('px-6 py-4 text-[10px] whitespace-nowrap w-fit', {
                        'text-green-800 font-medium': userForListing.accessLevel === 1,
                        'text-purple-800 font-bold': userForListing.accessLevel === 2,
                      })}
                    >
                      <span
                        className={classNames('rounded-[10px] px-[10px] pt-[3px] pb-[2px]', {
                          'bg-green-100': userForListing.accessLevel === 1,
                          'bg-purple-100': userForListing.accessLevel === 2,
                        })}
                      >
                        {accessLevelsParams[userForListing.accessLevel]?.label}
                      </span>
                    </td>
                    <td
                      className={classNames('px-6 py-4 whitespace-nowrap w-fit', {
                        '!text-green-500':
                          userForListing.status &&
                          String(UserStatusEnum[userForListing.status]) === UserStatusEnum.ACTIVED,
                        '!text-gray-500':
                          userForListing.status &&
                          String(UserStatusEnum[userForListing.status]) === UserStatusEnum.PENDING,
                        '!text-red-500':
                          userForListing.status &&
                          String(UserStatusEnum[userForListing.status]) ===
                            UserStatusEnum.DESACTIVED,
                      })}
                    >
                      {userForListing.status &&
                        String(UserStatusEnum[userForListing.status]).toUpperCase()}
                    </td>
                    <td className="px-6 py-4 w-fit max-w-[106px] flex justify-between font-medium whitespace-nowrap">
                      <button
                        onClick={() =>
                          navigate(
                            PAGES_ROUTES.authenticated.user.edit.replace(
                              ':userId',
                              (userForListing.id ?? -1).toString()
                            )
                          )
                        }
                        className="mr-3 group"
                      >
                        <PenIcon className="" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* Mobile */}
            <div className="flex flex-col gap-y-[18px] md:hidden w-full">
              {userListData?.data?.map((userForListing, userForListingIndex) => (
                <div
                  className="bg-white px-[20px] py-[16px] overflow-hidden shadow-container rounded-[5px] w-full "
                  key={`company-card-${userForListing.id}-row-${userForListingIndex}-mobile`}
                >
                  <div className="w-full flex flex-col gap-y-[10px]">                 
                    <div className="flex flex-row justify-between h-fit">
                    <span
                      className={classNames(
                        'w-fit h-fit rounded-[10px] px-[10px] pt-[3px] pb-[2px] text-[10px]',
                        {
                          'bg-green-100 text-green-800 font-medium':
                            userForListing.accessLevel === 1,
                          'bg-purple-100 text-purple-800 font-bold':
                            userForListing.accessLevel === 2,
                        }
                      )}
                    >
                      {accessLevelsParams[userForListing.accessLevel]?.label}
                    </span>
                    <div className='flex flex-row gap-x-2.5'>
                      <div
                        className={classNames('text-xs flex justify-end self-end', {
                          '!text-green-500':
                            userForListing.status &&
                            String(UserStatusEnum[userForListing.status]) ===
                              UserStatusEnum.ACTIVED,
                          '!text-gray-500':
                            userForListing.status &&
                            String(UserStatusEnum[userForListing.status]) ===
                              UserStatusEnum.PENDING,
                          '!text-red-500':
                            userForListing.status &&
                            String(UserStatusEnum[userForListing.status]) ===
                              UserStatusEnum.DESACTIVED,
                        })}
                      >
                        {' '}
                        {userForListing.status &&
                          String(UserStatusEnum[userForListing.status]).toUpperCase()}
                      </div>
                      <button
                        onClick={() =>
                          navigate(
                            PAGES_ROUTES.authenticated.user.edit.replace(
                              ':userId',
                              (userForListing.id ?? -1).toString()
                            )
                          )
                        }
                        className={classNames({
                          group: true,
                          'md:mr-3': userForListing.email !== userLogged?.email,
                        })}
                      >
                        <PenIcon className="group-enabled:hover:text-secondary" />
                      </button>
                    </div>
                    </div>
                  <div className="flex flex-row w-full">
                    <span className="w-full">
                      
                      <p className=" text-black text-xs/[20px] font-normal">
                        {userForListing.givenName} {userForListing.familyName}
                      </p>
                      <p className="text-xs/[20px] text-gray-500">{userForListing.email}</p>
                      <p className="text-xs/[20px] text-gray-500">
                        {userForListing.company?.fantasyName}
                      </p>
                      <p className="text-xs/[20px] text-gray-500">
                        {userForListing.company?.cnpj && cnpjMask(userForListing.company.cnpj)}
                      </p>
                    </span>
                  </div>
                  </div>

                </div>
              ))}
            </div>
          </TableContainer>
        )}
        {!showSkeletonLoading && (
          <NavigationSelector
            totalResults={userListData?.total || 0}
            pageSize={pageSize}
            currentPage={page}
            onChangePageSize={setPageSize}
            onPageChange={setPage}
            isLoading={IsLoadingUserList || IsFetchingUserList}
          />
        )}
      </div>
    </div>
  );
}

export default UserList;
