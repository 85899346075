/* eslint-disable import/no-unused-modules */
import Create from './Create';
import Edit from './Edit';
import List from './List';
import ListToSelect from './ListToSelect';
import ListGroup from './ListGroup';
import Group from './Group';
import Dashboard from './Dashboard';
import Summary from './Summary';

export { Create };
export { Edit };
export { List };
export { ListToSelect };
export { ListGroup };
export { Group };
export { Dashboard };
export { Summary };

export default {
  Create,
  Edit,
  List,
  ListToSelect,
  ListGroup,
  Group,
  Dashboard,
  Summary,
};
