// Third-party
import { useMutation } from '@tanstack/react-query';

// App
import queryClient from '../queryClient';
import { refreshTokenService } from 'services/auth';
import useAuthStore from 'store/client/auth/useAuthStore';
import { selectSetIsRefreshToken } from 'store/client/auth/selectors';
import { IUpdateUserPayload } from 'interfaces/userTypes';
import { updateUser } from 'services/api/user';
import queriesKeys from './queriesKeys';

export const useRefreshToken = () => {
  const setIsRefreshToken = useAuthStore(selectSetIsRefreshToken);

  const mutation = useMutation({
    mutationKey: [queriesKeys.getUser],
    mutationFn: async (reload?: boolean) => {
      try {
        if (mutation.isIdle) {
          setIsRefreshToken(true);

          await refreshTokenService();

          if (reload) {
            location.reload();
          }
        }
      } catch (error) {
        localStorage.clear();
        queryClient.invalidateQueries({ queryKey: [queriesKeys.getUser] });
        location.reload();
      }
    },
    onSettled: () => {
      setIsRefreshToken(false);
      mutation.reset();
    },
  });
  return mutation;
};

export const useUpdateUserMutation = (userId: number) =>
  useMutation({
    mutationFn: async (data: IUpdateUserPayload) => {
      await updateUser(userId, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queriesKeys.userDetails, userId] });
    },
  });
