import React from 'react'
import Skeleton from 'react-loading-skeleton';
import { convertToBrazilianCurrencyFormat } from 'utils/currency.utils';
import { DEFAULT_VALUES } from 'constants/valueDiplay';

export interface ITotalValueDisplay {
    title?:string;
    isLoadingData? : boolean
    skeletonProps: {
        useSkeleton?: boolean;
        skeletonHeight?: number;
        skeletonBaseClassName?: string;
        skeletonContainerClassName?:string; 
    },
    displayedValueProps: {
        containerClassName?: string;
        textColor?: string;
        valueToDisplay?: string | number | null;
        defaultCharToDisplay?: string;
        useCurrencyMask?: boolean
    };
    keyNumber?:number|string
}
function TotalValueDisplay({ isLoadingData, skeletonProps, displayedValueProps, keyNumber }: ITotalValueDisplay) {
    const { useSkeleton, skeletonHeight, skeletonBaseClassName, skeletonContainerClassName } = skeletonProps;
    const { containerClassName, textColor, valueToDisplay, useCurrencyMask, defaultCharToDisplay} = displayedValueProps;
    const {DEFAULT_STRING_VALUE} = DEFAULT_VALUES

    // Optional currency formatting function
    const displayValueTreatment = (value: string | number) => {
        return useCurrencyMask ? convertToBrazilianCurrencyFormat(`${value}`) : value;
    };        

    return (
        isLoadingData ? (
            useSkeleton ? (
                <Skeleton
                    height={skeletonHeight || 40.5}
                    className={skeletonContainerClassName  || "inline-block w-full"}
                    containerClassName={skeletonBaseClassName || "inline-block w-full"}
                    key={`${keyNumber}-skeleton`}
                />
            ) : <></>
        ) : (
            <div 
            key={keyNumber}            
            className={containerClassName || 'text-xl mx-auto'}>
                <span style={{ color: textColor || '#ff5900' }}>
                    {valueToDisplay ? displayValueTreatment(valueToDisplay) : defaultCharToDisplay || DEFAULT_STRING_VALUE}
                </span>
            </div>
        )
    );
}

export default TotalValueDisplay