import classNames from 'classnames';
import transactionIcon from '../../../public/icons/transactions-icon.png'; 

function TransactionsIcon({ className }: { className?: string | Record<string, unknown>; }) {
  return (
    <img
      src={transactionIcon}
      alt="transaction-icon"
      className={classNames('w-2.5 h-1.5', className)}
    />
  );
}

export default TransactionsIcon;