import { postRefreshToken } from 'services/api/user/auth';
import useAuthStore from 'store/client/auth/useAuthStore';
import queryClient from 'store/server/queryClient';
import userQueriesKeys from 'store/server/user/queriesKeys';
import useCompanySelectedStore from 'store/client/companySelected/useCompanySelectedStore';

export const refreshTokenService = async () => {
  const refreshToken = localStorage.getItem('refresh_token');

  if (!refreshToken) {
    throw new Error('Refresh token not found.');
  }

  const authKeys = await postRefreshToken(refreshToken);

  localStorage.setItem('access_token', authKeys.response.AuthenticationResult.IdToken);
};

export const signOutService = () => {
  const { tokenTimeoutId, cleanTokenTimeoutId } = useAuthStore.getState();
  const { setCompanySelected } = useCompanySelectedStore.getState();
  if (tokenTimeoutId) {
    clearTimeout(tokenTimeoutId);
    cleanTokenTimeoutId();
  }

  localStorage.clear();
  queryClient.invalidateQueries({ queryKey: [userQueriesKeys.getUser] });
  setCompanySelected(null);
  location.reload();
};
