// Third-party
import { number, object, string } from 'yup';

// App
import { emailRegex } from 'constants/validations';
import { IEditClientPayload } from 'interfaces/clientTypes';

export const formSchema = object<IEditClientPayload>({  
  name: string().nullable(),
  comercialName: string().nullable(),
  zipCode: string().nullable(),
  address: string().nullable(),
  addressNumber: string().nullable(),
  complement: string(),
  district: string().nullable(),
  city: string().nullable(),
  state: string().nullable(),
  phone: string().nullable(),
  email: string().matches(emailRegex, 'Formato inválido').nullable(),
  typeClientId: number().nullable(),
});
