import ExpensesChart from '../../ExpensesChart';
import ExpensesList from '../../ExpensesList';
import ExpensesPayList from '../../ExpensesPayList';
import SectionContainer from '../SectionContainer';

interface IExpensesSectionProps {
  toExport?: boolean;
}

function ExpensesSection({ toExport }: IExpensesSectionProps) {
  return (
    <SectionContainer
      title="DESPESAS"
      cssClasses={{
        titleContainerStyle: 'bg-[#ff0000]',
        container: toExport ? 'max-h-[1250px] overflow-hidden' : undefined,
      }}
      toExport={toExport}
    >
      <ExpensesChart toExport={toExport} />
      <ExpensesList toExport={toExport} />
      <ExpensesPayList toExport={toExport} />
    </SectionContainer>
  );
}

export default ExpensesSection;
