/* eslint-disable react-hooks/rules-of-hooks */
// Third-party
import React, { useCallback, useRef, useState } from 'react';
import { Navigate } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import classNames from 'classnames';
import JsPDF from 'jspdf';
import html2canvas from 'html2canvas';

// App
import { useGetSummaryData } from 'store/server/company/queries';
import useCompanySelectedStore from 'store/client/companySelected/useCompanySelectedStore';
import { selectCompanySelected } from 'store/client/companySelected/selectors';
import { PAGES_ROUTES } from 'constants/routes';
import { LoadingSkeleton } from './components';
import FilterBar from './components/FilterBar';
import getMonthStartAndEndUtil from 'utils/getMonthStartAndEnd.util';
import { useFilterBarContext } from './context/FilterBarContext';

import './style.css';
import { setPageCount, setPageCountWithTotal, setPdfHeader } from 'utils/pdf.utils';
import { cnpjMask } from 'utils/mask.utils';
import ExportButton from 'components/ExportButton ';
import { GraphIcon, PDFIcon } from 'assets/icons';
import Header from './components/Header';
import PageHeader from 'components/PageHeader/components/PageHeader';
import { SECTIONS_LIST } from 'constants/summary';
import { ISummarySection } from 'interfaces/summaryType';
import {
  ExpensesSection,
  SalesControlSection,
  SalesSection,
  ServicesSection,
  SummarySection,
} from './components/sections';
import { dateUtils } from 'utils/formatDate';

const { DATE_DISPLAY_FORMAT } = dateUtils.constants;

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/Sao_Paulo');

const Summary: React.FC = () => {
  const dashboardContainerRef = useRef<HTMLDivElement>(null);
  const dashboardToExportContainerRef = useRef<HTMLDivElement>(null);
  const companySelected = useCompanySelectedStore(selectCompanySelected);
  const [isGeneratingPdf, setIsGeneratingPdf] = useState<boolean>(false);

  const { selectedMonth, selectedYear } = useFilterBarContext();

  const [firstSection] = SECTIONS_LIST;
  const [selectedSection, setSelectedSection] = useState<ISummarySection>(firstSection);

  if (!companySelected) {
    return <Navigate to={PAGES_ROUTES.authenticated.commonInitialRoute} replace />;
  }

  const {
    data: summaryData,
    isLoading: dashboardDataIsLoading,
    isRefetching: dashboardDataIsRefetching,
  } = useGetSummaryData(companySelected.id, {
    ...getMonthStartAndEndUtil(Number(selectedYear), Number(selectedMonth)),
  });

  const handlePrint = useCallback(async () => {
    setIsGeneratingPdf(true);
    try {
      const pdf = new JsPDF('p', 'pt', 'a4');
      const pageWidth = 595.28;
      const marginX = 20;
      const marginY = 20;

      const chartDivs =
        dashboardToExportContainerRef?.current?.querySelectorAll('.to-export') ?? [];

      const period = getMonthStartAndEndUtil(selectedYear, selectedMonth);

      let position = marginY;
      setPdfHeader(pdf, {
        pageWidth,
        companyName: companySelected.name,
        companyCnpj: cnpjMask(companySelected.cnpj),
        selectedMonth,
        selectedYear,
        pdfPeriod: `${dayjs(period.initialDate).format(DATE_DISPLAY_FORMAT)} - ${dayjs(
          period.finalDate
        ).format(DATE_DISPLAY_FORMAT)}`,
        headerTitle: 'Resumo Financeiro',
      });

      for (let index = 0; index < chartDivs.length; index++) {
        const chartDiv = chartDivs[index];

        const period = getMonthStartAndEndUtil(selectedYear, selectedMonth);

        if (index !== 0) {
          pdf.addPage();
          pdf.addImage('/logo_objetiva.png', 'PNG', 20, 20, 87, 60);
          setPdfHeader(pdf, {
            pageWidth,
            companyName: companySelected.name,
            companyCnpj: cnpjMask(companySelected.cnpj),
            selectedMonth,
            selectedYear,
            headerTitle: 'Resumo Financeiro',
            pdfPeriod: `${dayjs(period.initialDate).format(DATE_DISPLAY_FORMAT)} - ${dayjs(
              period.finalDate
            ).format(DATE_DISPLAY_FORMAT)}`,
          });
          position = marginY + 100;
        }

        await html2canvas(chartDiv as HTMLElement, { scale: 2 }).then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const imgWidth = pageWidth - marginX * 2;
          const imgHeight = (canvas.height / canvas.width) * imgWidth;

          const imgX = marginX;
          const imgY = pdf.getNumberOfPages() === 1 ? position + 120 : position;

          pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth, imgHeight);

          position += imgHeight + marginY;
        });
      }

      setPageCountWithTotal(pdf, { pageWidth });

      pdf.save(`ECVFinancy_ResumoFinanceiro_${dayjs().format('DD_MM_YYYY_hh_mm_ss')}.pdf`);
    } finally {
      setIsGeneratingPdf(false);
    }
  }, [companySelected.cnpj, companySelected.name, selectedMonth, selectedYear]);

  return (
    <div className="summary-container">
      <PageHeader
        iconTextTitleProps={{
          iconProps: {
            iconJSX: <GraphIcon className="!text-secondary w-[22px] h-[22px]" />,
          },
          textProps: {
            content: 'Resumo',
          },
        }}
      />
      <div className="filter-bar-container">
        <FilterBar disabled={dashboardDataIsLoading || dashboardDataIsRefetching} />

        <ExportButton
          loading={dashboardDataIsLoading || dashboardDataIsRefetching || isGeneratingPdf}
          onClick={() => {
            handlePrint();
          }}
          icon={<PDFIcon className="ml-4 !text-buttontextcolor" />}
          buttonClassName="md:!max-w-[190px] !gap-x-0"
          buttonText="Exportar"
          buttonDisabled={dashboardDataIsLoading || dashboardDataIsRefetching}
        />
      </div>
      <Header
        summaryData={summaryData}
        isLoading={dashboardDataIsLoading || dashboardDataIsRefetching}
      />
      <div className="sections-bar w-full mb-[40px] overflow-x-auto">
        <div className="flex md:!w-full">
          {SECTIONS_LIST.map((section, sectionIndex) => (
            <button
              key={`summary-section-${section.title}-${sectionIndex}`}
              onClick={() => setSelectedSection(section)}
              className={classNames(
                'bg-none border-b-[3px] border-primary min-w-fit w-full text-primary text-center text-base leading-3 font-bold p-[10px] h-fit whitespace-nowrap',
                {
                  '!text-[#6B7280] !border-[#C4C4C480] font-semibold':
                    selectedSection.title !== section.title,
                }
              )}
            >
              {section.title}
            </button>
          ))}
        </div>
      </div>
      {dashboardDataIsLoading ? (
        <LoadingSkeleton />
      ) : (
        <div ref={dashboardContainerRef} className="dashboard-container max-w-[1400px]">
          {<selectedSection.component />}
        </div>
      )}
      {dashboardDataIsLoading ? (
        <LoadingSkeleton />
      ) : (
        <div style={{ position: 'absolute', width: '0', height: '0', overflow: 'hidden' }}>
          <div
            ref={dashboardToExportContainerRef}
            className="!w-[1224px] h-fit self-center px-[24px] flex flex-col gap-y-[100px]"
          >
            <div className="to-export flex flex-col gap-y-[40px] gap-x-[20px] min-h-[500px] max-w-[100%]">
              <Header
                summaryData={summaryData}
                isLoading={dashboardDataIsLoading || dashboardDataIsRefetching}
              />
              <SummarySection toExport />
            </div>

            <ExpensesSection toExport />
            <SalesSection toExport />
            <SalesControlSection toExport />
            <ServicesSection toExport />
          </div>
        </div>
      )}
    </div>
  );
};

export default Summary;
