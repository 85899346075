// Third-party
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

// App

function EditUserSkeleton() {
  /*
  **** Component organization ****

   └── Declaration of generic hooks (e.g., useNavigate)
   └── State declaration
   └── Side effects (e.g., useEffect)
   └── Memoization (e.g., useMemo)
   └── Handlers (e.g., useCallback)
   └── JSX
   */

  return (
    <div className="bg-[#F3F4F6] pt-24 md:pt-28 w-full h-full min-h-screen flex justify-center items-start p-6 mt-5 md:mt-6">
      <div className="flex flex-col items-center md:grid md:gap-x-6 gap-y-[34px] md:gap-y-10 md:grid-cols-4 w-full min-w-0 max-w-[100%] md:max-w-[755px]">
        <Skeleton height={44} containerClassName="w-full md:col-start-1 md:col-end-3" />
        <Skeleton height={44} containerClassName="w-full md:col-start-3 md:col-end-5" />
        <Skeleton height={44} containerClassName="w-full md:col-start-1 md:!col-end-4" />
        <Skeleton height={44} containerClassName="w-full md:col-start-4 md:col-end-5" />

        <div className="flex justify-between md:justify-end items-center gap-11 md:row-start-5 md:row-end-6 !w-full md:col-start-3 md:col-end-5 mt-[-24px] md:mt-[-80px]">
          <Skeleton width={68} height={20} />
          <Skeleton width={120} height={42} />
        </div>
      </div>
    </div>
  );
}

export default EditUserSkeleton;
