import { IUserFromDB } from 'interfaces/apiTypes';
import { useQuery } from '@tanstack/react-query';
import { IUser, IUserListParams } from 'interfaces/userTypes';
import queriesKeys from './queriesKeys';
import { getUserByEmail, getUserById, getUsersList } from 'services/api/user';
import { PAGES_ROUTES } from 'constants/routes';
import useCompanySelectedStore from 'store/client/companySelected/useCompanySelectedStore';

interface IUserWithInitialRouter extends IUser {
  initialRoute: string;
}

export const useGetUser = (enabled = false) =>
  useQuery<IUserWithInitialRouter | null>({
    queryKey: [queriesKeys.getUser],
    queryFn: async () => {
      const token = localStorage.getItem('access_token');

      if (token) {
        const { email }: IUserFromDB = JSON.parse(atob(token.split('.')[1]));

        const userByEmail = await getUserByEmail(email);

        if (userByEmail) {
          let initialRoute;
          switch (userByEmail.accessLevel) {
            case 1:
              initialRoute = PAGES_ROUTES.authenticated.adminInitialRoute;
              break;
            case 2:
            default:
              initialRoute = PAGES_ROUTES.authenticated.commonInitialRoute;
              break;
          }

          if ((userByEmail.accessLevel ?? 2) === 2 && userByEmail.company) {
            useCompanySelectedStore.getState().setCompanySelected(userByEmail.company);
          }

          return {
            ...userByEmail,
            accessLevel: userByEmail.accessLevel ?? 2,
            initialRoute,
          };
        }

        return null;
      } else {
        throw new Error('Requires the access token');
      }
    },
    enabled,
    retry: 0,
  });

export const useGetUserList = (params: IUserListParams) => {
  const { start, size, order, field, filter } = params;
  const queryKey = [queriesKeys.userList, start, size, order, field];

  if (filter) {
    queryKey.push(filter);
  }

  return useQuery({
    queryKey,
    queryFn: async () => {
      const users = await getUsersList(params);

      return users;
    },
    placeholderData: (previousData) => previousData,
  });
};
export const useGetUserById = (userId: number) =>
  useQuery<IUser | null>({
    queryKey: [queriesKeys.userDetails, userId],
    queryFn: async () => {
      const user = await getUserById(userId.toString());

      return user;
    },
  });
