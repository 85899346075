/* eslint-disable react-hooks/rules-of-hooks */
// Third-party
import { Navigate, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';

//App
import { InputText, Toggle } from 'components';
import {
  AddUserIcon,
  ArrowIcon,
  CustomerIcon,
  DeleteIcon,
  LoadingSpinIcon,
  PDFIcon,
  PenIcon,
  SearchIcon,
} from 'assets/icons';
import LoadingListSkeleton from './components/LoadingListSkeleton';
import Skeleton from 'react-loading-skeleton';
import { PAGES_ROUTES } from 'constants/routes';
import { useGetUser } from 'store/server/user/queries';
import { cpfCnpjMask, phoneNumberMask } from 'utils/mask.utils';
import usePersistedState from 'hooks/usePersistedState';
import { useGetClientList } from 'store/server/client/queries';
import { IClientListOrderAttribute } from 'interfaces/clientTypes';
import useCompanySelectedStore from 'store/client/companySelected/useCompanySelectedStore';
import { selectCompanySelected } from 'store/client/companySelected/selectors';
import { useEditClientStatusMutation } from 'store/server/client/mutations';
import useNotificationStore from 'store/client/notification/useNotificationStore';
import { selectAddNotification } from 'store/client/notification/selectors';
import { AxiosError } from 'axios';
import { ClientStatusEnum } from 'interfaces/companyTypes';
import ConfirmationModal from 'components/ConfirmationModal';
import dayjs from 'dayjs';
import PageHeader from 'components/PageHeader/components/PageHeader';
import TableContainer from 'components/TableContainer';
import NavigationSelector from 'components/NavigationSelector';
import { useExportReports } from 'store/server/company/queries';
import ExportButton from 'components/ExportButton ';
import { exportPdf } from 'utils/pdf.utils';
import { dateUtils } from '../../../utils/formatDate';
import { screenConfig } from 'utils/screenConfig.util';
import { exportExcel } from 'utils/excelExport.utils';
import { ORDER_ATTRIBUTTES, ascDescType } from 'constants/orderAttributtes';

// Constants
const { ASC, DESC } = ORDER_ATTRIBUTTES;
const { DATE_DISPLAY_FORMAT } = dateUtils.constants;
const { getFirstDayOfYear, getLastDayOfMonth, getCurrentMonth, getCurrentYear } = dateUtils.generateDate; 
const { MEDIUM_SCREEN_SIZE } = screenConfig.constants
const firstDayOfYear = getFirstDayOfYear();
const lastDayOfMonth = getLastDayOfMonth();
const currentMonth = getCurrentMonth();
const currentYear = getCurrentYear();

function List() {
  /*
  **** Component organization ****

   └── Declaration of generic hooks (e.g., useNavigate)
   └── State declaration
   └── Side effects (e.g., useEffect)
   └── Memoization (e.g., useMemo)
   └── Handlers (e.g., useCallback)
   └── JSX
   */

  // └── Declaration of generic hooks (e.g., useNavigate)
  const navigate = useNavigate();
  const tableContainerRef = useRef<HTMLTableElement>(null);

  
  // └── State declaration
  // Selected table row
  const [selectedRowId, setSelectedRowId] = useState<null|number>(null);

  // Pagination states
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = usePersistedState<number>('clientList.pageSize', 10);

  // Sort states
  const [orderAttribute, setOrderAttribute] = useState<IClientListOrderAttribute>('client.name');
  const [order, setOrder] = useState<ascDescType>(ASC);
  const [loadingReportExports, setLoadingReportExports] = useState<boolean>(false);
  const [loadingPdfExports, setLoadingPdfExports] = useState<boolean>(false);


  // └── State declaration
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);
  const [submitedFilters, setSubmitedFilters] = useState<{
    filter: string | null;
  }>({
    filter: null,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [clientProps, setClientIdSelected] = useState<null | { clientId: number; status: boolean }>(
    null
  );

  const { data: user, isLoading: userLoggedIsLoading } = useGetUser();

  const addNotification = useNotificationStore(selectAddNotification);
  const companySelected = useCompanySelectedStore(selectCompanySelected);

  if (!companySelected)
    return <Navigate to={user?.initialRoute ?? PAGES_ROUTES.initialRoute} replace />;

  const editClientMutation = useEditClientStatusMutation(companySelected?.id);

  const {
    data: clientListData,
    isLoading: clientListIsLoading,
    isFetching: clientListIsFetching,
  } = useGetClientList(companySelected.id, {
    start: page,
    size: pageSize,
    field: orderAttribute,
    order,
    filter: submitedFilters.filter,
  });

  const { refetch: ExcelRefetch } =  useExportReports({
    size: pageSize,
    start: page * pageSize,
    filter: submitedFilters?.filter,
    initialDate: firstDayOfYear,
    endDate: lastDayOfMonth,
    companyId: companySelected.id, 
    report: 'client',
    type: 'xls'
  });

  const { refetch: pdfRefetch } =  useExportReports({
    size: pageSize,
    start: page * pageSize,
    filter: submitedFilters?.filter,
    initialDate: firstDayOfYear,
    endDate: lastDayOfMonth,
    companyId: companySelected.id, 
    report: 'client',
    type: 'pdf'
  });

  // └── Side effects (e.g., useEffect)

  const handleSortByColumn = useCallback(
    (columnName: IClientListOrderAttribute, orderSelected?: ascDescType) => () => {
      if (orderAttribute !== columnName) {
        setOrder(ASC); // If the sort field is different from the clicked column, set the order to ASC
      } else {
        setOrder(orderSelected ? orderSelected : order === ASC ? DESC : ASC); // If the sort field is the same as the clicked column, toggle between ASC and 'desc' order
      }
      setOrderAttribute(columnName); // Set the sort field to the clicked column
    },
    [orderAttribute, order]
  );
    /**
   * Returns a memoized arrow component with the correct direction based on the 'order' prop.
   *
   * @param {string} order - The order prop indicating the direction (ASC or 'desc').
   * @returns {React.Component} - The memoized Arrow component.
   */
    const ArrowWithCorrectDirection = useMemo(
      () =>
        function Arrow({ className }: { className?: string | Record<string, unknown> }) {
          return (
            <ArrowIcon
              className={classNames(
                {
                  'rotate-180': order === ASC, // Adds the 'rotate-180' class if the 'order' prop is ASC
                },
                className
              )}
            />
          );
        },
      [order] // Dependency for useMemo, the component will be recomputed when the 'order' prop changes
    );
  
  // └── Handlers (e.g., useCallback)
  const checkIfShowCards = useCallback(() => {
    if (window.innerWidth < MEDIUM_SCREEN_SIZE) {
      orderAttribute !== 'client.name' && setOrderAttribute('client.name');
      order !== ASC && setOrder(ASC);
    }
  }, [order, orderAttribute]);

  useEffect(() => {
    window.removeEventListener('resize', checkIfShowCards);
    window.addEventListener('resize', checkIfShowCards);

    return () => {
      window.removeEventListener('resize', checkIfShowCards);
    };
  }, [checkIfShowCards]);

  const showSkeletonLoading = useMemo(
    () => (!clientListData?.data && clientListIsLoading) || userLoggedIsLoading,
    [userLoggedIsLoading, clientListData, clientListIsLoading]
  );

  const showClearFiltersButton = useMemo(() => !!submitedFilters?.filter, [submitedFilters]);

  const handleClearFilters = useCallback(() => {
    setSelectedFilter(null);

    if (submitedFilters.filter) {
      setPage(0);
      setSubmitedFilters({
        filter: null,
      });
    }
  }, [submitedFilters.filter]);

  const hasFiltersToSubmit = useMemo(
    () => submitedFilters === null || submitedFilters.filter !== selectedFilter,
    [selectedFilter, submitedFilters]
  );

  // └── Handlers (e.g., useCallback)
  const onSubmit = async ({ status, clientId }: { status: boolean; clientId: string }) => {
    try {
      await editClientMutation.mutateAsync({
        status,
        clientId,
      });

      addNotification({
        type: 'success',
        message: 'Cliente atualizado com sucesso!',
      });
      return;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (
          error.response?.status &&
          error.response?.status >= 400 &&
          error.response?.status < 500
        ) {
          addNotification({
            type: 'error',
            message: error.response?.data.message,
          });
          return;
        }
        addNotification({
          type: 'error',
          message: 'Erro com o serviço. Tente novamente mais tarde.',
        });
      }
    }
  };

  const handleOpenModal = (clientId?: number, status?: boolean) => {
    setIsModalOpen(true);
    if (clientId) {
      setClientIdSelected({ clientId, status: !!status });
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    if (clientProps) {
      setClientIdSelected(null);
    }
  };

  const handleConfirm = async () => {
    if (clientProps?.clientId) {
      setModalLoading(true);
      await onSubmit({ clientId: clientProps?.clientId.toString(), status: !!clientProps?.status });
    }
    setModalLoading(false);
    handleCloseModal();
  };

  const handleExportReportsExcel = useCallback(() => {
    exportExcel({
        refetch: ExcelRefetch,
        setLoading: setLoadingReportExports,
        addNotification,
        filenamePrefix: 'lista_de_clientes',        
    });
  }, [ExcelRefetch, setLoadingReportExports, addNotification]);
  
  const handleExportReportsPdf = useCallback(async () => {
    exportPdf({
      refetch: pdfRefetch,
      setLoading: setLoadingPdfExports,
      addNotification,
      fileName: `ECVFinancy_lista_de_clientes_`,
      company:{
        name: companySelected.name,
        doc : companySelected.cnpj
      },
      docTitle: 'Relatório Lista de Clientes'
    })
  },
    [pdfRefetch, setLoadingPdfExports, addNotification],
  )
  
  const handleRowClick = (clientId:number) => {    
    setSelectedRowId(clientId === selectedRowId ? null : clientId);
  };
 
  return (
    <>
      {isModalOpen && (
        <ConfirmationModal
          title="Mudança de Status do cliente"
          description={`Você deseja ${clientProps?.status ? 'Ativar' : 'Desativar'} este cliente?`}
          onConfirm={handleConfirm}
          onClose={handleCloseModal}
          isProcessing={modalLoading}
        />
      )}
      <div
        className={classNames('h-full', {
          'overflow-hidden max-h-[calc(100vh-64px)] md:max-h-[calc(100vh-71px)]':
            showSkeletonLoading,
        })}
      >
        <div
          className={classNames(
            'pt-5 md:pt-[42px] p-[27px] md:p-8 w-full min-h-[calc(100vh-64px)] md:min-h-[calc(100vh-71px)]',
            {
              'overflow-hidden !h-screen': showSkeletonLoading,
            }
          )}
        >
           {/* HEADER */}
           <PageHeader 
            iconTextTitleProps={{
              iconProps:{
                iconJSX: <CustomerIcon className=" !text-secondary" />                 
              },
              textProps:{
                content: 'Lista de Clientes'
              },
            }}
          /> 
          <div className="w-full flex flex-col md:flex-row justify-between mb-4 gap-3">
            <div
              className={classNames(
                'row-start-1 row-end-2 md:row-start-2 md:row-end-2 col-start-1 col-end-4 md:col-end-3 flex flex-col md:flex-row items-start justify-start gap-x-2.5 gap-y-2.5 w-full h-fit'
              )}
            >
              {/* Filter */}
              {showSkeletonLoading ? (
                <Skeleton
                  height={42}
                  baseColor="#f3f3f3"
                  highlightColor="white"
                  containerClassName="flex w-full md:max-w-[300px] h-[42px] row-start-2 row-end-3 md:row-start-1 md:row-end-2 col-start-1 col-end-4 md:col-start-2 md:col-end-2 "
                  className="!rounded-lg"
                />
              ) : (
                <InputText
                  name="filter"
                  type="text"
                  placeholder="Busque por nome, email ou CPF/CNPJ"
                  onChange={async (event: { target: { value: string } }) =>
                    setSelectedFilter(event.target.value)
                  }
                  value={selectedFilter ?? ''}
                  title={'Filtro'}
                  cssClasses={{
                    label:
                      'md:max-w-[300px] !flex-1 row-start-2 row-end-3 md:row-start-1 md:row-end-2 max-md:col-start-1 max-md:col-end-4',
                    input:
                      '!mt-0 w-full !h-[42px] !text-[#374151] !font-normal text-xs placeholder:font-normal placeholder:text-xs disabled:placeholder:!text-[#D1D5DB] truncate disabled:bg-gray-100',
                  }}
                  disabled={clientListIsLoading || clientListIsFetching}
                />
              )}

              {/* Button to filter */}
              {showSkeletonLoading ? (
                <Skeleton
                  height={42}
                  baseColor="#f3f3f3"
                  highlightColor="white"
                  containerClassName="flex w-full md:max-w-[56px] h-[42px] row-start-3 md:row-start-1 row-end-4 md:row-end-2 col-start-1 col-end-4 md:col-start-3 md:col-end-4"
                  className="!rounded-lg"
                />
              ) : (
                <>
                  <button
                    className={classNames(
                      'row-start-3 md:row-start-1 row-end-4 md:row-end-2 col-start-1 col-end-4 md:col-start-4 md:!col-end-5 bg-primary disabled:bg-[#D1D5DB] text-white py-2 px-4 rounded-lg flex justify-center items-center w-full h-[42px] md:max-w-[56px]',
                      {
                        '!col-end-3': showClearFiltersButton,
                      }
                    )}
                    onClick={() => {
                      if (submitedFilters.filter !== selectedFilter) {
                        setPage(0);
                        setSubmitedFilters({
                          filter: selectedFilter,
                        });
                      }
                    }}
                    disabled={!hasFiltersToSubmit || clientListIsLoading || clientListIsFetching}
                  >
                    {clientListIsLoading || clientListIsFetching ? (
                      <LoadingSpinIcon className=" w-5 !text-primary md:!text-buttontextcolor " />
                    ) : (
                      <SearchIcon className="!text-buttontextcolor" />
                    )}
                  </button>
                  {showClearFiltersButton && (
                    <button
                      className="bg-primary text-xs font-bold leading-4 tracking-[0.6px] text-white flex justify-center items-center gap-x-3 w-full md:w-fit h-[42px] py-2 px-4 rounded-lg disabled:bg-[#D1D5DB]"
                      onClick={handleClearFilters}
                      disabled={clientListIsLoading || clientListIsFetching}
                    >
                      <span className="hidden min-[1060px]:inline-block">LIMPAR</span>
                      <DeleteIcon />
                    </button>
                  )}
                </>
              )}
            </div>

            <div className="flex lg:flex-row flex-col w-full lg:max-w-fit justify-between items-center gap-[10px]">
                <ExportButton                   
                  loading={loadingReportExports}
                  onClick={() => {                 
                    handleExportReportsExcel()
                  }}
                  buttonText="Exportar Excel"
                  buttonDisabled={clientListIsLoading || clientListIsFetching}
                />
                <ExportButton                   
                  loading={loadingPdfExports}
                  onClick={handleExportReportsPdf}
                  icon={<PDFIcon className="ml-4 !text-buttontextcolor" />}
                  buttonClassName='!md:w-[150px] !gap-x-0'
                  buttonText="Exportar"
                  buttonDisabled={clientListIsLoading || clientListIsFetching || loadingPdfExports}
                />
              {showSkeletonLoading ? (
                <Skeleton
                  width={147}
                  height={42}
                  baseColor="white"
                  highlightColor="#f3f3f3"
                  containerClassName="flex h-[42px]"
                  className="!rounded-lg"
                />
              ) : (
                <button
                  type="button"
                  className="bg-primary text-xs font-bold leading-4 disabled:bg-[#D1D5DB] text-buttontextcolor py-2 pl-4 pr-[18px] rounded-lg flex justify-center items-center h-[42px] w-full !md:w-[150px] tracking-[0.6px]"
                  onClick={() => navigate(PAGES_ROUTES.authenticated.client.create)}
                >
                  CADASTRAR
                  <AddUserIcon className="ml-4 w-[19px] h-[22px] !text-buttontextcolor" />
                </button>
              )}
            </div>
          </div>

          {showSkeletonLoading ? (
            <LoadingListSkeleton />
          ) : clientListData?.data && clientListData?.data?.length <= 0 ? (
            <p className="w-full text-center text-[#595959] font-semibold">
              Nenhum cliente encontrado!
            </p>
          ) : (
            <TableContainer>            
              {/* Desktop */}
              <table 
                ref={tableContainerRef}  
                id='table-data'              
                className="w-full text-sm text-left text-gray-500 hidden md:table table-data">
                <thead className="font-inter text-[10px] text-primary font-bold uppercase bg-gray-50 w-full">
                  <tr>
                  <th 
                    scope="col" 
                    className="h-full px-6 py-3 cursor-default w-fit"
                    onClick={handleSortByColumn('client.createdAt')}                     
                  >
                      <span className="flex flex-row items-center gap-2 w-full truncate">
                        DATA DE CADASTRO
                        <span className={classNames('min-w-[12px]')}>
                        {orderAttribute === 'client.createdAt' && <ArrowWithCorrectDirection />}
                        </span>
                      </span>
                    </th>
                    <th 
                      scope="col" 
                      className="h-full px-6 py-3 cursor-default w-fit"
                      onClick={handleSortByColumn('client.name')}                     
                      >
                      <span className="flex flex-row items-center gap-2 w-full truncate">
                        NOME/NOME FANTASIA
                        <span className={classNames('min-w-[12px]')}>
                        {orderAttribute === 'client.name' && <ArrowWithCorrectDirection />}
                        </span>
                      </span>
                    </th>
                    <th 
                      scope="col" 
                      className="h-full px-6 py-3 cursor-default w-fit"
                      onClick={handleSortByColumn('client.cpfCnpj')}                     
                      >
                      <span className="flex flex-row items-center gap-2 truncate">
                        CPF/CNPJ
                        <span className={classNames('min-w-[12px]')}>
                        {orderAttribute === 'client.cpfCnpj' && <ArrowWithCorrectDirection />}
                        </span>
                      </span>
                    </th>
                    <th 
                      scope="col" 
                      className="h-full px-6 py-3 cursor-default w-fit"
                      onClick={handleSortByColumn('client.email')}                     
                    >
                      <span className="flex flex-row items-center gap-2 truncate">
                        EMAIL
                        <span className={classNames('min-w-[12px]')}>
                        {orderAttribute === 'client.email' && <ArrowWithCorrectDirection />}
                        </span>
                      </span>
                    </th>
                    <th 
                      scope="col" 
                      className="h-full px-6 py-3 cursor-default w-fit"
                      onClick={handleSortByColumn('client.phone')}                     

                    >
                      <span className="flex flex-row items-center gap-2 truncate">
                        TELEFONE
                        <span className={classNames('min-w-[12px]')}>
                        {orderAttribute === 'client.phone' && <ArrowWithCorrectDirection />}
                        </span>
                      </span>
                    </th>
                    <th 
                      scope="col" 
                      className="h-full px-6 py-3 cursor-default w-fit"
                      onClick={handleSortByColumn('client.status')}                     

                    >
                      <span className="flex flex-row items-center gap-2 truncate">
                        STATUS
                        <span className={classNames('min-w-[12px]')}>
                        {orderAttribute === 'client.status' && <ArrowWithCorrectDirection />}
                        </span>
                      </span>
                    </th>
                    <th 
                    scope="col"
                    id='table-actions'                        
                    className="h-full px-6 py-3 cursor-default w-fit table-actions" >
                      <span 
                        className="flex flex-row items-center gap-2 truncate">
                        AÇÕES                        
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody className="w-full">
                  {clientListData?.data.map((clientForListing, clientForListingIndex) => (
                    <tr                      
                      className={classNames(
                        "bg-white border-b w-full h-[60px] text-xs text-black",
                        {
                          '!bg-white hover:!bg-gray-100 ': selectedRowId !== clientForListing.id,                      
                          '!bg-gray-300': selectedRowId === clientForListing.id,    
                        }

                      )}
                      key={`client-${clientForListing.id}-row-${clientForListingIndex}`}
                      onClick={() => handleRowClick(clientForListing.id)}

                    >
                      <td className="px-6 py-4 font-normal whitespace-nowrap w-fit">
                        {clientForListing.createdAt && dayjs.utc(clientForListing.createdAt).format(DATE_DISPLAY_FORMAT) }
                      </td>
                      <td className="px-6 py-4 font-normal whitespace-nowrap w-fit">
                        {clientForListing.name ?? '-'}
                      </td>
                      <td className="px-6 py-4 font-normal whitespace-nowrap w-fit">
                        {clientForListing.cpfCnpj ? cpfCnpjMask(clientForListing.cpfCnpj) : '-'}
                      </td>
                      <td className="px-6 py-4 font-normal whitespace-nowrap w-fit">
                        {clientForListing.email ?? '-'}
                      </td>
                      <td className="px-6 py-4 font-normal whitespace-nowrap w-fit">
                        {clientForListing.phone ? phoneNumberMask(clientForListing.phone) : '-'}
                      </td>
                      <td className={classNames("px-6 py-4 whitespace-nowrap w-fit",
                      {                        
                        '!text-green-500':
                        clientForListing.status && String(ClientStatusEnum[clientForListing.status]) === ClientStatusEnum.ACTIVED,
                        '!text-gray-500':
                        clientForListing.status && String(ClientStatusEnum[clientForListing.status]) === ClientStatusEnum.PENDING,
                        '!text-red-500':
                        clientForListing.status && String(ClientStatusEnum[clientForListing.status]) === ClientStatusEnum.DESACTIVED,
                      }
                    )}>
                      {clientForListing.status &&
                        String(ClientStatusEnum[clientForListing.status]).toUpperCase()}
                    </td>
                      <td 
                        id='table-actions'                        
                        className="px-6 py-4 w-fit max-w-[106px] flex justify-between font-medium gap-x-3 whitespace-nowrap table-actions">
                        <Toggle
                          name="changeStatus"
                          onClick={(event) => event.preventDefault()}
                          onChange={(event) => {
                            handleOpenModal(clientForListing.id, event?.target?.checked);
                          }}
                          checked={ClientStatusEnum[clientForListing?.status] === ClientStatusEnum.ACTIVED}
                        />
                        <button
                          onClick={() =>
                            navigate(
                              PAGES_ROUTES.authenticated.client.edit.replace(
                                ':clientId',
                                (clientForListing.id ?? -1).toString()
                              )
                            )
                          }
                          className="mr-3 group"
                        >
                          <PenIcon className="" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {/* Mobile */}
              <div className="flex flex-col gap-y-[18px] md:hidden w-full font-inter font-medium text-gray-500 text-xs/[20px]">
                {clientListData?.data.map((clientForListing, clientForListingIndex) => (
                  <div
                    className={classNames(
                      'bg-white px-[20px] py-[16px] overflow-hidden shadow-container rounded-[5px] w-full',
                      {
                        '!bg-gray-200 hover:!bg-gray-300':
                        ClientStatusEnum[clientForListing.status] !== ClientStatusEnum.ACTIVED,
                      }
                    )}
                    key={`client-card-${clientForListing.id}-row-${clientForListingIndex}-mobile`}
                  >
                    <div className="w-full flex flex-row  justify-between gap-y-[10px]">
                    <p className=' mt-1 uppercase' >
                        <b className="text-primary normal-case ">Nome: </b>{' '}
                          {clientForListing.name ?? '-'}
                    </p>
                    <div className="flex flex-row gap-x-2.5 h-fit w-full max-w-[25%]">
                        <Toggle
                          name="changeStatus"
                          onClick={(event) => event.preventDefault()}
                          onChange={(event) => {
                            handleOpenModal(clientForListing.id, event?.target?.checked);
                          }}
                          checked={ClientStatusEnum[clientForListing.status] === ClientStatusEnum.ACTIVED}
                        />
                        <button
                          onClick={() =>
                            navigate(
                              PAGES_ROUTES.authenticated.client.edit.replace(
                                ':clientId',
                                (clientForListing.id ?? -1).toString()
                              )
                            )
                          }
                          className="mr-3 group"
                        >
                          <PenIcon className="" />
                        </button>
                      </div>
                    </div>
                    <div className={classNames('flex flex-row  justify-between w-full')}>
                      <div className="w-full pr-[10px]">
                        
                        <p className="uppercase">
                          <b className="text-primary normal-case">Data de Cadastro: </b>{' '}
                          {clientForListing.createdAt && dayjs.utc(clientForListing.createdAt).format(DATE_DISPLAY_FORMAT) }
                        </p>
                        <p>
                          <b className="text-primary">CPF/CNPJ: </b>
                          {clientForListing.cpfCnpj ? cpfCnpjMask(clientForListing.cpfCnpj) : '-'}
                        </p>
                        <p>
                          <b className="text-primary">Email: </b>
                          {clientForListing.email || '-'}
                        </p>
                        <p>
                          <b className="text-primary">Telefone: </b>
                          {clientForListing.phone ? phoneNumberMask(clientForListing.phone) : '-'}
                        </p>
                      </div>                      
                      
                    </div>
                  </div>
                ))}
              </div>            
            </TableContainer>
          )}
          {!showSkeletonLoading && !!clientListData?.total && clientListData?.total > 0 && (
            <NavigationSelector
            totalResults={clientListData?.total || 0}
            pageSize={pageSize}
            currentPage={page}
            onChangePageSize={setPageSize}
            onPageChange={setPage}
            isLoading={clientListIsFetching || clientListIsLoading} />  
          )}
        </div>
      </div>
    </>
  );
}
export default List;
