import { Dayjs } from 'dayjs';
import { DateType } from 'react-tailwindcss-datepicker';
import { number } from 'yup';

export interface ICompany<CompanyType = ICompanyType> {
  id: number;
  cnpj: string;
  name: string;
  fantasyName: string;
  status: keyof typeof CompanyStatusEnum;
  createdAt: string;
  updatedAt: string | null;
  deletedAt: null;
  units: (ICreateCompanyPayload & { id: number })[];
  companyType: CompanyType;
  monthlyInstallments?: string;
  urlDashboard: string;
  typePlan?: string;
  dateRegister?: string;
  monthlyFeeDay?: string;
  comment?: string;
}

export interface ICreateCompanyPayload {
  cnpj: string;
  name: string;
  fantasyName: string;
  zipCode: string;
  address: string;
  addressNumber: string;
  complement?: string;
  district: string;
  city: string;
  state: string;
  phone: string;
  givenName: string;
  familyName: string;
  email: string;
  companyType: number;
  monthlyInstallments: string;
  typePlan: string;
  dateRegister: string;
  monthlyFeeDay: string;
  comment: string;
}

export interface IUpdateCompanyPayload {
  name?: string;
  fantasyName?: string;
  status?: string;
  companyType?: ICompanyType | number;
  monthlyInstallments?: string;
  typePlan?: string;
  dateRegister?: string;
  monthlyFeeDay?: string;
  comment?: string;
}

export interface ICompanyListParams {
  start: number;
  size: number;
  order: string;
  field: string;
  filter: string | null;
}
export interface IExpenseListParams {
  start: number;
  size: number;
  order: string;
  field: string;
  description: string | null;
  companyId: number;
  dateInit: DateType;
  dateFinal: DateType;
}



export interface IExpense {
  id: number;
  description: string;
  amount: string;
  dateEvent: string | null;
  status: keyof typeof ExpenseStatusEnum;
  company: string;
  companyId: number;
  clientId: number;
  client: string;
  category: string;
  paymethod: string;
  paytype: string;
  createdAt?: string;
  receivePay?: string;
  amountPay?: string;
  amountPending?: string;
  actions?:string;
  payments?:any;
}

export interface ICompanySalesListParams {
  start: number;
  size: number;
  order: string;
  field: string;
  filter: string | null;
  companyId: string | number;
  dateInit: DateType;
  dateFinal: DateType;
}

export interface ICompanyPendingSalesListParams {
  start: number;
  size: number;
  order: string;
  field: string;
  filter: string | null;
  companyId: string | number;
  clientId?: number | null;
  dateInit?: DateType;
  dateFinal?: DateType;
}

export type ISaleListOrderAttribute =
  | 'category'
  | 'paymethod'
  | 'paytipe'
  | 'amount'
  | 'totalAmount'
  | 'amountPending'
  | 'quantity'
  | 'dateInit'
  | 'dateFinal'
  | 'client'
  | 'description'
  | 'dateEvent'
  | 'datePayment'
  | 'service'
  | 'typeclient'
  | 'status';

export type ICompanyListOrderAttribute = 'id' | 'cnpj' | 'fantasyName' | 'status' | 'createdAt';

export type IExpenseListOrderAttribute =
  | 'amount'
  | 'actions'
  | 'status'
  | 'description'
  | 'category'
  | 'paymethod'
  | 'paytype'
  | 'dateInit'
  | 'dateFinal'
  | 'dateEvent'
  | 'receivePay'
  | 'amountPending'
  | 'amountPay';

export type ILogListOrderAttribute =
 | 'email'
 | 'entity'
 | 'operation' 
 | 'dateEvent'
;

export enum CompanyStatusEnum {
  'PENDING' = 'Aguardando',
  'ACTIVED' = 'Ativo',
  'DESACTIVED' = 'Inativo',
}

export enum ClientStatusEnum {
  'PENDING' = 'Aguardando',
  'ACTIVED' = 'Ativo',
  'DESACTIVED' = 'Inativo',
}

export enum ClientStatusPayloadEnum {
  'PENDING' = 'PENDING',
  'ACTIVED' = 'ACTIVED',
  'DESACTIVED' = 'DESACTIVED',
}
export interface ICompanyGroupListParams {
  companyId: number;
  groupId: string;
  status?: boolean;
  order: string;
  field: string;
}

export interface ICompanyGroup {
  id: number;
  description: string;
  type_regiter: string;
  status: boolean;
  createdAt: string;
}

export interface ICreateCompanyGroupPayload {
  description: string;
}

export interface ICreateExpensePayload {
  description: string;
  amount: string;
  dataEvent: Date | string | Dayjs;
  categoryId: string;
  paymentMethodId: string;
}

export interface IUpdateCompanyGroupTypePayload {
  description?: string;
  status?: boolean;
}

export interface ICompanyType {
  id: number;
  description: string;
  createdAt?: string;
  updatedAt?: string;
  status?: boolean;
  complementService: string;
}

export interface IComplementService {
  label: string;
  name: string;
  size: number;
  mask: string;
}

interface IGroupType {
  id: number;
  createdAt: string;
  updatedAt: string | null;
  deletedAt: string | null;
  acidControl: any;
  versionCtrl: number;
  description: string;
  registerType: 'DESPESA_CATEGORIA' | 'DESPESA_FORMA_PAGAMENTO ' | 'DESPESA_TIPO_PAGAMENTO';
  status: boolean;
}

export interface IListElementExpense {
  id: number;
  createdAt: string;
  updatedAt: string | null;
  deletedAt: string | null;
  acidControl: any;
  versionCtrl: number;
  description: string;
  status: any;
  groupType: IGroupType;
}

export interface IExpenseResponseData {
  data: IListElementExpense[];
  pageNumber: number;
  nextPage: number;
  lastPage: number;
  totalPages: number;
  total: number;
  pageSize: number;
}

export interface IExportReportsParams {
  size: number;
  start: number;
  filter?: string | null;
  initialDate?: Date | string | null;
  endDate?: Date | string | null;
  companyId: string | number;
  report: 'sale' | 'expense' | 'sale-pending' | 'client'| 'payment';
  type: 'xls' | 'pdf';
  clientId?: number | null;
}

export interface IExportReportsResponse {
  statusCode: number;
  headers: {
    'Content-Disposition': string;
    'Content-Type': string;
  };
  isBase64Encoded: boolean;
  body: string;
}

export interface ISale {
  id: number;
  description: string;
  amount: string;
  quantity: number | null;
  totalAmount?: string | null;
  amountTotal?: string | null;
  dateEvent: string | Date;
  status: keyof typeof PaymentEnum;
  company: string;
  companyId: number;
  clientId: number;
  client: string;
  paymethod: string;
  paytype: string;
  service: string;
  amountPay?: string | null;
  amountPending?: string | null;
  name?: string;
  comercialName?: string;
  cpf?: string;
  saleType: 'PRIVATE' | 'CLIENT';
  typeclient: string;
}

export enum PaymentEnum {
  PAID = 'PAGO',
  PENDING = 'PENDENTE',
  PARTIAL = 'PAGO PARCIAL',
}

export enum PaymentEnumCompare {
  PAID = 'PAID',
  PENDING = 'PENDING',
  PARTIAL = 'PARTIAL',
}

export enum ExpenseStatusEnum {
  PAID = 'PAGO',
  PENDING = 'PENDENTE',
  PARTIAL = 'PAGO PARCIAL',
}

export enum ExpenseStatusCompareEnum {
  PAID = 'PAID',
  PENDING = 'PENDING',
  PARTIAL = 'PARTIAL',
}

export interface ISalesListInputTypes {
  note: string;
  amount: string;
  dataEvent: Date;  
}

export interface ICreatePaymentPayload {
  note: string;
  amount: number | string;
  dataEvent: Date | string | Dayjs;
  paymentMethodId: number;
}
export interface ICreateManyWriteOff {
  comment: string;
  clientId:number;
  amount: number | string;
  dateEvent: Date | string | Dayjs;
  paymentMethodId: number;
  companyId?: number;
}

export interface ICreateWriteOffPaymentPayload {
  comment: string;
  dataEvent: Date | string | Dayjs;
  paymentMethodId: number;
  amount:number | string;
}

export interface ISaleById {
  id: number;
  createdAt: string;
  updatedAt: string | null;
  deletedAt: string | null;
  acidControl: any;
  versionCtrl: number;
  amount: string;
  quantity: number;
  totalAmount: string;
  description: string;
  complement: string;
  dataEvent: string;
  dataReceipt: string | null;
  status: string;
  payments: IPayment[];  
}

export interface IPayment {
  id: number;
  createdAt: string;
  updatedAt: string | null;
  deletedAt: string | null;
  acidControl: any;
  versionCtrl: number;
  amount: string;
  note: string;
  dataPayment: string;  
  paymethod: string;
  paymentType?: IPaymentType | null 
}

export interface IPaymentType {
  id?:number;
  description?:string;
}

export enum companyTypePlanEnum {
  MONTHLY = 'mensal',
  YEARLY = 'anual'
}

export interface  IPaymentData {
  description: string;
  note: string;
  amount: string;
  datePayment: string;
  dateSale: string;
  companyId: number;
  client: string;
  comercialName: string | null;
  paymethod: string;
  paytype: string;
  service: string;
  name: string;
}