// Third-party
import { string } from 'yup';

export const password = string()
  .required('Este campo é obrigatório')
  .matches(
    new RegExp(
      /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*/\\,><':;|_~`+=[\]{}()?\-"!]).{8,}$/
    ),
    'Formato incorreto'
  );
