import ClientsList from '../../ClientsList';
import SectionContainer from '../SectionContainer';

interface ISalesControlSectionProps {
  toExport?: boolean;
}

function SalesControlSection({ toExport }: ISalesControlSectionProps) {
  return (
    <SectionContainer
      title="Controle de Venda por Cliente(AR) e Contratantes"
      cssClasses={{
        titleContainerStyle: 'bg-primary',
        container: toExport ? 'max-h-[1380px] overflow-hidden' : undefined,
      }}
      toExport={toExport}
    >
      <ClientsList toExport={toExport} />
    </SectionContainer>
  );
}

export default SalesControlSection;
