/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// Third parties
import classNames from 'classnames';
import { NavLink, useNavigate } from 'react-router-dom';

// App
import { RegisterOptionSkeletonLoading, SideMenuSkeletonLoading } from './components';
import {
  CustomerIcon,
  GraphIcon,
  ListIcon,
  LogoutIcon,
  ProfileIcon,
  RegistrationIcon,
} from 'assets/icons';
import { GiTakeMyMoney } from 'react-icons/gi';
import useAuth from 'hooks/useAuth';
import { ReactNode, useMemo } from 'react';
import { PAGES_ROUTES } from 'constants/routes';
import { useGetUser } from 'store/server/user/queries';
import DocumentsIcon from 'assets/icons/Documents.icon';
import useGlobalParamsStore from 'store/client/globalParams/useGlobalParamsStore';
import { selectFirstRender } from 'store/client/globalParams/selectors';
import { useMenuGroupList } from 'store/server/menu/queries';
import useCompanySelectedStore from 'store/client/companySelected/useCompanySelectedStore';
import { selectCompanySelected } from 'store/client/companySelected/selectors';
import MenuToggleButton from './components/MenuArrowIcon';
import NavLinkComponent from './components/NavLinkComponent';
import removeValuesAfterColonUtil from 'utils/removeValuesAfterColon.util';

export interface IMenuSectionOptions {
  hidden?: boolean;
  style?: string;
  styleWhenSelected?: string;
  isSelected?: boolean;
  openChildren: boolean;
  loading?: {
    isLoading: boolean;
    loadingElement: React.ReactNode;
  };
}

export interface IMenuSection {
  navLinkTitle: string;
  to: string;
  icon: ReactNode;
  badge?: {
    isLoading?: boolean;
    value: string | number;
  };
  children?: React.ReactNode;
  state?: object;
  options?: Partial<IMenuSectionOptions>;
}

interface ISideMenuProps {
  toggleMobileMenu: (event: React.MouseEvent<HTMLElement>) => void;
  showMenu: boolean;
  windowSize: number;
}

function SideMenu({ toggleMobileMenu, showMenu, windowSize }: ISideMenuProps) {
  /*
  **** Component organization ****

   └── Declaration of generic hooks (e.g., useNavigate)
   └── State declaration
   └── Side effects (e.g., useEffect)
   └── Memoization (e.g., useMemo)
   └── Handlers (e.g., useCallback)
   └── JSX
   */
  //  └── Declaration of generic hooks (e.g., useNavigate)
  const navigate = useNavigate();
  const { signOut } = useAuth();
  const { data: menuGroupList, isLoading: menuGroupListIsLoading } = useMenuGroupList();
  const companySelected = useCompanySelectedStore(selectCompanySelected);

  // └── State declaration
  //   └── Global
  const { isLoading: userIsLoading, data: user } = useGetUser();
  const firstRender = useGlobalParamsStore(selectFirstRender);
  //   └── Internal

  //└── Side effects (e.g., useEffect)

  // └── Memoization (e.g., useMemo)
  const transactionNavLinks = useMemo<Omit<IMenuSection, 'hidden' | 'icon'>[]>(
    () => [
      {
        navLinkTitle: 'Despesas',
        to: PAGES_ROUTES.authenticated.transactions.expenses.list,
      },
      {
        navLinkTitle: 'Vendas',
        to: PAGES_ROUTES.authenticated.transactions.sale.list,
      },
      {
        navLinkTitle: 'Vendas Pendentes',
        to: PAGES_ROUTES.authenticated.transactions.pendingSales.list,
      },
      {
        navLinkTitle: 'Recebimentos',
        to: PAGES_ROUTES.authenticated.transactions.payments.list,
      },
    ],
    []
  );

  const menuNavLinks = useMemo<IMenuSection[]>(
    () => [
      {
        navLinkTitle: 'Empresas',
        to: PAGES_ROUTES.authenticated.company.list,
        icon: <DocumentsIcon className="!text-secondary w-[22px] h-[22px]" />,
        options: {
          hidden: [2].includes(user?.accessLevel || 0),
          isSelected:
            ![
              PAGES_ROUTES.authenticated.company.logList,
              PAGES_ROUTES.authenticated.company.dashboard,
              PAGES_ROUTES.authenticated.company.summary,
              removeValuesAfterColonUtil(PAGES_ROUTES.authenticated.company.listGroup),
            ].some((routeConst) => location.pathname.includes(routeConst)) &&
            location.pathname.includes(PAGES_ROUTES.authenticated.company.list.split('/')[1]),
        },
      },
      {
        navLinkTitle: 'Usuários',
        to: PAGES_ROUTES.authenticated.user.list,
        icon: <ProfileIcon className="!text-secondary w-[22px] h-[22px]" />,
        options: {
          hidden: [2].includes(user?.accessLevel || 0),
        },
      },
      {
        navLinkTitle: 'Clientes',
        to: PAGES_ROUTES.authenticated.client.list,
        icon: <CustomerIcon className="!text-secondary w-[22px] h-[22px]" />,
        options: {
          hidden: !companySelected,
        },
      },
      {
        navLinkTitle: 'Transaçōes',
        to: PAGES_ROUTES.authenticated.transactions.expenses.list,
        icon: <GiTakeMyMoney className="!text-secondary w-[22px] h-[22px]" />,
        children: (
          <div className="ml-5 mt-[3px] flex flex-col mb-[15px]">
            {transactionNavLinks?.map((transactionMenuToList, transactionMenuToListIndex) => (
              <NavLink
                key={`transaction-menu-${transactionMenuToListIndex}`}
                to={transactionMenuToList.to}
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  toggleMobileMenu(e);
                }}
                className={classNames(
                  'flex justify-start items-center gap-2.5 text-[#595959] hover:text-orange text-sm font-inter font-normal pl-[40px] cursor-pointer py-[8px] leading-4 hamburger-menu',
                  {
                    ' !bg-[#F4F4F4] !font-bold rounded-[5px] hamburger-menu':
                      location.pathname === transactionMenuToList.to,
                    '!text-red-500': transactionMenuToList.navLinkTitle
                      .toLowerCase()
                      .includes('despesa'),
                    '!text-secondary': transactionMenuToList.navLinkTitle
                      .toLowerCase()
                      .includes('venda'),
                      '!text-green-700': transactionMenuToList.navLinkTitle
                      .toLowerCase()
                      .includes('recebimentos'),  
                  }
                )}
              >
                {transactionMenuToList.navLinkTitle}{' '}
              </NavLink>
            ))}
          </div>
        ),
        options: {
          hidden: !companySelected,
          style: '!pt-[6px] pb-[0px]',
          styleWhenSelected: '!pb-[6px]',
          isSelected: location.pathname.includes('/transacoes'),
          openChildren: true,
        },
      },
      {
        navLinkTitle: 'Resumo',
        to: PAGES_ROUTES.authenticated.company.summary,
        icon: <GraphIcon className="!text-secondary w-[22px] h-[22px]" />,
        options: {
          hidden: !companySelected,
          isSelected: location.pathname.includes(PAGES_ROUTES.authenticated.company.summary),
        },
      },
      {
        navLinkTitle: 'Gráficos',
        to: PAGES_ROUTES.authenticated.company.dashboard,
        icon: <GraphIcon className="!text-secondary w-[22px] h-[22px]" />,
        options: {
          hidden: !companySelected,
          isSelected: location.pathname === PAGES_ROUTES.authenticated.company.dashboard,
        },
      },
      {
        navLinkTitle: 'Cadastros',
        to: menuGroupList?.data?.[0]
          ? `${PAGES_ROUTES.authenticated.company.listGroup.replace(
              ':groupId',
              menuGroupList?.data?.[0].id.toString()
            )}`
          : '',
        icon: <RegistrationIcon className="!text-secondary w-[22px] h-[22px]" />,
        children: (
          <div className="ml-5 flex flex-col mb-[15px]">
            {menuGroupList?.data.map((menuGroupToList, menuGroupToListIndex) => (
              <NavLink
                key={`${menuGroupToList.description}-${menuGroupToListIndex}`}
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  toggleMobileMenu(e);
                }}
                to={`${PAGES_ROUTES.authenticated.company.listGroup.replace(
                  ':groupId',
                  menuGroupToList.id.toString()
                )}`}
                state={{
                  group: menuGroupToList,
                }}
                className={classNames(
                  'cyan-700 flex justify-start items-center gap-2.5 text-[#595959] hover:text-orange text-sm font-inter font-normal pl-[40px] cursor-pointer py-[8px] leading-4 hamburger-menu',
                  {
                    ' !text-primary !bg-[#F4F4F4] !font-bold rounded-[5px] hamburger-menu':
                      location.pathname ===
                      `${PAGES_ROUTES.authenticated.company.listGroup.replace(
                        ':groupId',
                        menuGroupToList.id.toString()
                      )}`,
                    '!text-red-500': menuGroupToList.description.toLowerCase().includes('despesa'),
                    '!text-secondary': menuGroupToList.description.toLowerCase().includes('venda'),
                    '!text-green-700': menuGroupToList.description.toLowerCase().includes('pagamento'),
                  }
                )}
              >
                {menuGroupToList.description}{' '}
              </NavLink>
            ))}
          </div>
        ),
        options: {
          hidden: !companySelected,
          isSelected: location.pathname.includes('/empresa/cadastros'),
          openChildren: false,
          style: '!pt-[6px] pb-[0px] mb-1',
          styleWhenSelected: '!pb-[6px]',
          loading: {
            isLoading: menuGroupListIsLoading,
            loadingElement: <RegisterOptionSkeletonLoading />,
          },
        },
      },
      {
        navLinkTitle: 'Auditoria de Logs',
        to: PAGES_ROUTES.authenticated.company.logList,
        icon: <ListIcon className="!text-secondary w-[22px] h-[22px]" />,
        options: {
          hidden: !companySelected,
          isSelected: location.pathname === PAGES_ROUTES.authenticated.company.logList,
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      user?.accessLevel,
      companySelected,
      transactionNavLinks,
      menuGroupList?.data,
      location.pathname,
    ]
  );

  const toggleVisibility = (isVisible: boolean) => {
    const button = document.getElementById('toggleButton');
    if (button) {
      // Ensure the button is not null
      if (isVisible) {
        button.style.opacity = '1'; // Make the button visible
      } else {
        button.style.opacity = '0'; // Make the button invisible
      }
    }
  };
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        onClick={toggleMobileMenu}
        onMouseOver={() => toggleVisibility(true)}
        onMouseOut={() => toggleVisibility(!showMenu)} // Only hide button on mouse out if showMenu is true
        onFocus={() => toggleVisibility(true)}
        onBlur={() => toggleVisibility(!showMenu)} // Only hide button on blur if showMenu is true
        className={classNames('fixed bg-[#0007] md:bg-transparent h-screen hamburger-menu', {
          'fixed top-0 left-0 w-full h-screen md:w-[228px]': showMenu,
          'w-0': !showMenu,
        })}
        style={{ zIndex: 45 }}
      >
        {/* BUTTON ARROW ICON */}
        {windowSize > 768 && (
          <MenuToggleButton showMenu={showMenu} toggleMobileMenu={toggleMobileMenu} />
        )}
        {firstRender || (!user && userIsLoading) ? (
          <div
            className={classNames('md:block w-[228px] h-full', {
              'hidden w-0': !showMenu,
            })}
          >
            <SideMenuSkeletonLoading />
          </div>
        ) : (
          <aside
            className={classNames(
              'w-[245px] h-full bg-white  border-r-4 border-solid border-grey-600 flex flex-col justify-between duration-300 overflow-auto',
              {
                hidden: !showMenu && !(windowSize > 768),
                'w-[40px]': !showMenu && windowSize > 768,
              }
            )}
          >
            {showMenu && (
              <>
                {/* LOGO CONTAINER*/}
                <div
                  className={classNames(
                    'bg-white flex justify-between items-center max-h-[64px] md:max-h-[150px] h-full pl-[24px]'
                  )}
                >
                  <button onClick={() => navigate(PAGES_ROUTES.authenticated.adminInitialRoute)}>
                    <img
                      src="/logo_objetiva.png"
                      alt="objetiva-logo"
                      className="lg:h-[120px] md:h-[100px] h-0 p-2 mx-auto mt-[10px]"
                    />
                  </button>
                </div>

                {/* SIDE MENU OPTIONS */}
                <menu
                  className={classNames(
                    `z-[100] max-w-[288px] md:w-[228px]  h-full flex flex-col gap-4 justify-between custom-container-scroll `
                  )}
                >
                  <div className="flex flex-col pt-3">
                    {menuNavLinks.map(
                      ({ navLinkTitle, to, icon, badge, children, options }, menuNavLinksIndex) => {
                        if (!options?.hidden) {
                          return (
                            <div key={`${navLinkTitle}-${menuNavLinksIndex}`}>
                              {options?.loading &&
                              options?.loading?.isLoading &&
                              options?.loading?.loadingElement ? (
                                <>{options?.loading?.loadingElement}</>
                              ) : (
                                <NavLinkComponent
                                  navLinkTitle={navLinkTitle}
                                  to={to}
                                  icon={icon}
                                  badge={badge}
                                  options={options}
                                  toggleMobileMenu={toggleMobileMenu}
                                  menuNavLinksIndex={menuNavLinksIndex}
                                >
                                  {children}
                                </NavLinkComponent>
                              )}
                            </div>
                          );
                        }
                      }
                    )}
                  </div>

                  <div className="flex flex-col gap-y-[81px] pl-[28px] pb-[23px]">
                    {/* BUTTONS CONTAINER */}
                    <div className="flex flex-col gap-y-6 w-full">
                      {/* SIGN OUT BUTTON */}
                      <button
                        className="text-[#595959] hover:text-orange font-inter font-semibold text-sm w-full h-fit flex gap-2 justify-start"
                        onClick={() => signOut()}
                      >
                        <LogoutIcon />
                        Sair
                      </button>
                    </div>
                    <div className="flex flex-col justify-between w-full pr-[22px] max-w-[175px]">
                      <h4 className="font-inter text-[8px] font-medium text-[#929292] w-fit h-fit self-end">
                        Versão: 1.0.0
                      </h4>
                    </div>
                  </div>
                </menu>
              </>
            )}
          </aside>
        )}
      </div>
    </>
  );
}

export default SideMenu;
