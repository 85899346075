// Third-party
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { AxiosError } from 'axios';

// App
import { password } from 'schemas';
import Modal from 'components/Modal';
import { InputPassword } from 'components';
import { confirmForgotPassword, firstPassword } from 'services/api/user';
import { PAGES_ROUTES } from 'constants/routes';
import useLoadingStore from 'store/client/loading/useLoadingStore';
import { selectLoading, selectSetLoading } from 'store/client/loading/selectors';
import useNotificationStore from 'store/client/notification/useNotificationStore';
import { selectAddNotification } from 'store/client/notification/selectors';
import queryClient from 'store/server/queryClient';
import useAuth from 'hooks/useAuth';
import userQueriesKeys from 'store/server/user/queriesKeys';
import useGlobalParamsStore from 'store/client/globalParams/useGlobalParamsStore';
import { selectSetLockComponentsRender } from 'store/client/globalParams/selectors';

type FormValues = {
  newPassword: string;
  repeatNewPassword: string;
};

function NewPasswordRequired() {
  /*
  **** Component organization ****

   └── Declaration of generic hooks (e.g., useNavigate)
   └── State declaration
   └── Side effects (e.g., useEffect)
   └── Memoization (e.g., useMemo)
   └── Handlers (e.g., useCallback)
   └── JSX
   */

  // └── Declaration of generic hooks (e.g., useNavigate)
  const navigate = useNavigate();
  const { state } = useLocation();
  const isLoading = useLoadingStore(selectLoading);
  const setLoading = useLoadingStore(selectSetLoading);
  const addNotification = useNotificationStore(selectAddNotification);
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<FormValues>({
    resolver: yupResolver(
      object({
        newPassword: password,
        repeatNewPassword: password.test(
          'isEqual',
          'area_to must be larger than area_from',
          (value, testContext) => {
            if (testContext.parent.newPassword === value) return true;
          }
        ),
      })
    ),
  });
  const { signIn } = useAuth();
  const setLockComponentsRender = useGlobalParamsStore(selectSetLockComponentsRender);

  // └── State declaration
  const [showModal, setShowModal] = useState<boolean>(false);

  // └── Side effects (e.g., useEffect)
  useEffect(() => {
    let newPasswordRequiredTimeout: NodeJS.Timeout; // Declare the timeout variable

    setLockComponentsRender(true);
    try {
      if (
        !state?.event ||
        !['newPasswordRequired', 'forgotPassword'].includes(state?.event) ||
        (state?.event === 'newPasswordRequired' && (!state.email || !state.currentPassword)) ||
        (state?.event === 'forgotPassword' && (!state.token || !state.email))
      ) {
        // Clears the entire global user estate
        queryClient.removeQueries({ queryKey: [userQueriesKeys.getUser] });

        // Send to initial application route and clear location state
        return navigate(PAGES_ROUTES.unauthenticated.initialRoute, { state: null });
      }

      if (state?.event === 'forgotPassword') {
        const decodedToken = JSON.parse(atob(state.token.split('.')[1]));

        newPasswordRequiredTimeout = setTimeout(() => {
          setShowModal(true);
        }, Math.floor(decodedToken.exp * 1000) - Date.now());
      }

      if (state?.event === 'newPasswordRequired') {
        // Set a timeout for 5 minutes (300000 milliseconds)
        // Timeout to show modal if the session expires in 5 min
        newPasswordRequiredTimeout = setTimeout(() => {
          setShowModal(true);
        }, 900000);
      }
    } finally {
      setLockComponentsRender(false);
    }
    // Clears the timeout when disassembling the component
    return () => {
      clearTimeout(newPasswordRequiredTimeout);
    };
  }, [
    navigate,
    state?.event,
    state.token,
    state.email,
    state.currentPassword,
    setLockComponentsRender,
  ]);

  // └── Handlers (e.g., useCallback)
  const onSubmit = async ({ newPassword, repeatNewPassword }: FormValues) => {
    if (newPassword !== repeatNewPassword)
      // Set an error message to the user
      return addNotification({ message: 'As senhas devem ser iguais', type: 'error' });

    setLoading(true);

    try {
      switch (state?.event) {
        case 'newPasswordRequired':
          await firstPassword({
            email: state.email,
            currentPassword: state.currentPassword,
            newPassword,
          });

          await signIn(state.email, newPassword);

          addNotification({
            type: 'success',
            message: 'Nova senha criada com sucesso!',
          });
          break;

        case 'forgotPassword':
          await confirmForgotPassword({
            email: state.email,
            token: state.token,
            newPassword,
          });

          addNotification({
            type: 'success',
            message: 'Nova senha criada com sucesso!',
          });

          navigate(PAGES_ROUTES.unauthenticated.initialRoute, { state: null });
          break;
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (state?.event === 'forgotPassword') {
          if (error.response?.status === 403) {
            addNotification({
              type: 'error',
              message: 'Tempo para troca de senha expirado. Faça um novo pedido de troca de senha.',
            });
          }

          addNotification({
            type: 'error',
            message: 'Sessão para troca de senha inválida. Faça um novo pedido de troca de senha.',
          });
          navigate(PAGES_ROUTES.unauthenticated.forgotPassword);
          return;
        }

        addNotification({
          message: 'Erro com o serviço. Tente novamente mais tarde.',
          type: 'error',
        });
        navigate(PAGES_ROUTES.unauthenticated.initialRoute);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {showModal && (
        <Modal
          content={
            <div className="flex flex-col gap-y-4">
              <div className="w-full flex justify-between items-start gap-4">
                <h2 className="text-2xl text-black font-inter font-bold">Sessão expirada!</h2>
              </div>
              <div className="text-black font-normal text-sm">
                {state?.event === 'forgotPassword'
                  ? 'Sua sessão expirou, faça um novo pedido de troca de senha.'
                  : 'Sua sessão expirou, acesse seu email e tente novamente!'}
              </div>
              <div className="flex flex-col items-center gap-5 w-full">
                <button
                  type="button"
                  onClick={() => {
                    navigate(
                      state?.event === 'forgotPassword'
                        ? PAGES_ROUTES.unauthenticated.forgotPassword
                        : PAGES_ROUTES.unauthenticated.initialRoute,
                      { state: null }
                    );
                  }}
                  className="px-4 py-2 text-buttontextcolor bg-primary rounded-md shadow-md duration-200 font-medium text-center w-full"
                >
                  Ok
                </button>
              </div>
            </div>
          }
        />
      )}
      <div>
        <h1 className="text-gray text-lg font-bold mb-6 leading-5 tracking-wide text-primary">
          Defina uma nova senha.
        </h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-y-6">
            <Controller
              control={control}
              name="newPassword"
              render={({ field }) => (
                <InputPassword labelText="Nova senha" canShowPassword {...field} />
              )}
            />
            <Controller
              control={control}
              name="repeatNewPassword"
              render={({ field }) => (
                <InputPassword labelText="Repetir nova senha" canShowPassword {...field} />
              )}
            />
          </div>
          <div className="flex flex-col justify-between">
            <button
              type="submit"
              className="bg-primary disabled:bg-[#D1D5DB] text-buttontextcolor py-2 px-4 rounded-[5px] mt-6 mb-6 transition duration-200 text-center"
              disabled={!isValid || isLoading}
            >
              Confirmar
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default NewPasswordRequired;
