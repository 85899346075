import classNames from 'classnames';
import { ReactNode } from 'react';

interface ICssStyle {
  titleContainerStyle: string;
  titleStyle: string;
  container: string;
}

interface ISectionContainer {
  children: ReactNode;
  title?: string;
  cssClasses?: Partial<ICssStyle>;
  toExport?: boolean;
}

function SectionContainer({ title, children, cssClasses, toExport }: ISectionContainer) {
  return (
    <div
      className={classNames(
        'to-export w-full flex flex-col gap-y-[40px] bg-[#f6f9fd] rounded-2xl px-[10px] md:px-[50px] py-[50px]',
        cssClasses?.container,
        {
          '!bg-white': toExport,
        }
      )}
    >
      {title && (
        <div className={classNames('py-[5px]', cssClasses?.titleContainerStyle)}>
          <h3
            className={classNames(
              'text-center w-full text-white font-semibold text-base',
              cssClasses?.titleStyle
            )}
          >
            {title}
          </h3>
        </div>
      )}
      {children}
    </div>
  );
}

export default SectionContainer;
