import classNames from 'classnames';

type Dezi9IconProps = {
  className?: string;
  width?: string;
  height?: string;
};

function Dezi9Icon({
  className,
  width = '86px',
  height = '39px',
}: Dezi9IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 86 39"
      className={classNames('primary-color', className)}
    >
      <path
        d="M52.6419 11.9951H49.3105V35.8511H52.6419V11.9951Z"
        fill="currentColor" // Defina a cor de preenchimento aqui
      />
      <path
        d="M52.6419 2.63821H49.3105V6.88624H52.6419V2.63821Z"
        fill="currentColor" // Defina a cor de preenchimento aqui
      />
      <path
        d="M28.104 0C19.1496 0 11.8497 8.22775 11.8497 18.3448C11.8497 28.4506 19.1384 36.6783 28.104 36.6783C37.0584 36.6783 44.3471 28.4506 44.3471 18.3448C44.3471 8.22775 37.0584 0 28.104 0ZM28.104 33.347C20.9718 33.347 15.1587 26.6172 15.1587 18.3336C15.1587 10.0499 20.9606 3.32017 28.104 3.32017C35.2362 3.32017 41.0381 10.0499 41.0381 18.3336C41.0381 26.606 35.2362 33.347 28.104 33.347Z"
        fill="currentColor" // Defina a cor de preenchimento aqui
      />
      <path
        d="M7.51229 35.84V2.49298C7.51229 1.5763 6.76330 0.827305 5.84662 0.827305H0V4.15865H4.18095V35.84H7.51229Z"
        fill="currentColor" // Defina a cor de preenchimento aqui
      />
      <path
        d="M79.8739 25.7229L67.3646 38.2099L64.9723 35.8175L72.6075 28.1823C72.3728 28.1935 72.1268 28.2046 71.8921 28.2046C64.1003 28.2046 57.7954 21.8885 57.7954 14.0967C57.7954 6.30496 64.1003 0 71.8921 0C79.6839 0 86 6.31614 86 14.0967C86 16.9809 85.2175 19.5633 83.7418 21.7991L81.3048 19.362C82.2103 17.7858 82.6686 16.0531 82.6686 14.1079C82.6686 8.16068 77.8505 3.34252 71.8921 3.34252C65.9337 3.34252 61.1267 8.16068 61.1267 14.1079C61.1267 20.0551 65.9448 24.8845 71.8921 24.8845C73.9267 24.8845 75.8383 24.3143 77.4704 23.3306L79.8739 25.7453"
        fill="currentColor" // Defina a cor de preenchimento aqui
      />
    </svg>
  );
}

export default Dezi9Icon;

