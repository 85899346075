/* eslint-disable react/jsx-key */
/* eslint-disable import/no-named-as-default */
/* eslint-disable react-hooks/rules-of-hooks */
// Third-party
import { Navigate, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useRef, useState, Key } from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
//App

import { ArrowIcon } from 'assets/icons';
import LoadingListSkeleton from './components/LoadingListSkeleton';
import { useExportReports, useGetPayments } from 'store/server/company/queries';
import { IPaymentData, ISaleListOrderAttribute } from 'interfaces/companyTypes';
import { DateType, DateValueType } from 'react-tailwindcss-datepicker';
import usePersistedState from 'hooks/usePersistedState';
import useCompanySelectedStore from 'store/client/companySelected/useCompanySelectedStore';
import { selectCompanySelected } from 'store/client/companySelected/selectors';
import { convertToBrazilianCurrencyFormat } from 'utils/currency.utils';
import useNotificationStore from 'store/client/notification/useNotificationStore';
import { selectAddNotification } from 'store/client/notification/selectors';
import PageHeader from 'components/PageHeader/components/PageHeader';
import TableContainer from 'components/TableContainer';
import NavigationSelector from 'components/NavigationSelector';
import { dateUtils } from 'utils/formatDate';
import { saleTypeEnum } from 'interfaces/saleTypes';
import useFilterStates from 'hooks/useFilterStates';
import { PAGINATION } from 'constants/clientParams';
import getMonthStartAndEndUtil from 'utils/getMonthStartAndEnd.util';
import { exportExcel } from 'utils/excelExport.utils';
import { screenConfig } from 'utils/screenConfig.util';
import { ORDER_ATTRIBUTTES, ascDescType } from 'constants/orderAttributtes';
import TransactionsIcon from 'assets/icons/Transactions.icon';
import TransactionsFilterBar from 'components/TransactionsFilterBar';
import ToolTip from 'components/ToolTip';
import { IColumnConfig } from 'interfaces/tableTypes';
import CustomTableCell from 'components/TableCell';
import TableHeaderCell from 'components/TableHeaderCell';
import { exportPdf } from 'utils/pdf.utils';

const { ASC, DESC } = ORDER_ATTRIBUTTES;
const { DATE_DISPLAY_FORMAT, DATE_TIME_DISPLAY_FORMAT, EXPORT_EXCEL_DATE_FORMAT } =
  dateUtils.constants;
const { MEDIUM_SCREEN_SIZE } = screenConfig.constants;

type IPaymentListOrderAttribute = ISaleListOrderAttribute | 'note' | 'dateSale' | 'saleType';

function List() {
  /*
  **** Component organization ****

   └── Declaration of generic hooks (e.g., useNavigate)
   └── State declaration
   └── Side effects (e.g., useEffect)
   └── Memoization (e.g., useMemo)
   └── Handlers (e.g., useCallback)
   └── JSX
   */

  // └── Declaration of generic hooks (e.g., useNavigate)
  const navigate = useNavigate();  
  dayjs.extend(utc);  
  dayjs.extend(timezone)
  
  const tableContainerRef = useRef<HTMLTableElement>(null);
  // └── State declaration

  // Selected table row
  const [selectedRowId, setSelectedRowId] = useState<null | number>(null);

  // Pagination states
  const [page, setPage] = usePersistedState<number>('salesList.page', PAGINATION.START_PAGE_NUM);
  const [pageSize, setPageSize] = usePersistedState<number>('salesList.pageSize', 10);

  const companySelected = useCompanySelectedStore(selectCompanySelected);
  const addNotification = useNotificationStore(selectAddNotification);

  if (!companySelected) return <Navigate to={'/'} replace />;

  // Sort states
  const [orderAttribute, setOrderAttribute] = useState<IPaymentListOrderAttribute>('datePayment');
  const [order, setOrder] = useState<ascDescType>(ASC);

  // └── State declaration
  const [loadingReportExports, setLoadingReportExports] = useState<boolean>(false);
  const [loadingPdfExports, setLoadingPdfExports] = useState<boolean>(false);
  const firstDayOfMonth = useMemo(() => getMonthStartAndEndUtil().initialDate, []);
  const lastDayOfMonth = useMemo(() => getMonthStartAndEndUtil().finalDate, []);

  const {
    tempFilterState,
    submittedFilterState,
    hasFilterStatesToSubmit,
    hasFilterStatesSubmitted,
    changeTempState,
    submitStates,
    cleanStates,
  } = useFilterStates<{
    search: string | null;
    initialDate: DateType;
    endDate: DateType;
  }>({
    filtersStateKey: 'paymentListFilter',
    initalState: {
      search: null,
      initialDate: firstDayOfMonth,
      endDate: lastDayOfMonth,
    },
  });

  const { refetch: excelRefetch } = useExportReports({
    size: pageSize,
    start: page * pageSize,
    filter: submittedFilterState?.search,
    initialDate: dayjs(submittedFilterState.initialDate).format(EXPORT_EXCEL_DATE_FORMAT),
    endDate: dayjs(submittedFilterState.endDate).format(EXPORT_EXCEL_DATE_FORMAT),
    companyId: companySelected.id,
    report: 'payment',
    type: 'xls',
  });

  const { refetch: pdfRefetch } = useExportReports({
    size: pageSize,
    start: page * pageSize,
    filter: submittedFilterState?.search,
    initialDate: dayjs(submittedFilterState.initialDate).format(EXPORT_EXCEL_DATE_FORMAT),
    endDate: dayjs(submittedFilterState.endDate).format(EXPORT_EXCEL_DATE_FORMAT),
    companyId: companySelected.id,
    report: 'payment',
    type: 'pdf',
  });

  const {
    data: paymentListData,
    isLoading: paymentsListIsLoading,
    isFetching: paymentsListIsFetching,
  } = useGetPayments({
    start: page,
    size: pageSize,
    field: orderAttribute,
    order,
    filter: submittedFilterState.search,
    companyId: companySelected.id,
    dateInit: submittedFilterState.initialDate,
    dateFinal: submittedFilterState.endDate,
  });

  // └── Side effects (e.g., useEffect)
  // └── Handlers (e.g., useCallback)
  const handleExportReportsExcel = useCallback(() => {
    exportExcel({
      refetch: excelRefetch,
      setLoading: setLoadingReportExports,
      addNotification,
      filenamePrefix: 'lista_de_recebimentos',
    });
  }, [excelRefetch, setLoadingReportExports, addNotification]);

  const handleSortByColumn = useCallback(
    (columnName: IPaymentListOrderAttribute | null, orderSelected?: ascDescType) => () => {
      if (!columnName) return;
      if (orderAttribute !== columnName) {
        setOrder(ASC); // If the sort field is different from the clicked column, set the order to ASC
      } else {
        setOrder(orderSelected ? orderSelected : order === ASC ? DESC : ASC); // If the sort field is the same as the clicked column, toggle between ASC and DESC order
      }
      setOrderAttribute(columnName); // Set the sort field to the clicked column
    },
    [orderAttribute, order]
  );

  const checkIfShowCards = useCallback(() => {
    if (window.innerWidth < MEDIUM_SCREEN_SIZE) {
      orderAttribute !== 'datePayment' && setOrderAttribute('datePayment');
      order !== ASC && setOrder(ASC);
    }
  }, [order, orderAttribute]);

  useEffect(() => {
    window.removeEventListener('resize', checkIfShowCards);
    window.addEventListener('resize', checkIfShowCards);

    return () => {
      window.removeEventListener('resize', checkIfShowCards);
    };
  }, [checkIfShowCards]);

  
  const showSkeletonLoading = useMemo(
    () => !paymentListData?.data && paymentsListIsLoading,
    [paymentListData, paymentsListIsLoading]
  );

  const handleClearFilters = useCallback(() => {
    setPage(PAGINATION.START_PAGE_NUM);
    cleanStates();
  }, [setPage, cleanStates]);

  const handleSearchFilter = useCallback(
    async (event: { target: { value: string } }) => changeTempState('search', event.target.value),
    [changeTempState]
  );

  const handleChangeDateFilter = useCallback(
    (value: DateValueType) => {
      changeTempState('initialDate', value?.startDate || firstDayOfMonth);
      changeTempState('endDate', value?.endDate || lastDayOfMonth);
    },
    [changeTempState, firstDayOfMonth, lastDayOfMonth]
  );

  const handleClickSubmitFilters = useCallback(() => {
    if (hasFilterStatesToSubmit) {
      setPage(PAGINATION.START_PAGE_NUM);
      submitStates();
    }
  }, [hasFilterStatesToSubmit, setPage, submitStates]);

  const handleExportReportsPdf = useCallback(async () => {
    exportPdf({
      refetch: pdfRefetch,
      setLoading: setLoadingPdfExports,
      addNotification,
      fileName: `ECVFinancy_lista_de_recebimentos_`,
      company: {
        name: companySelected.name,
        doc: companySelected.cnpj,
      },
      docTitle: 'Relatório Lista de Recebimentos',      
      pdfPeriod: `${dayjs(submittedFilterState.initialDate).format(DATE_DISPLAY_FORMAT)} - ${dayjs(submittedFilterState.endDate).format(DATE_DISPLAY_FORMAT)}`
    })
  },
    [pdfRefetch, setLoadingPdfExports, addNotification,submittedFilterState.initialDate,submittedFilterState.endDate ],
  )
  
  const handleRowClick = (clientId:number) => {    
    setSelectedRowId(clientId === selectedRowId ? null : clientId);
  };

  const pageHeaderProps = {
    iconTextTitleProps: {
      iconProps: {
        iconJSX: <TransactionsIcon className="w-[45px] h-[45px] !text-secondary" />,
      },
      textProps: {
        content: 'Lista de Recebimentos',
      },
    },
  };

  const transactionFilterBarProps = {
    showSkeletonLoading: showSkeletonLoading,
    isFetching: paymentsListIsFetching,
    isLoading: paymentsListIsLoading,
    tempFilterState: tempFilterState,
    isExportingExcel: loadingReportExports,
    isExportingPDF: loadingPdfExports,
    handleChangeDateFilter: handleChangeDateFilter,
    handleChangeSearchFilter: handleSearchFilter,
    handleClearFilters: handleClearFilters,
    handleExportReportsExcel: handleExportReportsExcel,
    handleExportReportsPdf: handleExportReportsPdf,
    hasFilterStatesSubmitted: hasFilterStatesSubmitted,
    hasFilterStatesToSubmit: hasFilterStatesToSubmit,
    handleClickSubmitFilters: handleClickSubmitFilters,
  };

  const navigationSelectorProps = {
    totalResults: paymentListData?.total || 0,
    pageSize: pageSize,
    currentPage: page,
    onChangePageSize: setPageSize,
    onPageChange: setPage,
    isLoading: paymentsListIsLoading || paymentsListIsFetching,
    resetSelection: setSelectedRowId,
  };

  const headerCells: { title: string; keyString?: IPaymentListOrderAttribute }[] = [
    { title: 'DATA RECEBIMENTO', keyString: 'datePayment' },
    { title: 'OBSERVAÇÃO RECEBIMENTO', keyString: 'note' },
    { title: 'VALOR RECEBIDO', keyString: 'amount' },
    { title: 'DATA VENDA', keyString: 'dateSale' },
    { title: 'DESCRIÇÃO', keyString: 'description' },
    { title: 'SERVIÇO', keyString: 'service' },
    { title: 'CLIENTE/CONTRATANTE', keyString: 'client' },
    { title: 'FORMA RECEBIMENTO', keyString: 'paymethod' },
  ];

  const columnConfig: IColumnConfig<IPaymentData>[] = [
    {
      key: 'datePayment',
      render: (payment) =>{        
        return payment.datePayment ? dayjs.utc(payment.datePayment).format(DATE_TIME_DISPLAY_FORMAT) : 'N/A'
      },
      renderMobile: (payment) => (
        <p>
          Data Recebimento:{' '}
          {payment.datePayment
            ? dayjs(payment.datePayment).format(DATE_TIME_DISPLAY_FORMAT)
            : 'N/A'}
        </p>
      ),
    },
    {
      key: 'note',
      render: (payment) =>
        payment.note?.length > 30 ? (
          <>
            {payment.note.slice(0, 30)}
            <ToolTip tooltip={payment.note}>...</ToolTip>
          </>
        ) : (
          payment.note
        ),
      renderMobile: (payment) => <p>Observaçäo Recebimento: {payment.note || 'N/A'}</p>,
    },
    {
      key: 'amount',
      render: (payment) => convertToBrazilianCurrencyFormat(payment.amount),
      renderMobile: (payment) => (
        <p>Valor Recebido: {convertToBrazilianCurrencyFormat(payment.amount)}</p>
      ),
    },
    {
      key: 'dateSale',
      render: (payment) =>
        payment.dateSale ? dayjs.utc(payment.dateSale).format(DATE_TIME_DISPLAY_FORMAT) : 'N/A',
      renderMobile: (payment) => (
        <p>
          Data Venda:{' '}
          {payment.dateSale ? dayjs.utc(payment.dateSale).format(DATE_TIME_DISPLAY_FORMAT) : 'N/A'}
        </p>
      ),
    },
    {
      key: 'description',
      render: (payment) =>
        payment.description?.length > 30 ? (
          <>
            {payment.description.slice(0, 30)}
            <ToolTip tooltip={payment.description}>...</ToolTip>
          </>
        ) : (
          payment.description
        ),
      renderMobile: (payment) => <p>Descrição: {payment.description || 'N/A'}</p>,
    },
    {
      key: 'service',
      render: (payment) => (payment.service ? payment.service : 'N/A'),
      renderMobile: (payment) => <p>Serviço: {payment.service ? payment.service : 'N/A'}</p>,
    },

    {
      key: 'client',
      render: (payment) =>
        payment?.client === saleTypeEnum.PRIVATE
          ? payment.name
          : payment?.client || 'Cliente não informado',
      renderMobile: (payment) => (
        <p>
          Cliente/Contratante:{' '}
          {payment?.client === saleTypeEnum.PRIVATE
            ? payment.name
            : payment?.client || 'Cliente não informado'}
        </p>
      ),
    },

    {
      key: 'paytype',
      render: (payment) => payment.paytype,
      renderMobile: (payment) => <p>Forma de Recebimento: {payment.paytype}</p>,
    },
  ];

  return (
    <>
      <div
        className={classNames('h-full', {
          'overflow-hidden max-h-[calc(100vh-64px)] md:max-h-[calc(100vh-71px)]':
            showSkeletonLoading,
        })}
      >
        <div
          className={classNames(
            'pt-5 md:pt-[42px] p-[27px] md:p-8 w-full min-h-[calc(100vh-64px)] md:min-h-[calc(100vh-71px)]',
            {
              'overflow-hidden !h-screen': showSkeletonLoading,
            }
          )}
        >
          {/* HEADER */}
          <PageHeader {...pageHeaderProps} />
          <TransactionsFilterBar {...transactionFilterBarProps} />
          {showSkeletonLoading ? (
            <LoadingListSkeleton />
          ) : paymentListData?.data && paymentListData?.data?.length <= 0 ? (
            <p className="w-full text-center text-[#595959] font-semibold">
              Nenhum recebimento encontrado!
            </p>
          ) : (
            <TableContainer>
              {/* Desktop */}
              <table
                ref={tableContainerRef}
                className="w-full text-sm text-left text-gray-500 hidden md:table table-data"
              >
                <thead className="font-inter text-[10px] text-primary font-bold uppercase bg-gray-50 w-full">
                  <tr>
                    {headerCells.map(({ title, keyString }, index) => (
                      <TableHeaderCell<IPaymentListOrderAttribute>
                        keyIndex={index}
                        order={order}
                        orderAttribute={orderAttribute}
                        handleSortByColumn={handleSortByColumn}
                        title={title}
                        keyString={keyString ?? null}
                      />
                    ))}
                  </tr>
                </thead>
                <tbody className="w-full">
                  {paymentListData?.data.map((payment, paymentsForListingIndex) => (
                    <tr
                      className={classNames(
                        'bg-white border-b w-full h-[60px] text-xs text-black',
                        {
                          '!bg-white hover:!bg-gray-100 ':
                            selectedRowId !== paymentsForListingIndex,
                          '!bg-gray-300': selectedRowId === paymentsForListingIndex,
                        }
                      )}
                      key={`company-${paymentsForListingIndex}-row-${paymentsForListingIndex}`}
                      onClick={() => handleRowClick(paymentsForListingIndex)}
                    >
                      {columnConfig.map(
                        (col: { key: Key; render: (arg0: IPaymentData) => any }) => (
                          <CustomTableCell<IPaymentData>
                            keyIndex={`${col.key}`}
                            content={payment}
                            renderContent={() => col.render(payment)}
                            className={`px-6 py-4 whitespace-nowrap ${
                              col.key === 'actions' ? 'flex flex-row gap-x-1 justify-between' : ''
                            }`}
                          />
                        )
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>

              {/* Mobile */}
              <div className="flex flex-col gap-y-[18px] md:hidden">
                {paymentListData?.data.map((salesForListing, salesForListingIndex) => (
                  <div
                    className="bg-white px-[20px] py-[16px] overflow-hidden flex flex-col justify-between gap-y-[9px] shadow-container rounded-[5px]"
                    key={`company-card-row-${salesForListingIndex}-mobile`}
                  >
                    <div className="flex flex-row justify-between w-full">
                      <div className="font-inter font-medium text-gray-500 text-xs/[20px] w-full">
                        <span className="uppercase">
                          <p>
                            <b className="text-primary">Data Recebimento: </b>
                            {salesForListing.datePayment &&
                              dayjs.utc(salesForListing.datePayment).format(DATE_TIME_DISPLAY_FORMAT)}
                          </p>

                          <p>
                            <b className="text-primary">Observação Recebimento: </b>
                            {salesForListing?.note}
                          </p>
                          <p>
                            <b className="text-primary">Valor Recebido: </b>
                            {convertToBrazilianCurrencyFormat(salesForListing?.amount || '0')}
                          </p>
                          <p>
                            <b className="text-primary">Data da Venda: </b>
                            {salesForListing.dateSale &&
                              dayjs(salesForListing.dateSale).format(DATE_TIME_DISPLAY_FORMAT)}
                          </p>
                          <p>
                            <b className="text-primary">Descrição: </b>
                            {salesForListing?.description}
                          </p>
                          <p>
                            <b className="text-primary">Serviços: </b>
                            {salesForListing?.service || 'Serviço não informado'}
                          </p>
                          <p>
                            <b className="text-primary">Cliente/Razão Social: </b>
                            {salesForListing?.name === saleTypeEnum.PRIVATE
                              ? salesForListing.name
                              : salesForListing?.client || 'Cliente não informado'}
                          </p>
                          <p>
                            <b className="text-primary">Forma de Recebimento: </b>
                            {salesForListing?.paymethod || 'Método de pagamento não descrito'}
                          </p>
                          <p>
                            <b className="text-primary">Tipo de Recebimento: </b>
                            {salesForListing?.paytype || 'Método de pagamento não descrito'}
                          </p>
                        </span>
                      </div>
                      <div className="flex flex-row gap-x-2.5 h-fit"></div>
                    </div>
                  </div>
                ))}
              </div>
            </TableContainer>
          )}
          {!showSkeletonLoading && <NavigationSelector {...navigationSelectorProps} />}
        </div>
      </div>
    </>
  );
}

export default List;
