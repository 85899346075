/* eslint-disable react-hooks/rules-of-hooks */
import { yupResolver } from '@hookform/resolvers/yup';
import { CloseIcon } from 'assets/icons';
import classNames from 'classnames';
import InputText from 'components/InputText';
import InputTextArea from 'components/InputTextArea';
import Modal from 'components/Modal';
import { ICompanyGroup, ICreatePaymentPayload, ISale } from 'interfaces/companyTypes';
import { Controller, useForm } from 'react-hook-form';
import { formSchema } from './schemas';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { handleChangeMonthlyInstallments } from 'utils/handlers';
import { AxiosError } from 'axios';
import { selectSetLoading } from 'store/client/loading/selectors';
import useLoadingStore from 'store/client/loading/useLoadingStore';
import { selectAddNotification } from 'store/client/notification/selectors';
import useNotificationStore from 'store/client/notification/useNotificationStore';
import { useCreatePaymentMutation } from 'store/server/company/mutations';
import { PAGES_ROUTES } from 'constants/routes';
import { Navigate } from 'react-router-dom';
import useCompanySelectedStore from 'store/client/companySelected/useCompanySelectedStore';
import { selectCompanySelected } from 'store/client/companySelected/selectors';
import { useGetCompanyGroupList, useGetSalesId } from 'store/server/company/queries';
import Select from 'components/Select';
import Skeleton from 'react-loading-skeleton';
import { convertToBrazilianCurrencyFormat, convertToCurrencyFormat } from 'utils/currency.utils';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { dateUtils } from 'utils/formatDate'
import {  ORDER_ATTRIBUTTES } from 'constants/orderAttributtes';
import { DEFAULT_VALUES } from 'constants/textAreaInput';


const { ASC } = ORDER_ATTRIBUTTES;
const { DATE_TIME_DISPLAY_FORMAT } = dateUtils.constants;
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/Sao_Paulo');

interface IProps {
  setOpenModal: (modalState: { display: boolean; sale: ISale | null }) => void;
  openModal: { display: boolean; sale: ISale | null };
}

function PaymentModal({ setOpenModal, openModal }: IProps) {
  const { register, handleSubmit, watch, control, reset, formState: { isValid } } = useForm<ICreatePaymentPayload>({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
  });
  const setLoading = useLoadingStore(selectSetLoading);
  const addNotification = useNotificationStore(selectAddNotification);
  const companySelected = useCompanySelectedStore(selectCompanySelected);

  const { sale } = openModal;
  const editAvailable = sale?.status !== 'PAID';

  if (!companySelected || !sale || !sale.id)
    return <Navigate to={PAGES_ROUTES.authenticated.commonInitialRoute} replace />;

  const createPaymentMutation = useCreatePaymentMutation(companySelected?.id, sale?.id);

  const { data: saleByID, isLoading: saleByIdIsLoading } = useGetSalesId(
    companySelected.id,
    sale.id
  );

  const { data: paymentMethodListData, isLoading: paymentMethodListIsLoading } = useGetCompanyGroupList(
    {
      companyId: companySelected.id,
      groupId: '7',
      status: true,
      field: 'description',
      order: ASC,
    }
  );

  const watchPaymentMethodId = watch('paymentMethodId');  
  // └── Handlers (e.g., useCallback)
  const onSubmit = async ({ note, dataEvent, amount }: ICreatePaymentPayload) => {
      
    setLoading(true);
    try {
      console.log(dataEvent)
      console.log(dateUtils.formatTo.formatDateToRegisterPaymentOrExpense(dataEvent))
      console.log(dayjs(dataEvent).format())
      await createPaymentMutation.mutateAsync({
        note,        
        dataEvent: dateUtils.formatTo.formatDateToRegisterPaymentOrExpense(dataEvent), // New Method
        paymentMethodId: watchPaymentMethodId,
        amount: parseFloat(
          amount && typeof amount === 'string'
            ? convertToCurrencyFormat(amount).toString()
            : '0'
        ),
      });

      addNotification({
        type: 'success',
        message: 'Cadastro realizado com sucesso!',
      });
      reset();
    } catch (error) {
      if (error instanceof AxiosError) {
        if (
          error.response?.status &&
          error.response?.status >= 400 &&
          error.response?.status < 500
        ) {
          addNotification({
            type: 'error',
            message: error.response?.data.message,
          });
          return;
        }
        addNotification({
          type: 'error',
          message: 'Erro com o serviço. Tente novamente mais tarde.',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const amountPaid = useMemo(
    () => saleByID?.payments.reduce((sum, payment) => sum + parseFloat(payment.amount), 0) ?? 0,
    [saleByID?.payments]
  );  

  return (
    <Modal
      content={
        <>
          <button
            className="absolute top-[15px] right-4"
            onClick={() => setOpenModal({ display: false, sale: null })}
          >
            <CloseIcon />
          </button>
          <div className="flex flex-col justify-center items-center gap-y-[30px] pr-2 pb-[41px]">
            <h2 className="text-xl text-primary font-semibold">Registrar Recebimento</h2>
            <form
              noValidate
              className="flex-row items-start w-full max-w-[600px] gap-x-[10px] xl:gap-x-[20px]"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="flex flex-col">
                <div className="w-full flex sm:gap-x-[16px] flex-col sm:flex-row">
                  {/* Valor total da venda */}
                  {!saleByIdIsLoading ? (
                    saleByID && saleByID.amount ? (
                      <InputText
                        labelText="Valor da Venda*"
                        name="Valor da Venda"
                        disabled
                        cssClasses={{
                          label: 'sm:max-w-[300px] h-[44px] mt-[10px]',
                          input: 'w-full sm:max-w-[300px]',
                        }}
                        type="text"
                        value={convertToBrazilianCurrencyFormat(saleByID.totalAmount ?? '0')}
                      />
                    ) : null
                  ) : (
                    <Skeleton height={44} containerClassName="w-full mt-[20px]" />
                  )}

                  {/* Valor previamente pago */}
                  {!saleByIdIsLoading ? (
                    saleByID && saleByID.payments ? (
                      <InputText
                        labelText="Valor Recebido*"
                        name="Valor Recebido"
                        disabled
                        cssClasses={{
                          label: 'sm:max-w-[300px] h-[44px] mt-[10px]',
                          input: 'w-full sm:max-w-[300px]',
                        }}
                        type="text"
                        value={convertToBrazilianCurrencyFormat(
                          saleByID.payments.length > 0 ? `${amountPaid}` : '0'
                        )}
                      />
                    ) : null
                  ) : (
                    <Skeleton height={44} containerClassName="w-full mt-[20px]" />
                  )}
                </div>
                <div className="w-full flex sm:gap-x-[16px] flex-col sm:flex-row ">
                  {/* Data do Evento */}
                  {editAvailable ? (
                     <LocalizationProvider dateAdapter={AdapterDayjs}>
                     <Controller
                       name="dataEvent"
                       control={control}
                       defaultValue={dayjs()} 
                       render={({ field }) => {
                         return (
                           <label
                             htmlFor="receivePay"
                             className="font-inter text-[#374151] text-sm font-medium flex flex-col justify-start w-full m-0 truncate !overflow-visible relative cursor-pointer mt-[9px] gap-y-2  max-w-[250px]"
                           >
                             Data do Recebimento*
                             <DateTimePicker
                               ampm={false}
                               format={DATE_TIME_DISPLAY_FORMAT}
                               onChange={field.onChange}
                               value={dayjs(field.value) || dayjs()}
                               slotProps={{
                                textField: {
                                  size: "small"
                                }
                              }
                              }
                             />
                           </label>
                         );
                       }}
                     />
                     </LocalizationProvider>
                  ) : (
                    <InputText
                      labelText="Data do Recebimento*"
                      name="Data do Recebimento"
                      value={dayjs(sale.dateEvent).format(DATE_TIME_DISPLAY_FORMAT)}
                      disabled={true}
                      cssClasses={{
                        label: 'sm:max-w-[300px] h-[44px] mt-[20px] md:mt-[10px]',
                        input: 'w-full max-h-[44px]',
                      }}
                      type="text"
                    />
                  )}
                  {/* FORMA DE PAGAMENTO */}
                  {paymentMethodListIsLoading? (
                    <Skeleton height={44} containerClassName="w-full mt-[30px]" />
                  ) : editAvailable ? (
                    <Select
                      labelText="Forma de Recebimento*"
                      cssClasses={{
                        label:'mt-[10px] ml-[8px]',
                        select: classNames({
                          '!text-[#a9b1bc] h-[44px] ':
                            watchPaymentMethodId === undefined ||
                            watchPaymentMethodId.toString() === `-1`,
                        }),
                      }}
                      {...register('paymentMethodId')}
                      placeholder="Lista de Formas de Recebimentos"
                      defaultValue={-1}
                    >
                      <option
                        value={-1}
                        className="font-normal font-inter text-sm leading-5 !text-[#a9b1bc] "
                      >
                        Lista de Formas de Recebimentos
                      </option>
                      {paymentMethodListData?.data.map(
                        (paymentMethod: ICompanyGroup, paymentMethodIndex: number) =>
                          paymentMethod.description && (
                            <option
                              value={Number(paymentMethod.id)}
                              key={`client-type-${paymentMethod.id}-${paymentMethod.description}-${paymentMethodIndex}`}
                              className="text-textcolor max-w-[300px]"
                              title={paymentMethod.description}
                            >
                              {paymentMethod.description}
                            </option>
                          )
                      )}                      
                    </Select>
                  ) : (
                    <InputText
                      labelText="Tipo de Recebimento*"
                      name="paytipe"
                      value={sale.paymethod}
                      disabled={true}
                      cssClasses={{
                        label: ' md:max-w-[300px] h-[44px] sm:mt-[20px] md:mt-[10px]',
                        input: 'w-full  md:max-w-[300px]',
                      }}
                      type="text"
                    />
                  )}
                </div>
                <div className="w-full flex sm:gap-x-[16px] flex-col sm:flex-row">
                  {/* Valor */}
                  <Controller
                    name="amount"
                    control={control}
                    defaultValue={
                      editAvailable ? 'R$ 0,00' : convertToBrazilianCurrencyFormat(sale.amount)
                    }
                    render={({ field }) => (
                      <InputText
                        {...field}
                        labelText="Valor*"
                        disabled={!editAvailable}
                        cssClasses={{
                          label: ' sm:max-w-[300px] h-[44px] mt-[10px]',
                          input: 'w-full  sm:max-w-[300px]',
                        }}
                        type="text"
                        onChange={handleChangeMonthlyInstallments(field.onChange)}
                      />
                    )}
                  />
                  {/* Valor pendente */}
                  {!saleByIdIsLoading ? (
                    saleByID && saleByID.totalAmount ? (
                      <InputText
                        labelText="Valor Pendente*"
                        name="Valor Pendente"
                        disabled
                        cssClasses={{
                          label: 'sm:max-w-[300px] h-[44px] mt-[10px]',
                          input: 'w-full sm:max-w-[300px]',
                        }}
                        type="text"
                        value={convertToBrazilianCurrencyFormat(
                          `${Number(saleByID.totalAmount) - amountPaid}` ?? '0'
                        )}
                      />
                    ) : null
                  ) : (
                    <Skeleton height={44} containerClassName="w-full mt-[30px]" />
                  )}
                </div>

                {/* Observacoes */}     
                <Controller
                  name='note'
                  control={control}
                  defaultValue={""}
                  render={({field}) => (
                    <InputTextArea
                      {...field}
                      labelText="Observações"
                      cssClasses={{
                        label:'mt-[10px]',
                        textarea: 'h-full min-h-[100px] max-h-[250px]',
                      }}
                      maxLength={DEFAULT_VALUES.MAX_250_CHAR}
                      disabled={!editAvailable}  
                    />
                  )}
                />                          
              </div>
              <div className="w-full flex flex-col justify-end items-end py-[30px]">                
                {editAvailable && (
                  <button
                    type="submit"
                    disabled={!isValid}
                    className="bg-primary text-xs font-bold leading-4 disabled:bg-[#D1D5DB] text-buttontextcolor py-2 pl-4 pr-[18px] rounded-lg flex justify-center items-center h-[42px] max-w-[200px] tracking-[0.6px]"
                  >
                    SALVAR RECEBIMENTO
                  </button>
                )}
              </div>
            </form>
          </div>
        </>
      }
      css={{
        modal: 'relative md:max-h-[600px] md:max-w-[600px] h-full w-full md:my-auto overflow-y-auto' ,
      }}
    />
  );
}

export default PaymentModal;
