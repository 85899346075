import React from 'react';

interface Column {
  key: string;
  header: string;
}

interface DataItem {
  [key: string]: any;
}

interface Formatters {
  [key: string]: (value: any) => string;
}

export interface GenericTableProps {
  columns: Column[];
  data: DataItem[];
  formatters?: Formatters;
}

const GenericTable: React.FC<GenericTableProps> = ({ columns, data, formatters }) => {
  return (
    <table className="w-full text-sm text-left text-gray-500 rounded-lg overflow-hidden">
      <thead className="font-inter text-[10px] text-primary font-bold uppercase bg-gray-50 w-full">
        <tr>
          {columns.map((column) => (
            <th
              key={column.key}
              className="h-full px-[8px] sm:px-[24px] py-3 cursor-pointer hover:bg-gray-100 w-fit"
            >
              <span className="flex flex-row items-center gap-2 truncate">{column.header}</span>
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="w-full">
        {data.map((item, index) => (
          <tr
            className="bg-white border-b hover:bg-gray-100 w-full h-[60px] text-xs"
            key={`row-${index}`}
          >
            {columns.map((column) => (
              <td
                key={column.key}
                className="px-[8px] sm:px-[24px] py-4 font-normal align-middle  w-fit"
              >
                {formatters && formatters[column.key]
                  ? formatters[column.key](item[column.key])
                  : item[column.key]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default GenericTable;