// Third-party
import { useEffect, useMemo, useRef, useState } from 'react';
import { HashLoader } from 'react-spinners';
import classNames from 'classnames';

//App
import { CloseIcon } from 'assets/icons';
import getTypeFromMimeTypeUtil from 'utils/getTypeFromMimeType.util';
import { selectAddNotification } from 'store/client/notification/selectors';
import useNotificationStore from 'store/client/notification/useNotificationStore';

interface FileModalProps {
  fileUrl: string;
  fileMimeType: string;
  fileName: string;
  onClose: () => unknown;
}

const fileDisplayers: Record<
  string,
  React.FC<{ fileUrl: string; fileName: string; onLoad: () => void; onClose?: () => void }>
> = {
  image: ({ fileUrl, fileName, onLoad, onClose }) => {
    const addNotification = useNotificationStore(selectAddNotification);
    return (
      <img
        src={fileUrl}
        alt="file"
        className="object-contain w-full h-full overflow-hidden"
        onLoad={onLoad}
        onError={() => {
          onClose?.();
          addNotification({
            type: 'error',
            message: `Erro ao tentar abrir arquivo <b>${fileName}</b>. Tente novamente mais tarde.`,
          });
        }}
      />
    );
  },
  video: ({ fileUrl, fileName, onLoad, onClose }) => {
    const addNotification = useNotificationStore(selectAddNotification);

    return (
      <video
        controls
        muted
        autoPlay
        className="overflow-hidden w-full h-full"
        onLoadedMetadata={onLoad}
        onError={() => {
          onClose?.();
          addNotification({
            type: 'error',
            message: `Erro ao tentar abrir arquivo <b>${fileName}</b>. Tente novamente mais tarde.`,
          });
        }}
      >
        <source src={fileUrl} />
        Seu navegador não suporta o elemento <code>video</code>.
      </video>
    );
  },
  audio: ({ fileUrl, fileName, onLoad, onClose }) => {
    const addNotification = useNotificationStore(selectAddNotification);

    return (
      <audio
        controls
        loop
        onLoadedMetadata={onLoad}
        onError={() => {
          onClose?.();
          addNotification({
            type: 'error',
            message: `Erro ao tentar abrir arquivo <b>${fileName}</b>. Tente novamente mais tarde.`,
          });
        }}
        className="w-full h-[55px]"
      >
        <track kind="captions" />
        <source src={fileUrl} />
        Seu navegador não suporta o elemento <code>audiio</code>.
      </audio>
    );
  },
  default: () => {
    return <p className="text-[#595959] text-base font-semibold">Tipo de arquivo não suportado.</p>;
  },
};

function FileModal({ fileMimeType, fileUrl, fileName, onClose }: FileModalProps) {
  /*
  **** Component organization ****

   └── Declaration of generic hooks (e.g., useNavigate)
   └── State declaration
   └── Side effects (e.g., useEffect)
   └── Memoization (e.g., useMemo)
   └── Handlers (e.g., useCallback)
   └── JSX
  */

  // └── Declaration of generic hooks (e.g., useNavigate)
  const modalRef = useRef<HTMLDivElement>(null);

  // └── State declaration
  const [isLoading, setIsLoading] = useState(true);

  // └── Side effects (e.g., useEffect)
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      if (!modalRef.current || modalRef.current.contains(event.target as Node)) {
        return;
      }
      if (onClose) {
        onClose();
      }
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [modalRef, onClose]);

  // └── Memoization (e.g., useMemo)
  const FileDisplayer = useMemo(() => {
    const type = getTypeFromMimeTypeUtil(fileMimeType);
    if (!fileUrl || !fileMimeType || !type || !fileDisplayers[type]) {
      setIsLoading(false);
      return fileDisplayers.default;
    }

    return fileDisplayers[type];
  }, [fileMimeType, fileUrl]);

  // └── Handlers (e.g., useCallback)
  const handleOnLoad = () => {
    setIsLoading(false);
  };

  return (
    <div className="fixed inset-0 w-full h-screen bg-black bg-opacity-40 flex items-center justify-center p-8 z-[999800] cursor-pointer">
      <HashLoader
        color="white"
        cssOverride={{
          display: isLoading ? 'block' : 'none',
        }}
      />
      <div
        className={classNames(
          'bg-white p-6 pt-4 rounded-md flex flex-col shadow-2xl cursor-default gap-y-4 w-fit h-fit max-w-full max-h-full overflow-hidden',
          {
            hidden: isLoading,
            'w-full !max-w-[452px]': getTypeFromMimeTypeUtil(fileMimeType) === 'audio',
          }
        )}
        ref={modalRef}
      >
        <div className="w-full flex justify-end">
          <button type="button" className="text-sm py-1 font-inter group" onClick={() => onClose()}>
            <CloseIcon className="!text-primary" />
          </button>
        </div>
        <FileDisplayer
          fileUrl={fileUrl}
          fileName={fileName}
          onLoad={handleOnLoad}
          onClose={onClose}
        />
      </div>
    </div>
  );
}

export default FileModal;
