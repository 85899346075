import classNames from 'classnames';

function PadlockIcon({ className }: { className?: string }) {
  return (
    <svg
      width="20"
      height="23"
      viewBox="0 0 20 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames('text-[#AB1CAE]', className)}
    >
      <path
        d="M16.5 7.66634H18.6667C18.954 7.66634 19.2295 7.78048 19.4327 7.98364C19.6359 8.18681 19.75 8.46236 19.75 8.74967V21.7497C19.75 22.037 19.6359 22.3125 19.4327 22.5157C19.2295 22.7189 18.954 22.833 18.6667 22.833H1.33333C1.04602 22.833 0.770465 22.7189 0.567301 22.5157C0.364137 22.3125 0.25 22.037 0.25 21.7497V8.74967C0.25 8.46236 0.364137 8.18681 0.567301 7.98364C0.770465 7.78048 1.04602 7.66634 1.33333 7.66634H3.5V6.58301C3.5 4.8591 4.18482 3.2058 5.40381 1.98681C6.62279 0.767827 8.27609 0.0830078 10 0.0830078C11.7239 0.0830078 13.3772 0.767827 14.5962 1.98681C15.8152 3.2058 16.5 4.8591 16.5 6.58301V7.66634ZM2.41667 9.83301V20.6663H17.5833V9.83301H2.41667ZM8.91667 14.1663H11.0833V16.333H8.91667V14.1663ZM4.58333 14.1663H6.75V16.333H4.58333V14.1663ZM13.25 14.1663H15.4167V16.333H13.25V14.1663ZM14.3333 7.66634V6.58301C14.3333 5.43374 13.8768 4.33154 13.0641 3.51888C12.2515 2.70622 11.1493 2.24967 10 2.24967C8.85073 2.24967 7.74853 2.70622 6.93587 3.51888C6.12321 4.33154 5.66667 5.43374 5.66667 6.58301V7.66634H14.3333Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default PadlockIcon;
