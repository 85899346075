// Third-party
import { Navigate, Route, Routes } from 'react-router-dom';

// App
import {
  AuthLayout,
  DefaultLayout,
  OpenRoute,
  ProtectedRoute,
  RefreshTokenProvider,
} from 'components';
import { PAGES_ROUTES } from 'constants/routes';

import pages from './pages';
import { useGetUser } from 'store/server/user/queries';
import { selectLockComponentsRender } from 'store/client/globalParams/selectors';
import useGlobalParamsStore from 'store/client/globalParams/useGlobalParamsStore';
import { FilterBarContextProvider } from 'pages/company/Summary/context/FilterBarContext';
import { useEffect } from 'react';



function Router() {
  /*
  **** Component organization ****
  └── Declaration of generic hooks (e.g., useNavigate)
  └── State declaration
  └── Side effects (e.g., useEffect)
  └── Memoization (e.g., useMemo)
  └── Handlers (e.g., useCallback)
  └── JSX
  */

  // └── Declaration of generic hooks (e.g., useNavigate)
  const lockComponentsRender = useGlobalParamsStore(selectLockComponentsRender);
  const { data: authenticatedUser } = useGetUser();


  // └── State declaration
  //   └── Global
  if (lockComponentsRender) return null;

  return (
    <>
      <Routes>
        <Route path={PAGES_ROUTES.notfound} element={<pages.common.NotFound />} />
        <Route path={PAGES_ROUTES.unavailable} element={<pages.common.Unavailable />} />

        {/**
         *  ***********ATTENTION***********
         *
         * RefreshTokenProvider is responsible for executing the logic for checking the existence, expiration and renewal of the access token.
         * When entering a nested route within RefreshTokenProvider, all the logic is triggered.
         * */}
        <Route element={<RefreshTokenProvider />}>
          {/**
           * Open routes that already authenticated users don't need/can't access
           */}
          <Route element={<OpenRoute />}>
            <Route element={<AuthLayout />}>
              <Route path={PAGES_ROUTES.unauthenticated.signin} element={<pages.auth.SignIn />} />
              <Route
                path={PAGES_ROUTES.unauthenticated.newPassword}
                element={<pages.auth.NewPasswordRequired />}
              />
              <Route
                path={PAGES_ROUTES.unauthenticated.forgotPassword}
                element={<pages.auth.ForgotPassword />}
              />
            </Route>
          </Route>

          {/**
           * Protected routes that only an already authenticated user can access
           */}

          {/**
           * Routes where only the user with access level "1 - ADMINISTRATOR" has access.
           */}
          <Route element={<ProtectedRoute allowedAccessLevels={[1]} />}>
            <Route element={<DefaultLayout />}>
              <Route
                path={PAGES_ROUTES.authenticated.user.list}
                element={<pages.user.UserList />}
              />
              <Route
                path={PAGES_ROUTES.authenticated.company.create}
                element={<pages.company.Create />}
              />
              <Route
                path={PAGES_ROUTES.authenticated.company.list}
                element={<pages.company.List />}
              />
              <Route
                path={PAGES_ROUTES.authenticated.company.listToSelect}
                element={<pages.company.ListToSelect />}
              />
              <Route path={PAGES_ROUTES.authenticated.unit.list} element={<pages.unit.List />} />
            </Route>
          </Route>

          {/**
           * Routes where only users with access level "1 - Administrator, 2 - Operator" have access.
           */}
          <Route element={<ProtectedRoute allowedAccessLevels={[1, 2]} />}>
            <Route element={<DefaultLayout />}>
              <Route
                path={PAGES_ROUTES.authenticated.user.changePassword}
                element={<pages.user.ChangePassword />}
              />
              <Route
                path={PAGES_ROUTES.authenticated.user.edit}
                element={<pages.user.EditUser />}
              />
              <Route
                path={PAGES_ROUTES.authenticated.company.edit}
                element={<pages.company.Edit />}
              />
              <Route
                path={PAGES_ROUTES.authenticated.company.listGroup}
                element={<pages.company.ListGroup />}
              />
              <Route
                path={PAGES_ROUTES.authenticated.company.group.create}
                element={<pages.company.Group.Create />}
              />
              <Route
                path={PAGES_ROUTES.authenticated.company.group.edit}
                element={<pages.company.Group.Edit />}
              />
              <Route
                path={PAGES_ROUTES.authenticated.company.dashboard}
                element={<pages.company.Dashboard />}
              />
              <Route
                path={PAGES_ROUTES.authenticated.company.summary}
                element={
                  <FilterBarContextProvider>
                    <pages.company.Summary />
                  </FilterBarContextProvider>
                }
              />
              <Route
                path={PAGES_ROUTES.authenticated.client.create}
                element={<pages.client.Create />}
              />
              <Route
                path={PAGES_ROUTES.authenticated.client.list}
                element={<pages.client.List />}
              />
              <Route
                path={PAGES_ROUTES.authenticated.client.edit}
                element={<pages.client.Edit />}
              />
              <Route
                path={PAGES_ROUTES.authenticated.transactions.expenses.list}
                element={<pages.transactions.Expenses.List />}
              />
              <Route
                path={PAGES_ROUTES.authenticated.transactions.expenses.create}
                element={<pages.transactions.Expenses.Create />}
              />
              <Route
                path={PAGES_ROUTES.authenticated.transactions.sale.list}
                element={<pages.transactions.Sales.List />}
              />
              <Route
                path={PAGES_ROUTES.authenticated.transactions.sale.create}
                element={<pages.transactions.Sales.Create />}
              />
              <Route
                path={PAGES_ROUTES.authenticated.transactions.pendingSales.list}
                element={<pages.transactions.PendingSales.List />}
              />
              <Route
                path={PAGES_ROUTES.authenticated.transactions.payments.list}
                element={<pages.transactions.Payments.List />}
              />
              <Route
                path={PAGES_ROUTES.authenticated.company.logList}
                element={<pages.loglist.List />}
              />
            </Route>
          </Route>
        </Route>

        {/**
         * Generic Routes
         */}
        <Route
          path="/"
          element={
            <Navigate
              to={
                authenticatedUser?.initialRoute
                  ? authenticatedUser?.initialRoute
                  : PAGES_ROUTES.unauthenticated.initialRoute
              }
              replace
            />
          }
        />
        <Route path="/*" element={<Navigate to={PAGES_ROUTES.notfound} replace />} />
      </Routes>
    </>
  );
}

export default Router;
