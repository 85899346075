/* eslint-disable react-hooks/rules-of-hooks */
// Third-party
import { NavLink, Navigate, useLocation, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import { useMemo } from 'react';

// App
import { formSchema } from './schemas';
import { InputText } from 'components';
import { AddUserIcon, IndicatorSmallIcon, RegistrationIcon } from 'assets/icons';
import { ICreateCompanyGroupPayload } from 'interfaces/companyTypes';
import useNotificationStore from 'store/client/notification/useNotificationStore';
import { selectAddNotification } from 'store/client/notification/selectors';
import useLoadingStore from 'store/client/loading/useLoadingStore';
import { selectLoading, selectSetLoading } from 'store/client/loading/selectors';
import { useCreateCompanyGroupMutation } from 'store/server/company/mutations';
import { PAGES_ROUTES } from 'constants/routes';
import { useGetUser } from 'store/server/user/queries';
import useCompanySelectedStore from 'store/client/companySelected/useCompanySelectedStore';
import { selectCompanySelected } from 'store/client/companySelected/selectors';
import { useMenuGroupList } from 'store/server/menu/queries';
import ReturnButton from 'components/ReturnButton';

function Create() {
  /*
  **** Component organization ****

   └── Declaration of generic hooks (e.g., useNavigate)
   └── State declaration
   └── Side effects (e.g., useEffect)
   └── Memoization (e.g., useMemo)
   └── Handlers (e.g., useCallback)
   └── JSX
   */

  // └── Declaration of generic hooks (e.g., useNavigate)
  const {
    register,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm<ICreateCompanyGroupPayload>({
    resolver: yupResolver(formSchema),
  });
  const isLoading = useLoadingStore(selectLoading);
  const setLoading = useLoadingStore(selectSetLoading);
  const addNotification = useNotificationStore(selectAddNotification);
  const companySelected = useCompanySelectedStore(selectCompanySelected);
  const { groupId } = useParams();

  const { data: user } = useGetUser();
  if (!companySelected || !groupId)
    return (
      <Navigate to={user?.initialRoute ?? PAGES_ROUTES.authenticated.adminInitialRoute} replace />
    );

  const createCompanyGroupMutation = useCreateCompanyGroupMutation(companySelected?.id, groupId);

  const { data: menuGroupList } = useMenuGroupList();
  const groupTypeSelected = useMemo(
    () => menuGroupList?.data.find((typeGroup) => typeGroup.id === Number(groupId)),
    [groupId, menuGroupList?.data]
  );

  // └── State declaration

  // └── Side effects (e.g., useEffect)
  // └── Handlers (e.g., useCallback)
  const onSubmit = async ({ description }: ICreateCompanyGroupPayload) => {
    setLoading(true);
    try {
      await createCompanyGroupMutation.mutateAsync({ description });

      addNotification({
        type: 'success',
        message: 'Cadastro realizado com sucesso!',
      });
      reset();
    } catch (error) {
      if (error instanceof AxiosError) {
        if (
          error.response?.status &&
          error.response?.status >= 400 &&
          error.response?.status < 500
        ) {
          addNotification({
            type: 'error',
            message: error.response?.data.message,
          });
          return;
        }

        if (
          ['Email already exists', 'User already exists'].includes(error.response?.data.message)
        ) {
          addNotification({
            type: 'error',
            message: 'Usuário já cadastrado no sistema.',
          });
          return;
        }

        addNotification({
          type: 'error',
          message: 'Erro com o serviço. Tente novamente mais tarde.',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full min-h-screen h-full flex flex-col justify-start gap-y-[19px] items-start pt-[23px] md:pt-[42px] p-[27px] md:p-8">
      <div className="flex flex-col gap-2">
        <h1 className="flex items-center gap-2.5 text-2xl font-semibold text-[#595959]">
          <RegistrationIcon className="w-[22px] h-[24px] !text-secondary" /> Cadastrar{' '}
          {groupTypeSelected?.description}
        </h1>
        <h3 className="text-xs text-[#374151]">Campos obrigatórios *</h3>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        className="self-center w-full max-w-[600px] gap-x-[10px] xl:gap-x-[20px] gap-y-10 flex flex-col justify-center"
      >
        <div className="gap-x-[10px] xl:gap-x-[20px] gap-y-2">
          <InputText labelText="Descrição*" {...register('description')} />
        </div>
        <div className="flex justify-between md:justify-end items-center md:gap-x-7 lg:gap-x-11 !w-full mt-[11px] md:mt-[30px]">          
          <ReturnButton 
            returnTo={PAGES_ROUTES.authenticated.company.listGroup.replace(':groupId', groupId)}
          />
          <button
            type="submit"
            className="bg-primary text-xs font-bold leading-4 disabled:bg-[#D1D5DB] text-buttontextcolor py-2 pl-4 pr-[18px] rounded-lg flex justify-center items-center h-[42px] max-w-[147px] tracking-[0.6px]"
            disabled={!isValid || isLoading}
          >
            CADASTRAR
            <AddUserIcon className="ml-4 !text-buttontextcolor" />
          </button>
        </div>
      </form>
    </div>
  );
}

export default Create;
