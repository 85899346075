import SignIn from './SignIn';
import NewPasswordRequired from './NewPasswordRequired';
import ForgotPassword from './ForgotPassword';

export { SignIn };
export { NewPasswordRequired };
export { ForgotPassword };

export default {
  SignIn,
  NewPasswordRequired,
  ForgotPassword,
};
