/* eslint-disable react-hooks/rules-of-hooks */
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from 'chart.js';
import useCompanySelectedStore from 'store/client/companySelected/useCompanySelectedStore';
import { selectCompanySelected } from 'store/client/companySelected/selectors';
import { PAGES_ROUTES } from 'constants/routes';
import { Navigate } from 'react-router-dom';
import { useFilterBarContext } from '../../context/FilterBarContext';
import { useGetSummaryData } from 'store/server/company/queries';
import getMonthStartAndEndUtil from 'utils/getMonthStartAndEnd.util';
import { useMemo } from 'react';
import { convertToBrazilianCurrencyFormat } from 'utils/currency.utils';
import classNames from 'classnames';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface ISummaryChartProps {
  toExport?: boolean;
}

function BarChart({ toExport }: ISummaryChartProps) {
  const companySelected = useCompanySelectedStore(selectCompanySelected);

  if (!companySelected) {
    return <Navigate to={PAGES_ROUTES.authenticated.commonInitialRoute} replace />;
  }

  const { selectedMonth, selectedYear } = useFilterBarContext();

  const { data: summaryData } = useGetSummaryData(
    companySelected.id,

    {
      ...getMonthStartAndEndUtil(Number(selectedYear), Number(selectedMonth)),
    },
    { requestIdEnabled: false }
  );

  const labels = useMemo(
    () => [
      `Lucro Valor Recebido\n${convertToBrazilianCurrencyFormat(
        `${summaryData?.resume.balanceToReceve}`
      )}`,
      `Despesa Total\n${convertToBrazilianCurrencyFormat(`${summaryData?.resume.expenseSum}`)}`,
      `Venda Total\n${convertToBrazilianCurrencyFormat(`${summaryData?.resume.saleSum}`)}`,
      `Lucro\n${convertToBrazilianCurrencyFormat(`${summaryData?.resume.balance}`)}`,
    ],
    [
      summaryData?.resume.balance,
      summaryData?.resume.balanceToReceve,
      summaryData?.resume.expenseSum,
      summaryData?.resume.saleSum,
    ]
  );

  const data = useMemo(
    () => ({
      labels,
      datasets: [
        {
          label: 'R$',
          data: [
            summaryData?.resume.balanceToReceve,
            summaryData?.resume.expenseSum,
            summaryData?.resume.saleSum,
            summaryData?.resume.balance,
          ],
          backgroundColor: ['#00a3e8', '#ff3336', '#049b18', '#376091'],
        },
      ],
    }),
    [
      labels,
      summaryData?.resume.balance,
      summaryData?.resume.balanceToReceve,
      summaryData?.resume.expenseSum,
      summaryData?.resume.saleSum,
    ]
  );

  const options = useMemo<ChartOptions<'bar'>>(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        tooltip: {
          callbacks: {
            title: (context) => context[0].label.split('\n')[0],
            label: (context) => {
              const value = context.parsed.y;
              const label = convertToBrazilianCurrencyFormat(`${value}`);
              return label;
            },
          },
        },
        legend: {
          display: false,
        },
        title: {
          display: false,
          text: 'Resumo Financeiro',
          color: '#a7b0bf',
        },
        datalabels: {
          display: false,
        },
        titleBackgroundPlugin: {
          backgroundColor: '#a7b0bf',
        },
      },
      scales: {
        y: {
          display: false,
          beginAtZero: true,
          min: 0,
        },
        x: {
          ticks: {
            callback: function (_value: string | number, index: number) {
              return labels[index].split('\n');
            },
            color: '#000000',
            font: {
              size: 16,
            },
          },
        },
      },
    }),
    [labels]
  );

  return (
    <div className={classNames(' w-full h-[500px] pt-[20px]')}>
      <Bar data={data} options={options} />
    </div>
  );
}

export default BarChart;
