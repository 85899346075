// Third-party
import { useSelector } from 'react-redux';

// App
import NotificationCard from 'components/NotificationCard';
import './style.css';
import useNotificationStore from 'store/client/notification/useNotificationStore';
import { selectNotifications } from 'store/client/notification/selectors';

function NotificationsProvider() {
  /*
  **** Component organization ****

   └── Declaration of generic hooks (e.g., useNavigate)
   └── State declaration
   └── Side effects (e.g., useEffect)
   └── Memoization (e.g., useMemo)
   └── Handlers (e.g., useCallback)
   └── JSX
   */

  // └── Declaration of generic hooks (e.g., useNavigate)
  const notifications = useNotificationStore(selectNotifications);

  return (
    <>
      {notifications.length > 0 && (
        <div className="fixed right-0 z-[9999999] w-full sm:w-[384px] px-5 overflow-hidden pointer-events-none">
          {notifications.map(({ id, type, message, timeout }) => (
            <NotificationCard key={id} id={id} message={message} type={type} timeout={timeout} />
          ))}
        </div>
      )}
    </>
  );
}

export default NotificationsProvider;
