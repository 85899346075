// Third-party
import { useEffect, useState } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

// App

function LoadingListSkeleton() {
  /*
  **** Component organization ****

   └── Declaration of generic hooks (e.g., useNavigate)
   └── State declaration
   └── Side effects (e.g., useEffect)
   └── Memoization (e.g., useMemo)
   └── Handlers (e.g., useCallback)
   └── JSX
   */

  // └── State declaration
  const [windowInnerHeight, setWindowInnerHeight] = useState<number>(window.innerHeight);

  // └── Side effects (e.g., useEffect)
  useEffect(() => {
    const getWindowInnerHeight = () => {
      setWindowInnerHeight(window.innerHeight);
    };

    window.addEventListener('resize', getWindowInnerHeight);

    return () => window.removeEventListener('resize', getWindowInnerHeight);
  }, []);

  return (
    <>
      <SkeletonTheme baseColor="#f3f3f3" highlightColor="white">
        <div className="w-full">
          <div className="flex flex-row w-full">
            <Skeleton
              height={40.5}
              className="inline-block w-full"
              containerClassName="inline-block w-full"
            />
            <Skeleton
              height={40.5}
              className="inline-block w-full"
              containerClassName="inline-block w-full"
            />
            <Skeleton
              height={40.5}
              className="inline-block w-full"
              containerClassName="inline-block w-full"
            />
          </div>
          {[...Array(Math.round(windowInnerHeight / 203)).keys()].map((element) => (
            <Skeleton width={'100%'} height={52.5} key={`user-table-row-skeleton-${element}`} />
          ))}
        </div>
      </SkeletonTheme>
    </>
  );
}

export default LoadingListSkeleton;
