import { ICompany } from './companyTypes';

export interface IUser {
  id: number;
  email: string;
  givenName: string;
  familyName: string;
  accessLevel: 1 | 2;
  accessLevelLabel?: string;
  status?: keyof typeof UserStatusEnum;
  company?: ICompany;
  createdAt?: string | null;
  updatedAt?: string | null;
  erasedAt?: string | null;
}

export interface ICreateUserRequest {
  email: string;
  givenName: string;
  familyName: string;
  photoUrl?: string;
  notificationEmail?: boolean;
  accessLevel: number;
  userTypesId?: number;
}

export interface IChangePasswordRequest {
  email: string;
  currentPassword: string;
  newPassword: string;
}

export interface IConfirmUserExistenceRequest {
  email: string;
}

export interface IUpdateUserRequest {
  email: string;
  given_name?: string;
  family_name?: string;
  access_level?: number;
  status?: 1 | 3; //1=inactive,2=registered, 3=active
  notification_email?: boolean;
  photo_url?: string;
  user_types_id?: number;
}

export enum UserAccessLabels {
  'VIEW' = 'visualizar',
  'EDIT' = 'editar',
  'NOACCESS' = 'sem accesso',
}

export enum UserStatusEnum {
  'PENDING' = 'Aguardando',
  'ACTIVED' = 'Ativo',
  'DESACTIVED' = 'Inativo',
}

export enum AccessLevel {
  ADMIN = 1,
  OPERATOR = 2,
}

export interface IUserListParams {
  start: number;
  size: number;
  order: string;
  field: string;
  filter: string | null;
}
export interface IUpdateUserPayload {
  givenName?: string;
  familyName?: string;
}

export interface IGetUserStatus {
  id: number;
  givenName: string;
  familyName: string;
  email: string;
  photoUrl: string;
  status: number;
  notificationEmail: string;
  accessLevel: number;
  userTypesId: number;
}
