// Third-party
import { createContext, ReactNode, useState, useContext, SetStateAction, Dispatch } from 'react';

// App
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/Sao_Paulo');

interface IFilterBarContextState {
  selectedMonth: number;
  selectedYear: number;
  setSelectedMonth: Dispatch<SetStateAction<number>>;
  setSelectedYear: Dispatch<SetStateAction<number>>;
}

interface IFilterBarProviderProps {
  children: ReactNode;
}

const initialFilterBarState = {
  selectedMonth: dayjs().month() + 1,
  selectedYear: dayjs().year(),
  setSelectedMonth: () => null,
  setSelectedYear: () => null,
};

const FilterBarContext = createContext<IFilterBarContextState>(initialFilterBarState);

function FilterBarContextProvider({ children }: IFilterBarProviderProps) {
  const [selectedMonth, setSelectedMonth] = useState<number>(initialFilterBarState.selectedMonth);
  const [selectedYear, setSelectedYear] = useState<number>(initialFilterBarState.selectedYear);

  return (
    <FilterBarContext.Provider
      value={{
        selectedMonth,
        selectedYear,
        setSelectedMonth,
        setSelectedYear,
      }}
    >
      {children}
    </FilterBarContext.Provider>
  );
}

export default { FilterBarContext, FilterBarContextProvider };

export { FilterBarContext, FilterBarContextProvider };

export function useFilterBarContext() {
  return useContext(FilterBarContext);
}
