interface ITableContainer {
    children: React.ReactNode;
    containerClassName?: string;
}

function TableContainer({children, containerClassName} : ITableContainer ) {
  return (
    <div
        className={containerClassName || 'relative overflow-visible md:overflow-x-auto w-full flex flex-col gap-y-5'}
    >
        {children}
    </div>
  )
}

export default TableContainer