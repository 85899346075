import React from 'react';
import classNames from 'classnames';
import { PAGES_ROUTES } from 'constants/routes';

import {
  PDFIcon,
} from 'assets/icons';
import Skeleton from 'react-loading-skeleton';
import InputText from 'components/InputText';
import Datepicker, { DateType, DateValueType } from 'react-tailwindcss-datepicker';
import { getDatepickerProps } from 'utils/datePickerProps.util';
import ExportButton from 'components/ExportButton ';
import { useNavigate } from 'react-router-dom';
import SearchButton from 'components/SearchButton';
import CleanFilterButton from 'components/CleanFilterButton';
import RegisterDataButton from 'components/RegisterDataButton';

// Define as props que o componente espera
interface FilterBarProps {
  isFetching: boolean;
  showSkeletonLoading: boolean;
  isLoading: boolean;
  isExportingPDF: boolean;
  isExportingExcel: boolean;
  tempFilterState: {
    search: string | null;
    initialDate: DateType;
    endDate: DateType;
  };
  handleChangeSearchFilter: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeDateFilter: (value: DateValueType, e?: HTMLInputElement | null | undefined) => void;
  handleClickSubmitFilters: () => void;
  handleClearFilters: () => void;
  handleExportReportsExcel: () => void;
  handleExportReportsPdf: () => void;
  redirectToNewRegister?: string;
  hasFilterStatesToSubmit: boolean;
  hasFilterStatesSubmitted: boolean;
}

const TransactionsFilterBar: React.FC<FilterBarProps> = ({
  showSkeletonLoading,
  isFetching,
  isLoading,
  tempFilterState,
  isExportingPDF,
  isExportingExcel,
  handleChangeSearchFilter,
  handleChangeDateFilter,
  handleClickSubmitFilters,
  handleClearFilters,
  handleExportReportsExcel,
  handleExportReportsPdf,
  hasFilterStatesToSubmit,
  hasFilterStatesSubmitted,
  redirectToNewRegister
}) => {  
  const navigate = useNavigate();

  const datepickerProps = getDatepickerProps(
    tempFilterState.initialDate,
    tempFilterState.endDate,
    handleChangeDateFilter,
    isLoading
  );  

  return (
    <div className="w-full flex flex-col lg:flex-row justify-between mb-4 gap-3">
      {isLoading ||showSkeletonLoading ? (
        <Skeleton
          height={42}
          baseColor="#f3f3f3"
          highlightColor="white"
          containerClassName="flex h-[42px] w-[600px]"
          className="!rounded-lg"
        />
      ) : (
        <div className="flex xl:flex-row flex-col w-full justify-start items-end gap-x-[10px] gap-y-[10px]">
          <InputText
            name="filter"
            type="text"
            placeholder="Busca"
            onChange={handleChangeSearchFilter}
            value={tempFilterState.search ?? ''}
            title={tempFilterState.search ?? ''}
            cssClasses={{
              label:
                'w-full xl:max-w-[450px] xl:min-w-[250px] !flex-1 ',
              input:
                '!mt-0 w-full !h-[42px] !text-[#374151] !font-normal text-xs placeholder:font-normal placeholder:text-xs disabled:placeholder:!text-[#D1D5DB] truncate disabled:bg-gray-100',
            }}
            disabled={isLoading}
          />
          <Datepicker
            {...datepickerProps}
          />
          {/* Button to filter */}
            <>
              <SearchButton
                handleClick={handleClickSubmitFilters}                
                isLoading={isLoading}
                isFetching={isFetching} 
                hasFilterStatesToSubmit={hasFilterStatesToSubmit} 
                showSkeletonLoading={showSkeletonLoading}
              />
              {hasFilterStatesSubmitted && (
                <CleanFilterButton
                handleClick={handleClearFilters}
                disabled={isLoading || isFetching}
                />
              )}
            </>          
        </div>
      )}
      <div className="flex xl:flex-row flex-col w-full xl:max-w-fit justify-start xl:justify-between items-center gap-[10px]">
          <ExportButton
            loading={isExportingExcel}
            onClick={() => {
              handleExportReportsExcel();
            }}
            buttonText="Exportar"
            buttonDisabled={isLoading || isFetching}
            showSkeletonLoading={showSkeletonLoading}
          />
          <ExportButton
            loading={isExportingPDF}
            onClick={handleExportReportsPdf}
            icon={<PDFIcon className="ml-4 !text-buttontextcolor" />}            
            buttonText="Exportar"
            buttonDisabled={isLoading || isFetching || isExportingPDF}
            showSkeletonLoading={showSkeletonLoading}
          />
          {
            redirectToNewRegister &&
            <RegisterDataButton 
              showSkeletonLoading={showSkeletonLoading}
              handleClick={()=> navigate(redirectToNewRegister)}
            />
          }
      </div>
    </div>
  );
};

export default TransactionsFilterBar;
