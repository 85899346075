/* eslint-disable react-hooks/rules-of-hooks */
// Third-party
import { Navigate, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';

//App
import { InputText } from 'components';
import {
  ArrowIcon,
  CloseIcon,
  DeleteIcon,
  DropDownArrowIcon,
  ListIcon,
  LoadingSpinIcon,
  OpenEyeIcon,
  SearchIcon,
} from 'assets/icons';
import LoadingListSkeleton from './components/LoadingListSkeleton';
import Skeleton from 'react-loading-skeleton';
import { PAGES_ROUTES } from 'constants/routes';
import { useGetUser } from 'store/server/user/queries';
import Datepicker, { DateType, DateValueType } from 'react-tailwindcss-datepicker';
import usePersistedState from 'hooks/usePersistedState';
import { selectCompanySelected } from 'store/client/companySelected/selectors';
import useCompanySelectedStore from 'store/client/companySelected/useCompanySelectedStore';
import PageHeader from 'components/PageHeader/components/PageHeader';
import TableContainer from 'components/TableContainer';
import NavigationSelector from 'components/NavigationSelector';
import { dateUtils } from 'utils/formatDate';
import { useGetCompanyLogList } from 'store/server/company/queries';
import { screenConfig } from 'utils/screenConfig.util';
import LogDetailsModal from 'components/LogDetailsModal';
import { ILogListOrderAttribute } from 'interfaces/companyTypes';
import { ILog } from 'interfaces/logTypes';
import useFilterStates from 'hooks/useFilterStates';
import {  ORDER_ATTRIBUTTES, ascDescType } from 'constants/orderAttributtes';
import { PAGINATION } from 'constants/clientParams';
import getMonthStartAndEndUtil from 'utils/getMonthStartAndEnd.util';



const { ASC, DESC } = ORDER_ATTRIBUTTES;
const { DATE_DISPLAY_FORMAT, DATE_TIME_DISPLAY_FORMAT,LOG_LIST_DATE_FORMAT } = dateUtils.constants;
const { getFirstDayOfMonth, getLastDayOfMonth } = dateUtils.generateDate;
const { MEDIUM_SCREEN_SIZE } = screenConfig.constants


function List() {
  /*
  **** Component organization ****

   └── Declaration of generic hooks (e.g., useNavigate)
   └── State declaration
   └── Side effects (e.g., useEffect)
   └── Memoization (e.g., useMemo)
   └── Handlers (e.g., useCallback)
   └── JSX
   */

  // └── Declaration of generic hooks (e.g., useNavigate)  
  const tableContainerRef = useRef<HTMLTableElement>(null);

  // └── State declaration
  const { data: user } = useGetUser();
  const companySelected = useCompanySelectedStore(selectCompanySelected);
  if (!companySelected)
    return (
      <Navigate to={user?.initialRoute ?? PAGES_ROUTES.authenticated.adminInitialRoute} replace />
    );

  // Pagination states
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = usePersistedState<number>('companyList.pageSize', 10);

  // Modal State
  const [detailsLogModal, setLogDetailsModal] = useState<{
    display: boolean;
    log: ILog | null;
  }>({
    display: false,
    log: null,
  });

  // Sort states
  const [orderAttribute, setOrderAttribute] = useState<ILogListOrderAttribute>('dateEvent');
  const [order, setOrder] = useState<ascDescType>(ASC);

  // └── State declaration  
  const firstDayOfMonth = useMemo(() => getMonthStartAndEndUtil().initialDate, []);
  const lastDayOfMonth = useMemo(() => getMonthStartAndEndUtil().finalDate, []);

  const {
    tempFilterState,
    submittedFilterState,
    hasFilterStatesToSubmit,
    hasFilterStatesSubmitted,
    changeTempState,
    submitStates,
    cleanStates,
  } = useFilterStates<{
    search: string | null;
    initialDate: DateType;
    endDate: DateType;
  }>({
    filtersStateKey: 'logListFilter',
    initalState: {
      search: null,
      initialDate: firstDayOfMonth,
      endDate: lastDayOfMonth,
    },
  });

  const {
    data: logListData,
    isLoading: logListIsLoading,
    isFetching: logListIsFetching,
  } = useGetCompanyLogList({
    start: page,
    size: pageSize,
    field: orderAttribute,
    order,
    filter: submittedFilterState.search,
    companyId: companySelected?.id,
    dateInit: dayjs(submittedFilterState.initialDate).format(LOG_LIST_DATE_FORMAT),
    dateFinal: dayjs(submittedFilterState.endDate).format(LOG_LIST_DATE_FORMAT),
  });
  
  // └── Side effects (e.g., useEffect)

  // └── Handlers (e.g., useCallback)

  const handleSortByColumn = useCallback(
    (columnName: ILogListOrderAttribute, orderSelected?: ascDescType) => () => {
      if (orderAttribute !== columnName) {
        setOrder(ASC); // If the sort field is different from the clicked column, set the order to ASC
      } else {
        setOrder(orderSelected ? orderSelected : order === ASC ? DESC : ASC); // If the sort field is the same as the clicked column, toggle between ASC and 'desc' order
      }
      setOrderAttribute(columnName); // Set the sort field to the clicked column
    },
    [orderAttribute, order]
  );

  const checkIfShowCards = useCallback(() => {
    if (window.innerWidth < MEDIUM_SCREEN_SIZE) {
      orderAttribute !== 'dateEvent' && setOrderAttribute('dateEvent');
      order !== ASC && setOrder(ASC);
    }
  }, [order, orderAttribute]);

  useEffect(() => {
    window.removeEventListener('resize', checkIfShowCards);
    window.addEventListener('resize', checkIfShowCards);

    return () => {
      window.removeEventListener('resize', checkIfShowCards);
    };
  }, [checkIfShowCards]);

  /**
   * Returns a memoized arrow component with the correct direction based on the 'order' prop.
   *
   * @param {string} order - The order prop indicating the direction (ASC or 'desc').
   * @returns {React.Component} - The memoized Arrow component.
   */
  const ArrowWithCorrectDirection = useMemo(
    () =>
      function Arrow({ className }: { className?: string | Record<string, unknown> }) {
        return (
          <ArrowIcon
            className={classNames(
              {
                'rotate-180': order === ASC, // Adds the 'rotate-180' class if the 'order' prop is ASC
              },
              className
            )}
          />
        );
      },
    [order] // Dependency for useMemo, the component will be recomputed when the 'order' prop changes
  );

  const showSkeletonLoading = useMemo(
    () => (!logListData && logListIsLoading) || logListIsLoading,
    [logListIsLoading, logListData, logListIsLoading]
  );

  const handleClearFilters = useCallback(() => {
    cleanStates();
    setPage(PAGINATION.START_PAGE_NUM);
  }, [cleanStates, setPage]);

  const handleClickSubmitFilters = useCallback(() => {
    if (hasFilterStatesToSubmit) {
      setPage(PAGINATION.START_PAGE_NUM);
      submitStates();
    }
  }, [hasFilterStatesToSubmit, setPage, submitStates]);

const handleChangeDateFilter = useCallback(
  (value: DateValueType) => {
    changeTempState('initialDate', value?.startDate || firstDayOfMonth);
    changeTempState('endDate', value?.endDate || lastDayOfMonth);
  },
  [changeTempState, firstDayOfMonth, lastDayOfMonth]
);
const handleChangeSearchFilter = useCallback(
  async (event: { target: { value: string } }) => {
    changeTempState('search', event.target.value);
  },
  [changeTempState]
);

  return (
    <>
    {detailsLogModal.display && detailsLogModal.log !== null && (
        <LogDetailsModal
          setOpenModal={setLogDetailsModal}
          logData={detailsLogModal}
        />
      )}
      <div
        className={classNames('h-full', {
          'overflow-hidden max-h-[calc(100vh-64px)] md:max-h-[calc(100vh-71px)]':
            showSkeletonLoading,
        })}
      >
        <div
          className={classNames(
            'pt-5 md:pt-[42px] p-[27px] md:p-8 w-full min-h-[calc(100vh-64px)] md:min-h-[calc(100vh-71px)]',
            {
              'overflow-hidden !h-screen': showSkeletonLoading,
            }
          )}
        >
          {/* HEADER */}
          <PageHeader
            iconTextTitleProps={{
              iconProps: {
                iconJSX: <ListIcon className="w-[22px] h-[24px] !text-secondary" />,
              },
              textProps: {
                content: 'Auditoria de Logs',
              },
            }}
          />
          <div className="w-full flex flex-col md:flex-row justify-between mb-4 gap-3">
            <div
              className={classNames(
                'row-start-1 row-end-2 md:row-start-2 md:row-end-2 col-start-1 col-end-4 md:col-end-3 flex flex-row justify-start items-start gap-x-2.5 gap-y-2.5 w-full h-fit'
              )}
            >
              {/* Filter */}
              {showSkeletonLoading ? (
                <Skeleton
                  height={42}
                  baseColor="#f3f3f3"
                  highlightColor="white"
                  containerClassName="flex h-[42px] md:row-start-1 md:row-end-2"
                  className="!rounded-lg"
                />
              ) : (
                <div className="flex lg:flex-row flex-col w-full justify-between items-end gap-x-[10px] gap-y-[10px]">
                  <div className="flex lg:flex-row flex-col w-full justify-start gap-[10px]">
                    <InputText
                      name="filter"
                      type="text"
                      placeholder="Busque por Descriçāo ou Categoria."
                      onChange={async (event: { target: { value: string } }) =>
                        handleChangeSearchFilter(event)
                      }
                      value={tempFilterState.search ?? ''}
                      title={tempFilterState.search ?? ''}
                      cssClasses={{
                        label:
                          'w-full lg:max-w-[450px] lg:min-w-[250px] !flex-1 md:row-start-1 md:row-end-2 max-md:col-start-1 max-md:col-end-4',
                        input:
                          '!mt-0 w-full !h-[42px] !text-[#374151] !font-normal text-xs placeholder:font-normal placeholder:text-xs disabled:placeholder:!text-[#D1D5DB] truncate disabled:bg-gray-100',
                      }}
                      min={'18'}
                      max={'18'}
                      disabled={logListIsLoading || logListIsFetching}
                    />
                    <Datepicker
                      primaryColor="indigo"
                      separator="-"
                      showShortcuts
                      showFooter
                      configs={{
                        shortcuts: {
                          today: 'Hoje',
                          yesterday: 'Ontem',
                          past: (period) => `${period} dias`,
                          lastYear: {
                            text: '1 ano',
                            period: {
                              start: dayjs().subtract(1, 'year').toDate(),
                              end: dayjs().toDate(),
                            },
                          },
                        },
                        footer: {
                          cancel: 'Cancelar',
                          apply: 'Aplicar',
                        },
                      }}
                      i18n={'pt-br'}
                      value={{
                        startDate: tempFilterState.initialDate,
                        endDate: tempFilterState.endDate,
                      }}
                      onChange={(value: DateValueType) => handleChangeDateFilter(value)}
                      dateLooking="middle"
                      displayFormat={DATE_DISPLAY_FORMAT}
                      placeholder="dd/mm/yyyy - dd/mm/yyyy"
                      toggleIcon={(props) => {
                        if (props) return <DropDownArrowIcon className="text-primary" />;
                        return <CloseIcon className="text-primary w-2.5" />;
                      }}
                      popoverDirection="down"
                      containerClassName={classNames(
                        'datepicker z-[40] md:z-50 bg-[#FBFCFD] focus-within:outline outline-1 outline-secondary w-full lg:max-w-[250px] lg:min-w-[150px] relative h-[42px] text-gray-700 border border-gray-300 rounded-lg text-xs grow-[0.35]',
                        {
                          'border-[#EEE] bg-gray-100': logListIsLoading || logListIsFetching,
                        }
                      )}
                      classNames={{
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        input: (input: HTMLInputElement) => {
                          if (input && tempFilterState.initialDate && tempFilterState.endDate) {
                            input.title =
                              dayjs(tempFilterState.initialDate).format(DATE_DISPLAY_FORMAT) +
                              ' - ' +
                              dayjs(tempFilterState.endDate).format(DATE_DISPLAY_FORMAT);
                          }

                          return 'w-[calc(100%_-_25px)] h-full text-xs pl-3 truncate text-textcolor disabled:placeholder:!text-[#D1D5DB] disabled:!text-[#D1D5DB]';
                        },
                      }}
                      disabled={logListIsLoading || logListIsFetching}
                    />
                    {/* Button to filter */}
                    {showSkeletonLoading ? (
                      <Skeleton
                        height={42}
                        baseColor="#f3f3f3"
                        highlightColor="white"
                        containerClassName="flex h-[42px]"
                        className="!rounded-lg"
                      />
                    ) : (
                      <>
                        <button
                          className={classNames(
                            ' bg-primary disabled:bg-[#D1D5DB] text-white py-2 px-4 rounded-lg flex justify-center items-center w-full h-[42px] lg:max-w-[56px]'
                          )}
                          onClick={handleClickSubmitFilters}
                          disabled={!hasFilterStatesToSubmit || logListIsLoading || logListIsFetching}
                        >
                          {logListIsLoading || logListIsFetching ? (
                            <LoadingSpinIcon className=" w-5 !text-primary md:!text-buttontextcolor " />
                          ) : (
                            <SearchIcon className="!text-buttontextcolor" />
                          )}
                        </button>
                        {hasFilterStatesSubmitted && (
                          <button
                            className="lg:max-w-fit bg-primary text-xs font-bold leading-4 tracking-[0.6px] text-white flex justify-center items-center gap-x-3  h-[42px] py-2 px-4 rounded-lg disabled:bg-[#D1D5DB]"
                            onClick={handleClearFilters}
                            disabled={logListIsLoading || logListIsFetching}
                          >
                            <span className="hidden min-[1060px]:inline-block">LIMPAR</span>
                            <DeleteIcon />
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          {showSkeletonLoading ? (
            <LoadingListSkeleton />
          ) : logListData?.data && logListData?.data.length <= 0 ? (
            <p className="w-full text-center text-[#595959] font-semibold">
              Nenhum log encontrado!
            </p>
          ) : (
            <TableContainer>
              {/* Desktop */}
              <table
                ref={tableContainerRef}
                className="w-full text-sm text-left text-gray-500 hidden md:table table-data"
              >
                <thead className="font-inter text-[10px] text-primary font-bold uppercase bg-gray-50 w-full">
                  <tr>
                    <th
                      scope="col"
                      className="h-full px-6 py-3 cursor-pointer hover:bg-gray-100 w-fit"
                      onClick={handleSortByColumn('entity')}
                    >
                      <span className="flex flex-row items-center gap-2 truncate">
                        Transação 
                        <span className={classNames('min-w-[12px]')}>
                          {orderAttribute === 'entity' && <ArrowWithCorrectDirection />}
                        </span>
                      </span>
                    </th>
                    <th
                      scope="col"
                      className="h-full px-6 py-3 cursor-pointer hover:bg-gray-100 w-fit"
                      onClick={handleSortByColumn('operation')}
                    >
                      <span className="flex flex-row items-center gap-2 w-full truncate">
                        Ação
                        <span className={classNames('min-w-[12px]')}>
                          {orderAttribute === 'operation' && <ArrowWithCorrectDirection />}
                        </span>
                      </span>
                    </th> 
                    <th
                      scope="col"
                      className="h-full px-6 py-3 cursor-pointer hover:bg-gray-100 w-fit"
                      onClick={handleSortByColumn('email')}
                    >
                      <span className="flex flex-row items-center gap-2 w-full truncate">
                        USUÁRIO
                        <span className={classNames('min-w-[12px]')}>
                          {orderAttribute === 'email' && <ArrowWithCorrectDirection />}
                        </span>
                      </span>
                    </th>
                    <th
                      scope="col"
                      className="h-full px-6 py-3 cursor-pointer hover:bg-gray-100 w-fit"
                      onClick={handleSortByColumn('dateEvent')}
                    >
                      <span className="flex flex-row items-center gap-2 w-full truncate">
                        DATA
                        <span className={classNames('min-w-[12px]')}>
                          {orderAttribute === 'dateEvent' && <ArrowWithCorrectDirection />}
                        </span>
                      </span>
                    </th>
                    <th scope="col" className="h-full px-6 py-3 w-fit table-actions">
                      <span className="flex flex-row items-center gap-2 truncate">AÇŌES</span>
                    </th>           
                  </tr>
                </thead>
                <tbody className="w-full">
                  {logListData?.data.map((log: ILog, logIndex: number) => (
                    <tr
                      className="bg-white border-b hover:bg-gray-100 w-full h-[60px] text-xs"
                      key={`company-${log.id}-row-${logIndex}`}
                    >
                      <td className="px-6 py-4 font-normal whitespace-nowrap w-fit">
                        {log.entity}
                      </td>
                      <td className="px-6 py-4 font-normal whitespace-nowrap w-fit">
                        {log.operation}
                      </td>
                      <td className="px-6 py-4 font-normal whitespace-nowrap w-fit">{log.email}</td>
                      <td className="px-6 py-4 whitespace-nowrap w-fit">
                        {log.dateEvent && dayjs(log.dateEvent).format(DATE_TIME_DISPLAY_FORMAT)}
                      </td>
                      <td className="px-6 py-4 w-fit max-w-[106px] gap-x-2 flex justify-between font-medium whitespace-nowrap table-actions">
                        <button 
                          onClick={() =>
                            setLogDetailsModal({ display: true, log: log })
                          }
                          className=" group">
                          <OpenEyeIcon />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {/* Mobile */}
              <div className="flex flex-col gap-y-[18px] md:hidden">
                {logListData?.data?.map((log, logIndex) => (
                  <div
                    className="bg-white px-[20px] py-[16px] overflow-hidden flex flex-col justify-between gap-y-[9px] shadow-container rounded-[5px]"
                    key={`company-card-${log.id}-row-${logIndex}-mobile`}
                  >
                    <div className="flex flex-row justify-between w-full">
                      <div className="font-inter font-medium text-gray-500 text-xs/[20px]">
                        <span className="uppercase">
                          <p>
                            <b className="text-primary">Transação: </b>
                            {log.entity}
                          </p>
                          <p>
                            <b className="text-primary">Ação: </b>
                            {log.operation}
                          </p>
                          <p>
                            <b className="text-primary">Usuário: </b>
                            {log.email}
                          </p>
                          <p>
                            <b className="text-primary">Data: </b>
                            {log.dateEvent && dayjs(log.dateEvent).format(DATE_TIME_DISPLAY_FORMAT)}
                          </p>
                        </span>
                      </div>
                      <div className="flex flex-row gap-x-2.5 h-fit">
                        <button
                          onClick={() => alert('Não implementado')}
                          className={classNames({
                            group: true,
                          })}
                        >
                          <OpenEyeIcon className="group-enabled:hover:text-secondary" />
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </TableContainer>
          )}
          {!showSkeletonLoading && (
            <NavigationSelector
              totalResults={logListData?.total || 0}
              pageSize={pageSize}
              currentPage={page}
              onChangePageSize={setPageSize}
              onPageChange={setPage}
              isLoading={logListIsLoading || logListIsFetching}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default List;
