//Thrid-pary
// App
import api from '../api';
import { routes } from '../../../constants/api';
import { IResponseWithData } from 'interfaces/apiTypes';
import { ICreateSalePayload } from 'interfaces/saleTypes';
import { AxiosResponse } from 'axios';
import { IExportReportsParams, IExportReportsResponse } from 'interfaces/companyTypes';

export async function createSale(
  data: ICreateSalePayload  
): Promise<any> {
  const result = await api.auth.post<{ message: string }>(routes.v1.company.create, {
    ...data,
    accessLevel: 2,
  });

  return result.data;
}

export async function exportReports({
  filter,
  initialDate,
  endDate,
  companyId,
  report,
  type,
  clientId,
  ...filters
}: IExportReportsParams): Promise<any> {
  const queryParams = {
    ...filters,
    filter,
    init: initialDate,
    final: endDate,
    report,
    type,
    ...(clientId ? { clientId: clientId } : {}),
  };
    const {data } : AxiosResponse<IResponseWithData<IExportReportsResponse>> = await api.auth.get(
      routes.v1.company.exportExcelPdf.replace(':companyId', String(companyId)),
      {
        params: queryParams,
      }
    );
    return data.response.body ? data.response.body : data.response;
}