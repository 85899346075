import UserList from './UserList';
import ChangePassword from './ChangePassword';
import EditUser from './EditUser';

export { UserList };
export { ChangePassword };
export { EditUser };

export default {
  UserList,
  ChangePassword,
  EditUser,
};
