export interface ITotalValueDisplayText { 
  title: string;
  spanClassName?: string;
}

function TotalValueDisplayText({title, spanClassName} : ITotalValueDisplayText ){
  return (
    <span 
        className={spanClassName ||"text-lg text-center"}>
            { title || 'Total do período selecionado'}
    </span>

  )
}

export default TotalValueDisplayText