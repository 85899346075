import { ArrowIcon } from 'assets/icons';
import classNames from 'classnames';
import  { MouseEventHandler, useMemo } from 'react';

type orderType = 'asc' | 'desc'
interface ITableHeaderCell<TOrderAttribute> {
  keyIndex: number;
  order: orderType;
  orderAttribute: TOrderAttribute;
  handleSortByColumn: (key: TOrderAttribute | null) => MouseEventHandler<never> | undefined;
  title: string;
  keyString: TOrderAttribute | null;
}

function TableHeaderCell<TOrderAttribute>({
  keyIndex,
  order,
  orderAttribute,
  handleSortByColumn,
  title,
  keyString,
} : ITableHeaderCell<TOrderAttribute>) {

    /**
   * Returns a memoized arrow component with the correct direction based on the 'order' prop.
   *
   * @param {string} order - The order prop indicating the direction ('asc' or 'desc').
   * @returns {React.Component} - The memoized Arrow component.
   */
    const ArrowWithCorrectDirection = useMemo(
      () =>
        function Arrow({ className }: { className?: string | Record<string, unknown> }) {
          return (
            <ArrowIcon
              className={classNames(
                {
                  'rotate-180': order === 'asc', // Adds the 'rotate-180' class if the 'order' prop is 'asc'
                },
                className
              )}
            />
          );
        },
      [order] // Dependency for useMemo, the component will be recomputed when the 'order' prop changes
    );  

  return (
    <th
      scope="col"
      className="h-full px-6 py-3 cursor-pointer hover:bg-gray-100 w-fit"
      onClick={handleSortByColumn(keyString)}
      key={keyIndex}
    >
      <span className="flex flex-row items-center gap-2 truncate">
        {title}
        <span className={classNames('min-w-[12px]')}>
          {orderAttribute === keyString && <ArrowWithCorrectDirection />}
        </span>
      </span>
    </th>
  );
}

export default TableHeaderCell;
