import classNames from 'classnames';

function MarkEmailReadIcon({ className }: { className?: string }) {
  return (
    <svg
      width="132"
      height="113"
      viewBox="0 0 132 113"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames('text-secondary', className)}
    >
      <path
        d="M113 0H13C6.125 0 0.5625 5.625 0.5625 12.5L0.5 87.5C0.5 94.375 6.125 100 13 100H63V87.5H13V25L63 56.25L113 25V56.25H125.5V12.5C125.5 5.625 119.875 0 113 0ZM63 43.75L13 12.5H113L63 43.75ZM96.375 112.5L74.25 90.375L83.0625 81.5625L96.3125 94.8125L122.812 68.3125L131.75 77.125L96.375 112.5Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default MarkEmailReadIcon;
