import classNames from 'classnames';

function GraphIcon({ className }: { className?: string | Record<string, unknown> }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      className={classNames('!text-primary', className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.875 17.5H17.75V16.25H1.5V0H0.25V16.875L0.875 17.5ZM2.75 14.375V4.375L3.375 3.75H5.875L6.5 4.375V14.375L5.875 15H3.375L2.75 14.375ZM5.25 13.75V5H4V13.75H5.25ZM12.75 1.875V14.375L13.375 15H15.875L16.5 14.375V1.875L15.875 1.25H13.375L12.75 1.875ZM15.25 2.5V13.75H14V2.5H15.25ZM7.75 14.375V6.875L8.375 6.25H10.875L11.5 6.875V14.375L10.875 15H8.375L7.75 14.375ZM10.25 13.75V7.5H9V13.75H10.25Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default GraphIcon;
