/* eslint-disable import/no-named-as-default */
/* eslint-disable react-hooks/rules-of-hooks */
// Third-party
import { Navigate, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

//App
import {
  ArrowIcon,
  MoneyIcon,
  OpenEyeIcon,
} from 'assets/icons';
import LoadingListSkeleton from './components/LoadingListSkeleton';
import { PAGES_ROUTES } from 'constants/routes';
import { useExportReports, useGetCompanySalesList } from 'store/server/company/queries';
import {
  IComplementService,
  ISale,
  ISaleListOrderAttribute,
  PaymentEnum,
  PaymentEnumCompare,
} from 'interfaces/companyTypes';
import { DateType, DateValueType } from 'react-tailwindcss-datepicker';
import usePersistedState from 'hooks/usePersistedState';
import useCompanySelectedStore from 'store/client/companySelected/useCompanySelectedStore';
import { selectCompanySelected } from 'store/client/companySelected/selectors';
import { convertToBrazilianCurrencyFormat } from 'utils/currency.utils';
import PaymentModal from 'components/PaymentModal';
import ListPaymentsModal from 'components/ListPaymentsModal';
import normalizeJsonStringUtil from 'utils/normalizeJsonString.util';
import DeleteIconTable from 'assets/icons/DeleteTable.icon';
import useNotificationStore from 'store/client/notification/useNotificationStore';
import { selectAddNotification } from 'store/client/notification/selectors';
import PageHeader from 'components/PageHeader/components/PageHeader';
import TableContainer from 'components/TableContainer';
import NavigationSelector from 'components/NavigationSelector';
import ExcludeSaleModal from 'components/ExcludeSaleModal';
import { exportPdf } from 'utils/pdf.utils';
import { dateUtils } from 'utils/formatDate';
import { saleTypeEnumCompare } from 'interfaces/saleTypes';
import useFilterStates from 'hooks/useFilterStates';
import { PAGINATION } from 'constants/clientParams';
import getMonthStartAndEndUtil from 'utils/getMonthStartAndEnd.util';
import { exportExcel } from 'utils/excelExport.utils';
import { screenConfig } from 'utils/screenConfig.util';
import { ORDER_ATTRIBUTTES, ascDescType } from '../../../../constants/orderAttributtes/index'
import TransactionsIcon from 'assets/icons/Transactions.icon';
import TransactionsFilterBar from 'components/TransactionsFilterBar';

const { ASC, DESC } = ORDER_ATTRIBUTTES
const { DATE_DISPLAY_FORMAT, DATE_TIME_DISPLAY_FORMAT } = dateUtils.constants;
const { MEDIUM_SCREEN_SIZE } = screenConfig.constants
const TOTAL_VALUE_DISPLAY_COLOR = '#3865BD';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/Sao_Paulo');

function List() {
  /*
  **** Component organization ****

   └── Declaration of generic hooks (e.g., useNavigate)
   └── State declaration
   └── Side effects (e.g., useEffect)
   └── Memoization (e.g., useMemo)
   └── Handlers (e.g., useCallback)
   └── JSX
   */

  // └── Declaration of generic hooks (e.g., useNavigate)
  const navigate = useNavigate();
  dayjs.extend(utc);
  const tableContainerRef = useRef<HTMLTableElement>(null);
  // └── State declaration
    // Selected table row
    const [selectedRowId, setSelectedRowId] = useState<null|number>(null);

  // Pagination states
  const [page, setPage] = usePersistedState<number>('salesList.page', PAGINATION.START_PAGE_NUM);
  const [pageSize, setPageSize] = usePersistedState<number>('salesList.pageSize', 10);

  const companySelected = useCompanySelectedStore(selectCompanySelected);
  if (!companySelected) return <Navigate to={'/'} replace />;
  const complementServiceArray: IComplementService[] = companySelected?.companyType
    ?.complementService
    ? JSON.parse(
        normalizeJsonStringUtil(
          `${companySelected?.companyType?.complementService?.replace(/\n/g, '')}`
        )
      )
    : [];

  // Sort states
  const [orderAttribute, setOrderAttribute] = useState<ISaleListOrderAttribute | string>(
    'dateEvent'
  );
  const [order, setOrder] = useState<ascDescType>(ASC);

  // └── State declaration
  const firstDayOfMonth = useMemo(() => getMonthStartAndEndUtil().initialDate, []);
  const lastDayOfMonth = useMemo(() => getMonthStartAndEndUtil().finalDate, []);

  const {
    tempFilterState,
    submittedFilterState,
    hasFilterStatesToSubmit,
    hasFilterStatesSubmitted,
    changeTempState,
    submitStates,
    cleanStates,
  } = useFilterStates<{
    search: string | null;
    initialDate: DateType;
    endDate: DateType;
  }>({
    filtersStateKey: 'salesListFilter',
    initalState: {
      search: null,
      initialDate: firstDayOfMonth,
      endDate: lastDayOfMonth,
    },
  });

  const [loadingReportExports, setLoadingReportExports] = useState<boolean>(false);
  const [loadingPdfExports, setLoadingPdfExports] = useState<boolean>(false);

  const addNotification = useNotificationStore(selectAddNotification);

  const [openAddPaymentModal, setOpenAddPaymentModal] = useState<{
    display: boolean;
    sale: ISale | null;
  }>({ display: false, sale: null });

  const [openListPaymentsModal, setOpenListPaymentsModal] = useState<{
    display: boolean;
    sale: ISale | null;
  }>({ display: false, sale: null });

  const [openRemovePaymentsModal, setOpenRemovePaymentsModal] = useState<{
    display: boolean;
    sale: ISale | null;
    customConfirmationMessage?: string;
  }>({ display: false, sale: null });

  const {
    data: salesListData,
    isLoading: salesListIsLoading,
    isFetching: salesListIsFetching,
  } = useGetCompanySalesList({
    start: page,
    size: pageSize,
    field: orderAttribute,
    order,
    filter: submittedFilterState.search,
    companyId: companySelected.id,
    dateInit: submittedFilterState.initialDate,
    dateFinal: submittedFilterState.endDate,
  });

  const { refetch: excelRefetch, isRefetching: isExcelRefetching } = useExportReports({
    size: pageSize,
    start: page * pageSize,
    filter: submittedFilterState?.search,
    initialDate: submittedFilterState?.initialDate,
    endDate: submittedFilterState?.endDate,
    companyId: companySelected.id,
    report: 'sale',
    type: 'xls',
  });

  const { refetch: pdfRefetch, isRefetching: isPDFRefetching} =  useExportReports({
    size: pageSize,
    start: page * pageSize,
    filter: submittedFilterState?.search,
    initialDate: submittedFilterState?.initialDate,
    endDate: submittedFilterState?.endDate,    
    companyId: companySelected.id, 
    report: 'sale',
    type: 'pdf'
  });

  // └── Side effects (e.g., useEffect)

  // └── Handlers (e.g., useCallback)

  const handleSortByColumn = useCallback(
    (columnName: ISaleListOrderAttribute | string, orderSelected?: ascDescType) => () => {
      if (orderAttribute !== columnName) {
        setOrder(ASC); // If the sort field is different from the clicked column, set the order to 'asc'
      } else {
        setOrder(orderSelected ? orderSelected : order === ASC ? DESC : ASC); // If the sort field is the same as the clicked column, toggle between 'asc' and 'desc' order
      }
      setOrderAttribute(columnName); // Set the sort field to the clicked column
    },
    [orderAttribute, order]
  );

  const checkIfShowCards = useCallback(() => {
    if (window.innerWidth < MEDIUM_SCREEN_SIZE) {
      orderAttribute !== 'dateEvent' && setOrderAttribute('dateEvent');
      order !== ASC && setOrder(ASC);
    }
  }, [order, orderAttribute]);

  useEffect(() => {
    window.removeEventListener('resize', checkIfShowCards);
    window.addEventListener('resize', checkIfShowCards);

    return () => {
      window.removeEventListener('resize', checkIfShowCards);
    };
  }, [checkIfShowCards]);

  /**
   * Returns a memoized arrow component with the correct direction based on the 'order' prop.
   *
   * @param {string} order - The order prop indicating the direction ('asc' or 'desc').
   * @returns {React.Component} - The memoized Arrow component.
   */
  const ArrowWithCorrectDirection = useMemo(
    () =>
      function Arrow({ className }: { className?: string | Record<string, unknown> }) {
        return (
          <ArrowIcon
            className={classNames(
              {
                'rotate-180': order === ASC, // Adds the 'rotate-180' class if the 'order' prop is 'asc'
              },
              className
            )}
          />
        );
      },
    [order] // Dependency for useMemo, the component will be recomputed when the 'order' prop changes
  );

  const showSkeletonLoading = useMemo(
    () => !salesListData?.result.data && salesListIsLoading,
    [salesListData, salesListIsLoading]
  );

  const handleClearFilters = useCallback(() => {
    setPage(PAGINATION.START_PAGE_NUM);
    cleanStates();
  }, [setPage, cleanStates]);

  const handleCloseModal = () => {
    setOpenRemovePaymentsModal({ display: false, sale: null });
  };

  const handleExportReportsExcel = useCallback(() => {
    exportExcel({
        refetch: excelRefetch,
        setLoading: setLoadingReportExports,
        addNotification,
        filenamePrefix: 'lista_de_vendas',        
    });
  }, [excelRefetch, setLoadingReportExports, addNotification]);

  const handleExportReportsPdf = useCallback(async () => {
    exportPdf({
      refetch: pdfRefetch,
      setLoading: setLoadingPdfExports,
      addNotification,
      fileName: `ECVFinancy_lista_de_vendas_`,
      company:{
        name: companySelected.name,
        doc : companySelected.cnpj
      },
      docTitle: `Relatório Lista de Vendas`,
      pdfPeriod: `${dayjs(submittedFilterState.initialDate).format(DATE_DISPLAY_FORMAT)} - ${dayjs(submittedFilterState.endDate).format(DATE_DISPLAY_FORMAT)}`
    })
  },
    [pdfRefetch, setLoadingPdfExports, addNotification, submittedFilterState.initialDate, submittedFilterState.endDate],
  )
  

  const handleChangeSearchFilter = useCallback(
    async (event: { target: { value: string } }) => changeTempState('search', event.target.value),
    [changeTempState]
  );

  const handleChangeDateFilter = useCallback(
    (value: DateValueType) => {
      changeTempState('initialDate', value?.startDate || firstDayOfMonth);
      changeTempState('endDate', value?.endDate || lastDayOfMonth);
    },
    [changeTempState, firstDayOfMonth, lastDayOfMonth]
  );

  const handleClickSubmitFilters = useCallback(() => {
    if (hasFilterStatesToSubmit) {
      setPage(PAGINATION.START_PAGE_NUM);
      submitStates();
    }
  }, [hasFilterStatesToSubmit, setPage, submitStates]);


  const handleRowClick = (clientId:number) => {    
    setSelectedRowId(clientId === selectedRowId ? null : clientId);
  };


  const transactionFilterBarProps = {
    showSkeletonLoading: showSkeletonLoading,
    isFetching: salesListIsFetching,
    isLoading: salesListIsLoading,
    tempFilterState: tempFilterState,
    isExportingExcel: loadingReportExports,
    isExportingPDF: loadingPdfExports,
    handleChangeSearchFilter: handleChangeSearchFilter,
    handleChangeDateFilter: handleChangeDateFilter,
    handleClearFilters: handleClearFilters,
    handleExportReportsExcel: handleExportReportsExcel,
    handleExportReportsPdf: handleExportReportsPdf,
    hasFilterStatesSubmitted: hasFilterStatesSubmitted,
    hasFilterStatesToSubmit: hasFilterStatesToSubmit,
    handleClickSubmitFilters: handleClickSubmitFilters,
    redirectToNewRegister: PAGES_ROUTES.authenticated.transactions.sale.create,
  };

  const navigationSelectorProps = {
    totalResults: salesListData?.result?.total || 0,
    pageSize: pageSize,
    currentPage: page,
    onChangePageSize: setPageSize,
    onPageChange: setPage,
    isLoading: salesListIsLoading || salesListIsFetching,
    resetSelection: setSelectedRowId
  };

  const pageHeaderProps = {
    iconTextTitleProps :{
      iconProps: {
        iconJSX: <TransactionsIcon className="w-[45px] h-[45px] !text-secondary" />,
      },
      textProps: {
        content: 'Lista de Vendas',
      },
    },
    totalValueDisplayProps :[
      {
        displayProps: {
          isLoadingData: salesListIsLoading,
          skeletonProps: {
            useSkeleton: true,
          },
          displayedValueProps: {
            valueToDisplay: salesListData?.totalPeriod,
            useCurrencyMask: true,
            textColor: TOTAL_VALUE_DISPLAY_COLOR
          },
        },
        textProps: {
          title: 'Total do período selecionado',
        },
      },
      {
        displayProps: {
          isLoadingData: salesListIsLoading,
          skeletonProps: {
            useSkeleton: true,
          },
          displayedValueProps: {
            valueToDisplay: salesListData?.totalMonth,
            useCurrencyMask: true,
            textColor: TOTAL_VALUE_DISPLAY_COLOR
          },
        },
        textProps: {
          title: 'Total do mês',
        },
      },
    ]
  }
 
 

  return (
    <>
      {openAddPaymentModal.display && openAddPaymentModal.sale !== null && (
        <PaymentModal setOpenModal={setOpenAddPaymentModal} openModal={openAddPaymentModal} />
      )}
      {openListPaymentsModal.display && openListPaymentsModal.sale !== null && (
        <ListPaymentsModal
          setOpenModal={setOpenListPaymentsModal}
          openModal={openListPaymentsModal}
        />
      )}
      {openRemovePaymentsModal.display && openRemovePaymentsModal.sale !== null && (
        <ExcludeSaleModal
          saleId={openRemovePaymentsModal.sale.id}
          title="Remover Venda"
          description={'Tem certeza que deseja remover essa venda?'}
          onClose={handleCloseModal}
          useInlineData={true}
          inlineData={[
            {
              name: 'Data',
              value: openRemovePaymentsModal?.sale?.dateEvent,
              formatter: (e) => dayjs(e).format(DATE_TIME_DISPLAY_FORMAT),
            },
            {
              name: 'Valor',
              value: openRemovePaymentsModal?.sale?.amount,
              formatter: (value) => convertToBrazilianCurrencyFormat(`${value}`),
            },
            {
              name: 'Cliente',
              value: openRemovePaymentsModal?.sale?.client,
            },
          ]}
        />
      )}
      <div
        className={classNames('h-full', {
          'overflow-hidden max-h-[calc(100vh-64px)] md:max-h-[calc(100vh-71px)]':
            showSkeletonLoading,
        })}
      >
        <div
          className={classNames(
            'pt-5 md:pt-[42px] p-[27px] md:p-8 w-full min-h-[calc(100vh-64px)] md:min-h-[calc(100vh-71px)]',
            {
              'overflow-hidden !h-screen': showSkeletonLoading,
            }
          )}
        >
          {/* HEADER */}
          <PageHeader
            {...pageHeaderProps}
          />
          <TransactionsFilterBar 
            {...transactionFilterBarProps}
          />          
          {showSkeletonLoading ? (
            <LoadingListSkeleton />
          ) : salesListData?.result.data && salesListData?.result.data?.length <= 0 ? (
            <p className="w-full text-center text-[#595959] font-semibold">
              Nenhuma venda encontrada!
            </p>
          ) : (
            <TableContainer>
              {/* Desktop */}
              <table
                ref={tableContainerRef}
                className="w-full text-sm text-left text-gray-500 hidden md:table table-data"
              >
                <thead className="font-inter text-[10px] text-primary font-bold uppercase bg-gray-50 w-full">
                  <tr>
                    <th
                      scope="col"
                      className="h-full px-6 py-3 cursor-pointer hover:bg-gray-100 w-fit"
                      onClick={handleSortByColumn('dateEvent')}
                    >
                      <span className="flex flex-row items-center gap-2 truncate">
                        DATA VENDA
                        <span className={classNames('min-w-[12px]')}>
                          {orderAttribute === 'dateEvent' && <ArrowWithCorrectDirection />}
                        </span>
                      </span>
                    </th>
                    <th
                      scope="col"
                      className="h-full px-6 py-3 cursor-pointer hover:bg-gray-100 w-fit"
                      onClick={handleSortByColumn('quantity')}
                    >
                      <span className="flex flex-row items-center gap-2 truncate">
                        QUANTIDADE
                        <span className={classNames('min-w-[12px]')}>
                          {orderAttribute === 'quantity' && <ArrowWithCorrectDirection />}
                        </span>
                      </span>
                    </th>
                    <th
                      scope="col"
                      className="h-full px-6 py-3 cursor-pointer hover:bg-gray-100 w-fit"
                      onClick={handleSortByColumn('amount')}
                    >
                      <span className="flex flex-row items-center gap-2 truncate">
                        VALOR UNITÁRIO
                        <span className={classNames('min-w-[12px]')}>
                          {orderAttribute === 'amount' && <ArrowWithCorrectDirection />}
                        </span>
                      </span>
                    </th>
                    <th
                      scope="col"
                      className="h-full px-6 py-3 cursor-pointer hover:bg-gray-100 w-fit"
                      onClick={handleSortByColumn('totalAmount')}
                    >
                      <span className="flex flex-row items-center gap-2 truncate">
                        VALOR TOTAL
                        <span className={classNames('min-w-[12px]')}>
                          {orderAttribute === 'totalAmount' && <ArrowWithCorrectDirection />}
                        </span>
                      </span>
                    </th>
                    <th
                      scope="col"
                      className="h-full px-6 py-3 cursor-pointer hover:bg-gray-100 w-fit"
                      onClick={handleSortByColumn('amountPay')}
                    >
                      <span className="flex flex-row items-center gap-2 truncate">
                        VALOR RECEBIDO
                        <span className={classNames('min-w-[12px]')}>
                          {orderAttribute === 'amountPay' && <ArrowWithCorrectDirection />}
                        </span>
                      </span>
                    </th>
                    <th
                      scope="col"
                      className="h-full px-6 py-3 cursor-pointer hover:bg-gray-100 w-fit"
                      onClick={handleSortByColumn('amountPending')}
                    >
                      <span className="flex flex-row items-center gap-2 truncate">
                        VALOR PENDENTE
                        <span className={classNames('min-w-[12px]')}>
                          {orderAttribute === 'amountPending' && <ArrowWithCorrectDirection />}
                        </span>
                      </span>
                    </th>
                    <th
                      scope="col"
                      className="h-full px-6 py-3 cursor-pointer hover:bg-gray-100 w-fit"
                      onClick={handleSortByColumn('service')}
                    >
                      <span className="flex flex-row items-center gap-2 truncate">
                        SERVIÇOS
                        <span className={classNames('min-w-[12px]')}>
                          {orderAttribute === 'service' && <ArrowWithCorrectDirection />}
                        </span>
                      </span>
                    </th>
                    <th
                      scope="col"
                      className="h-full px-6 py-3 cursor-pointer hover:bg-gray-100 w-fit"
                      onClick={handleSortByColumn('client')}
                    >
                      <span className="flex flex-row items-center gap-2 truncate">
                        CLIENTE/CONTRATANTE
                        <span className={classNames('min-w-[12px]')}>
                          {orderAttribute === 'client' && <ArrowWithCorrectDirection />}
                        </span>
                      </span>
                    </th>
                    <th
                      scope="col"
                      className="h-full px-6 py-3 cursor-pointer hover:bg-gray-100 w-fit"
                      onClick={handleSortByColumn('typeclient')}
                    >
                      <span className="flex flex-row items-center gap-2 truncate">
                        TIPO DE CLIENTE
                        <span className={classNames('min-w-[12px]')}>
                          {orderAttribute === 'typeclient' && <ArrowWithCorrectDirection />}
                        </span>
                      </span>
                    </th>
                    <th
                      scope="col"
                      className="h-full px-6 py-3 cursor-pointer hover:bg-gray-100 w-fit"
                      onClick={handleSortByColumn('paymethod')}
                    >
                      <span className="flex flex-row items-center gap-2 truncate">
                        CONDIÇÃO DE RECEBIMENTO
                        <span className={classNames('min-w-[12px]')}>
                          {orderAttribute === 'paymethod' && <ArrowWithCorrectDirection />}
                        </span>
                      </span>
                    </th>
                    

                    {complementServiceArray.length > 0 &&
                      complementServiceArray.map((e, index: number) => {
                        return (
                          <th
                            scope="col"
                            className="h-full px-6 py-3 cursor-pointer hover:bg-gray-100 w-fit"
                            key={`${e.name}+${index}`}
                            onClick={handleSortByColumn(e.name)}
                          >
                            <span className="flex flex-row items-center gap-2 truncate">
                              {e.label.toUpperCase()}
                              <span className={classNames('min-w-[12px]')}>
                                {orderAttribute === e.name && <ArrowWithCorrectDirection />}
                              </span>
                            </span>
                          </th>
                        );
                      })}

                    <th
                      scope="col"
                      className="h-full px-6 py-3 cursor-pointer hover:bg-gray-100 w-fit"
                      onClick={handleSortByColumn('status')}
                    >
                      <span className="flex flex-row items-center gap-2 truncate">
                        STATUS
                        <span className={classNames('min-w-[12px]')}>
                          {orderAttribute === 'status' && <ArrowWithCorrectDirection />}
                        </span>
                      </span>
                    </th>

                    <th
                      scope="col"
                      id="table-actions"
                      className="h-full px-6 py-3 w-fit table-actions"
                    >

                      <span className="flex flex-row items-center gap-2 truncate">AÇÕES</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="w-full">
                  {salesListData?.result.data.map((salesForListing, salesForListingIndex) => (
                    <tr
                    className={classNames(
                      "bg-white border-b w-full h-[60px] text-xs text-black",
                      {
                        '!bg-white hover:!bg-gray-100 ': selectedRowId !== salesForListing.id,                      
                        '!bg-gray-300': selectedRowId === salesForListing.id,    
                      }

                    )}
                      key={`company-${salesForListing.id}-row-${salesForListingIndex}`}
                      onClick={() => handleRowClick(salesForListing.id)}
                    >
                      <td className="px-6 py-4 font-normal whitespace-nowrap w-fit">
                        {salesForListing.dateEvent &&
                          dayjs.utc(salesForListing.dateEvent).format(DATE_TIME_DISPLAY_FORMAT)}
                      </td>
                      <td className="px-6 py-4 font-normal whitespace-nowrap w-fit">
                        {salesForListing?.quantity || '0'}
                      </td>
                      <td className="px-6 py-4 font-normal whitespace-nowrap w-fit">
                        {convertToBrazilianCurrencyFormat(salesForListing?.amount || '0')}
                      </td>
                      <td className="px-6 py-4  whitespace-nowrap w-fit font-semibold text-black">
                        {convertToBrazilianCurrencyFormat(salesForListing?.totalAmount || '0')}
                      </td>
                      <td className="px-6 py-4 font-normal whitespace-nowrap w-fit text-secondary">
                        {convertToBrazilianCurrencyFormat(salesForListing?.amountPay || '0')}
                      </td>
                      <td className="px-6 py-4 font-normal whitespace-nowrap w-fit text-[#ff5900]">
                        {convertToBrazilianCurrencyFormat(salesForListing?.amountPending || '0')}
                      </td>
                      <td className="px-6 py-4 font-normal whitespace-nowrap w-fit">
                        {salesForListing?.service || 'Serviço não informado'}
                      </td>
                      <td className="px-6 py-4 font-normal whitespace-nowrap w-fit">
                        {salesForListing?.saleType === saleTypeEnumCompare.PRIVATE
                          ? salesForListing.name
                          : salesForListing?.client || 'Cliente não informado'}
                      </td>
                      <td className="px-6 py-4 font-normal whitespace-nowrap w-fit">
                        {salesForListing?.saleType === saleTypeEnumCompare.PRIVATE
                          ? 'Particular'
                          : salesForListing?.typeclient || 'Tipo de cliente não descrito'}
                      </td>
                      <td className="px-6 py-4 font-normal whitespace-nowrap w-fit">
                        {salesForListing?.paymethod || 'Método de pagamento não descrito'}
                      </td>
                      
                      {complementServiceArray.length > 0 &&
                        complementServiceArray.map((e: IComplementService, index: number) => {
                          return (
                            <td
                              className="px-6 py-4 font-normal whitespace-nowrap w-fit"
                              key={`${e.name}${index}`}
                            >
                              {salesForListing[e.name]}
                            </td>
                          );
                        })}

<td
                        className={classNames(
                          'px-6 items-center font-normal whitespace-nowrap w-fit',
                          {
                            'text-green-800 font-medium': salesForListing?.status ===  PaymentEnumCompare.PAID,
                            'text-yellow-800 font-medium': salesForListing?.status === PaymentEnumCompare.PARTIAL,
                            'text-red-800 font-medium': salesForListing?.status === PaymentEnumCompare.PENDING,                            
                          }
                        )}
                      >
                        <span
                          className={classNames('rounded-[10px] px-[10px] py-[5px] items-center', {
                            'bg-green-100': salesForListing?.status ===  PaymentEnumCompare.PAID,
                            'bg-yellow-100': salesForListing?.status === PaymentEnumCompare.PARTIAL,
                            'bg-red-100': salesForListing?.status === PaymentEnumCompare.PENDING,          
                            })}
                        >
                          {PaymentEnum[salesForListing?.status as keyof typeof PaymentEnum] ||
                            'Status não descrito'}
                        </span>
                      </td>  
                      <td
                        id="table-actions"
                        className="px-6 py-4 w-fit max-w-[130px] flex gap-x-[12px] justify-between font-medium whitespace-nowrap pr-[36px] table-actions"
                      >
                        {
                          salesForListing?.status !== PaymentEnumCompare.PAID &&    
                          <button
                            onClick={() =>
                              setOpenAddPaymentModal({ display: true, sale: salesForListing })
                            }
                            className="group"
                          >
                            <MoneyIcon />
                          </button>
                        }
                        <button
                          onClick={() =>
                            setOpenListPaymentsModal({ display: true, sale: salesForListing })
                          }
                          className="group"
                        >
                          <OpenEyeIcon className="" />
                        </button>
                        <button
                          onClick={() =>
                            setOpenRemovePaymentsModal({
                              display: true,
                              sale: salesForListing,
                              customConfirmationMessage: `Você tem certeza que deseja excluir a venda para o cliente: ${
                                salesForListing.client
                              }. No dia: ${dayjs
                                .utc(salesForListing.dateEvent)
                                .format(
                                  DATE_TIME_DISPLAY_FORMAT
                                )}. No valor de: ${convertToBrazilianCurrencyFormat(
                                salesForListing.totalAmount || '0'
                              )}?`,
                            })
                          }
                          className="group"
                        >
                          <DeleteIconTable className="group-enabled:hover:text-secondary" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {/* Mobile */}
              <div className="flex flex-col gap-y-[18px] md:hidden">
                {salesListData?.result.data.map((salesForListing, salesForListingIndex) => (
                  <div
                  className="bg-white px-[20px] py-[16px] overflow-hidden shadow-container rounded-[5px] w-full"
                    key={`company-card-${salesForListing.id}-row-${salesForListingIndex}-mobile`}
                  >
                    <div className="w-full flex flex-col gap-y-[10px]">
                    <div className="flex flex-row justify-between">
                        <p
                          className={classNames(
                            'w-fit h-fit rounded-[10px] px-[10px] my-[5px] pb-[2px] text-[10px]',
                            {
                              'bg-green-100 text-green-800 font-medium':
                                salesForListing?.status === PaymentEnumCompare.PAID ,
                              'bg-yellow-100 text-yellow-800 font-medium':
                                salesForListing?.status === PaymentEnumCompare.PARTIAL,
                              'bg-red-100 text-red-800 font-medium':
                                salesForListing?.status === PaymentEnumCompare.PENDING,
                            }
                          )}
                        >
                          {PaymentEnum[salesForListing?.status as keyof typeof PaymentEnum] ||
                            'Status não descrito'}
                        </p>
                        <div className="flex flex-row gap-x-2.5 h-fit">
                          {
                            salesForListing?.status !== PaymentEnumCompare.PAID &&                           
                              <button
                                onClick={() =>
                                  setOpenAddPaymentModal({ display: true, sale: salesForListing })
                                }
                                className="group"
                              >
                                <MoneyIcon />
                              </button>
                          }
                          <button
                            onClick={() =>
                              setOpenListPaymentsModal({ display: true, sale: salesForListing })
                            }
                            className={classNames({
                              group: true,
                            })}
                          >
                            <OpenEyeIcon className="group-enabled:hover:text-secondary" />
                          </button>
                          <button
                            onClick={() =>
                              setOpenRemovePaymentsModal({
                                display: true,
                                sale: salesForListing,
                                customConfirmationMessage: `Você tem certeza que deseja excluir a venda para o cliente: ${
                                  salesForListing.client
                                }. No dia :${dayjs
                                  .utc(salesForListing.dateEvent)
                                  .format(
                                    DATE_TIME_DISPLAY_FORMAT
                                  )}. No valor de: ${convertToBrazilianCurrencyFormat(
                                  salesForListing.totalAmount
                                )}?`,
                              })
                            }
                            className="group"
                          >
                            <DeleteIconTable className=" group-enabled:hover:text-secondary" />
                          </button>
                        </div>
                      </div>
                      <div className="flex flex-row w-full font-inter font-medium text-gray-500 text-xs/[20px]">
                        <span className="uppercase w-full">                        
                          <p>
                            <b className="text-primary">Data Venda: </b>
                            {salesForListing.dateEvent &&
                              dayjs.utc(salesForListing.dateEvent).format(DATE_TIME_DISPLAY_FORMAT)}
                          </p>
                          <b className="text-primary">Quantidade: </b>{' '}
                          {salesForListing?.quantity || '0'}
                          <p>
                            <b className="text-primary">Valor Unitário: </b>
                            {convertToBrazilianCurrencyFormat(salesForListing?.amount || '0')}
                          </p>
                          <p>
                            <b className="text-primary">Valor Total: </b>
                            {convertToBrazilianCurrencyFormat(salesForListing?.totalAmount || '0')}
                          </p>
                          <p>
                            <b className="text-primary">Valor Recebido: </b>
                            {convertToBrazilianCurrencyFormat(salesForListing?.amountPay || '0')}
                          </p>
                          <p>
                            <b className="text-primary">Valor Pendente: </b>
                            {convertToBrazilianCurrencyFormat(
                              salesForListing?.amountPending || '0'
                            )}
                          </p>
                          <p>
                            <b className="text-primary">Serviços: </b>
                            {salesForListing?.service || 'Serviço não informado'}
                          </p>
                          <p>
                            <b className="text-primary">Cliente/Razão Social: </b>
                            {salesForListing?.saleType === 'PRIVATE'
                              ? salesForListing.name
                              : salesForListing?.client || 'Cliente não informado'}
                          </p>
                          <p>
                            <b className="text-primary">Tipo de cliente: </b>
                            {salesForListing?.saleType === saleTypeEnumCompare.PRIVATE
                              ? 'Particular'
                              : salesForListing?.typeclient || 'Tipo de cliente não descrito'}
                          </p>
                          <p>
                            <b className="text-primary">Condição de Recebimento: </b>
                            {salesForListing?.paymethod || 'Método de pagamento não descrito'}
                          </p>
                          <p>
                            <b className="text-primary">Status: </b>
                            {PaymentEnum[salesForListing?.status as keyof typeof PaymentEnum] ||
                              'Status não descrito'}
                          </p>
                          {complementServiceArray.length > 0 &&
                            complementServiceArray.map((e: IComplementService, index: number) => {
                              return (
                                <p key={`${e.name}+${index}`}>
                                  <b className="text-primary">{e.label}: </b>
                                  {salesForListing[e.name]}
                                </p>
                              );
                            })}
                        </span>
                      </div>                      
                    </div>
                  </div>
                ))}
              </div>
            </TableContainer>
          )}
          {!showSkeletonLoading && (
            <NavigationSelector
              {...navigationSelectorProps}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default List;
