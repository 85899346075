// Third-party
import { useQuery } from '@tanstack/react-query';

// App
import queriesKeys from './queriesKeys';
import { getClientById, listClient } from 'services/api/client';
import { IClient, IClientListParams } from 'interfaces/clientTypes';
import { removeCpfCnpjMask } from 'utils/mask.utils';
import { isNotEmailOrName } from 'utils/filters.utils';

export const useGetClientList = (companyId: number, params: IClientListParams) => {
  const { start, size, field, order, status } = params;
  let { filter } = params;
  const queryKey: (string | number)[] = [queriesKeys.clientList, start, size, field, order, companyId];

  if (filter) {
    if (isNotEmailOrName(filter)) {
      filter = removeCpfCnpjMask(filter);
      queryKey.push(filter);
    } else {
      queryKey.push(filter);
    }
  }

  if (status) {
    queryKey.push(status);
  }

  return useQuery({
    queryKey,
    queryFn: async () => {
      const client = await listClient(companyId, { ...params, filter });

      return client;
    },
    placeholderData: (previousData) => previousData,
  });
};

export const useGetClientById = (companyId: number, clientId: number) =>
  useQuery<IClient | null>({
    queryKey: [queriesKeys.clientForEdit, companyId, clientId],
    queryFn: async () => {
      const client = await getClientById(companyId, clientId);

      return client;
    },
  });
