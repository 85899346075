// Third-party
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// App
import Router from 'routes';
import { FileModal, ModalLoading, NotificationsProvider } from 'components';
import { removeDisplayedFile, selectFileToDisplay } from 'store/reducers/fileModalSlice';
import useLoadingStore from 'store/client/loading/useLoadingStore';
import { selectLoading } from 'store/client/loading/selectors';
import { useGetUser } from 'store/server/user/queries';

const App: React.FC = () => {
  /*
  **** Component organization ****

   └── Declaration of generic hooks (e.g., useNavigate)
   └── State declaration
   └── Side effects (e.g., useEffect)
   └── Memoization (e.g., useMemo)
   └── Handlers (e.g., useCallback)
   └── JSX
  */
  // └── Declaration of generic hooks (e.g., useNavigate)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // └── State declaration
  //   └── Global
  const isLoading = useLoadingStore(selectLoading);
  const fileToDisplay = useSelector(selectFileToDisplay);
  const { data: authenticatedUser, isLoading: isLoadingAuthenticatedUser } = useGetUser();
  //   └── Internal

  useEffect(() => {
    window.scrollTo(0, 0);
    const routeChanged = () => {
      navigate(window.location.pathname, { replace: true });
    };

    window.addEventListener('routeChanged', routeChanged);

    return () => {
      window.removeEventListener('routeChanged', routeChanged);
    };
  }, [navigate]);

  // └── Side effects (e.g., useEffect)

  return (
    <>
      {(isLoading || (!authenticatedUser && isLoadingAuthenticatedUser)) && <ModalLoading />}
      {fileToDisplay.fileUrl && fileToDisplay.fileMimeType && (
        <FileModal
          fileUrl={fileToDisplay.fileUrl}
          fileMimeType={fileToDisplay.fileMimeType}
          fileName={fileToDisplay.fileName}
          onClose={() => dispatch(removeDisplayedFile())}
        />
      )}
      <NotificationsProvider />
      <Router />
    </>
  );
};

export default App;
