export const titleBackgroundPlugin = {
  id: 'titleBackgroundPlugin',
  beforeDraw(chart: any) {
    const ctx = chart.ctx;
    const title = chart?.options?.plugins?.title;
    const chartArea = chart.chartArea;

    if (title?.display) {
      ctx.save();

      ctx.fillStyle = chart?.options?.plugins?.titleBackgroundPlugin?.backgroundColor ?? '#ffffff';
      const titleHeight = (title?.font?.size ?? 16) * 1.5;

      ctx.fillRect(
        chartArea.left - (chart?.options?.plugins?.titleBackgroundPlugin?.align ?? 155) - 50,
        0,
        chartArea.right -
          chartArea.left +
          (chart?.options?.plugins?.titleBackgroundPlugin?.align ?? 155) +
          500,
        titleHeight + 2
      );

      ctx.fillStyle = '#fff';
      ctx.font = `${title?.font?.size ?? 16}px Arial`;
      ctx.textBaseline = 'middle';
      ctx.textAlign = 'start';
      ctx.fillText(chart?.options?.plugins?.titleBackgroundPlugin?.text as string, 10, 15);

      ctx.restore();
    }
  },
};
