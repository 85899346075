import classNames from 'classnames';

function PasswordKeyIcon({ className }: { className?: string | Record<string, unknown> }) {
  return (
    <svg
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames('text-white ml-3', className)}
    >
      <path
        d="M16.4062 2.0625C15.3083 2.06225 14.2255 2.31919 13.2447 2.81274C12.2639 3.30629 11.4124 4.02272 10.7583 4.90462C10.1043 5.78652 9.66588 6.80937 9.4783 7.8912C9.29072 8.97303 9.35915 10.0838 9.67812 11.1344L1.5625 19.25V23.9375H6.25L14.3656 15.8219C15.3327 16.1154 16.3518 16.197 17.3533 16.0612C18.3548 15.9253 19.3152 15.5751 20.1691 15.0344C21.0231 14.4938 21.7504 13.7754 22.3016 12.9282C22.8527 12.081 23.2148 11.125 23.3631 10.1253C23.5113 9.12551 23.4423 8.10555 23.1608 7.13488C22.8792 6.16421 22.3917 5.26565 21.7314 4.50044C21.0712 3.73523 20.2537 3.12135 19.3347 2.70063C18.4158 2.27992 17.4169 2.06227 16.4062 2.0625V2.0625ZM16.4062 14.5625C15.8684 14.5622 15.3335 14.4827 14.8187 14.3266L13.9227 14.0547L13.2609 14.7164L10.7758 17.2016L9.69844 16.125L8.59375 17.2297L9.67109 18.307L8.43203 19.5461L7.35469 18.4688L6.25 19.5734L7.32734 20.6508L5.60312 22.375H3.125V19.8969L10.7828 12.2391L11.4453 11.5773L11.1734 10.6812C10.8395 9.58046 10.8612 8.40241 11.2354 7.31466C11.6097 6.22691 12.3173 5.28487 13.2578 4.62252C14.1983 3.96018 15.3237 3.61126 16.474 3.62541C17.6242 3.63957 18.7407 4.01606 19.6646 4.70134C20.5885 5.38663 21.2728 6.3458 21.6202 7.44242C21.9675 8.53905 21.9602 9.71728 21.5993 10.8095C21.2384 11.9018 20.5423 12.8524 19.61 13.5262C18.6776 14.2 17.5566 14.5626 16.4062 14.5625V14.5625Z"
        fill="currentColor"
      />
      <path
        d="M17.1875 9.875C18.0504 9.875 18.75 9.17545 18.75 8.3125C18.75 7.44956 18.0504 6.75 17.1875 6.75C16.3246 6.75 15.625 7.44956 15.625 8.3125C15.625 9.17545 16.3246 9.875 17.1875 9.875Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default PasswordKeyIcon;
