import Pagination from 'components/Pagination';
import Select from 'components/Select';
import React from 'react';


interface IResultNavigationProps {
  totalResults: number;
  pageSize: number;
  currentPage: number;
  onChangePageSize: (newSize: number) => void;
  onPageChange: (newPage: number) => void;
  resetSelection?: (resetSelection: null) => void
  isLoading: boolean;
}

const NavigationSelector: React.FC<IResultNavigationProps> = ({
  totalResults,
  pageSize,
  currentPage,
  onChangePageSize,
  onPageChange,
  isLoading,
  resetSelection
}) => {

  const handlePageChange = (state:number) => {
    onPageChange(state -1);
    if(resetSelection) resetSelection(null);
  }
  return (
    <div className="flex w-full justify-center md:justify-between items-center gap-x-2.5 md:mt-[37px] md:px-[39px] pt-6 md:pt-0">
      <p className="hidden md:block w-fit lg:whitespace-nowrap">
        {totalResults <= 0
          ? `Mostrando 0 resultados`
          : `Mostrando ${currentPage * pageSize + 1} a ${
            // currentPage * pageSize + totalResults ?? 0
            Math.min((currentPage + 1) * pageSize, totalResults)
            } de ${totalResults} resultados`}
      </p>
      <Select
        name="itemsPerPage"
        placeholder="itemsPerPage"
        cssClasses={{
          label: 'hidden md:block max-w-[209px] !m-0',
          select: 'text-xs !m-0 disabled:bg-gray-100',
        }}
        onChange={(event) => {
          onPageChange(0)
          onChangePageSize(Number(event.target.value));
          if(resetSelection) resetSelection(null)
        }}
        value={pageSize}
        title={`Mostrando ${pageSize} registros`}
        disabled={isLoading}
      >
        <option value="10">Mostrar 10 registros</option>
        <option value="20">Mostrar 20 registros</option>
        <option value="50">Mostrar 50 registros</option>
        <option value="100">Mostrar 100 registros</option>
      </Select>
      {totalResults > 0 && (
        <Pagination
          currentPage={currentPage + 1}
          totalPages={Math.ceil(totalResults / pageSize)}
          onPageChange={(state) => handlePageChange(state)}
          disabled={isLoading}
        />
      )}
    </div>
  );
};

export default NavigationSelector;
