export default {
  auth: {
    signin: '/v1/signin',
    refreshToken: '/v1/token/refresh',
    checkStatus: '/v1/user/status/:email',
  },
  create: '/v1/users',
  changePassword: '/v1/users/change/password',
  forgotPassword: {
    requestChange: '/v1/users/auth/forgot',
    confirmChangePassword: '/v1/users/auth/change',
  },
  getById: '/v1/users/user/:userId',
  getByEmail: '/v1/users/user/email/:userEmail',
  update: `/v1/users/user/:userId`,
  getList: `/v1/users`,
  delete: `/v1/users/:userId`,
  resendInvite: `/v1/users/resendinvite/:userId`,
  firstPassword: '/v1/users/auth/password',
} as const;
