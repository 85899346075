const formProperties = {
    cnpjInput : {      
      labelText: 'CNPJ*',
      placeHolderText: '11.222.333/4444-55',
    },
    statusInput: {      
      labelText: ( isCompanyActive:boolean ) => isCompanyActive ? 'Ativo' : 'Inativo'
    },
    fanstasyNameInput: {            
      labelText: 'Nome Fantasia*'
    },
    nameInput: {            
      labelText: 'Razão Social*'
    },
    zipCodeInput: {
      labelText: 'CEP*',
      placeHolderText: '00000-000'
    },
    addressInput: {  
      labelText: 'Endereço*'
    },
    addressNumber : {      
      labelText: 'Número*'
    },
    complementInput : {      
      labelText: 'Complemento'
    },
    districtInput : {      
      labelText: 'Bairro*'
    },
    cityInput : {      
      labelText: 'Cidade*'
    },
    stateInput: {     
      labelText: 'UF*'
    },
    phoneInput : {
      labelText: 'Telefone*',
      placeHolderText: '(99) 99999-9999'

    },
    companyTipeInput : {
      labelText: 'Tipo da empresa*',
      placeHolderText: 'Selecione um tipo',
    },
    selectPlanTypeInput: {
      labelText: 'Selecione o plano de pagamento*',
      placeHolderText: 'Selecione um tipo de plano',
    },
    dateRegisterInput: {
      labelText: 'Data de contratação*'
    },
    monthlyFeeDayInput: {            
      labelText:(watchTypePlan:string ) =>  `Dia do vencimento da  ${watchTypePlan !== 'anual' ? 'mensalidade' : 'anuidade'}*`,    
      placeHolderText:  (watchTypePlan : string) =>  watchTypePlan !== 'mensal' ? 'Insira um plano de pagamento' : 'Insira o dia de vencimento' ,
    },
    monthlyInstallments: { 
      labelText:(watchTypePlan:string ) =>  `Valor da ${watchTypePlan !== 'anual' ? 'mensalidade' : 'anuidade'}*`,
    },
    commentInput: {
      labelText: 'Observaçōes'
    },
  }

const userFormProperties = {
  nameInput: {
    labelText: 'Nome*'
  },
  surnameInput: {
    labelText: 'Sobrenome*'
  },
  emailInput: {
    labelText: 'Email*'
  },
 }

  export  {formProperties, userFormProperties}