// Third-party
import classNames from 'classnames';

function IndicatorSmallIcon({ className }: { className?: string }) {
  return (
    <svg
      width="8"
      height="13"
      viewBox="0 0 8 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames('text-secondary', className)}
    >
      <path
        d="M6.25 1.58337L1.25 6.58337L6.25 11.5834"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IndicatorSmallIcon;
