// Third-party
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function LogoOrg({ className }: { className?: string }) {
  /*
  **** Component organization ****

   └── Declaration of generic hooks (e.g., useNavigate)
   └── State declaration
   └── Side effects (e.g., useEffect)
   └── Memoization (e.g., useMemo)
   └── Handlers (e.g., useCallback)
   └── JSX
   */

  // └── Declaration of generic hooks (e.g., useNavigate)
  const navigate = useNavigate();

  // └── State declaration

  const isFormPage = window.location.href.includes("form-denuncia");
  const handleClick = () => {
    if (isFormPage) return null;
    return navigate("/");
  };

  // └── JSX
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
    <img
      className={`${className} ${!isFormPage && "cursor-pointer"}`}
      onClick={() => handleClick()}
      src={"/logo_objetiva.png" || ""}
      alt="company-logo"
    />
  );
}

export default LogoOrg;
