import dayjs, { Dayjs } from "dayjs";

export const dateUtils = {  
  constants: { 
   DATE_DISPLAY_FORMAT: 'DD/MM/YYYY',
   DATE_TIME_DISPLAY_FORMAT: 'DD/MM/YYYY HH:mm',
   EXPORT_EXCEL_DATE_FORMAT: 'YYYY/MM/DD',
   LOG_LIST_DATE_FORMAT: 'YYYY-MM-DD',
  },
  formatTo: { 
    formatDateToRegisterPaymentOrExpense
  },
  validate: {
    validateDateStringDayjsToDate
  },  
  generateDate: {
    getCurrentDate:() => new Date(),  
    getCurrentDateIsoString :() => new Date().toISOString().split('T')[0],
    getCurrentMonth: () => dayjs().month() + 1,
    getCurrentYear: () => dayjs().year(),
    getFirstDayOfYear : () =>  dayjs('2024-01-01').startOf('day').toDate(), //DEFINIDO PELO BACKEND
    getFirstDayOfMonth: () => {
      return dayjs().startOf('month').format('DD-MM-YYYY');
    },
    
    getLastDayOfMonth: () => {
      return dayjs().endOf('month').format('DD-MM-YYYY');
    },
  }
}

function validateDateStringDayjsToDate(_: any, originalValue: string): Date {
  const parsedDate = dayjs(originalValue, dateUtils.constants.DATE_DISPLAY_FORMAT);
  return parsedDate.isValid() ? parsedDate.toDate() : new Date('');
}

function formatDateToRegisterPaymentOrExpense(dataEvent: string |Date |Dayjs): string {    
    return dayjs(dataEvent).tz('America/Sao_Paulo').format('YYYY-MM-DDTHH:mm:ssZ');  
}
