import classNames from 'classnames';

function CloseIcon({ color, className }: { color?: string; className?: string }) {
  return (
    <svg
      className={classNames(`fill-current h-6 w-6 text-green-400`, color, className)}
      role="button"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <title>Fechar</title>
      <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
    </svg>
  );
}

export default CloseIcon;
