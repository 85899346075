import InputTextArea from 'components/InputTextArea';
import { FactoryArg, InputMask } from 'imask/esm/index';
import { useCallback, useEffect, useRef } from 'react';
import { IMask } from 'react-imask';

type IMaskInstance = InputMask & {
  masked: {
    currentMask: { mask: string };
    mask: string | string[];
  };
};

const TextAreaWithMask = ({
  mask,
  ...props
}: {
  mask: string;
  labelText: string;
  maxLength?: number;
  value: string;
}) => {
  const ref = useRef(null);

  const parseStringToArray = (input: string): string | FactoryArg[] => {
    try {
      if (RegExp(/^\[.*\]$/).test(input)) {
        return input
          .replace(/^\[|\]$/g, '')
          .replaceAll("'", '')
          .split(',')
          .map((mask) => ({
            mask,
            definitions: {
              A: /[A-z]/,
            },
          }));
      }
    } catch {
      null;
    }
    return input;
  };

  const checkForCapitalization = useCallback(
    (maskInstance: IMaskInstance) => () => {
      if (
        (Array.isArray(maskInstance.masked.mask) &&
          maskInstance.masked.currentMask.mask[maskInstance.value.length - 1] === 'A') ||
        maskInstance.masked.mask[maskInstance.value.length - 1] === 'A'
      ) {
        maskInstance.value =
          maskInstance.value.substring(0, maskInstance.value.length - 1) +
          maskInstance.value[maskInstance.value.length - 1].toUpperCase();
      }
    },
    []
  );

  useEffect(() => {
    const element = ref.current;
    if (element && mask) {
      const maskProcessed = parseStringToArray(mask);

      const maskOptions = {
        mask: maskProcessed,
        definitions: {
          A: /[A-z]/,
        },
      };
      const maskInstance = IMask(element, maskOptions as FactoryArg) as IMaskInstance;

      maskInstance.on('accept', checkForCapitalization(maskInstance));
    }
  }, [checkForCapitalization, mask]);

  return (
    <InputTextArea
      ref={ref}
      {...props}
      value={props.value ?? ''}
      cssClasses={{
        label: 'max-w-[calc(100%/3-10px)] min-w-1/3',
      }}
    />
  );
};

export default TextAreaWithMask;
