// Third-party
import { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

// App
import { IUser } from 'interfaces/userTypes';
import { PAGES_ROUTES } from 'constants/routes';
import { useGetUser } from 'store/server/user/queries';
import useAuthStore from 'store/client/auth/useAuthStore';
import { selectIsRefreshToken } from 'store/client/auth/selectors';
import useGlobalParamsStore from 'store/client/globalParams/useGlobalParamsStore';
import { selectGlobalParams, selectSetFirstRendered } from 'store/client/globalParams/selectors';

/**
 * A component that provides protected routes, allowing only authenticated users with specific access levels to access certain content.
 *
 * @props allowedAccessLevels - An array of access levels that are allowed to access the protected route.
 * @returns {Outlet} Component Outlet from React Router Dom -> https://reactrouter.com/en/main/components/outlet
 *
 * @example
 * // Example usage of ProtectedRoute with allowedAccessLevels prop:
 * <ProtectedRoute allowedAccessLevels={[1, 2]} />
 */
function ProtectedRoute({ allowedAccessLevels }: { allowedAccessLevels?: IUser['accessLevel'][] }) {
  /*
  **** Component organization ****

   └── Declaration of generic hooks (e.g., useNavigate)
   └── State declaration
   └── Side effects (e.g., useEffect)
   └── Memoization (e.g., useMemo)
   └── Handlers (e.g., useCallback)
   └── JSX
   */

  // └── Declaration of generic hooks (e.g., useNavigate)
  const user = useGetUser();
  const isRefreshToken = useAuthStore(selectIsRefreshToken);
  const globalParams = useGlobalParamsStore(selectGlobalParams);
  const setFirstRendered = useGlobalParamsStore(selectSetFirstRendered);

  // └── Side effects (e.g., useEffect)
  useEffect(() => {
    setFirstRendered();
  }, [setFirstRendered]);

  if (globalParams.firstRender || (!user.data && (isRefreshToken || !user.isFetched))) return null;

  /**
   * Checks for session user information,or if a request for it is being made,
   * or if it is still the first render of the application, if not, a redirect is made to the /signin route.
   *
   * Prevents unauthenticated users from accessing pages that require authentication
   *
   * Render component Navigate from React Router Dom -> https://reactrouter.com/en/main/components/navigate
   */
  if (
    !user.data &&
    user.isFetched &&
    !['loading', 'pending'].includes(user.status) &&
    !isRefreshToken &&
    !globalParams.firstRender
  ) {
    return <Navigate to={PAGES_ROUTES.unauthenticated.initialRoute} replace />;
  }

  // /**
  //  * Checks if the user of the session has the access level for the route in question, if not, it is redirected to the / route of the application.
  //  *
  //  * The allowed access levels are listed via prop, defined when the ProtectedRoute component is invoked.
  //  *
  //  * Prevents users with a certain access level from viewing some content.
  //  *
  //  * Render component Navigate from React Router Dom -> https://reactrouter.com/en/main/components/navigate
  //  */
  if (
    user.data &&
    allowedAccessLevels &&
    allowedAccessLevels.length &&
    !allowedAccessLevels.includes(user.data?.accessLevel)
  ) {
    return <Navigate to={user.data.initialRoute} replace />;
  }

  /**
   * Outlet is responsible for rendering child routes of the ProtectedRoute component, which allows using nested routes.
   *
   * Render component Outlet from React Router Dom -> https://reactrouter.com/en/main/components/outlet
   */
  return <Outlet />;
}

export default ProtectedRoute;
