// App
import { IGlobalParamsState } from './interfaces';

export const selectGlobalParams = (state: IGlobalParamsState) => state;

export const selectLockComponentsRender = (state: IGlobalParamsState) => {
  return state.lockComponentsRender > 0;
};

export const selectIsCheckingToken = (state: IGlobalParamsState) => {
  return state.isCheckingToken;
};

export const selectFirstRender = (state: IGlobalParamsState) => {
  return state.firstRender;
};

export const selectSetFirstRendered = (state: IGlobalParamsState) => {
  return state.setFirstRendered;
};

export const selectSetLockComponentsRender = (state: IGlobalParamsState) => {
  return state.setLockComponentsRender;
};

export const selectSetIsCheckingToken = (state: IGlobalParamsState) => {
  return state.setIsCheckingToken;
};
