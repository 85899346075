//Third-party
import classNames from 'classnames';

function MenuIcon({ className }: { className?: string }) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames('text-primary', className)}
    >
      <path
        d="M4.5 6H20.5M4.5 12H20.5M4.5 18H11.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default MenuIcon;
