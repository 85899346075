import { jsPDF } from 'jspdf';
import dayjs from 'dayjs';
import html2canvas from 'html2canvas';
import { cnpjMask } from './mask.utils';
import { IPdfPrintProps } from 'interfaces/pdfTypes';
import autoTable, { Color } from 'jspdf-autotable';
import { QueryObserverResult } from '@tanstack/react-query';
import { convertToBrazilianCurrencyFormat } from './currency.utils';
import { MESSAGES } from 'constants/messages';
import { pad } from 'crypto-js';


type exportList = 'expenseList'
interface IExportPdfParams {
  refetch: () => Promise<QueryObserverResult<any, Error>>;
  setLoading: (loading: boolean) => void;
  addNotification: (notification: INotificationType) => void;
  fileName: string;
  company: {
    doc: string;
    name: string;
  };
  docTitle: string;
  clientName?: string | null;
  pdfPeriod?: string;
  page?: exportList
}
interface INotificationType {
  type: 'error' | 'success';
  message: string;
}


const yellowColor: Color = [245, 158, 11]; // RGB text-yellow-500
const greenColor: Color = [16, 185, 129]; // RGB text-green-500
const redColor: Color = [239, 68, 68]; // RGB text-red-500

export const setPdfHeader = (
  pdfInstance: jsPDF,
  {
    pageWidth,
    companyName,
    companyCnpj,
    pdfPeriod,
    headerTitle,
    headerSubtitle,
    clientName,
  }: {
    pageWidth: number;
    companyName: string;
    companyCnpj: string;
    selectedMonth?: number;
    selectedYear?: number;
    pdfPeriod?: string;
    headerTitle: string;
    headerSubtitle?: string;
    clientName?: string | null;
  }
) => {  
  pdfInstance.setFontSize(12);
  pdfInstance.setTextColor(40);
  pdfInstance.addImage('/logo_objetiva.png', 'PNG', 20, 20, 87, 60);

  const nameWidth = pdfInstance.getTextWidth(companyName);
  const imageX = pageWidth - 20 - nameWidth - 15 - 10;
  pdfInstance.addImage('/company-build.png', imageX, 15, 15, 19);

  pdfInstance.text(`${companyName}`, pageWidth - 20, 30, { align: 'right' });
  pdfInstance.text(`CNPJ: ${companyCnpj}`, pageWidth - 20, 50, { align: 'right' });
  pdfInstance.text(`Gerado em: ${dayjs().format('DD/MM/YYYY')}`, pageWidth - 20, 70, {
    align: 'right',
  });
  if (pdfPeriod) {
    pdfInstance.text(`Período: ${pdfPeriod}`, pageWidth - 20, 90, { align: 'right' });
  }

  pdfInstance.setFontSize(18);
  const centerX = pageWidth / 2;
  pdfInstance.text(headerTitle, centerX, 60, { align: 'center' });
  pdfInstance.setFontSize(14);
  headerSubtitle && pdfInstance.text(headerSubtitle, centerX, 80, { align: 'center' });
  pdfInstance.setFontSize(16);
  if (clientName) {
    pdfInstance.text(`Cliente : ${clientName}`, centerX, 85, { align: 'center' });
  }
};

export const pdfTablePrinter = async ({
  fileName,
  pageName,
  companyInfo,
  pdfSettings,
  setLoading,
  tableRef,
  addNotification,
}: IPdfPrintProps) => {
  try {
    const { jsPdfParams, measures } = pdfSettings;
    const { orientation, unit, format } = jsPdfParams;
    const { pageWidth, marginX, marginY, pageHeight, headerHeight, maxRowsPerPage } = measures;

    setLoading(true);
    const pdf = new jsPDF(orientation, unit, format);

    const tableContent = tableRef?.current;
    if (!tableContent) {
      addNotification({
        type: 'error',
        message: `A tabela da ${pageName} não foi encontrada.`,
      });
      setLoading(false);
      return;
    }
    const originalTable = tableContent.cloneNode(true) as HTMLElement;

    if (window.innerWidth < 768) {
      originalTable.className = originalTable.className
        .split(' ')
        .map((className) => {
          if (className.startsWith('md:')) {
            return className.replace('md:', '');
          } else if (className === 'hidden') {
            return '';
          } else {
            return className;
          }
        })
        .join(' ')
        .trim();
    }

    const elementsToHide = originalTable.querySelectorAll('.table-actions');
    elementsToHide.forEach((el) => el.remove());

    const tempDiv = document.createElement('div');
    tempDiv.style.position = 'absolute';
    tempDiv.style.top = '-9999px';
    document.body.appendChild(tempDiv);

    const rows = Array.from(originalTable.querySelectorAll('tbody tr'));

    console.log('Linhas',rows);
    // Fix color status element
    rows.forEach((row) => {
      const cells = row.querySelectorAll('td');
      cells.forEach((cell) => {
        const span = cell.querySelector('span');
        if (span) {
          cell.style.display = 'inline-flex';
          cell.style.alignContent = 'center';
          cell.style.justifyContent = 'center';
          cell.style.width = '100%';
          cell.style.height = '45px';

          span.style.padding = '5px 10px 5px 10px';
          span.style.borderRadius = '10px';
          span.style.textAlign = 'center';
          span.style.width = '100%';
          span.style.height = '100%';
        }
      });
    });

    const totalPages = Math.ceil(rows.length / maxRowsPerPage);

    for (let i = 0; i < totalPages; i++) {
      const clonedTable = originalTable.cloneNode(true) as HTMLElement;
      tempDiv.appendChild(clonedTable);

      const startRow = i * maxRowsPerPage;
      const endRow = Math.min(startRow + maxRowsPerPage, rows.length);
      const tbody = clonedTable.querySelector('tbody');

      if (tbody) {
        tbody.innerHTML = '';
        rows.slice(startRow, endRow).forEach((row) => tbody?.appendChild(row.cloneNode(true)));
      }

      if (i !== 0) {
        pdf.addPage();
      }
      setPdfHeader(pdf, {
        pageWidth,
        companyName: 'companyInfo.name',
        companyCnpj: cnpjMask(companyInfo.cnpj),
        ...(companyInfo.pdfPeriod ? { pdfPeriod: companyInfo.pdfPeriod } : {}),
        ...(companyInfo.currentMonth ? { selectedMonth: companyInfo.currentMonth } : {}),
        ...(companyInfo.currentYear ? { selectedYear: companyInfo.currentYear } : {}),
        headerTitle: 'docTitle',
      });

      await html2canvas(clonedTable, { scale: 2 }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const imgHeight = (canvas.height / canvas.width) * (pageWidth - 2 * marginX);
        const imgY = marginY + headerHeight;
        const finalImgHeight = Math.min(imgHeight, pageHeight - headerHeight - 2 * marginY);
        pdf.addImage(imgData, 'PNG', marginX, imgY, pageWidth - 2 * marginX, finalImgHeight);
      });

      tempDiv.removeChild(clonedTable);
    }

    document.body.removeChild(tempDiv);
    pdf.save(
      `ECVFinancy_${fileName}_${dayjs().format('DD-MM-YYYY')}_${dayjs().format(
        'HH'
      )}h${dayjs().format('mm')}m${dayjs().format('ss')}s.pdf`
    );
  } catch (error) {
    addNotification({
      type: 'error',
      message: `Erro ao exportar PDF. Tente novamente mais tarde.`,
    });
  } finally {
    setLoading(false);
  }
};

const extractHeaders = (data: any[]) => {
  const headersSet = new Set<string>();
  data.forEach((item) => {
    Object.keys(item).forEach((key) => {
      headersSet.add(key);
    });
  });
  return Array.from(headersSet);
};



export const generatePdf = (
  dataPdf: any,
  fileName: string,
  company: { doc: string; name: string },
  docTitle: string,
  clientName?: string | null,
  pdfPeriod?: string,
  page?: exportList,
) => {  
  const pdf = new jsPDF('l', 'pt', 'a4');
  let currentYPosition = 60;
  const headerToTableDistance = 50;
  const rowsPerPage = 20;

  const pageWidth = 842;

  setPdfHeader(pdf, {
    pageWidth,
    companyName: company.name,
    companyCnpj: cnpjMask(company.doc),
    headerTitle: docTitle,
    clientName,
    ...(pdfPeriod ? { pdfPeriod: pdfPeriod } : {}),
  });

  currentYPosition += headerToTableDistance;
  const dataList = dataPdf.data; 
  const resumeList = dataPdf.resume;

  if (dataList.length === 0) {
    pdf.setFontSize(20);
    pdf.text('Sem registros para exportar', 421, 200, { align: 'center' });
    pdf.save(
      `${fileName}${dayjs().format('DD-MM-YYYY')}_${dayjs().format('HH')}h${dayjs().format(
        'mm'
      )}m${dayjs().format('ss')}.pdf`
    );
    return;
  }

  const headers = extractHeaders(dataList);
  const rows = dataList.map((item: { [x: string]: any; }) => {
    return headers.map((header) => item[header] || '');
  });

  const customColumnStyle = {
    'expenseList' : {
      'Descrição': {        
        cellWidth: 250
      },
      'Data Despesa': {        
        cellWidth: 65,
        cellPadding: 5
      },
      'Data Pagamento': {        
        cellWidth: 65,
        cellPadding: 5
      }
    }
  };

  const getColumnStyles = (page: exportList | undefined| null) => {
    return {
      ID: {
        minCellWidth: 10,
        textColor: redColor
      },
      ...( (page && customColumnStyle[page]) || {})
    };
  };
  

  for (let i = 0; i < rows.length; i += rowsPerPage) {
    const pageRows = rows.slice(i, i + rowsPerPage);

    autoTable(pdf, {
      startY: currentYPosition,
      columns: headers.map((header) => ({ header, dataKey: header })),
      body: pageRows,
      theme: 'grid',
      showHead:'everyPage',
      styles: {
        fontSize: 7,
        overflow: 'linebreak',
        cellWidth: 'auto',      
      },
      headStyles: {
        fillColor: '#F3F3F3',
        textColor: '#0B2B69',
        fontStyle: 'bold',
        halign: 'center',
      },
      columnStyles: getColumnStyles(page),
      didParseCell: function (data) {
        
        if (data.column.dataKey === 'Status' && typeof data.cell.raw === 'string') {
          const status = data.cell.raw.toUpperCase();
          if (status === 'PAGO PARCIAL') {
            data.cell.styles.textColor = yellowColor;
          } else if (status === 'ATIVO' || status === 'PAGO') {
            data.cell.styles.textColor = greenColor;
          } else if (status === 'INATIVO' || status === 'PENDENTE') {
            data.cell.styles.textColor = redColor;
          }
        }
        if (typeof data.cell.raw === 'string' && !isNaN(Date.parse(data.cell.raw))) {
          if (data.column.dataKey.toString().toLowerCase().includes('data')) {
            data.cell.raw = dayjs(data.cell.raw).format('DD/MM/YYYY HH:mm');
          }
        }
  
        if (typeof data.cell.raw === 'string' && !isNaN(parseFloat(data.cell.raw))) {
          if (data.column.dataKey.toString().toLowerCase().includes('valor')) {
            data.cell.raw = convertToBrazilianCurrencyFormat(data.cell.raw);
          }
        }
        
      },
      didDrawPage: function(data){

        setPdfHeader(pdf, {
          pageWidth,
          companyName: company.name,
          companyCnpj: cnpjMask(company.doc),
          headerTitle: docTitle,
          clientName,
          ...(pdfPeriod ? { pdfPeriod: pdfPeriod } : {}),
        });
  
        if(resumeList.length > 0){
          let startX = 40; 
          let startY =  535; 
          let rowHeight = 9;
          let colWidthLabel = 150; 
          let colWidthValue = 70; 
      
          pdf.setFillColor(200, 200, 200);
          pdf.rect(startX, startY - rowHeight, colWidthLabel, rowHeight, 'F'); 
          pdf.rect(startX + colWidthLabel, startY - rowHeight, colWidthValue, rowHeight, 'F'); 
        
          pdf.setFontSize(8);   
          pdf.setTextColor(11, 43, 105);
          pdf.setFont("tahoma", "bold");
          pdf.text("Descrição", startX + 4, startY - (rowHeight - 7) );
          pdf.text("Valor", startX + colWidthLabel + 5, startY - (rowHeight - 7));
          
      
          pdf.setFontSize(8);
          pdf.setTextColor(0, 0, 0);
          pdf.setFont("tahoma","normal");
          
          let positionY =  currentYPosition + (35* dataList.length);
          resumeList.map((item: { value: { toString: () => string; }; label: string | string[]; }) => {
            const value = convertToBrazilianCurrencyFormat(item.value.toString())
      
            pdf.rect(startX, startY, colWidthLabel, rowHeight); 
            pdf.rect(startX + colWidthLabel, startY, colWidthValue, rowHeight); 
      
            pdf.text(item.label, startX + 5, startY + 8);
            pdf.text(value, startX + colWidthLabel + 5, startY + 8); 
      
            startY += rowHeight;
            positionY = positionY + 10;
          })
        }
  
      }
    });


    if (i + rowsPerPage < rows.length) {
      pdf.addPage();
    }
  
  
  }


  
  




  // if(resumeList.length > 0){
  //   let startX = 40; 
  //   let startY =  565; 
  //   let rowHeight = 9;
  //   let colWidthLabel = 150; 
  //   let colWidthValue = 70; 

  //    // pdf.setFontStyle('bold');
  //   pdf.setFillColor(200, 200, 200);
  //   // Desenha as bordas do cabeçalho
  //   pdf.rect(startX, startY - rowHeight, colWidthLabel, rowHeight, 'F'); 
  //   pdf.rect(startX + colWidthLabel, startY - rowHeight, colWidthValue, rowHeight, 'F'); 
  
  //   // Desenha o cabeçalho da tabela
  //   pdf.setFontSize(8);   
  //   pdf.setTextColor(11, 43, 105);
  //   pdf.setFont("tahoma", "bold");
  //   pdf.text("Descrição", startX + 4, startY - (rowHeight - 7) );
  //   pdf.text("Valor", startX + colWidthLabel + 5, startY - (rowHeight - 7));
    

  //   pdf.setFontSize(8);
  //   pdf.setTextColor(0, 0, 0);
  //   pdf.setFont("tahoma","normal");
    
  //   let positionY =  currentYPosition + (35* dataList.length);
  //   resumeList.map((item: { value: { toString: () => string; }; label: string | string[]; }) => {
  //     const value = convertToBrazilianCurrencyFormat(item.value.toString())

  //     pdf.rect(startX, startY, colWidthLabel, rowHeight); 
  //     pdf.rect(startX + colWidthLabel, startY, colWidthValue, rowHeight); 

  //     pdf.text(item.label, startX + 5, startY + 8);
  //     pdf.text(value, startX + colWidthLabel + 5, startY + 8); 

  //     startY += rowHeight;
      
  //     // pdf.text(`${item.label} ${value}`, 10, positionY);
  //     positionY = positionY + 10;
  //   })
  // }

  

  setPageCountWithTotal(pdf, {
    pageWidth,
    position: {
      y: 530,
    },
  });


  
  pdf.save(
    `${fileName}${dayjs().format('DD-MM-YYYY')}_${dayjs().format('HH')}h${dayjs().format(
      'mm'
    )}m${dayjs().format('ss')}.pdf`
  );
};

export async function exportPdf({
  refetch,
  setLoading,
  addNotification,
  fileName,
  company,
  docTitle,
  clientName,
  pdfPeriod,
  page
}: IExportPdfParams): Promise<void> {  
  setLoading(true);
  try {
    const response = await refetch();    
    if (response.isSuccess && response.data.data) {
      generatePdf(response.data, fileName, company, docTitle, clientName, pdfPeriod, page);
    } else {
      throw new Error();
    }
  } catch (error: any) {
    console.error('Error fetching Excel data:', error);
    addNotification({
      type: 'error',
      message: error.message || MESSAGES.DEFAULT_FAILURE_EXPORT_MESSAGE,
    });
  } finally {
    setLoading(false);
  }
}

export function setPageCount(
  pdfInstance: jsPDF,
  {
    pageWidth,
    pageCountText,
    position,
  }: {
    pageWidth: number;
    pageCountText?: string;
    position?: {
      x?: number;
      y?: number;
    };
  }
) {
  pdfInstance.setFontSize(10);
  pdfInstance.text(
    pageCountText ? pageCountText : pdfInstance.getNumberOfPages().toString(),
    position?.x || pageWidth - 25,
    position?.y || 825
  );
}

export function setPageCountWithTotal(
  pdfInstance: jsPDF,
  {
    pageWidth,
    position,
  }: {
    pageWidth: number;
    pageCountText?: string;
    position?: {
      x?: number;
      y?: number;
    };
  }
) {
  const totalPagesCount = pdfInstance.getNumberOfPages();

  for (let pageIndex = 1; pageIndex <= totalPagesCount; pageIndex += 1) {
    pdfInstance.setPage(pageIndex);
    setPageCount(pdfInstance, {
      pageWidth,
      pageCountText: `${pageIndex}/${totalPagesCount}`,
      position: {
        x: position?.x || pageWidth - 30,
        y: position?.y,
      },
    });
  }
}
