// Third-party
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';

// App
import InputText from 'components/InputText';
import { SimpleSpinnerIcon } from 'assets/icons';
import classNames from 'classnames';
import { ListItemType } from 'types/listItemType';

interface IElementOption {
  title: string;
  subtitle: string;
}

interface IInputOptions {
  labelText: string;
  placeholderText: string;
  disabled: boolean;
  labelTextClassName: string;
  inputTextClassName: string;
}

export interface IInputSearchProps<TListToSelect> {
  listToSelect: (IElementOption & TListToSelect)[];
  isLoading: boolean;
  emptyListMessage: string;
  value?: string;
  storedValue?: ListItemType<IInputSearchProps<TListToSelect>['listToSelect']> | undefined;
  onSearch: (search: string) => void;
  onSelect: (elementSelected: (IElementOption & TListToSelect) | undefined) => void;
  inputOptions?: Partial<IInputOptions>;
}

function InputSearch<TListToSelect>({
  isLoading,
  listToSelect,
  emptyListMessage,
  value,
  storedValue,
  onSearch,
  onSelect,
  inputOptions,
}: IInputSearchProps<TListToSelect>) {
  const [showList, setShowList] = useState<boolean>(false);
  const clientNameToSearchTimeout = useRef<NodeJS.Timeout>(setTimeout(() => null, 0));
  const [textToSearch, setTextToSearch] = useState<string>('');
  const searchInputRef = useRef<HTMLInputElement>(null);
  const optionsContainerRef = useRef<HTMLDivElement>(null);
  const [selectedElement, setSelectedElement] = useState<
    ListItemType<IInputSearchProps<TListToSelect>['listToSelect']> | undefined
  >();

  useEffect(() => {
    clearTimeout(clientNameToSearchTimeout.current);
    if (textToSearch.length > 3 || (value?.length ?? 0) > 3) {
      clientNameToSearchTimeout.current = setTimeout(async () => {
        onSearch(value || textToSearch);
      }, 500);
    } else {
      onSearch('');
      setSelectedElement(undefined);
    }
  }, [onSearch, textToSearch]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        searchInputRef.current &&
        !searchInputRef.current.contains(event.target as Node) &&
        optionsContainerRef.current &&
        !optionsContainerRef.current.contains(event.target as Node)
      ) {
        setShowList(false);
      }
    };

    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!storedValue) {
      onSelect(selectedElement);
      return;
    }
  }, [onSelect, selectedElement]);

  const handleValue = useCallback((valueString: string) => {
    value !== undefined ? onSearch(valueString) : setTextToSearch(valueString);
  }, []);

  const handleStoredValue = useCallback(
    (
      selectedOption: ListItemType<IInputSearchProps<TListToSelect>['listToSelect']> | undefined
    ) => {
      storedValue !== undefined
        ? onSelect(selectedOption ?? undefined)
        : setSelectedElement(selectedOption ?? undefined);
    },
    []
  );

  const handleSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      handleStoredValue(undefined);
      handleValue(event.target.value);
    },
    [onSearch, value]
  );

  const handleSelected = useCallback(
    (selectedOption: ListItemType<IInputSearchProps<TListToSelect>['listToSelect']>) => {
      handleValue(selectedOption.title);
      handleStoredValue(selectedOption);
      setShowList(false);
    },
    [onSearch, value]
  );

  useEffect(() => {
    if (value !== undefined && value !== selectedElement?.title) {
      setSelectedElement(undefined);
    }
  }, [selectedElement?.title, value]);

  return (
    <>
      <div onFocus={() => setShowList(true)} className="relative w-full">
        <div>
          <InputText
            name="client-visualization"
            type="text"
            labelText={inputOptions?.labelText}
            placeholder={inputOptions?.placeholderText}
            cssClasses={{
              label: inputOptions?.labelText ? `${inputOptions?.labelTextClassName}` : undefined,
              input: inputOptions?.labelText ? `${inputOptions?.inputTextClassName}` : '!mt-[0]',
            }}
            autoComplete="off"
            autoCorrect="off"
            onChange={handleSearch}
            value={selectedElement ? selectedElement?.title : value ? value : textToSearch}
            ref={searchInputRef}
            disabled={inputOptions?.disabled}
          />
          {isLoading && (
            <SimpleSpinnerIcon
              className={classNames('absolute right-3 !top-[14px]', {
                '!top-[38px]': inputOptions?.labelText,
              })}
            />
          )}
        </div>
        {showList && (
          <div
            ref={optionsContainerRef}
            className="absolute bg-white flex flex-col justify-between gap-x-2.5 text-xs font-normal min-w-full w-fit mt-1 rounded-md shadow-[0px_0px_25px_0px_rgba(0,_0,_0,_0.10)] z-[60]"
          >
            {listToSelect ? (
              listToSelect.map((elementToList, elementIndex) => (
                <button
                  className="py-2 whitespace-nowrap hover:bg-gray-200 px-3 rounded-md z-[99999]"
                  onClick={() => handleSelected(elementToList)}
                  key={`input-search-option-to-selected-${elementToList.title}-${elementIndex}`}
                  type="button"
                >
                  {elementToList.title}
                  {elementToList.subtitle && `- ${elementToList.subtitle}`}
                </button>
              ))
            ) : (
              <span className="py-2 whitespace-nowrap px-3 rounded-md z-[99999] text-gray-500">
                {emptyListMessage}
              </span>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default InputSearch;
