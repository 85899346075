import { ChangeEvent } from "react";

export const handleChangeMonthlyInstallments =
  (onChange: (value: string) => void) => (event: ChangeEvent<HTMLInputElement>) => {
    let inputValue = Number(event.target.value.replace(/\D/g, '')) / 100;
    
    const formattedValue = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(inputValue);
    
    onChange(formattedValue);
  };

