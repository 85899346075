/* eslint-disable react-hooks/rules-of-hooks */
import { CloseIcon, SimpleSpinnerIcon } from 'assets/icons';
import GenericTable, { GenericTableProps } from 'components/GenericTable';
import Modal from 'components/Modal';
import InputTextArea from 'components/InputTextArea';
import { useRemoveExepenseMutation } from 'store/server/company/mutations';
import useCompanySelectedStore from 'store/client/companySelected/useCompanySelectedStore';
import { selectCompanySelected } from 'store/client/companySelected/selectors';
import { useGetUser } from 'store/server/user/queries';
import useNotificationStore from 'store/client/notification/useNotificationStore';
import { selectAddNotification } from 'store/client/notification/selectors';
import { Navigate } from 'react-router-dom';
import { PAGES_ROUTES } from 'constants/routes';
import { useState } from 'react';
import { AxiosError } from 'axios';
import { Controller, useForm } from 'react-hook-form';
import { DEFAULT_VALUES } from 'constants/textAreaInput';

interface InlineDataItem {
  name: string;
  value: any;
  formatter?: (value: any) => string;
}

interface IExcludeExpenseModalProps {    
    title: string;
    description: string;    
    useTable?: boolean;
    tableData?: GenericTableProps;
    useInlineData?: boolean;
    inlineData?: InlineDataItem[];
    displayComments?: boolean;
    expenseId: number;
    onClose: () => void;
}

interface IExcludeExpense {
    comment: string;
}

function ExcludeExpenseModal({ 
  title, 
  description, 
  useTable = false, 
  tableData, 
  useInlineData = false, 
  inlineData,
  expenseId,
  onClose 
}: IExcludeExpenseModalProps) {
  /* └── Declaration of generic hooks (e.g., useNavigate) */
  const { control, handleSubmit, reset } = useForm<IExcludeExpense>();   

  /* └── State declaration */
  const companySelected = useCompanySelectedStore(selectCompanySelected);
  const addNotification = useNotificationStore(selectAddNotification);
  
  const { data: user } = useGetUser();

  /* └── Side effects (e.g., useEffect) */
  if (!companySelected)
    return (
      <Navigate to={user?.initialRoute ?? PAGES_ROUTES.authenticated.adminInitialRoute} replace />
    );
  
  const removeExpenseMutation = useRemoveExepenseMutation(companySelected.id);

  const [modalLoading, setModalLoading] = useState(false);
  
  /* └── Handlers (e.g., useCallback) */
  const onSubmit = async ({ comment }: IExcludeExpense) => {
    setModalLoading(true);
    try {
      await removeExpenseMutation.mutateAsync({ expenseId: expenseId, comment })   

      addNotification({
        type: 'success',
        message: 'Despesa removida com sucesso!',
      });
      reset();
      onClose()
    } catch (error) {
      if (error instanceof AxiosError) {
        if (
          error.response?.status &&
          error.response?.status >= 400 &&
          error.response?.status < 500
        ) {
          addNotification({
            type: 'error',
            message: error.response?.data.message,
          });
          return;
        }
        addNotification({
          type: 'error',
          message: 'Erro com o serviço. Tente novamente mais tarde.',
        });
      }
    } finally {
      setModalLoading(false);
      onClose()
    }
  };

  /* └── JSX */
  return (
    <Modal
      content={
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-y-4">
            <label className='flex items-end justify-end' htmlFor='closeIcon'>
              <button
                name='closeIcon'
                className=""
                disabled={modalLoading}
                onClick={() => onClose()}
              >
                <CloseIcon className="!text-primary h-[15px] w-[15px]" />
              </button>
            </label>
            <div className="w-full flex flex-col gap-4">
              <h2 className="text-2xl text-black font-inter text-center font-bold">
                {title}
              </h2>
            </div>
            <div className="text-black font-normal text-center text-sm">
              {description}     
            </div>
            {useTable && tableData &&
              <div>
                <GenericTable
                  columns={tableData.columns}
                  data={tableData.data}
                  formatters={tableData.formatters}
                />
              </div>
            }
            {/* CONTENT */}
            <div className='py-5'>
            {useInlineData && inlineData &&
              <div className="bg-white px-[20px] py-[16px] overflow-hidden flex flex-col justify-between gap-y-[9px] shadow-container rounded-[5px]">
                <div className="flex flex-row justify-between w-full">
                  <div className="font-inter font-medium text-gray-500 text-xs/[20px]">
                    <span className="uppercase">
                      {inlineData.map((e, i) => {
                        return (
                          <p key={`${e.name}+${i}`}>
                            <b className="text-primary">{e.name}: </b>
                            {e.formatter ? e.formatter(e.value) : e.value }
                          </p>
                        )
                      })}
                    </span>
                  </div>
                </div>
              </div>
            }
            <Controller
              name='comment'
              control={control}
              defaultValue={""}
              render={({field}) => (
                <InputTextArea
                  {...field}
                  labelText="Observações"
                  cssClasses={{
                    label: 'mt-[20px]',
                    textarea: 'h-full min-h-[100px] max-h-[250px]',
                  }}
                  maxLength={DEFAULT_VALUES.MAX_250_CHAR}
                />  
              )}          
            />   

            </div>
            <div className="flex flex-col items-center gap-5 w-full">
              <button
                type="submit"
                className="px-4 py-2 text-buttontextcolor bg-primary rounded-md shadow-md duration-200 font-medium text-center w-full disabled:bg-gray-300 flex justify-center h-[40px]"
                disabled={modalLoading}
              >
                {!modalLoading ? (
                  'Confirmar'
                ) : (
                  <SimpleSpinnerIcon className="w-[24px] h-[24px]" />
                )}
              </button>
            </div>
          </div>
        </form>
      }
      onClose={onClose}
      css={{        
        modal: 'relative md:max-h-[550px] md:max-w-[600px] h-full w-full md:w-fit md:my-auto overflow-y-auto',
      }}
    />
  )
}

export default ExcludeExpenseModal;
