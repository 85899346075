/* eslint-disable react-hooks/rules-of-hooks */
// Third-party
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Navigate } from 'react-router-dom';

// App
import useCompanySelectedStore from 'store/client/companySelected/useCompanySelectedStore';
import { selectCompanySelected } from 'store/client/companySelected/selectors';
import { PAGES_ROUTES } from 'constants/routes';
import { useGetSummaryData } from 'store/server/company/queries';
import getMonthStartAndEndUtil from 'utils/getMonthStartAndEnd.util';
import { useFilterBarContext } from '../../context/FilterBarContext';
import { splitArray } from 'utils/splitArray.utils';
import SectionContainer from '../sections/SectionContainer';
import classNames from 'classnames';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

interface IClientsList {
  toExport?: boolean;
}

const MAX_ITEMS_TO_EXPORT = 36;

function ServicesPerClientsList({ toExport }: IClientsList) {
  const companySelected = useCompanySelectedStore(selectCompanySelected);

  if (!companySelected) {
    return <Navigate to={PAGES_ROUTES.authenticated.commonInitialRoute} replace />;
  }

  const { selectedMonth, selectedYear } = useFilterBarContext();

  const { data: dashboardData } = useGetSummaryData(
    companySelected.id,
    {
      ...getMonthStartAndEndUtil(Number(selectedYear), Number(selectedMonth)),
    },
    { requestIdEnabled: false }
  );

  const length = dashboardData?.listServices?.length || 0;
  const itemsToExportLength = toExport ? MAX_ITEMS_TO_EXPORT : length || MAX_ITEMS_TO_EXPORT;

  return (
    <>
      {(length === 0
        ? [[]]
        : splitArray(dashboardData?.listServices ?? [], itemsToExportLength)
      ).map((servicePerClientsListBatch, servicePerClientsListBatchIndex) => {
        const table = (
          <div
            className={classNames('w-full h-fit overflow-auto', {
              'min-h-[10000px]':
                toExport &&
                servicePerClientsListBatchIndex === 0 &&
                servicePerClientsListBatch.length === MAX_ITEMS_TO_EXPORT,
            })}
            key={`clients-list-${servicePerClientsListBatchIndex}`}
          >
            <div className="overflow-auto">
              <table className={classNames('w-full text-lg text-left text-black')}>
                <thead
                  className={classNames(
                    'font-inter text-[14px] text-primary font-bold uppercase bg-gray-100 w-full'
                  )}
                >
                  <tr>
                    <th className="py-[4px] pl-[6px]">Serviços</th>
                    <th className="py-[4px] whitespace-nowrap text-right pr-2"></th>
                  </tr>
                </thead>
                <tbody>
                  {servicePerClientsListBatch.map((servicePerClients) => (
                    <tr
                      key={`client-${servicePerClients.service}`}
                      className={classNames(
                        'bg-white border-b hover:bg-gray-100 w-full text-base h-[25px]'
                      )}
                    >
                      <td className="align-middle pl-[10px] py-[2px]">
                        {servicePerClients.service}
                      </td>
                      <td className="align-middle text-right pr-2">{servicePerClients.quantity}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr
                    className={classNames(
                      'font-inter text-[14px] text-primary font-bold uppercase bg-gray-100 w-full'
                    )}
                  >
                    <th className="py-[4px] pl-[10px] pr-[8px] text-left whitespace-nowrap">
                      Total de Serviço
                    </th>
                    <th className="py-[4px] text-right pr-2 ">
                      {servicePerClientsListBatch.reduce(
                        (acc: number, servicePerClientsListBatchCurr) => {
                          acc += Number(servicePerClientsListBatchCurr.quantity ?? 0);
                          return acc;
                        },
                        0
                      )}
                    </th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        );

        if (toExport && servicePerClientsListBatchIndex > 0) {
          return (
            <SectionContainer key={`service-list-container-${servicePerClientsListBatchIndex}`}>
              {table}
            </SectionContainer>
          );
        }

        return table;
      })}
    </>
  );
}

export default ServicesPerClientsList;
