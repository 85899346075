import classNames from 'classnames';

function LogoutIcon({ className }: { className?: string }) {
  return (
    <svg
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames('text-secondary', className)}
    >
      <path d="M19.1915 15.8711H17.4752C17.358 15.8711 17.2481 15.9224 17.1749 16.0127C17.004 16.2203 16.8209 16.4204 16.628 16.6109C15.8391 17.4005 14.9048 18.0298 13.8765 18.4639C12.8112 18.9139 11.6662 19.1447 10.5098 19.1426C9.34039 19.1426 8.20757 18.9131 7.14312 18.4639C6.11487 18.0298 5.18049 17.4005 4.39166 16.6109C3.60141 15.8239 2.97129 14.8912 2.53619 13.8643C2.08453 12.7998 1.85748 11.6695 1.85748 10.5C1.85748 9.3306 2.08697 8.20023 2.53619 7.13578C2.97076 6.10795 3.59576 5.18265 4.39166 4.3892C5.18755 3.59574 6.11285 2.97074 7.14312 2.53617C8.20757 2.08695 9.34039 1.85746 10.5098 1.85746C11.6793 1.85746 12.8121 2.08451 13.8765 2.53617C14.9068 2.97074 15.8321 3.59574 16.628 4.3892C16.8209 4.58207 17.0015 4.78226 17.1749 4.98734C17.2481 5.07767 17.3604 5.12894 17.4752 5.12894H19.1915C19.3453 5.12894 19.4405 4.95805 19.355 4.82865C17.4825 1.91849 14.2061 -0.00777554 10.483 0.00199008C4.63336 0.0166385 -0.0565858 4.76517 0.00200795 10.6075C0.0606017 16.357 4.74322 20.9981 10.5098 20.9981C14.2232 20.9981 17.4849 19.0743 19.355 16.1714C19.438 16.042 19.3453 15.8711 19.1915 15.8711ZM21.3619 10.3462L17.8975 7.61185C17.7681 7.50932 17.5801 7.60209 17.5801 7.76566V9.62113H9.91412C9.8067 9.62113 9.71881 9.70902 9.71881 9.81644V11.1836C9.71881 11.2911 9.8067 11.3789 9.91412 11.3789H17.5801V13.2344C17.5801 13.398 17.7706 13.4908 17.8975 13.3882L21.3619 10.6538C21.3852 10.6356 21.4041 10.6122 21.4171 10.5856C21.4301 10.5589 21.4368 10.5297 21.4368 10.5C21.4368 10.4704 21.4301 10.4411 21.4171 10.4145C21.4041 10.3878 21.3852 10.3645 21.3619 10.3462Z" />
    </svg>
  );
}

export default LogoutIcon;
