// Third-party
import { useState, ChangeEvent, InputHTMLAttributes, forwardRef, ForwardedRef } from 'react';
import classNames from 'classnames';
import { Tooltip } from 'react-tooltip';

// App
import { OpenEyeIcon, ClosedEyeIcon } from './assets/icons';

interface IError {
  showOutline: boolean;
}

interface ICssClasses {
  input: string; // CSS classes
  label: string; // CSS classes
}

type InputPasswordProps = {
  cssClasses?: Partial<ICssClasses>;
  labelText?: string;
  canShowPassword?: boolean;
  disablePassworPatternTip?: boolean;
  error?: Partial<IError>;
};

const InputPassword = forwardRef(
  (
    {
      labelText,
      cssClasses,
      canShowPassword,
      disablePassworPatternTip,
      error,
      name,
      onChange = () => ({}),
      ...inputHtmlProps
    }: InputPasswordProps & InputHTMLAttributes<HTMLInputElement>,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    /*
  **** Component organization ****

   └── Declaration of generic hooks (e.g., useNavigate)
   └── State declaration
   └── Side effects (e.g., useEffect)
   └── Memoization (e.g., useMemo)
   └── Handlers (e.g., useCallback)
   └── JSX
   */

    // └── Declaration of generic hooks (e.g., useNavigate)
    // State to control password visibility
    const [showPassword, setShowPassword] = useState<boolean>(false);
    // States for password validations
    const [minLengthValidation, setMinLengthValidation] = useState<boolean>(false);
    const [upperCaseValidation, setUpperCaseValidation] = useState<boolean>(false);
    const [lowerCaseValidation, setLowerCaseValidation] = useState<boolean>(false);
    const [numberValidation, setNumberValidation] = useState<boolean>(false);
    const [symbolValidation, setSymbolValidation] = useState<boolean>(false);

    // Handle onChange password input
    const handleChangeWithValidation = (event: ChangeEvent<HTMLInputElement>) => {
      const inputValue = event.target.value;

      const isMinLengthValid = inputValue.length >= 8;
      const hasUppercaseLetter = /[A-Z]/.test(inputValue);
      const hasLowercaseLetter = /[a-z]/.test(inputValue);
      const hasNumber = /\d/.test(inputValue);
      const hasSymbol = /[!@#$%^&*()\-=_+[\]{};':"\\|,.<>/?~`¨´]/.test(inputValue);

      setMinLengthValidation(isMinLengthValid);
      setUpperCaseValidation(hasUppercaseLetter);
      setLowerCaseValidation(hasLowercaseLetter);
      setNumberValidation(hasNumber);
      setSymbolValidation(hasSymbol);

      // Call to the onChange of the reactHook form coming from the return of the register function
      onChange(event);
    };

    return (
      <label
        className={classNames(
          'font-inter flex flex-col justify-start text-sm font-medium relative h-fit text-[#374151] overflow-visible w-full',
          cssClasses?.label
        )}
      >
        <div className="flex gap-2 align-center min-w-full min-h-full">
          <span className="truncate">{labelText}</span>

          {/* Password Questionmark Tooltip */}
          {!disablePassworPatternTip && (
            <div>
              <div
                className="bg-secondary w-5 h-5 rounded-full text-white flex justify-center align-center cursor-pointer"
                data-tooltip-id={`my-tooltip-multiline-${name}`}
              >
                ?
              </div>
              {/* Multi-line tooltip father div */}
              <Tooltip id={`my-tooltip-multiline-${name}`} float style={{ zIndex: '2000' }}>
                {/* tooltip line */}
                <p className={minLengthValidation ? 'text-[#4CAF50] font-bold' : 'text-gray-400'}>
                  Mínimo de 8 caracteres{' '}
                  <svg
                    className={minLengthValidation ? 'inline' : 'hidden'}
                    xmlns="http://www.w3.org/2000/svg"
                    height="16px"
                    width="16px"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="none"
                  >
                    <circle cx="12" cy="12" r="10" fill="#4CAF50" />
                    <path
                      d="M8 12L10 14L16 8"
                      stroke="#FFFFFF"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </p>
                {/* tooltip line */}
                <p className={upperCaseValidation ? 'text-[#4CAF50] font-bold' : 'text-gray-400'}>
                  Requer letra maiúscula{' '}
                  <svg
                    className={upperCaseValidation ? 'inline' : 'hidden'}
                    xmlns="http://www.w3.org/2000/svg"
                    height="16px"
                    width="16px"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="none"
                  >
                    <circle cx="12" cy="12" r="10" fill="#4CAF50" />
                    <path
                      d="M8 12L10 14L16 8"
                      stroke="#FFFFFF"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </p>
                {/* tooltip line */}
                <p className={lowerCaseValidation ? 'text-[#4CAF50] font-bold' : 'text-gray-400'}>
                  Requer letra minúscula{' '}
                  <svg
                    className={lowerCaseValidation ? 'inline' : 'hidden'}
                    xmlns="http://www.w3.org/2000/svg"
                    height="16px"
                    width="16px"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="none"
                  >
                    <circle cx="12" cy="12" r="10" fill="#4CAF50" />
                    <path
                      d="M8 12L10 14L16 8"
                      stroke="#FFFFFF"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </p>
                {/* tooltip line */}
                <p className={numberValidation ? 'text-[#4CAF50] font-bold' : 'text-gray-400'}>
                  Requer número{' '}
                  <svg
                    className={numberValidation ? 'inline' : 'hidden'}
                    xmlns="http://www.w3.org/2000/svg"
                    height="16px"
                    width="16px"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="none"
                  >
                    <circle cx="12" cy="12" r="10" fill="#4CAF50" />
                    <path
                      d="M8 12L10 14L16 8"
                      stroke="#FFFFFF"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </p>
                {/* tooltip line */}
                <p className={symbolValidation ? 'text-[#4CAF50] font-bold' : 'text-gray-400'}>
                  Requer símbolo{' '}
                  <svg
                    className={symbolValidation ? 'inline' : 'hidden'}
                    xmlns="http://www.w3.org/2000/svg"
                    height="16px"
                    width="16px"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="none"
                  >
                    <circle cx="12" cy="12" r="10" fill="#4CAF50" />
                    <path
                      d="M8 12L10 14L16 8"
                      stroke="#FFFFFF"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </p>
              </Tooltip>
            </div>
          )}
        </div>

        <input
          name={name}
          className={classNames(
            'bg-[#FBFCFD] border border-[#D1D5DB] text-[#37415] text-sm rounded-lg focus:outline outline-1 outline-secondary w-full p-2.5 mt-1 h-11 disabled:text-gray-500  read-only:text-gray-500 disabled:border-[#EEE] read-only:border-[#EEE]',
            cssClasses?.input,
            {
              'shadow-red-400 shadow-[0_0_0_2px] border-opacity-0 !outline-0': error?.showOutline,
            }
          )}
          onChange={handleChangeWithValidation}
          ref={ref}
          {...inputHtmlProps}
          type={canShowPassword && showPassword ? 'text' : 'password'} // Input type (can be text or the provided type)
        />
        {canShowPassword && (
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            className="absolute top-11 right-3 transform -translate-y-1/2 bg-transparent border-none cursor-pointer"
          >
            {showPassword ? <OpenEyeIcon /> : <ClosedEyeIcon />}
          </button>
        )}
      </label>
    );
  }
);

InputPassword.displayName = 'InputPassword';

export default InputPassword;
