// Third-party
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

// App
import rootReducer from './reducers';

// Create a Saga middleware instance
const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

// Create the Redux store
export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(middleware),
});

// Run the sagas

// Export store types
export type RootState = ReturnType<typeof store.getState>;

export const initStore = ({ preloadedState }: { preloadedState?: RootState }) => {
  const sagaMiddleware = createSagaMiddleware();
  const middleware = [sagaMiddleware];

  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).concat(middleware),
    preloadedState,
  });

  return store;
};

// Export store
export default store;
