import { useCallback, useMemo, useState } from 'react';
import usePersistedState from './usePersistedState';
import getParsedLocalStorageItemUtil from 'utils/getParsedLocalStorageItem.util';

interface IUseFilterSTatesParams<TState extends object> {
  filtersStateKey: string;
  initalState: TState;
}

function useFilterStates<TStateStructure extends object>({
  initalState,
  filtersStateKey,
}: IUseFilterSTatesParams<TStateStructure>) {
  const [tempFilterState, setTempFilterState] = useState<TStateStructure>(
    getParsedLocalStorageItemUtil(filtersStateKey, initalState)
  );
  const [submittedFilterState, setSubmittedFilterState] = usePersistedState<TStateStructure>(
    filtersStateKey,
    initalState
  );

  const changeTempState = useCallback(
    (stateKeyToChange: keyof TStateStructure, value: TStateStructure[keyof TStateStructure]) => {
      setTempFilterState((prevTempFilterState) => {
        const copyTempFilterState = { ...prevTempFilterState };

        copyTempFilterState[stateKeyToChange] = value;

        return copyTempFilterState;
      });
    },
    []
  );

  const submitStates = useCallback(() => {
    setSubmittedFilterState(tempFilterState);    
  }, [setSubmittedFilterState, tempFilterState]);

  const cleanStates = useCallback(() => {
    setTempFilterState(initalState);
    setSubmittedFilterState(initalState);
  }, [initalState, setSubmittedFilterState]);

  const hasFilterStatesToSubmit = useMemo(() => {
    let objectsStatesIsEqual = true;

    Object.keys(submittedFilterState).forEach((submittedFilterStateKey: string) => {
      if (
        submittedFilterState[submittedFilterStateKey as keyof typeof submittedFilterState] !==
        tempFilterState[submittedFilterStateKey as keyof typeof submittedFilterState]
      ) {
        objectsStatesIsEqual = false;
      }
    });

    return !objectsStatesIsEqual;
  }, [submittedFilterState, tempFilterState]);

  const hasFilterStatesSubmitted = useMemo(() => {
    let objectsStatesIsEqual = true;

    Object.keys(submittedFilterState).forEach((submittedFilterStateKey: string) => {
      if (
        submittedFilterState[submittedFilterStateKey as keyof typeof submittedFilterState] !==
        initalState[submittedFilterStateKey as keyof typeof submittedFilterState]
      ) {
        objectsStatesIsEqual = false;
      }
    });

    return !objectsStatesIsEqual;
  }, [initalState, submittedFilterState]);

  return {
    tempFilterState,
    submittedFilterState,
    hasFilterStatesToSubmit,
    hasFilterStatesSubmitted,
    changeTempState,
    submitStates,
    cleanStates,
  };
}

export default useFilterStates;
