// Third-party
import { create } from 'zustand';

// App
import { IAuthState } from './interfaces';
import { cleanTokenTimeoutId, setTokenTimeoutId, setIsRefreshToken } from './actions';

const useAuthStore = create<IAuthState>((set, get) => ({
  // isRefreshToken
  isRefreshToken: 0,
  setIsRefreshToken: setIsRefreshToken(set),
  // tokenTimeoutId
  tokenTimeoutId: null,
  setTokenTimeoutId: setTokenTimeoutId(set),
  cleanTokenTimeoutId: cleanTokenTimeoutId(set),
}));

export default useAuthStore;
