import { HashLoader } from 'react-spinners';

type Props = {
  className?: string;
};

function ModalLoading({ className }: Props) {
  /*
  **** Component organization ****

   └── Declaration of generic hooks (e.g., useNavigate)
   └── State declaration
   └── Side effects (e.g., useEffect)
   └── Memoization (e.g., useMemo)
   └── Handlers (e.g., useCallback)
   └── JSX
   */

  return (
    <div
      role="status"
      className="bg-black bg-opacity-50 fixed inset-0 flex justify-center items-center z-[99999999]"
    >
      <HashLoader color="white" />
    </div>
  );
}

export default ModalLoading;
