import { Dayjs } from "dayjs";

export interface ICreateSalePayload<ComplementType = string> {
  clientId: number;
  paymentMethodId: number;  
  serviceId: number;
  companyId: number;
  amount: number;
  quantity: number;
  totalAmount: number;
  dataEvent: Date | string | Dayjs;
  description: string;
  complement: ComplementType;
  saleType : saleTypeEnum;
  name?: string;
  cpf?: string; 
  phone?: string;
}

export enum saleTypeEnum {
  CLIENT = 'Cliente',
  PRIVATE = 'Particular'
}

export enum saleTypeEnumCompare {
  CLIENT = 'CLIENT',
  PRIVATE = 'PRIVATE'
}