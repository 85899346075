/* eslint-disable react-hooks/rules-of-hooks */
import { CloseIcon } from 'assets/icons';
import Modal from 'components/Modal';
import { PAGES_ROUTES } from 'constants/routes';
import { Navigate } from 'react-router-dom';
import useCompanySelectedStore from 'store/client/companySelected/useCompanySelectedStore';
import { selectCompanySelected } from 'store/client/companySelected/selectors';
import { useGetSalesId } from 'store/server/company/queries';
import { convertToBrazilianCurrencyFormat } from 'utils/currency.utils';
import dayjs from 'dayjs';

import InputTextArea from 'components/InputTextArea';
import LoadingListSkeleton from './components/LoadingListSkeleton';
import { IPayment, ISale } from 'interfaces/companyTypes';
import { dateUtils } from 'utils/formatDate';

const { DATE_TIME_DISPLAY_FORMAT } = dateUtils.constants;

interface IProps {
  setOpenModal: (modalState: { display: boolean; sale: ISale | null }) => void;
  openModal: { display: boolean; sale: ISale | null };
}

function ListPaymentsModal({ setOpenModal, openModal }: IProps) {
  const companySelected = useCompanySelectedStore(selectCompanySelected);

  const { sale } = openModal;

  if (!companySelected || !sale || !sale.id)
    return <Navigate to={PAGES_ROUTES.authenticated.commonInitialRoute} replace />;

  const { data: saleByID, isLoading: saleByIdIsLoading } = useGetSalesId(
    companySelected.id,
    sale.id
  );
  
  return (
    <Modal
      content={
        <>
          <button
            className="absolute top-[15px] right-4"
            onClick={() => setOpenModal({ display: false, sale: null })}
          >
            <CloseIcon />
          </button>
          <div className="flex flex-col justify-center items-center gap-y-[30px] pr-2 pb-[41px]">
            <h2 className="text-xl text-primary font-semibold">Recebimentos</h2>
            <InputTextArea
              labelText={'Descrição da Venda'}
              value={sale.description}
              readOnly
              disabled
            />

            {saleByIdIsLoading ? (
              <LoadingListSkeleton />
            ) : (
              <table className="w-full text-sm text-left text-gray-500 rounded-lg overflow-hidden">
                <thead className="font-inter text-[10px] text-primary font-bold uppercase bg-gray-50 w-full">
                  <tr>
                    <th
                      scope="col"
                      className="h-full px-[8px] sm:px-[24px] py-3 cursor-pointer hover:bg-gray-100 w-fit"
                    >
                      <span className="flex flex-row items-center gap-2 truncate">Data</span>
                    </th>
                    <th
                      scope="col"
                      className="h-full px-[8px] sm:px-[24px] py-3 cursor-pointer hover:bg-gray-100 w-fit"
                    >
                      <span className="flex flex-row items-center gap-2 truncate">VALOR RECEBIDO</span>
                    </th>
                    <th
                      scope="col"
                      className="h-full px-[8px] sm:px-[24px] py-3 cursor-pointer inline-block hover:bg-gray-100 w-[80px] sm:w-fit"
                    >
                      <span className="flex flex-row items-center gap-2 truncate whitespace-normal">
                        FORMA DE RECEBIMENTO
                      </span>
                    </th>
                    <th
                      scope="col"
                      className="h-full px-[8px] sm:px-[24px] py-3 cursor-pointer  hover:bg-gray-100 w-[80px] sm:w-fit"
                    >
                      <span className="flex flex-row items-center gap-2 truncate whitespace-normal">
                        DESCRIÇÃO
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody className="w-full">
                  {saleByID?.payments.map((paymentForListing, paymentForListingIndex) => {                   
                    const showToolTip = paymentForListing?.note?.length > 40; 
                    return (
                      <tr
                        className="bg-white border-b hover:bg-gray-100 w-full h-[60px] text-xs"
                        key={`company-${paymentForListing.id}-row-${paymentForListingIndex}`}
                      >
                        <td className="px-[8px] sm:px-[24px] py-4 font-normal align-middle whitespace-nowrap w-fit">
                          {paymentForListing.dataPayment &&
                            dayjs
                              .utc(paymentForListing.dataPayment)
                              .format(DATE_TIME_DISPLAY_FORMAT)}
                        </td>
                        <td className="px-[8px] sm:px-[24px] py-4 font-normal align-middle whitespace-nowrap w-fit">
                          {convertToBrazilianCurrencyFormat(paymentForListing?.amount || '0')}
                        </td>
                        <td className="px-[8px] sm:px-[24px] py-6 font-normal align-middle whitespace-normal w-[80px] sm:w-fit">
                        {paymentForListing?.paymentType?.description || '-'}
                        </td>
                        <td className="px-[8px] sm:px-[24px] py-6 font-normal align-middle whitespace-normal w-[80px] sm:w-fit relative">
                          <div className="w-full h-full" title={paymentForListing?.note}>
                            {paymentForListing?.note && showToolTip
                              ? `${paymentForListing?.note.slice(0, 40)}...`
                              : paymentForListing?.note}
                          </div>
                          {showToolTip && (
                            <div className="absolute text-center py-4 px-4 top-0 md:top-4 bg-primary text-white opacity-0 hover:opacity-100 left-[-250px] md:left-[-400px] sm:hover:left-[-400px] max-w-[100vw] sm:max-w-none md:min-w-[580px] w-auto h-fit min-h-[120px] md:min-h-[80px] overflow-visible z-10">
                              {paymentForListing?.note} 
                            </div>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </>
      }
      css={{
        modal: 'relative md:max-h-[600px] md:max-w-[600px] h-full w-full md:my-auto overflow-y-auto' ,
      }}
    />
  );
}

export default ListPaymentsModal;
