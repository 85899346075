import { SaveIcon, SimpleSpinnerIcon } from 'assets/icons';
import React from 'react';

interface ISaveButtonProps {
  text: string;
  isLoading: boolean;
  isDisabled: boolean;
  showSpinner: boolean;
  onClick?: () => void; 
  type?: "button" | "submit" | "reset";
}

const SaveButton: React.FC<ISaveButtonProps> = ({
  text,
  isLoading,
  isDisabled,
  showSpinner,
  onClick,
  type = "submit"
}) => {
  return (
    <button
      type={type}
      className={`bg-primary text-xs font-bold leading-4 ${isDisabled ? 'disabled:bg-[#D1D5DB] cursor-pointer' : ''} text-buttontextcolor py-2 pl-4 pr-[18px] rounded-lg flex justify-center items-center h-[42px] max-w-[147px] tracking-[0.6px]`}
      disabled={isDisabled}
      onClick={onClick}
    >
      {text}
      {showSpinner || isLoading ? (        
        <SimpleSpinnerIcon className="ml-[18px] z-10" />
      ) : (
        <SaveIcon className="ml-4 !text-buttontextcolor" />
      )}
    </button>
  );
};

export default SaveButton;