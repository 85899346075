//Third-party
import { DropDownArrowIcon } from 'assets/icons';
import classNames from 'classnames';
import { ReactNode, useState } from 'react';

// App

type ModalProps = {
  title: string;
  children: ReactNode;
  startOpen?: boolean;
};

function ToogleListElement({ title, children, startOpen = false }: ModalProps) {
  /*
  **** Component organization ****

   └── Declaration of generic hooks (e.g., useNavigate)
   └── State declaration
   └── Side effects (e.g., useEffect)
   └── Memoization (e.g., useMemo)
   └── Handlers (e.g., useCallback)
   └── TSX
   */

  // └── State declaration
  const [showContent, setShowContent] = useState(startOpen);

  return (
    <div className="font-inter">
      <button
        type="button"
        onClick={() => setShowContent(!showContent)}
        className="flex items-center gap-x-2 w-fit"
      >
        <DropDownArrowIcon
          className={classNames('!text-secondary min-w-[14px] h-2 transform -rotate-90', {
            '-rotate-0': showContent,
          })}
        />
        <h3 className="text-sm text-primary normal-case font-bold">{title}</h3>
      </button>
      <div
        className={classNames('h-0 overflow-hidden duration-200 pl-[30px] pt-2', {
          '!h-fit': showContent,
        })}
      >
        {children}
      </div>
    </div>
  );
}

export default ToogleListElement;
