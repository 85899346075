// Third-party
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

// App

function LoadingSkeleton() {
  /*
  **** Component organization ****

   └── Declaration of generic hooks (e.g., useNavigate)
   └── State declaration
   └── Side effects (e.g., useEffect)
   └── Memoization (e.g., useMemo)
   └── Handlers (e.g., useCallback)
   └── JSX
   */

  return (
    <div className="w-full min-h-screen h-full flex flex-col justify-start gap-y-[19px] items-start pt-[44px] md:pt-[42px]">
      <div className="self-center w-full max-w-[800px] gap-x-[10px] xl:gap-x-[20px] gap-y-10 flex flex-col justify-center">
        <div className="flex flex-col md:grid md:grid-cols-12 gap-x-[10px] xl:gap-x-[20px] md:gap-y-2">
          <Skeleton
            height={44}
            containerClassName="w-full row-start-2 row-end-3 col-start-1 col-end-5 md:w-[170px] md:mt-[1px]"
            className="!rounded-lg"
          />
          <Skeleton
            height={44}
            containerClassName="w-full row-start-3 row-end-4 col-start-1 col-end-7  mt-[20px]"
            className="!rounded-lg"
          />
          <Skeleton
            height={44}
            containerClassName="w-full row-start-3 row-end-4 col-start-7 col-end-13  mt-[20px]"
            className="!rounded-lg"
          />
          <Skeleton
            height={44}
            containerClassName="w-full row-start-4 row-end-5 col-start-1 col-end-5  mt-[20px]"
            className="!rounded-lg"
          />
          <Skeleton
            height={44}
            containerClassName="w-full row-start-5 row-end-6 col-start-1 col-end-7  mt-[20px]"
            className="!rounded-lg"
          />
          <Skeleton
            height={44}
            containerClassName="w-full row-start-5 row-end-6 col-start-7 col-end-9  mt-[20px]"
            className="!rounded-lg"
          />
          <Skeleton
            height={44}
            containerClassName="w-full row-start-5 row-end-6 col-start-9 col-end-[13]  mt-[20px]"
            className="!rounded-lg"
          />
          <Skeleton
            height={44}
            containerClassName="w-full row-start-6 row-end-7 col-start-1 col-end-6  mt-[20px]"
            className="!rounded-lg"
          />
          <Skeleton
            height={44}
            containerClassName="w-full row-start-6 row-end-7 col-start-6 col-end-10  mt-[20px]"
            className="!rounded-lg"
          />
          <Skeleton
            height={44}
            containerClassName="w-full row-start-6 row-end-7 col-start-10 col-end-12  mt-[20px]"
            className="!rounded-lg"
          />
          <Skeleton
            height={44}
            containerClassName="w-full row-start-7 row-end-8 col-start-1 col-end-5  mt-[20px]"
            className="!rounded-lg"
          />
        </div>
        <div className="flex  justify-between md:justify-end items-center md:gap-x-7 lg:gap-x-11 md:row-start-[9] !w-full md:col-start-8 md:col-end-13 mt-[11px] md:mt-[27px]">
          <Skeleton width={68} height={20} />
          <Skeleton width={123} height={42} className="!rounded-lg" />
        </div>
      </div>
    </div>
  );
}

export default LoadingSkeleton;
