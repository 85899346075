import React, {
  ForwardedRef,
  ReactNode,
  TextareaHTMLAttributes,
  forwardRef,
  useState,
  useEffect,
} from 'react';
import classNames from 'classnames';

interface IError {
  showOutline: boolean;
}

interface ICssClasses {
  textarea: string;
  label: string;
  textLabel: string;
  icon: string;
}

interface InputTextAreaProps {
  cssClasses?: Partial<ICssClasses>;
  labelText: ReactNode | string;
  error?: Partial<IError>;
  maxLength?: number;
}

const InputTextArea = forwardRef(
  (
    {
      labelText,
      cssClasses,
      error,
      maxLength,
      ...textareaHtmlProps
    }: InputTextAreaProps & TextareaHTMLAttributes<HTMLTextAreaElement>,
    ref: ForwardedRef<HTMLTextAreaElement>
  ) => {
    const [charCount, setCharCount] = useState(0);

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setCharCount(event.target.value.length);
      if (textareaHtmlProps.onChange) {
        textareaHtmlProps.onChange(event);
      }
    };

    useEffect(() => {
      setCharCount(
        typeof textareaHtmlProps.value === 'string' ? textareaHtmlProps.value.length : 0
      );
    }, [textareaHtmlProps.value]);

    useEffect(() => {
      if (
        textareaHtmlProps.value &&
        typeof textareaHtmlProps.value === 'string' &&
        textareaHtmlProps.value.length > 0
      ) {
        setCharCount(textareaHtmlProps.value.length);
      } else {
        setCharCount(0);
      }
    }, [textareaHtmlProps.value]);

    return (
      <label
        className={classNames(
          'font-inter text-[#374151] text-sm font-medium flex flex-col justify-start w-full m-0 truncate !overflow-visible relative cursor-pointer ',

          cssClasses?.label,
          {
            'gap-y-1': labelText,
          }
        )}
        htmlFor={textareaHtmlProps.name}
      >
        <span className={classNames('truncate w-fit', cssClasses?.textLabel)}>{labelText}</span>
        <textarea
          className={classNames(
            'border p-2 rounded text-primary max-h-[200px] min-h-[100px]',
            'bg-[#FBFCFD]  disabled:bg-gray-100  read-only:bg-gray-100 border text-sm rounded-lg focus:outline outline-1 outline-secondary w-full  disabled:text-black  read-only:text-black disabled:border-[#EEE] read-only:border-[#EEE]',
            cssClasses?.textarea,
            {
              'outline-none ring-2 ring-red-500': error?.showOutline,
            }
          )}
          ref={ref}
          {...textareaHtmlProps}
          maxLength={maxLength}
          onChange={handleChange}
        />
        {maxLength && (
          <div className={classNames('absolute right-0 bottom-[-20px] text-sm text-gray-600')}>
            {`${charCount}/${maxLength}`}
          </div>
        )}
      </label>
    );
  }
);

InputTextArea.displayName = 'TextArea';

export default InputTextArea;
