// Third-party
import { ForwardedRef, InputHTMLAttributes, forwardRef } from 'react';
import classNames from 'classnames';

// App
import './style.css';
import { UploadIcon } from './assets/icons';

interface IError {
  showOutline: boolean;
}

interface ICssClasses {
  input: string; // CSS classes
  label: string; // CSS classes
}

type InputProfileImageProps = {
  cssClasses?: Partial<ICssClasses>;
  imageSrc?: string;
  labelText?: string; // Label text
  error?: Partial<IError>;
};

const InputProfileImage = forwardRef(
  (
    {
      labelText,
      cssClasses,
      imageSrc,
      error,
      ...inputHtmlProps
    }: InputProfileImageProps & InputHTMLAttributes<HTMLInputElement>,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    /*
  **** Component organization ****

   └── Declaration of generic hooks (e.g., useNavigate)
   └── State declaration
   └── Side effects (e.g., useEffect)
   └── Memoization (e.g., useMemo)
   └── Handlers (e.g., useCallback)
   └── JSX
   */

    return (
      <label
        className={classNames(
          'font-inter flex flex-col justify-start text-sm font-medium text-white overflow-hidden relative !w-[106px] !h-[106px] rounded-full bg-[#1F2937] bg-opacity-50 items-center cursor-pointer group mb-5',
          cssClasses?.label
        )}
      >
        <div className="flex gap-2 align-center min-w-full min-h-full">
          {imageSrc ? (
            <div className="relative w-full h-full">
              <img alt="profile" src={imageSrc} className="w-full h-full object-cover z-10" />
              <div className="hidden group-hover:flex absolute inset-0 justify-center items-center">
                <p className="relative text-sm text-white z-50" style={{ textAlign: 'center' }}>
                  Alterar Foto
                </p>
                <div className="absolute inset-0 bg-opacity-50 bg-[#1F2937] z-20" />
              </div>
            </div>
          ) : (
            <div className="flex flex-col justify-center items-center gap-1 min-w-full min-h-full">
              <UploadIcon className="text-red" />
              {labelText}
            </div>
          )}
        </div>

        <input
          className={classNames('!hidden', cssClasses?.input, {
            'shadow-warningcolor shadow-[0_0_0_2px] border-opacity-0 !outline-0':
              error?.showOutline,
          })}
          ref={ref}
          {...inputHtmlProps}
          type="file"
        />
      </label>
    );
  }
);

InputProfileImage.displayName = 'InputProfileImage';

export default InputProfileImage;
