/* eslint-disable react/jsx-no-undef */
import { CloseIcon } from 'assets/icons';
import classNames from 'classnames';
import InputText from 'components/InputText';
import dayjs from 'dayjs';
import { ExpenseStatusCompareEnum, IExpense } from 'interfaces/companyTypes';
import { convertToBrazilianCurrencyFormat, toFloat } from 'utils/currency.utils';
import InputTextArea from 'components/InputTextArea';
import Modal from 'components/Modal';
import { dateUtils } from 'utils/formatDate';

const { DATE_TIME_DISPLAY_FORMAT } = dateUtils.constants;

interface IProps {
  setOpenModal: (modalState: { display: boolean; expense: IExpense | null }) => void;
  expenseData: { display: boolean; expense: IExpense | null };
}

function ExpenseDetailsModal({ setOpenModal, expenseData }: IProps) {
  const amoutPending = toFloat(expenseData.expense?.amount) - toFloat(expenseData.expense?.amountPay);
  console.log(expenseData.expense);
  return (
    <Modal
      content={
        <>
          <button
            className="absolute top-[15px] right-4"
            onClick={() => setOpenModal({ display: false, expense: null })}
          >
            <CloseIcon />
          </button>
          <div className="flex flex-col justify-center items-center gap-y-[30px] pr-2 pb-[41px]">
            <h2 className="text-xl text-primary font-semibold">Resumo da Despesa</h2>
            <div className="flex flex-col justify-center gap-y-3 w-full">
              <label
                className={'text-primary text-base font-bold leading-5 flex flex-col gap-y-[9px]'}
              >
                <form
                  noValidate
                  className="md:items-start items-center w-full max-w-[600px] gap-x-[10px] xl:gap-x-[20px]  flex flex-col"
                >
                  {/* MAIN CONTENT */}
                  <div className="md:w-[100%] w-[70%] ">
                    <div className="flex flex-col w-full gap-y-5">
                      {/* CATEGORIAS DE DESPESAS*/}
                      <InputText
                        labelText="Categoria da Despesa*"
                        value={expenseData.expense?.category}
                        cssClasses={{
                          label: 'max-w-[200px] md:max-w-[350px]  h-[44px]',
                          input: classNames(
                            'w-full max-w-[200px] md:max-w-[350px] h-[44px] bg-[#FBFCFD] border border-[#D1D5DB] rounded-lg text-sm p-2.5'
                          ),
                        }}
                        disabled
                      />

                      {/* PAGAMENTO */}
                      <InputText
                        labelText="Pagamento*"
                        value={expenseData.expense?.paymethod}
                        cssClasses={{
                          label: 'max-w-[200px] md:max-w-[350px]  h-[44px]',
                          input: classNames(
                            'w-full max-w-[200px] md:max-w-[350px] h-[44px] bg-[#FBFCFD] border border-[#D1D5DB] rounded-lg text-sm p-2.5'
                          ),
                        }}
                        disabled
                      />

                      {/* FORMA DE PAGAMENTO */}
                      <InputText
                        labelText="Forma de Pagamento*"
                        value={expenseData.expense?.paytype}
                        cssClasses={{
                          label: 'max-w-[200px] md:max-w-[350px]  h-[44px]',
                          input: classNames(
                            'w-full max-w-[200px] md:max-w-[350px] h-[44px] bg-[#FBFCFD] border border-[#D1D5DB] rounded-lg text-sm p-2.5'
                          ),
                        }}
                        disabled
                      />
                     
                      {/* Data da Despesa */}
                      <InputText
                        labelText="Data da Despesa*"
                        value={dayjs.utc(expenseData.expense?.dateEvent).format(
                          DATE_TIME_DISPLAY_FORMAT
                        )}
                        cssClasses={{
                          label: 'max-w-[200px]  h-[44px]',

                          input: classNames(
                            'w-full max-w-[200px] md:max-w-[350px] h-[44px] bg-[#FBFCFD] border border-[#D1D5DB] rounded-lg text-sm p-2.5'
                          ),
                        }}
                        disabled
                      />
                      {/* Valor */}
                      <InputText
                        labelText="Valor*"
                        value={
                          expenseData.expense?.amount &&
                          convertToBrazilianCurrencyFormat(expenseData.expense?.amount)
                        }
                        cssClasses={{
                          label: 'max-w-[200px]  h-[44px]',
                          input: 'w-full max-w-[200px] md:max-w-[350px]',
                        }}
                        disabled
                      />
                      {/* Valor Baixado */}
                      <InputText
                        labelText="Valor Pago*"
                        value={
                          expenseData.expense?.amountPay &&
                          convertToBrazilianCurrencyFormat(expenseData.expense?.amountPay)
                        }
                        cssClasses={{
                          label: 'max-w-[200px]  h-[44px]',
                          input: 'w-full max-w-[200px] md:max-w-[350px]',
                        }}
                        disabled
                      />
                      {/* Valor Pendente */}
                      
                      <InputText
                        labelText="Valor Pendente*"
                        value={
                          convertToBrazilianCurrencyFormat( amoutPending.toString() )
                        }
                        cssClasses={{
                          label: 'max-w-[200px]  h-[44px]',
                          input: 'w-full max-w-[200px] md:max-w-[350px]',
                        }}
                        disabled
                      />

                      {expenseData.expense?.receivePay &&
                        expenseData.expense?.status === ExpenseStatusCompareEnum.PAID && (
                          <InputText
                            labelText="Data do pagamento"
                            value={dayjs.utc(expenseData.expense?.receivePay).format(
                              DATE_TIME_DISPLAY_FORMAT
                            )}
                            cssClasses={{
                              label: 'max-w-[200px]  h-[44px]',
                              input: 'w-full max-w-[200px] md:max-w-[350px]',
                            }}
                            disabled
                          />
                        )}
                      {/* Descrição da Despesa */}
                      <InputTextArea
                        labelText="Descrição"
                        value={expenseData.expense?.description}
                        cssClasses={{
                          textarea: 'min-h-[100px] max-h-[250px] md:max-w-[350px]',
                        }}
                        disabled
                      />

                        <table className="w-full text-sm text-left text-gray-500 rounded-lg overflow-hidden mt-[20px]">
                          <thead className="font-inter text-[10px] text-primary font-bold uppercase bg-gray-50 w-full">
                            <tr>
                              <th
                                scope="col"
                                className="h-full px-[8px] sm:px-[24px] py-3 cursor-pointer hover:bg-gray-100 w-fit"
                              >
                                <span className="flex flex-row items-center gap-2 truncate">Data</span>
                              </th>
                              <th
                                scope="col"
                                className="h-full px-[8px] sm:px-[24px] py-3 cursor-pointer hover:bg-gray-100 w-fit"
                              >
                                <span className="flex flex-row items-center gap-2 truncate">VALOR PAGO</span>
                              </th>
                              <th
                                scope="col"
                                className="h-full px-[8px] sm:px-[24px] py-3 cursor-pointer inline-block hover:bg-gray-100 w-[80px] sm:w-fit"
                              >
                                <span className="flex flex-row items-center gap-2 truncate whitespace-normal">
                                  FORMA DE PAGAMENTO
                                </span>
                              </th>
                              {/* <th
                                scope="col"
                                className="h-full px-[8px] sm:px-[24px] py-3 cursor-pointer  hover:bg-gray-100 w-[80px] sm:w-fit"
                              >
                                <span className="flex flex-row items-center gap-2 truncate whitespace-normal">
                                  DESCRIÇÃO
                                </span>
                              </th> */}
                            </tr>
                          </thead>
                          <tbody className="w-full">
                            {expenseData.expense?.payments.map((paymentForListing: { description: string | any[]; id: any; datapagamento: string | number | Date | dayjs.Dayjs | null | undefined; valor: any; metodopagamento: any; }, paymentForListingIndex: any) => {                   
                              const showToolTip = paymentForListing?.description?.length > 40; 
                              return (
                                <tr
                                  className="bg-white border-b hover:bg-gray-100 w-full h-[60px] text-xs"
                                  key={`company-${paymentForListing.id}-row-${paymentForListingIndex}`}
                                >
                                  <td className="px-[8px] sm:px-[24px] py-4 font-normal align-middle whitespace-nowrap w-fit">
                                    {paymentForListing.datapagamento &&
                                      dayjs
                                        .utc(paymentForListing.datapagamento)
                                        .format(DATE_TIME_DISPLAY_FORMAT)}
                                  </td>
                                  <td className="px-[8px] sm:px-[24px] py-4 font-normal align-middle whitespace-nowrap w-fit">
                                    {convertToBrazilianCurrencyFormat(paymentForListing?.valor || '0')}
                                  </td>
                                  <td className="px-[8px] sm:px-[24px] py-6 font-normal align-middle whitespace-normal w-[80px] sm:w-fit">
                                  {paymentForListing?.metodopagamento || '-'}
                                  </td>
                                  {/* <td className="px-[8px] sm:px-[24px] py-6 font-normal align-middle whitespace-normal w-[80px] sm:w-fit relative">
                                    <div className="w-full h-full" title={paymentForListing?.descricao}>
                                      {paymentForListing?.descricao && showToolTip
                                        ? `${paymentForListing?.descricao.slice(0, 40)}...`
                                        : paymentForListing?.descricao}
                                    </div>
                                    {showToolTip && (
                                      <div className="absolute text-center py-4 px-4 top-0 md:top-4 bg-primary text-white opacity-0 hover:opacity-100 left-[-250px] md:left-[-400px] sm:hover:left-[-400px] max-w-[100vw] sm:max-w-none md:min-w-[580px] w-auto h-fit min-h-[120px] md:min-h-[80px] overflow-visible z-10">
                                        {paymentForListing?.descricao} 
                                      </div>
                                    )}
                                  </td> */}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>

                    </div>
                  </div>
                </form>
              </label>
            </div>
          </div>
        </>
      }
      css={{
        modal:
          'relative md:max-h-[600px] md:max-w-[600px] h-full w-full md:my-auto overflow-y-auto',
      }}
    />
  );
}

export default ExpenseDetailsModal;
