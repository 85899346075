// Third-party
import { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

//App
import { InputText, Pagination, Select } from 'components';
import { DeleteIcon, LoadingSpinIcon, ProfileIcon, SearchIcon } from 'assets/icons';
import LoadingListSkeleton from './components/LoadingListSkeleton';
import Skeleton from 'react-loading-skeleton';
import { useGetUser } from 'store/server/user/queries';
import { useGetCompanyList } from 'store/server/company/queries';
import { CompanyStatusEnum, ICompany, ICompanyListOrderAttribute } from 'interfaces/companyTypes';
import { cnpjMask } from 'utils/mask.utils';
import LibraryBuilding from 'assets/icons/LibraryBuilding.icon';
import usePersistedState from 'hooks/usePersistedState';
import useCompanySelectedStore from 'store/client/companySelected/useCompanySelectedStore';
import {
  selectSetCompanySelected,
  selectCompanySelected,
} from 'store/client/companySelected/selectors';
import { PAGES_ROUTES } from 'constants/routes';
import { screenConfig } from 'utils/screenConfig.util';
import { ORDER_ATTRIBUTTES, ascDescType } from 'constants/orderAttributtes';

const { ASC } = ORDER_ATTRIBUTTES;

const { MEDIUM_SCREEN_SIZE } = screenConfig.constants

function ListToSelect() {
  /*
  **** Component organization ****

   └── Declaration of generic hooks (e.g., useNavigate)
   └── State declaration
   └── Side effects (e.g., useEffect)
   └── Memoization (e.g., useMemo)
   └── Handlers (e.g., useCallback)
   └── JSX
   */

  // └── Declaration of generic hooks (e.g., useNavigate)
  const setCompanySelected = useCompanySelectedStore(selectSetCompanySelected);
  const companySelected = useCompanySelectedStore(selectCompanySelected);

  // └── State declaration

  // Pagination states
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = usePersistedState<number>('companyListToSelect.pageSize', 10);

  // Sort states
  const [orderAttribute, setOrderAttribute] = useState<ICompanyListOrderAttribute>('id');
  const [order, setOrder] = useState<ascDescType>(ASC);

  // └── State declaration
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);

  const [submitedFilters, setSubmitedFilters] = useState<{
    filter: string | null;
  }>({
    filter: selectedFilter,
  });

  const navigate = useNavigate();

  const { isLoading: userLoggedIsLoading } = useGetUser();
  const {
    data: companyListData,
    isLoading: companyListIsLoading,
    isFetching: companyListIsFetching,
  } = useGetCompanyList({
    start: page,
    size: pageSize,
    field: orderAttribute,
    order,
    filter: submitedFilters.filter,
  });

  // └── Side effects (e.g., useEffect)

  // └── Handlers (e.g., useCallback)
  const checkIfShowCards = useCallback(() => {
    if (window.innerWidth < MEDIUM_SCREEN_SIZE) {
      orderAttribute !== 'id' && setOrderAttribute('id');
      order !== ASC && setOrder(ASC);
    }
  }, [order, orderAttribute]);

  useEffect(() => {
    window.removeEventListener('resize', checkIfShowCards);
    window.addEventListener('resize', checkIfShowCards);

    return () => {
      window.removeEventListener('resize', checkIfShowCards);
    };
  }, [checkIfShowCards]);

  const showSkeletonLoading = useMemo(
    () => (!companyListData?.data && companyListIsLoading) || userLoggedIsLoading,
    [userLoggedIsLoading, companyListData, companyListIsLoading]
  );

  const showClearFiltersButton = useMemo(() => !!submitedFilters?.filter, [submitedFilters]);

  const handleClearFilters = useCallback(() => {
    setSelectedFilter(null);

    if (submitedFilters.filter) {
      setPage(0);
      setSubmitedFilters({
        filter: null,
      });
    }
  }, [submitedFilters.filter]);

  const hasFiltersToSubmit = useMemo(
    () => submitedFilters === null || submitedFilters.filter !== selectedFilter,
    [selectedFilter, submitedFilters]
  );

  const handleSetCompanySelected = useCallback(
    (company: ICompany) => {
      localStorage.removeItem('expensesListFilter');
      localStorage.removeItem('salesListFilter');
      setCompanySelected(companySelected?.id == company.id ? null : company);
      if (companySelected?.id !== company.id) {
        navigate(PAGES_ROUTES.authenticated.company.summary);
      }
    },
    [companySelected?.id, navigate, setCompanySelected]
  );

  return (
    <div
      className={classNames('h-full', {
        'overflow-hidden max-h-[calc(100vh-64px)] md:max-h-[calc(100vh-71px)]': showSkeletonLoading,
      })}
    >
      <div
        className={classNames(
          'pt-5 md:pt-[42px] p-[27px] md:p-8 w-full min-h-[calc(100vh-64px)] md:min-h-[calc(100vh-71px)]',
          {
            'overflow-hidden !h-screen': showSkeletonLoading,
          }
        )}
      >
        <h1 className="flex items-center gap-2.5 text-2xl font-semibold text-[#595959] mb-3 md:mb-[27px]">
          <ProfileIcon className="w-[22px] h-[24px] !text-secondary" /> Selecione uma empresa
        </h1>
        <div className="w-full flex flex-col md:flex-row justify-between mb-4 gap-3">
          {/* Filters */}
          <div
            className={classNames(
              'row-start-1 row-end-2 md:row-start-2 md:row-end-2 col-start-1 col-end-4 md:col-end-3 flex flex-col md:flex-row justify-start items-start gap-x-2.5 gap-y-2.5 w-full h-fit'
            )}
          >
            {/* Filter */}
            {showSkeletonLoading ? (
              <Skeleton
                height={42}
                baseColor="#f3f3f3"
                highlightColor="white"
                containerClassName="flex w-full md:max-w-[300px] h-[42px] md:row-start-1 md:row-end-2"
                className="!rounded-lg"
              />
            ) : (
              <InputText
                name="filter"
                type="text"
                placeholder="Busque por CNPJ ou por nome fantasia"
                onChange={async (event: { target: { value: string } }) =>
                  setSelectedFilter(event.target.value)
                }
                value={selectedFilter ?? ''}
                title={selectedFilter ?? ''}
                cssClasses={{
                  label:
                    'md:max-w-[300px] !flex-1 md:row-start-1 md:row-end-2 max-md:col-start-1 max-md:col-end-4',
                  input:
                    '!mt-0 w-full !h-[42px] !text-[#374151] !font-normal text-xs placeholder:font-normal placeholder:text-xs disabled:placeholder:!text-[#D1D5DB] truncate disabled:bg-gray-100',
                }}
                min={'18'}
                max={'18'}
                disabled={companyListIsLoading || companyListIsFetching}
              />
            )}

            {/* Button to filter */}
            {showSkeletonLoading ? (
              <Skeleton
                height={42}
                baseColor="#f3f3f3"
                highlightColor="white"
                containerClassName="flex w-full md:max-w-[56px] h-[42px] row-start-3 md:row-start-1 row-end-4 md:row-end-2 col-start-1 col-end-4 md:col-start-3 md:col-end-4"
                className="!rounded-lg"
              />
            ) : (
              <>
                <button
                  className={classNames(
                    'row-start-3 md:row-start-1 row-end-4 md:row-end-2 col-start-1 col-end-4 md:col-start-3 md:!col-end-4 bg-primary disabled:bg-[#D1D5DB] text-white py-2 px-4 rounded-lg flex justify-center items-center w-full h-[42px] md:max-w-[56px]',
                    {
                      '!col-end-3': showClearFiltersButton,
                    }
                  )}
                  onClick={() => {
                    if (submitedFilters.filter !== selectedFilter) {
                      setPage(0);
                      setSubmitedFilters({
                        filter: selectedFilter,
                      });
                    }
                  }}
                  disabled={!hasFiltersToSubmit || companyListIsLoading || companyListIsFetching}
                >
                  {companyListIsLoading || companyListIsFetching ? (
                    <LoadingSpinIcon className=" w-5 !text-primary md:!text-buttontextcolor " />
                  ) : (
                    <SearchIcon className="!text-buttontextcolor" />
                  )}
                </button>
                {showClearFiltersButton && (
                  <button
                    className="bg-primary text-xs font-bold leading-4 tracking-[0.6px] text-white flex justify-center items-center gap-x-3 w-full md:w-fit h-[42px] py-2 px-4 rounded-lg disabled:bg-[#D1D5DB]"
                    onClick={handleClearFilters}
                    disabled={companyListIsLoading || companyListIsFetching}
                  >
                    <span className="hidden min-[1060px]:inline-block">LIMPAR</span>
                    <DeleteIcon />
                  </button>
                )}
              </>
            )}
          </div>
        </div>

        {showSkeletonLoading ? (
          <LoadingListSkeleton />
        ) : companyListData?.data && companyListData?.data?.length <= 0 ? (
          <p className="w-full text-center text-[#595959] font-semibold">
            Nenhuma empresa encontrada!
          </p>
        ) : (
          <div className="relative overflow-visible w-full flex flex-col">
            <div
              className={classNames(
                'w-full flex flex-row flex-wrap justify-start gap-x-[15px] gap-y-[18px] self-center max-w-[600px]',
                {
                  '!max-w-[1800px]': companyListData?.data && companyListData?.data.length >= 2,
                }
              )}
            >
              {companyListData?.data.map((companyForListing, companyForListingIndex) => (
                <button
                  className={classNames(
                    'group bg-white hover:bg-gray-100 px-[20px] py-[40px] overflow-hidden flex flex-col justify-between content-between gap-y-[9px] shadow-container rounded-[5px] w-full',
                    {
                      'lg:max-w-[calc(100%_/_2_-_10px)]': companyListData.data.length >= 2,
                      'xl:max-w-[calc(100%_/_3_-_10px)]': companyListData.data.length >= 3,
                      '!bg-gray-300 hover:!bg-gray-400':
                        companyForListing.id == companySelected?.id,
                    }
                  )}
                  key={`company-card-${companyForListing.id}-row-${companyForListingIndex}-mobile`}
                  onClick={() => handleSetCompanySelected(companyForListing)}
                >
                  <div className="flex flex-row justify-start w-full gap-x-4">
                    <div className="bg-primary w-[46px] h-[46px] flex justify-center items-center rounded-md">
                      <LibraryBuilding />
                    </div>
                    <div className="font-inter font-medium text-gray-500 text-xs/[20px] flex flex-col gap-y-5 -mt-7">
                      <p className="text-primary">{companyForListing.fantasyName || '-'}</p>
                      <p
                        className={classNames({
                          'group-hover:!text-white !text-gray-800':
                            companyForListing.id == companySelected?.id,
                        })}
                      >
                        <b className="text-primary">CNPJ: </b>
                        {cnpjMask(companyForListing.cnpj) ?? '-'}
                      </p>
                      <p
                        className={classNames('text-red-500 font-bold mt-[-15px]', {
                          '!text-green-500': companyForListing.status === 'ACTIVED',
                        })}
                      >
                        {CompanyStatusEnum[companyForListing.status].toUpperCase()}
                      </p>
                    </div>
                  </div>
                </button>
              ))}
            </div>
          </div>
        )}
        {!showSkeletonLoading && (
          <div className="flex w-full justify-center md:justify-between items-center gap-x-2.5 md:mt-[37px] md:px-[39px] pt-6 md:pt-0">
            <p className="hidden md:block w-fit lg:whitespace-nowrap">
              {companyListData?.total && companyListData?.total <= 0
                ? `Mostrando 0 resultados`
                : `Mostrando ${page * pageSize + 1} a ${
                    page * pageSize + (companyListData?.data.length ?? 0)
                  } de ${companyListData?.total} resultados`}
            </p>
            <Select
              name="itemsPerPage"
              placeholder="itemsPerPage"
              cssClasses={{
                label: 'hidden md:block max-w-[209px] !m-0',
                select: 'text-xs !m-0 disabled:bg-gray-100',
              }}
              onChange={async (event: { target: { value: string; title?: string } }) => {
                setPage(0);
                setPageSize(Number(event.target.value));
              }}
              value={pageSize}
              title={`Mostrando ${pageSize} registros`}
              disabled={companyListIsLoading || companyListIsFetching}
            >
              <option value="10">Mostrar 10 registros</option>
              <option value="20">Mostrar 20 registros</option>
              <option value="50">Mostrar 50 registros</option>
              <option value="100">Mostrar 100 registros</option>
            </Select>
            {!!companyListData?.total && companyListData?.total > 0 ? (
              <Pagination
                currentPage={page + 1}
                totalPages={companyListData.totalPages}
                onPageChange={(page) => {
                  setPage(page - 1);
                }}
                disabled={companyListIsLoading || companyListIsFetching}
              />
            ) : (
              <span></span>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default ListToSelect;
