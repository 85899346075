// Third-party
import { useMutation } from '@tanstack/react-query';

// App
import {
  ICreateCompanyGroupPayload,
  ICreateCompanyPayload,
  ICreateExpensePayload,
  ICreateManyWriteOff,
  ICreatePaymentPayload,
  ICreateWriteOffPaymentPayload,
  IUpdateCompanyGroupTypePayload,
  IUpdateCompanyPayload,
} from 'interfaces/companyTypes';
import {
  createCompany,
  createCompanyGroup,
  createExpense,
  createManyWriteOff,
  createPayment,
  createSale,
  createWriteOffPayment,
  removeExpense,
  removeSale,
  updateCompany,
  updateCompanyGroupType,
} from 'services/api/company';
import queryClient from '../queryClient';
import queriesKeys from './queriesKeys';
import { ICreateSalePayload, saleTypeEnum } from 'interfaces/saleTypes';
import { AxiosError } from 'axios';

// App

export const useCreateCompanyMutation = () =>
  useMutation({
    mutationFn: async ({
      cnpj,
      fantasyName,
      name,
      zipCode,
      address,
      addressNumber,
      district,
      city,
      state,
      phone,
      givenName,
      familyName,
      email,
      companyType,
      monthlyInstallments,
      typePlan,
      dateRegister,
      monthlyFeeDay,
      comment
    }: ICreateCompanyPayload) => {
      await createCompany({
        cnpj,
        fantasyName,
        name,
        zipCode,
        address,
        addressNumber,
        district,
        city,
        state,
        phone,
        givenName,
        familyName,
        email,
        companyType,
        monthlyInstallments,
        typePlan,
        dateRegister,
        monthlyFeeDay,
        comment
      });
    },
  });

export const useUpdateCompanyMutation = (companyId: number) =>
  useMutation({
    mutationFn: async (data: IUpdateCompanyPayload) => {
      await updateCompany(companyId, data);
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queriesKeys.companyDetails, companyId],
      });

      queryClient.invalidateQueries({
        queryKey: [queriesKeys.companyList],
      });
    },
  });

export const useCreateCompanyGroupMutation = (companyId: number, typeId: string) =>
  useMutation({
    mutationFn: async ({ description }: ICreateCompanyGroupPayload) => {
      await createCompanyGroup(companyId, typeId, {
        description,
      });
    },

    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: [queriesKeys.companyGroupList, companyId, typeId],
      });
    },
  });

export const useUpdateCompanyGroupTypeStatusMutation = (groupId: string, companyId: number) =>
  useMutation({
    mutationFn: async ({
      typeId,
      params,
    }: {
      typeId: number;
      params: IUpdateCompanyGroupTypePayload;
    }) => {
      await updateCompanyGroupType(groupId, companyId, typeId, params);
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queriesKeys.companyGroupList],
      });
      queryClient.invalidateQueries({
        queryKey: ['categoryList'],
      });
      queryClient.invalidateQueries({
        queryKey: ['paymentMethod'],
      });
      queryClient.invalidateQueries({
        queryKey: ['paymentType'],
      });
    },
  });

export const useCreatePaymentMutation = (companyId: number | string, saleId: number | string) =>
  useMutation({
    mutationFn: async ({ note, dataEvent, amount, paymentMethodId }: ICreatePaymentPayload) => {
      await createPayment(companyId, saleId, {
        note,
        dataEvent,
        paymentMethodId,
        amount,
      });
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queriesKeys.saleById],
      });
      queryClient.invalidateQueries({
        queryKey: [queriesKeys.companySaleList],
      });
    },
  });

  export const useCreateMultipleWriteOffMutation = (companyId: number | string ) =>
    useMutation({
      mutationFn: async ({ comment, dateEvent, amount, paymentMethodId, clientId }: ICreateManyWriteOff) => {
        await createManyWriteOff(companyId, {
          comment,
          dateEvent,
          paymentMethodId,
          amount,
          clientId          
        });
      },
  
      onSuccess: () => {       
        queryClient.invalidateQueries({
          queryKey: [queriesKeys.companySaleList],
        });
        queryClient.invalidateQueries({
          queryKey: [queriesKeys.companyPendingSaleslList],
        });
      },
    });

export const useCreateSaleMutation = (companyId: number) =>
  useMutation({
    mutationFn: async ({
      clientId,
      paymentMethodId,      
      serviceId,
      description,
      amount,
      quantity,
      totalAmount,
      dataEvent,
      complement,
      saleType,
      name,
      phone,
      cpf
    }: Omit<ICreateSalePayload, 'companyId'>) => {

      const saleTypeKey = Object.keys(saleTypeEnum).find(
        key => saleTypeEnum[key as keyof typeof saleTypeEnum] === saleType
      );
      
      await createSale(companyId, {
        clientId,
        paymentMethodId,        
        serviceId,
        companyId,
        description,
        amount,
        quantity,
        totalAmount,
        dataEvent,
        complement,
        saleType: saleTypeKey as saleTypeEnum,
        name,
        phone,
        cpf
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queriesKeys.companySaleList],
      });
    },
  });
  export const useCreateWriteOffPaymentMutation = (companyId: number | string, expenseId: number|string) =>
    useMutation({
      mutationFn: async ({ comment, dataEvent, paymentMethodId, amount }: ICreateWriteOffPaymentPayload) => {
        await createWriteOffPayment(companyId, expenseId, {
          comment,
          dataEvent,
          paymentMethodId,          
          amount
        });
      },
  
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [queriesKeys.companyExpenseList],
        });
      },
    });

  export const useRemoveSaleMutation = (companyId: number | string, onErrorCallback: (error: AxiosError<any>) => void) =>
  useMutation({
    mutationFn: async ({
      saleId,
      comment
    }: { saleId : number|string, comment:string }) => {
      await removeSale(companyId, saleId, comment);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queriesKeys.companySaleList],
      });
    },
    onError: (error:AxiosError<any>) => {
      onErrorCallback(error)
    },
  });

export const useCreateExepenseMutation = (companyId: number | string) =>
  useMutation({
    mutationFn: async ({
      description,
      categoryId,
      paymentMethodId,
      dataEvent,
      amount,
    }: ICreateExpensePayload) => {
      await createExpense(companyId, {
        description,
        categoryId,        
        paymentMethodId,
        dataEvent,
        amount,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queriesKeys.companyExpenseList],
      });
    },
  });

  export const useRemoveExepenseMutation = (companyId: number | string) =>
  useMutation({
    mutationFn: async ({
      expenseId,
      comment
    }: { 
        expenseId : number|string,
        comment?: string
       }) => {
      await removeExpense(companyId, expenseId, comment);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queriesKeys.companyExpenseList],
      });
    },
  });

