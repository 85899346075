/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// Third-party
import { useNavigate } from "react-router-dom";
// App
import NotFoundIconPageContent from "assets/icons/NotFoundPageContent.icon";

import Dezi9Icon from "assets/icons/Dezi9.icon";
import LogoOrg from "components/LogoOrg";

function NotFound() {
  /*
  **** Component organization ****
   └── Declaration of generic hooks (e.g., useNavigate)
   └── State declaration
   └── Side effects (e.g., useEffect)
   └── Memoization (e.g., useMemo)
   └── Handlers (e.g., useCallback)
   └── JSX
   */

  // └── Declaration of generic hooks (e.g., useNavigate)
  const navigate = useNavigate();

  // └── State declaration

  // └── Side effects (e.g., useEffect)

  // └── Handlers (e.g., useCallback)
  const handleComeback = () => {
    navigate("/");
  };

  const CURRENT_YEAR: number = new Date().getFullYear();

  return (
    <div className=" w-full h-screen min-h-screen flex flex-col justify-between">
      <header
        className={`flex flex-row mx-auto w-full justify-between  h-[112px] py-[24px] px-[35px] md:px-[78px] items-center text-white bg-[#f1f2f4]`}
      >
        <div
          className={`flex flex-row items-center justify-center w-full h-[63px]`}
        >
          <div className="h-full w-full flex flex-row items-start justify-start">
            <LogoOrg
              className={`max-[900px]:w-auto max-[900px] h-[60px] max-[900px] md:mt-[21px] max-[900px md:ml-[14px] min-[900px] md:w-auto min-[900px] md:h-[75px]`}
            />
          </div>
        </div>
      </header>
      <div className="flex flex-col  items-center">        
        <NotFoundIconPageContent className="max-md:w-[300px] max-md:h-[180px]" />

      </div>
      <div
        className={`flex md:flex-row flex-col md:justify-between items-center w-full md:h-[42px] h-[113px] px-[96px] py-[28px] max-md:gap-5 md:px-[46px] md:py-[10px] bg-[#061739] text-white flex-shrink-0 `}
      >
        <div className="flex items-center justify-around md:justify-center w-[153px] h-[26px] whitespace-nowrap">
          <div
            onClick={(e) => {
              e.preventDefault();
              window.open("https://10i9.com.br/", "_blank");
            }}
            rel="noopener noreferrer"
            className="cursor-pointer flex flex-row items-center justify-around text-[14px] font-open-sans font-normal leading-[22px] tracking-tight md:ml-10"
          >
            ©Copyright {CURRENT_YEAR}{" "}
            <p className="mx-2">
              - <span className="max-md:hidden">10i9 Tecnologia</span>
            </p>
          </div>
          <div
            onClick={(e) => {
              e.preventDefault();
              window.open("https://10i9.com.br/", "_blank");
            }}
            rel="noopener noreferrer"
            className="md:hidden w-[58px] h-[20px] cursor-pointer"
          >
            <Dezi9Icon width={"58"} height={"20"} />
          </div>
        </div>
      </div>
    </div>
  );
}


export default NotFound;
<div className="flex flex-col items-center">
</div>