import { date, number, object } from 'yup';
import { dateUtils } from 'utils/formatDate';

const { validateDateStringDayjsToDate } = dateUtils.validate
const REQUIRED_FIELD_MESSAGE = 'Este campo é obrigatório';
const PAYTIPE_FIELD_MESSAGE = 'Selecione um tipo de pagamento válido.';
const PAYTYPEID_GREATER_THAN_ZERO_MESSAGE = 'O tipo de pagamento deve ser válido e maior que zero.';

export const formSchema = object({
  paymentMethodId: number()
    .required(PAYTIPE_FIELD_MESSAGE)
    .moreThan(0, PAYTYPEID_GREATER_THAN_ZERO_MESSAGE),
    dataEvent: date()
    .transform(validateDateStringDayjsToDate)
    .required(REQUIRED_FIELD_MESSAGE)    
});

