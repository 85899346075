export function getGradientColorUtil(
  value: number,
  min: number,
  max: number,
  startColor: string,
  endColor: string
): string {
  const ratio = (value - min) / (max - min);

  const startRGB = parseInt(startColor.slice(1), 16);
  const endRGB = parseInt(endColor.slice(1), 16);

  const r = Math.floor((startRGB >> 16) * (1 - ratio) + (endRGB >> 16) * ratio);
  const g = Math.floor(((startRGB >> 8) & 0x00ff) * (1 - ratio) + ((endRGB >> 8) & 0x00ff) * ratio);
  const b = Math.floor((startRGB & 0x0000ff) * (1 - ratio) + (endRGB & 0x0000ff) * ratio);

  return `rgb(${r}, ${g}, ${b})`;
}
