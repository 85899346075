import { StoreApi } from 'zustand';
import { IGlobalParamsState } from './interfaces';

export const setFirstRendered =
  (set: StoreApi<IGlobalParamsState>['setState']): IGlobalParamsState['setFirstRendered'] =>
  () =>
    set({
      firstRender: false,
    });

export const setIsCheckingToken =
  (set: StoreApi<IGlobalParamsState>['setState']): IGlobalParamsState['setIsCheckingToken'] =>
  (value) =>
    set((state) => {
      if (value) return { lockComponentsRender: state.lockComponentsRender + 1 };
      if (state.lockComponentsRender > 0)
        return { lockComponentsRender: state.lockComponentsRender - 1 };

      return { loadingCount: 0 };
    });

export const setLockComponentsRender =
  (set: StoreApi<IGlobalParamsState>['setState']): IGlobalParamsState['setLockComponentsRender'] =>
  (value) =>
    set((state) => {
      if (value) return { lockComponentsRender: state.lockComponentsRender + 1 };
      else if (state.lockComponentsRender > 0)
        return {
          lockComponentsRender: state.lockComponentsRender - 1,
        };

      return { lockComponentsRender: 0 };
    });
