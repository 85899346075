// Third-party
import { number, object, string, date } from 'yup';

// App
import { ICreateSalePayload } from 'interfaces/saleTypes';
import { removeMonetaryMask } from 'utils/mask.utils';
import { dateUtils } from 'utils/formatDate';

const { validateDateStringDayjsToDate } = dateUtils.validate
const REQUIRED_FIELD_MESSAGE = 'Este campo é obrigatório';

export const formSchema = object<ICreateSalePayload & { complement: { [key: string]: string } }>({
  clientId: string(),
  paymentMethodId: string().required(REQUIRED_FIELD_MESSAGE),  
  serviceId: string().required(REQUIRED_FIELD_MESSAGE),
  amount: string()
    .required(REQUIRED_FIELD_MESSAGE)
    .test((value) => removeMonetaryMask(value) > 0),
  quantity: number().required(REQUIRED_FIELD_MESSAGE),
  dataEvent: date()
    .transform(validateDateStringDayjsToDate)
    .required(REQUIRED_FIELD_MESSAGE),  
  description: string().nullable(),
  complement: object().test((value: { [key: string]: string }) => {
    let allFieldsIsValid = true;

    if (value) {
      Object.keys(value).forEach((complementFields) => {
        if (allFieldsIsValid && value[complementFields]) {
          allFieldsIsValid = typeof value[complementFields] === 'string';
        }
      });
    }

    return allFieldsIsValid;
  }),
});
