/* eslint-disable react-hooks/rules-of-hooks */
// Third-party
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Navigate } from 'react-router-dom';
import { useMemo } from 'react';

// App
import useCompanySelectedStore from 'store/client/companySelected/useCompanySelectedStore';
import { selectCompanySelected } from 'store/client/companySelected/selectors';
import { PAGES_ROUTES } from 'constants/routes';
import useDashboardStore from 'store/client/dashboard/useDashboardStore';
import {
  selectSelectedClient,
  selectSelectedMonth,
  selectSelectedYear,
} from 'store/client/dashboard/selectors';
import { useGetDashboardData } from 'store/server/company/queries';
import getMonthStartAndEndUtil from 'utils/getMonthStartAndEnd.util';
import classNames from 'classnames';
import { convertToBrazilianCurrencyFormat } from 'utils/currency.utils';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

interface IPaymentChartProps {
  toExport?: boolean;
}

function ReceiptsInPeriod({ toExport }: IPaymentChartProps) {
  const companySelected = useCompanySelectedStore(selectCompanySelected);

  if (!companySelected) {
    return <Navigate to={PAGES_ROUTES.authenticated.commonInitialRoute} replace />;
  }

  const selectedMonth = useDashboardStore(selectSelectedMonth);
  const selectedYear = useDashboardStore(selectSelectedYear);
  const selectedClient = useDashboardStore(selectSelectedClient);

  const { data: dashboardData } = useGetDashboardData(
    companySelected.id,
    {
      ...getMonthStartAndEndUtil(Number(selectedYear), Number(selectedMonth)),
      clientId: selectedClient?.id,
    },
    { requestIdEnabled: false }
  );

  const periodReceivablesLabels = useMemo(
    () => dashboardData?.salesByMethodTotal.map((saleByMethodTotal) => saleByMethodTotal.paymethod),
    [dashboardData]
  );

  const periodReceivablesOptions = useMemo<ChartOptions<'bar'>>(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          bottom: 100,
        },
      },
      scales: {
        y: {
          beginAtZero: true,
          title: {
            display: false,
          },
          ticks: {
            callback: function (value) {
              return convertToBrazilianCurrencyFormat(`${value}`);
            },
            color: '#000',
            font: {
              size: 14,
            },
          },
        },
        x: {
          display: false,
        },
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: function (context) {
              let label = context.dataset.label || '';
              if (label) {
                label += ': ';
              }
              const value = context.parsed.y;
              label += convertToBrazilianCurrencyFormat(`${value}`);
              return label;
            },
          },
        },
        legend: {
          position: 'top' as const,
          display: false,
        },
        title: {
          display: true,
          text: 'Forma de Recebimento do Mês',
          font: {
            size: toExport ? 24 : 20,
          },
          padding: {
            bottom: 40,
          },
        },
        datalabels: {
          anchor: 'start',
          color: 'rgb(0, 0, 0)',
          align: 'bottom',
          rotation: -20,
          font: {
            size: 16,
          },
          formatter: (value, context) => periodReceivablesLabels?.[context.dataIndex],
        },
      },
    }),
    [periodReceivablesLabels, toExport]
  );

  const periodReceivablesData = useMemo<ChartData<'bar'>>(
    () => ({
      labels: periodReceivablesLabels,

      datasets: [
        {
          label: 'R$',

          data: dashboardData?.salesByMethodTotal.map((saleByMethodTotal) =>
            Number(saleByMethodTotal.salestotal)
          ) as number[],
          backgroundColor: '#00a3e8',
        },
      ],
    }),
    [dashboardData?.salesByMethodTotal, periodReceivablesLabels]
  );

  return (
    <div
      className={classNames(
        'graph w-full flex flex-col relative gap-x-[50px] bg-[#f6f9fd] rounded-2xl pt-[50px] md:pb-[10px] px-[10px] md:pl-[50px] md:pr-[80px]',
        {
          'flex-col gap-y-[80px] !px-[100px]': toExport,
          '!bg-white': toExport,
        }
      )}
    >
      <div className={classNames('flex align-baseline relative', {})}>
        <Bar data={periodReceivablesData} options={periodReceivablesOptions} height={600} />
      </div>
    </div>
  );
}

export default ReceiptsInPeriod;
