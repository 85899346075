//Thrid-pary
// App
import api from '../api';
import { routes } from '../../../constants/api';
import { IResponseWithDataListPaginated } from 'interfaces/apiTypes';
import { IGroup } from 'interfaces/menuTypes';

export async function listGroupOptions(): Promise<
  IResponseWithDataListPaginated<IGroup>['response']
> {
  const { data, status } = await api.auth.get<IResponseWithDataListPaginated<IGroup>>(
    routes.v1.menu.listGroup
  );

  if (status === 204) {
    return {
      data: [],
      pageNumber: 0,
      nextPage: 0,
      lastPage: 0,
      totalPages: 0,
      total: 0,
      pageSize: 0,
    };
  }

  return data.response;
}
