// Third-party
import { ForwardedRef, InputHTMLAttributes, forwardRef } from 'react';
import classNames from 'classnames';

// App

interface IError {
  showOutline: boolean;
}

interface ICssClasses {
  input: string; // CSS classes
  label: string; // CSS classes
}

interface InputTextProps {
  cssClasses: Partial<ICssClasses>;
  labelText: string; // Label text
  error: Partial<IError>;
}

const InputText = forwardRef(
  (
    {
      labelText,
      cssClasses,
      error,
      ...inputHtmlProps
    }: Partial<InputTextProps> & InputHTMLAttributes<HTMLInputElement>,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    /*
  **** Component organization ****

   └── Declaration of generic hooks (e.g., useNavigate)
   └── State declaration
   └── Side effects (e.g., useEffect)
   └── Memoization (e.g., useMemo)
   └── Handlers (e.g., useCallback)
   └── JSX
   */

    // └── JSX
    return (
      <label
        className={classNames(
          'font-inter flex flex-col justify-start text-sm font-medium relative h-fit text-[#374151] overflow-visible w-full',
          cssClasses?.label
        )}
      >
        <div className="flex gap-2 align-center min-w-full min-h-fit">
          <span className="truncate">{labelText}</span>
        </div>

        <input
          className={classNames(
            'bg-[#FBFCFD] text-primary  disabled:bg-gray-100  read-only:bg-gray-100 border border-[#D1D5DB] text-sm rounded-lg focus:outline outline-1 outline-secondary w-full p-2.5 mt-1 h-11 disabled:text-black read-only:text-black disabled:border-[#EEE] read-only:border-[#EEE]',
            cssClasses?.input,
            {
              'shadow-warningcolor shadow-[0_0_0_2px] border-opacity-0 !outline-0':
                error?.showOutline,
            }
          )}
          ref={ref}
          type="text"
          {...inputHtmlProps}
        />
      </label>
    );
  }
);

InputText.displayName = 'InputText';

export default InputText;
