/* eslint-disable react-hooks/rules-of-hooks */
// Third-party
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  Tooltip,
  ChartOptions,
  LinearScale,
  Legend,
  Title,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import { Navigate } from 'react-router-dom';
import { useMemo } from 'react';

// App
import { getGradientColorUtil } from 'utils/colors.utils';
import useCompanySelectedStore from 'store/client/companySelected/useCompanySelectedStore';
import { selectCompanySelected } from 'store/client/companySelected/selectors';
import { PAGES_ROUTES } from 'constants/routes';
import { useGetDashboardData } from 'store/server/company/queries';
import getMonthStartAndEndUtil from 'utils/getMonthStartAndEnd.util';
import useDashboardStore from 'store/client/dashboard/useDashboardStore';
import {
  selectSelectedClient,
  selectSelectedMonth,
  selectSelectedYear,
} from 'store/client/dashboard/selectors';
import useWindowSize from 'hooks/useWindowSize';
import classNames from 'classnames';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

interface IServicesByClientChartProps {
  toExport?: boolean;
}

function ServicesByClientChart({ toExport }: IServicesByClientChartProps) {
  const companySelected = useCompanySelectedStore(selectCompanySelected);
  const { width } = useWindowSize();

  if (!companySelected) {
    return <Navigate to={PAGES_ROUTES.authenticated.commonInitialRoute} replace />;
  }

  const selectedMonth = useDashboardStore(selectSelectedMonth);
  const selectedYear = useDashboardStore(selectSelectedYear);
  const selectedClient = useDashboardStore(selectSelectedClient);

  const { data: dashboardData } = useGetDashboardData(
    companySelected.id,
    {
      ...getMonthStartAndEndUtil(Number(selectedYear), Number(selectedMonth)),
      clientId: selectedClient?.id,
    },
    { requestIdEnabled: false }
  );

  const dataValues = useMemo(
    () => dashboardData?.salesByClients.map((salesByClient) => Number(salesByClient.salesCount)),
    [dashboardData?.salesByClients]
  );

  const minVal = Math.min(...(dataValues ?? []));
  const maxVal = Math.max(...(dataValues ?? []));

  const startColor = '#118dff';
  const endColor = '#148030';

  const backgroundColors = (dataValues ?? []).map((value) =>
    minVal === maxVal ? endColor : getGradientColorUtil(value, minVal, maxVal, startColor, endColor)
  );

  const serviceByClientData = useMemo(
    () => ({
      labels: dashboardData?.salesByClients.map((salesByClient) => salesByClient.client),
      datasets: [
        {
          label: 'Quantidade de Serviços Realizados',
          data: dataValues,
          backgroundColor: backgroundColors,
          borderColor: backgroundColors.map((color) =>
            color.replace('rgb', 'rgba').replace(')', ', 1)')
          ),
          borderWidth: 1,
          barThickness: 50,
        },
      ],
    }),
    [backgroundColors, dashboardData?.salesByClients, dataValues]
  );

  const options = useMemo<ChartOptions<'bar'>>(
    () => ({
      indexAxis: 'y',
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: 'Cliente',
            align: 'center',
            color: '#000',
            font: {
              size: 18,
              weight: 'normal',
            },
          },
          ticks: {
            color: '#000',
            font: {
              size: 16,
            },
          },
        },
        x: {
          beginAtZero: true,
          title: {
            display: true,
            text: 'Quantidade',
            align: 'center',
            color: '#000',
            font: {
              size: 18,
              weight: 'normal',
            },
          },
          ticks: {
            color: '#000',
            font: {
              size: 14,
            },
            callback: (value) =>
              value.toLocaleString('pt-BR', {
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
                useGrouping: false,
              }),
          },
        },
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: function (context) {
              let label = context.dataset.label || '';
              if (label) {
                label += ': ';
              }
              const value = context.parsed.x;
              label += value.toLocaleString('pt-BR', {
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
                useGrouping: false,
              });
              return label;
            },
          },
        },
        legend: {
          display: false,
        },
        title: {
          display: true,
          text:
            width > 450
              ? ['Quantidade de Serviços Realizados por Cliente']
              : ['Quantidade de Serviços', ' Realizados por Cliente'],
          font: {
            size: toExport ? 24 : 20,
            weight: 'bold',
          },
          align: 'center',
          position: 'top',
          padding: {
            bottom: 30,
          },
        },
        datalabels: {
          display: false,
        },
      },
    }),
    [toExport, width]
  );

  return (
    <div
      className={classNames('graph bg-[#f6f9fd] rounded-2xl px-[10px] md:px-[50px] py-[50px]', {
        '!bg-white': toExport,
      })}
      style={{
        height: `${
          dashboardData?.salesByClients.length
            ? Math.max(dashboardData?.salesByClients.length * 200, 300)
            : 500
        }px`,
      }}
    >
      <Bar data={serviceByClientData} options={options} />
    </div>
  );
}

export default ServicesByClientChart;
