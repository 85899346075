// Third-party
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

// App

function AuthLayout() {
  /*
  **** Component organization ****

   └── Declaration of generic hooks (e.g., useNavigate)
   └── State declaration
   └── Side effects (e.g., useEffect)
   └── Memoization (e.g., useMemo)
   └── Handlers (e.g., useCallback)
   └── JSX
   */
  // └── State declaration
  //   └── Global
  //   └── Internal

  return (
    <div className="overflow-y-auto min-h-screen flex flex-col md:flex-row bg-white w-full h-full">
      {/* SIGN IN FORM SIDE */}
      <div className="flex flex-col gap-y-10 p-8 md:p-16 md:pt-[106px] min-h-[500px] h-full w-full md:max-w-[495px] grow-2 overflow-hidden md:overflow-visible" >
        <div className="flex flex-col justify-start">
          {/* COMPANY LOGO */}
          <img
            src="/logo_objetiva.png"
            alt="company-logo"
            className="max-w-[250px] md:max-w-[288px]"
          />

          {/* TITLE and SUBTITLE */}
          <div className="flex flex-col justify-start gap-2 md:text-left w-fit">
            <h1 className="font-inter  font-medium text-textcolor md:whitespace-nowrap ">
            SISTEMA DE CONTROLE FINANCEIRO
            </h1>
          </div>
        </div>

        {/* FORM */}
        <div>
          <Outlet />
        </div>
      </div>

      {/* IMAGE SIDE */}      
      <div        
        className="min-h-[300px] flex flex-col justify-end items-end text-right grow-[2] w-full bg-gray-300 bg-cover bg-center"
        style={{
          backgroundImage: "url(/side-image.svg)",
          backgroundPositionY: "25%",
        }}
      />
    </div>
  );
}

export default AuthLayout;
