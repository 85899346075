// Third-party
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// App
import useAuth from 'hooks/useAuth';
import { formSchema } from './schemas';
import { InputPassword, InputText } from 'components';
import { PAGES_ROUTES } from 'constants/routes';
import useLoadingStore from 'store/client/loading/useLoadingStore';
import { selectLoading } from 'store/client/loading/selectors';

type FormValues = {
  email: string;
  password: string;
};

function SignIn() {
  /*
  **** Component organization ****

   └── Declaration of generic hooks (e.g., useNavigate)
   └── State declaration
   └── Side effects (e.g., useEffect)
   └── Memoization (e.g., useMemo)
   └── Handlers (e.g., useCallback)
   └── JSX
  */

  // └── Declaration of generic hooks (e.g., useNavigate)
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const {
    handleSubmit,
    register,
    formState: { isValid },
  } = useForm<FormValues>({
    resolver: yupResolver(formSchema),
  });
  const isLoading = useLoadingStore(selectLoading);

  // └── Handlers (e.g., useCallback)
  const onSubmit = async ({ email, password }: FormValues) => {
    signIn(email, password, { firstLogin: true });
  };

  return (
    <div className="w-full">
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-y-2 md:gap-y-6">
          <InputText
            labelText="Email"
            cssClasses={{
              label: 'mt-0',
            }}
            {...register('email')}
          />

          <InputPassword
            labelText="Senha"
            canShowPassword
            disablePassworPatternTip
            {...register('password')}
          />
        </div>
        <div className="flex flex-col font-inter">
          <div className="flex justify-end my-6">
            <button
              type="button"
              className="text-primary font-medium text-sm transition duration-200"
              onClick={() => navigate(PAGES_ROUTES.unauthenticated.forgotPassword)}
              disabled={isLoading}
            >
              Esqueceu sua senha? 
              Clique aqui!
            </button>
          </div>
          <button
            type="submit"
            className="bg-primary disabled:bg-[#D1D5DB] text-sm text-buttontextcolor text-center py-[11px] px-4 rounded-[5px] mb-6 transition duration-200 "
            disabled={!isValid || isLoading}
          >
            Entrar
          </button>
        </div>
      </form>
    </div>
  );
}

export default SignIn;
