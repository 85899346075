export const PAGES_ROUTES = {
  // Open routes
  initialRoute: '/',
  notfound: '/nao-encontrado',
  unavailable: '/indisponível',

  // Routes from the admin panel
  unauthenticated: {
    initialRoute: '/entrar',
    signin: '/entrar',
    newPassword: '/nova-senha',
    forgotPassword: '/recuperar-senha',
  },
  authenticated: {
    adminInitialRoute: '/empresa/selecionar',
    commonInitialRoute: '/empresa/resumo',
    user: {
      create: '/user/create',
      list: '/usuario/lista',
      edit: '/usuario/perfil/:userId',
      changePassword: '/user/change-password',
    },
    unit: {
      list: '/unidade/lista',
      create: '/unidade/criacao',
      edit: '/unidade/edicao/:unitId',
    },
    company: {
      create: '/empresa/cadastro',
      edit: '/empresa/detalhes/:companyId',
      list: '/empresa/lista',
      listToSelect: '/empresa/selecionar',
      listGroup: '/empresa/cadastros/:groupId',
      logList: '/empresa/logs',
      group: {
        create: '/empresa/cadastros/registrar/:groupId',
        edit: '/empresa/cadastros/:groupId/editar/:typeId',
      },
      dashboard: '/empresa/grafico',
      summary: '/empresa/resumo',
    },
    client: {
      create: '/cliente/cadastro',
      list: '/cliente/lista',
      edit: '/cliente/detalhes/:clientId',
    },
    transactions: {
      expenses: {
        list: '/transacoes/despesas/lista',
        create: '/transacoes/despesas/cadastro',
      },
      sale: {
        list: '/transacoes/vendas/lista',
        create: '/transacoes/vendas/cadastro',
      },
      pendingSales: {
        list: '/transacoes/vendas/pendentes/lista',        
      },
      payments: {
        list: '/transacoes/vendas/recebimentos/lista',        
      }
    },
  },
} as const;
