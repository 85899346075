/* eslint-disable import/no-unused-modules */
import Create from './Create';
import Edit from './Edit';

export { Create };
export { Edit };

export default {
  Create,
  Edit,
};
