import user from './user';
import company from './company';
import menu from './menu';
import unit from './unit';
import client from './client';

export { user };
export { company };
export { menu };
export { unit };
export { client };

export default {
  user,
  company,
  menu,
  unit,
  client,
};
