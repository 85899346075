/* eslint-disable react-hooks/rules-of-hooks */
import { yupResolver } from '@hookform/resolvers/yup';
import { CloseIcon } from 'assets/icons';
import classNames from 'classnames';
import InputText from 'components/InputText';
import InputTextArea from 'components/InputTextArea';
import Modal from 'components/Modal';
import { ICompanyGroup, ICreateManyWriteOff, ISale } from 'interfaces/companyTypes';
import { Controller, useForm } from 'react-hook-form';
import { formSchema } from './schemas';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import { handleChangeMonthlyInstallments } from 'utils/handlers';
import { AxiosError } from 'axios';
import { selectSetLoading } from 'store/client/loading/selectors';
import useLoadingStore from 'store/client/loading/useLoadingStore';
import { selectAddNotification } from 'store/client/notification/selectors';
import useNotificationStore from 'store/client/notification/useNotificationStore';
import { useCreateMultipleWriteOffMutation } from 'store/server/company/mutations';
import { PAGES_ROUTES } from 'constants/routes';
import { Navigate } from 'react-router-dom';
import useCompanySelectedStore from 'store/client/companySelected/useCompanySelectedStore';
import { selectCompanySelected } from 'store/client/companySelected/selectors';
import { useGetCompanyGroupList } from 'store/server/company/queries';
import Select from 'components/Select';
import Skeleton from 'react-loading-skeleton';
import { convertToBrazilianCurrencyFormat, convertToCurrencyFormat } from 'utils/currency.utils';
import { dateUtils } from '../../utils/formatDate';
import dayjs from 'dayjs';
import {  ORDER_ATTRIBUTTES } from 'constants/orderAttributtes';
import { DEFAULT_VALUES } from 'constants/textAreaInput';


const { ASC } = ORDER_ATTRIBUTTES;

const { DATE_TIME_DISPLAY_FORMAT } = dateUtils.constants;


interface IProps {
  setCloseModal: (closeModal: boolean) => void;
  totalAmountPending: number
  sale?: ISale
}

function MultipleWriteOffModal({ setCloseModal, totalAmountPending, sale }: IProps) {
  const { register, handleSubmit, watch, control, reset, formState: { isValid } } = useForm<ICreateManyWriteOff>({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
  });
  const setLoading = useLoadingStore(selectSetLoading);
  const addNotification = useNotificationStore(selectAddNotification);
  const companySelected = useCompanySelectedStore(selectCompanySelected);


  if (!companySelected)
    return <Navigate to={PAGES_ROUTES.authenticated.commonInitialRoute} replace />;


  const multipleWriteOffMutation = useCreateMultipleWriteOffMutation(companySelected?.id); 

  const { data: paymentMethodListData, isLoading: paymentMethodListIsLoading } = useGetCompanyGroupList(
    {
      companyId: companySelected.id,
      groupId: '7',
      status: true,
      field: 'description',
      order: ASC,
    }
  );
  
  const watchPaymentMethodId = watch('paymentMethodId');
  
  // └── Handlers (e.g., useCallback)
  const onSubmit = async ({ comment, dateEvent, amount }: ICreateManyWriteOff) => {
    if(!sale?.clientId) {
      addNotification({
        type: 'error',
        message: 'Erro ao identificar o cliente. Tente novamente mais tarde.',
      });
      return setCloseModal(false)
    }
    setLoading(true);
    try {
      await multipleWriteOffMutation.mutateAsync({
        companyId : companySelected?.id,
        clientId: sale?.clientId,
        comment,
        dateEvent: dateUtils.formatTo.formatDateToRegisterPaymentOrExpense(dateEvent),
        paymentMethodId: watchPaymentMethodId,
        amount: parseFloat(
          amount && typeof amount === 'string'
            ? convertToCurrencyFormat(amount).toString()
            : '0'
        ),
      });

      addNotification({
        type: 'success',
        message: 'Cadastro realizado com sucesso!',
      });
      reset();
    } catch (error) {
      if (error instanceof AxiosError) {
        if (
          error.response?.status &&
          error.response?.status >= 400 &&
          error.response?.status < 500
        ) {
          addNotification({
            type: 'error',
            message: error.response?.data.message,
          });
          return;
        }
        addNotification({
          type: 'error',
          message: 'Erro com o serviço. Tente novamente mais tarde.',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      content={
        <>
          <button
            className="absolute top-[15px] right-4"
            onClick={() => setCloseModal(false)}
          >
            <CloseIcon />
          </button>
          <div className="flex flex-col justify-center items-center gap-y-[30px] pr-2 pb-[41px]">
            <h2 className="text-xl text-primary font-semibold">Registrar Baixas</h2>
            <form
              noValidate
              className="flex-row items-start w-full max-w-[600px] gap-x-[10px] xl:gap-x-[20px]"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="flex flex-col">
              <div className="w-full flex sm:gap-x-[16px] flex-col sm:flex-row">
                  {/* Valor total da venda */}
                    <InputText
                    labelText="Cliente"
                    name="client"
                    disabled
                    cssClasses={{
                        label: 'sm:max-w-[300px] h-[44px] mt-[10px]',
                        input: 'w-full sm:max-w-[300px]',
                    }}
                    type="text"
                    value={ sale?.client || sale?.name || sale?.comercialName}
                    />
                </div>
                <div className="w-full flex sm:gap-x-[16px] flex-col sm:flex-row">
                  {/* Valor total da venda */}
                    <InputText
                    labelText="Valor Total Pendente"
                    name="Valor da Venda"
                    disabled
                    cssClasses={{
                        label: 'sm:max-w-[300px] h-[44px] mt-[10px]',
                        input: 'w-full sm:max-w-[300px]',
                    }}
                    type="text"
                    value={convertToBrazilianCurrencyFormat(`${totalAmountPending}` ?? '0')}
                    />
                </div>
                <div className="w-full flex sm:gap-x-[16px] flex-col sm:flex-row pt-[20px]">
                  {/* Data do Evento */}
                  {
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Controller
                        name="dateEvent"
                        control={control}  
                        defaultValue={dayjs()} 
                        render={({ field }) => {
                          return (
                            <label
                              htmlFor="dataEvent"
                              className=" z-100 font-inter text-[#374151] text-sm font-medium flex flex-col justify-start w-full gap-y-1.5 truncate !overflow-visible relative cursor-pointer max-w-[250px]"
                            >
                              Data do Recebimento*
                              <DateTimePicker                                
                                ampm={false}
                                format={DATE_TIME_DISPLAY_FORMAT}
                                onChange={field.onChange}
                                value={dayjs(field.value) || dayjs()}
                                slotProps={{
                                  textField: {
                                    size: "small"
                                  }
                                }
                                }
                              />
                            </label>
                          );
                        }}
                      />
                    </LocalizationProvider>
                  }
                  {/* FORMA DE PAGAMENTO */}
                  {paymentMethodListIsLoading ? (
                    <Skeleton height={44} containerClassName="w-full mt-[25px]" />
                  ) : (
                    <Select
                      labelText="Forma de Recebimento*"
                      cssClasses={{                        
                        select: classNames({
                          '!text-[#a9b1bc] h-[44px] ':
                            watchPaymentMethodId === undefined ||
                            watchPaymentMethodId.toString() === `-1`,
                        }),
                      }}
                      {...register('paymentMethodId')}
                      placeholder="Lista de Formas de Recebimento"
                      defaultValue={-1}
                    >
                      <option
                        value={-1}
                        className="font-normal font-inter text-sm leading-5 !text-[#a9b1bc] "
                      >
                        Lista de Formas de Recebimento
                      </option>
                      {paymentMethodListData?.data.map(
                        (paymentMethod: ICompanyGroup, paymentMethodIndex: number) =>
                          paymentMethod.description && (
                            <option
                              value={Number(paymentMethod.id)}
                              key={`client-type-${paymentMethod.id}-${paymentMethod.description}-${paymentMethodIndex}`}
                              className="text-textcolor max-w-[300px]"
                              title={paymentMethod.description}
                            >
                              {paymentMethod.description}
                            </option>
                          )
                      )}
                    </Select>
                  )}                  
                </div>
                <div className="w-full flex sm:gap-x-[16px] flex-col sm:flex-row">
                  {/* Valor */}
                  <Controller
                    name="amount"
                    control={control}
                    defaultValue={'R$ 0,00'}
                    render={({ field }) => (
                      <InputText
                        {...field}
                        labelText="Valor*"                        
                        cssClasses={{
                          label: ' sm:max-w-[300px] h-[44px] mt-[10px]',
                          input: 'w-full  sm:max-w-[300px]',
                        }}
                        type="text"
                        onChange={handleChangeMonthlyInstallments(field.onChange)}
                      />
                    )}
                  />                
                </div>

                {/* Observacoes */}     
                <Controller
                  name='comment'
                  control={control}
                  defaultValue={""}
                  render={({field}) => (
                    <InputTextArea
                      {...field}
                      labelText="Observações"
                      cssClasses={{
                        label:'mt-[10px]',
                        textarea: 'h-full min-h-[100px] max-h-[250px]',
                      }}
                      maxLength={DEFAULT_VALUES.MAX_250_CHAR}    
                    />
                  )}
                />
               
              </div>
              <div className="w-full flex flex-col justify-end items-end py-[30px]">
                {/* Botão de envio, se necessário */}
                { (
                  <button
                    type="submit"
                    disabled={!isValid}
                    className="bg-primary text-xs font-bold leading-4 disabled:bg-[#D1D5DB] text-buttontextcolor py-2 pl-4 pr-[18px] rounded-lg flex justify-center items-center h-[42px] max-w-[200px] tracking-[0.6px]"
                  >
                    SALVAR PAGAMENTO
                  </button>
                )}
              </div>
            </form>
          </div>
        </>
      }
      css={{
        modal: 'relative md:max-h-[720px] md:max-w-[600px] h-full w-full md:my-auto overflow-y-auto' ,

      }}
    />
  );
}

export default MultipleWriteOffModal;
