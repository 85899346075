// Third-party
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { AxiosError } from 'axios';

// App
import { formSchema } from './schemas';
import { InputText, Select } from 'components';
import { AddUserIcon, SimpleSpinnerIcon } from 'assets/icons';
import { getAddressByCep } from 'services/api/external/viaCep';
import { cepMask, cnpjMask, phoneNumberMask } from 'utils/mask.utils';
import { ICreateCompanyPayload, companyTypePlanEnum } from 'interfaces/companyTypes';
import useNotificationStore from 'store/client/notification/useNotificationStore';
import { selectAddNotification } from 'store/client/notification/selectors';
import useLoadingStore from 'store/client/loading/useLoadingStore';
import { selectLoading, selectSetLoading } from 'store/client/loading/selectors';
import { useCreateCompanyMutation } from 'store/server/company/mutations';
import { PAGES_ROUTES } from 'constants/routes';
import { useGetCompanyTypes } from 'store/server/company/queries';
import Skeleton from 'react-loading-skeleton';
import Datepicker from 'react-tailwindcss-datepicker';
import ReturnButton from 'components/ReturnButton';
import InputTextArea from 'components/InputTextArea';
import { dateUtils } from '../../../utils/formatDate';
import { mainStyles, formStyles, userForm } from './styles';
import  { formProperties, userFormProperties } from './formProperties';
import { DEFAULT_VALUES } from 'constants/textAreaInput';


const { DATE_DISPLAY_FORMAT } = dateUtils.constants;
const { getCurrentDateIsoString } = dateUtils.generateDate;
const { DEFAULT_MAX_LENGHT} = DEFAULT_VALUES


function Create() {
  /*
  **** Component organization ****

   └── Declaration of generic hooks (e.g., useNavigate)
   └── State declaration
   └── Side effects (e.g., useEffect)
   └── Memoization (e.g., useMemo)
   └── Handlers (e.g., useCallback)
   └── JSX
   */

  // └── Declaration of generic hooks (e.g., useNavigate)
  const {
    register,
    handleSubmit,
    formState: { isValid },
    reset,
    watch,
    setValue,
    control,
  } = useForm<ICreateCompanyPayload>({
    resolver: yupResolver(formSchema),
  });
  const isLoading = useLoadingStore(selectLoading);
  const setLoading = useLoadingStore(selectSetLoading);
  const addNotification = useNotificationStore(selectAddNotification);
  const createCompanyMutation = useCreateCompanyMutation();
  const { data: companyTypes, isLoading: companyTypesIsLoading } = useGetCompanyTypes();

  // └── State declaration
  const [addressByCepIsLoading, setAddressByCepIsLoading] = useState<boolean>(false);

  const watchCEP = watch('zipCode');
  const watchCompanyType = watch('companyType');
  const watchTypePlan = watch('typePlan');
  const currentDate = getCurrentDateIsoString();

  // └── Side effects (e.g., useEffect)
  useEffect(() => {
    (async () => {
      if (watchCEP?.length === 9) {
        try {
          setAddressByCepIsLoading(true);
          const address = await getAddressByCep(watchCEP.replace('-', ''));

          address.logradouro && setValue('address', address.logradouro);
          address.bairro && setValue('district', address.bairro);
          address.localidade && setValue('city', address.localidade);
          address.uf && setValue('state', address.uf);
        } catch (error) {
          addNotification({
            type: 'error',
            message: 'Erro ao consultar endereço. Verifique o CEP inserido e tente novamente.',
          });
        } finally {
          setAddressByCepIsLoading(false);
        }
      }
    })();
  }, [addNotification, setValue, watchCEP]);

  useEffect(() => {
    if (watchTypePlan === companyTypePlanEnum.YEARLY) {
      setValue('monthlyFeeDay', currentDate);
      return;
    }
    if (watchTypePlan === companyTypePlanEnum.MONTHLY) {
      setValue('monthlyFeeDay', '10');
      return;
    }
    setValue('monthlyFeeDay', '');
  }, [watchTypePlan, setValue]);

  const handleChangeMonthlyInstallments =
    (onChange: (value: string) => void) => (event: ChangeEvent<HTMLInputElement>) => {
      let inputValue: string | number = Number(event.target.value.replace(/\D/g, ''));
      inputValue = (inputValue / 100).toFixed(2);
      inputValue = inputValue.replace('.', ',');
      const formattedValue = `R$ ${inputValue}`;
      onChange(formattedValue);
    };

  // └── Handlers (e.g., useCallback)
  const onSubmit = async ({
    cnpj,
    fantasyName,
    name,
    zipCode,
    address,
    addressNumber,
    complement,
    district,
    city,
    state,
    phone,
    givenName,
    familyName,
    email,
    companyType,
    monthlyInstallments,
    typePlan,
    dateRegister,
    monthlyFeeDay,
    comment,
  }: ICreateCompanyPayload) => {
    setLoading(true);
    try {
      await createCompanyMutation.mutateAsync({
        cnpj: cnpj.replace(/[./-]/g, ''),
        fantasyName,
        name,
        zipCode: zipCode.replace('-', ''),
        address,
        addressNumber,
        complement,
        district,
        city,
        state,
        phone: phone.replace(/[()-]/g, ''),
        givenName,
        familyName,
        email,
        companyType,
        monthlyInstallments: parseFloat(
          monthlyInstallments?.replace(/R\$\s/g, '').replace(',', '.') ?? '0'
        ).toString(),
        typePlan,
        dateRegister,
        monthlyFeeDay,
        comment,
      });

      addNotification({
        type: 'success',
        message: 'Empresa criada com sucesso!',
      });
      reset();
    } catch (error) {
      if (error instanceof AxiosError) {
        if (
          error.response?.status &&
          error.response?.status >= 400 &&
          error.response?.status < 500
        ) {
          addNotification({
            type: 'error',
            message: error.response?.data.message,
          });
          return;
        }

        if (
          ['Email already exists', 'User already exists'].includes(error.response?.data.message)
        ) {
          addNotification({
            type: 'error',
            message: 'Usuário já cadastrado no sistema.',
          });
          return;
        }

        addNotification({
          type: 'error',
          message: 'Erro com o serviço. Tente novamente mais tarde.',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleMaskValue = useCallback(
    <TFieldName extends keyof ICreateCompanyPayload>(
      field: ControllerRenderProps<ICreateCompanyPayload, TFieldName>,
      maskCallback: (value: string) => string
    ) => {
      return (event: ChangeEvent<HTMLInputElement>) => {
        event.target.value = maskCallback(event.target.value);
        field.onChange(event);
      };
    },
    []
  );

  return (
    <>
      {/* MAIN CONTAINER */}
      <div className={mainStyles.mainContainer}>
        {/* SUBHEADER CONTAINER */}
        <div className={mainStyles.subHeaderContainer}>
          {/* ADD USER ICON - COMPANY DETAILS HEADER */}
          <h1 className={mainStyles.addUserIconContainerH1}>
            <AddUserIcon className={mainStyles.addUserIconStyles} /> Cadastrar Empresa
          </h1>
          {/* REQUIRED FIELDS TEXT */}
          <h3 className={mainStyles.requiredFieldsH3}>Campos obrigatórios *</h3>
        </div>
        {/* FORM CONTAINER */}
        <form onSubmit={handleSubmit(onSubmit)} noValidate className={mainStyles.formContainer}>
          {/* FORM QUESTIONS SECTION */}
          <div className={mainStyles.formQuestionsSection}>
            {/* FORM TITLE */}
            <h3 className={mainStyles.formTitle}>Dados da Empresa</h3>
            {/* Company inputs */}
            <Controller
              name="cnpj"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <InputText
                  {...field}
                  labelText={formProperties.cnpjInput.labelText}
                  cssClasses={{
                    label: formStyles.cnpjInput.labelStyle,
                  }}
                  type="text"
                  placeholder={formProperties.cnpjInput.placeHolderText}
                  onChange={handleMaskValue<'cnpj'>(field, cnpjMask)}
                />
              )}
            />
            {/* FANTASY NAME INPUT */}
            <InputText
              labelText={formProperties.fanstasyNameInput.labelText}
              cssClasses={{ label: formStyles.fanstasyNameInput.labelStyle }}
              {...register('fantasyName')}
            />
            {/* NAME INPUT */}

            <InputText
              labelText={formProperties.nameInput.labelText}
              cssClasses={{ label: formStyles.nameInput.labelStyle }}
              {...register('name')}
            />
            {/* Address inputs */}
            {/* ZIPCODE INPUT */}

            <Controller
              name="zipCode"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <div className={formStyles.zipCodeInput.mainContainerStyle}>
                  {addressByCepIsLoading && (
                    <SimpleSpinnerIcon className={formStyles.zipCodeInput.spinnerIconStyle} />
                  )}
                  <InputText
                    {...field}
                    labelText={formProperties.zipCodeInput.labelText}
                    cssClasses={{
                      label: formStyles.zipCodeInput.inputText.labelStyle,
                    }}
                    type="text"
                    placeholder={formProperties.zipCodeInput.placeHolderText}
                    onChange={handleMaskValue<'zipCode'>(field, cepMask)}
                    disabled={addressByCepIsLoading}
                  />
                </div>
              )}
            />
            {/* ADDRESS INPUT */}

            <InputText
              labelText={formProperties.addressInput.labelText}
              cssClasses={{ label: formStyles.addressInput.labelStyle }}
              {...register('address')}
              disabled={addressByCepIsLoading}
            />
            {/* ADDRESSNUMBER INPUT */}

            <InputText
              labelText={formProperties.addressNumber.labelText}
              cssClasses={{
                label: formStyles.addressNumber.labelStyle,
              }}
              {...register('addressNumber')}
              disabled={addressByCepIsLoading}
            />
            {/* COMPLEMENT INPUT */}

            <InputText
              labelText={formProperties.complementInput.labelText}
              cssClasses={{
                label: formStyles.complementInput.labelStyle,
              }}
              {...register('complement')}
              disabled={addressByCepIsLoading}
            />
            {/* DISTRICT INPUT */}

            <InputText
              labelText={formProperties.districtInput.labelText}
              cssClasses={{ label: formStyles.districtInput.labelStyle }}
              {...register('district')}
              disabled={addressByCepIsLoading}
            />
            {/* CITY INPUT */}

            <InputText
              labelText={formProperties.cityInput.labelText}
              cssClasses={{ label: formStyles.cityInput.labelStyle }}
              {...register('city')}
              disabled={addressByCepIsLoading}
            />
            {/* STATE INPUT */}

            <InputText
              labelText={formProperties.stateInput.labelText}
              cssClasses={{
                label: formStyles.stateInput.labelStyle,
              }}
              {...register('state')}
              disabled={addressByCepIsLoading}
            />
            <Controller
              name="phone"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <InputText
                  {...field}
                  labelText={formProperties.phoneInput.labelText}
                  cssClasses={{ label: formStyles.phoneInput.labelStyle }}
                  type="text"
                  placeholder={formProperties.phoneInput.placeHolderText}
                  onChange={handleMaskValue<'phone'>(field, phoneNumberMask)}
                />
              )}
            />
            {/* Company manager inputs */}
            {companyTypesIsLoading ? (
              <Skeleton
                height={formStyles.companyTipeInput.skeleton.heightSkeleton}
                baseColor={formStyles.companyTipeInput.skeleton.baseColor}
                highlightColor={formStyles.companyTipeInput.skeleton.highlightColor}
                containerClassName={formStyles.companyTipeInput.skeleton.containerClassName}
                className={formStyles.companyTipeInput.skeleton.classNameSkeleton}
              />
            ) : (
              //COMPANYTYPE INPUT
              <Select
                labelText={formProperties.companyTipeInput.labelText}
                cssClasses={{
                  label: formStyles.companyTipeInput.selectInput.labelStyle,
                  select: formStyles.companyTipeInput.selectInput.selectStyle(watchCompanyType),
                }}
                {...register('companyType')}
                placeholder={formProperties.companyTipeInput.placeHolderText}
                defaultValue={-1}
                disabled={addressByCepIsLoading}
              >
                <option
                  defaultChecked={true}
                  value={-1}
                  className={formStyles.companyTipeInput.selectInput.defaultOptionStyle}
                >
                  Selecione um tipo
                </option>
                {companyTypes?.data.map((companyType, companyTypeIndex) => (
                  <option
                    value={companyType.id}
                    className="text-textcolor"
                    key={`company-type-${companyType.description}-${companyType.id}-option-${companyTypeIndex}`}
                  >
                    {companyType.description ?? '-'}
                  </option>
                ))}
              </Select>
            )}
            {/* PLANTYPE INPUT */}

            <Select
              labelText={formProperties.selectPlanTypeInput.labelText}
              cssClasses={{
                label: formStyles.selectPlanTypeInput.labelStyle,
                select: formStyles.selectPlanTypeInput.selectStyle(watchTypePlan),
              }}
              {...register('typePlan')}
              placeholder={formProperties.selectPlanTypeInput.placeHolderText}
              defaultValue={''}
            >
              <option
                defaultChecked={true}
                value={''}
                className={formStyles.selectPlanTypeInput.optionsStyle}
              >
                Selecione um tipo plano
              </option>
              <option
                value={companyTypePlanEnum.MONTHLY}
                className={formStyles.selectPlanTypeInput.optionsStyle}
              >
                Plano mensal
              </option>
              <option
                value={companyTypePlanEnum.YEARLY}
                className={formStyles.selectPlanTypeInput.optionsStyle}
              >
                Plano anual
              </option>
            </Select>
            {/* DATEREGISTER INPUT */}

            <Controller
              name="dateRegister"
              control={control}
              defaultValue={currentDate}
              render={({ field }) => {
                return (
                  <label
                    htmlFor="dateRegister"
                    className={formStyles.dateRegisterInput.labelStyleContainer}
                  >
                    {formProperties.dateRegisterInput.labelText}
                    <Datepicker
                      inputName="dateRegister"
                      {...field}
                      asSingle={true}
                      primaryColor="indigo"
                      i18n={'pt-br'}
                      dateLooking="middle"
                      displayFormat={DATE_DISPLAY_FORMAT}
                      placeholder="dd/mm/yyyy"
                      popoverDirection="down"
                      containerClassName={formStyles.dateRegisterInput.datePickerStyleContainer}
                      inputClassName={formStyles.dateRegisterInput.datePickerStyleInput}
                      onChange={(event) => field.onChange(event?.startDate ?? '')}
                      value={{ startDate: field.value || null, endDate: field.value || null }}
                    />
                  </label>
                );
              }}
            />
            {watchTypePlan !== companyTypePlanEnum.YEARLY ? (
              <Controller
                name="monthlyFeeDay"
                control={control}
                render={({ field }) => (
                  <InputText
                    labelText={formProperties.monthlyFeeDayInput.labelText(watchTypePlan || '')}
                    placeholder={
                      watchTypePlan !== companyTypePlanEnum.MONTHLY
                        ? 'Insira um plano de pagamento'
                        : 'Insira o dia de vencimento'
                    }
                    cssClasses={{ label: 'row-start-[8] row-end-[9] col-start-5 col-end-9' }}
                    type="number"
                    {...field}
                    onChange={(e) => {
                      const value = Math.max(1, Math.min(31, Number(e.target.value)));
                      field.onChange(value.toString());
                    }}
                    disabled={watchTypePlan !== companyTypePlanEnum.MONTHLY}
                  />
                )}
              />
            ) : (
              <Controller
                name="monthlyFeeDay"
                control={control}
                defaultValue={currentDate}
                render={({ field }) => (
                  <label
                    htmlFor="monthlyFeeDay"
                    className={formStyles.monthlyFeeDayInput.datePickerStyleContainerLabel}
                  >
                    {formProperties.monthlyFeeDayInput.labelText(watchTypePlan)}
                    <Datepicker
                      inputName="monthlyFeeDay"
                      {...field}
                      asSingle={true}
                      primaryColor="indigo"
                      i18n={'pt-br'}
                      dateLooking="middle"
                      displayFormat={DATE_DISPLAY_FORMAT}
                      placeholder="dd/mm/yyyy"
                      popoverDirection="down"
                      containerClassName={
                        formStyles.monthlyFeeDayInput.datePickerStyleInputContainer
                      }
                      inputClassName={formStyles.monthlyFeeDayInput.inputDatePickerStyle}
                      onChange={(event) => field.onChange(event?.startDate ?? '')}
                      value={{ startDate: field.value || null, endDate: field.value || null }}
                    />
                  </label>
                )}
              />
            )}
            {/* MONTHLYINSTALLMENTS INPUT */}

            <Controller
              name="monthlyInstallments"
              control={control}
              defaultValue="R$ 0,00"
              render={({ field }) => (
                <InputText
                  {...field}
                  labelText={formProperties.monthlyInstallments.labelText(watchTypePlan || '')}
                  cssClasses={{ label: formStyles.monthlyInstallments.labelStyle }}
                  type="text"
                  onChange={handleChangeMonthlyInstallments(field.onChange)}
                />
              )}
            />
            <Controller
              name="comment"
              control={control}
              defaultValue={''}
              render={({ field }) => (
                <InputTextArea
                  {...field}
                  labelText={formProperties.commentInput.labelText}
                  cssClasses={{
                    label: formStyles.comments.labelStyle,
                    textarea: formStyles.comments.textarea,
                  }}
                  maxLength={DEFAULT_MAX_LENGHT}
                />
              )}
            />
          </div>
          <div className={mainStyles.userInputSection}>
            <h3 className={mainStyles.userTitleH3}>Dados do Usuário</h3>
            <InputText
              labelText={userFormProperties.nameInput.labelText}
              cssClasses={{ label: userForm.nameInput.labelStyle }}
              {...register('givenName')}
            />
            <InputText
              labelText={userFormProperties.surnameInput.labelText}
              cssClasses={{ label: userForm.surnameInput.labelStyle }}
              {...register('familyName')}
            />
            <InputText
              labelText={userFormProperties.emailInput.labelText}
              placeholder="email@email.com"
              cssClasses={{
                label: userForm.emailInput.labelStyle,
              }}
              {...register('email')}
            />
          </div>
          <div className={mainStyles.submitButtonsSection}>
            <ReturnButton returnTo={PAGES_ROUTES.authenticated.company.list} />
            <button
              type="submit"
              className={mainStyles.submitButtonStyle}
              disabled={!isValid || isLoading || watchCompanyType == -1}
            >
              CADASTRAR
              <AddUserIcon className={mainStyles.submitButtonIconStyle} />
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default Create;
