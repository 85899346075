export const backgroundColors = [
  'rgba(17, 141, 255, 255)',
  'rgba(107, 0, 123, 255)',
  'rgba(230, 108, 55, 255)',
  'rgba(34, 139, 34, 255)',
  'rgba(220, 20, 60, 255)',
  'rgba(255, 255, 0, 255)',
  'rgba(255, 165, 0, 255)',
  'rgba(75, 0, 130, 255)',
  'rgba(139, 69, 19, 255)',
  'rgba(255, 69, 0, 255)',
  'rgba(173, 216, 230, 255)',
  'rgba(148, 0, 211, 255)',
  'rgba(255, 192, 203, 255)',
  'rgba(0, 100, 0, 255)',
  'rgba(18, 35, 158, 255)',
  'rgba(64, 224, 208, 255)',
];
