// Third-party
import { number, object, string } from 'yup';

const REQUIRED_FIELD_MESSAGE = 'Este campo é obrigatório';
const PAYTIPE_FIELD_MESSAGE = 'Insira um valor válido no campo "Tipo de Empresa"';
const CEP_MESSAGE = 'O CEP deve ter no mínimo 9 caracteres';
const PHONE_MESSAGE ='O telefone deve ter no mínimo 14 caracteres';

export const formSchema = object().shape({
  fantasyName: string().required(REQUIRED_FIELD_MESSAGE),
  name: string().required(REQUIRED_FIELD_MESSAGE),
  address: string().nullable(),
  addressNumber: string().nullable(),
  city: string().nullable(),
  complement: string().nullable(),
  district: string().nullable(),
  state: string().nullable(),
  zipCode: string().min(9, CEP_MESSAGE).nullable(),
  phone: string().min(14, PHONE_MESSAGE).nullable(),
  companyType: number()
    .required(REQUIRED_FIELD_MESSAGE)
    .test(
      'is-greater-than-zero',
      PAYTIPE_FIELD_MESSAGE,
      value => value > 0
    ),
  typePlan: string().required(REQUIRED_FIELD_MESSAGE),
  dateRegister: string().required(REQUIRED_FIELD_MESSAGE),
  monthlyFeeDay: string().required(REQUIRED_FIELD_MESSAGE),
});