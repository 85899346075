// Third-party
import { combineReducers } from '@reduxjs/toolkit';

// App
import fileModalReducer from './fileModalSlice';

// Combine reducers
const rootReducer = combineReducers({
  // Add the reducers here
  // e.g.: counter: counterReducer,
  fileModal: fileModalReducer,
});

export default rootReducer;
