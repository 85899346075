import SalesChart from '../../SalesChart';
import SalesList from '../../SalesList';
import SalesPerPayList from '../../SalesPerPayList';
import SectionContainer from '../SectionContainer';

interface ISalesSectionProps {
  toExport?: boolean;
}

function SalesSection({ toExport }: ISalesSectionProps) {
  return (
    <SectionContainer
      title="VENDAS - Fluxo de Caixa"
      cssClasses={{
        titleContainerStyle: 'bg-[#38761d]',
        container: toExport ? 'max-h-[1250px] overflow-hidden' : undefined,
      }}
      toExport={toExport}
    >
      <SalesChart toExport={toExport} />
      <SalesList toExport={toExport} />
      <SalesPerPayList toExport={toExport} />
    </SectionContainer>
  );
}

export default SalesSection;
