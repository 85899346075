// Third-party
import { Outlet, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

// App
import { Header, SideMenu } from './components';

// interface OutletContextType {}

function DefaultLayout() {
  /*
  ** Component organization **

   └── Declaration of generic hooks (e.g., useNavigate)
   └── State declaration
   └── Side effects (e.g., useEffect)
   └── Memoization (e.g., useMemo)
   └── Handlers (e.g., useCallback)
   └── JSX
   */

  //  └── Declaration of generic hooks (e.g., useNavigate)
  const outletDivRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  // └── State declaration
  //   └── Global
  //   └── Internal
  const [windowSize, setWindowSize] = useState<number>(window.innerWidth);
  const [showMenu, setShowMenu] = useState<boolean>(windowSize > 768);


  // └── Side effects (e.g., useEffect)
  useEffect(() => {    
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };    
    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Remove active unit and active brand for other pages
  useEffect(() => {
    if (outletDivRef.current) {
      outletDivRef.current.scrollTo(0, 0);
    }
  }, [location]);

  // └── Handlers (e.g., useCallback)
  // Toggle mobile side menu
  const toggleMobileMenu = (event: React.MouseEvent<HTMLElement>, forceChange?: boolean) => {
    if (forceChange) {
      setShowMenu(!showMenu);
      return;
    }           
    if (event?.target && event?.target instanceof HTMLElement) {
      const htmlTarget = event.target as HTMLElement;  
      if (((htmlTarget.classList.contains('hamburger-menu') || htmlTarget.classList.contains('!hamburger-menu')) && windowSize < 768) || htmlTarget.classList.contains('arrow-icon')) {
        setShowMenu(!showMenu);
      }
    }
  };
  // └── JSX
  return (
    <div className={classNames('bg-[#F3F4F6] flex h-full')}>
      {/* SIDE MENU */}
      <div className="w-full">
      <SideMenu toggleMobileMenu={toggleMobileMenu} showMenu={showMenu} windowSize={windowSize} />
        {/* HEADER CONTENT */}
        <Header setShowMenu={setShowMenu} showMenu={showMenu} />

        {/* MAIN CONTENT */}
        <div
          ref={outletDivRef}
          className={classNames(
            "w-full overflow-x-hidden pt-[64px] md:pt-[0px] min-h-screen bg-white transition-all duration-500 ease-in-out",
            {
              'md:pl-[240px]' : showMenu,
              'md:pl-[50px] ': !showMenu
            }
        )}
        >
          <Outlet context={{}} />
        </div>
      </div>
    </div>
  );
}

export default DefaultLayout;
