// Third-party
import { useQuery } from '@tanstack/react-query';

// App
import {
  getCompanyById,
  getDashboardData,
  getExpenseById,
  getSaleById,
  getSummaryData,
  listCompany,
  listCompanyExpenses,
  listCompanyPayments,
  listCompanyPendingSales,
  listCompanySales,
  listCompayLogs,
  listExpensesTypes,
  listGroup,
  listLogsDetails,
  listTypes,
} from 'services/api/company';
import {
  ICompany,
  ICompanyGroupListParams,
  ICompanyListParams,
  ICompanyPendingSalesListParams,
  ICompanySalesListParams,
  IExpense,
  IExpenseListParams,
  IExportReportsParams,  
  ISaleById,
} from 'interfaces/companyTypes';
import queriesKeys from './queriesKeys';
import { isNotEmailOrName } from 'utils/filters.utils';
import { removeCpfCnpjMask } from 'utils/mask.utils';
import { exportReports } from 'services/api/sale';
import { ILogDetailsParams, ILogListParams } from 'interfaces/logTypes';

export const useGetCompanyById = (companyId: number) =>
  useQuery<ICompany>({
    queryKey: [queriesKeys.companyDetails, companyId],
    queryFn: async () => {
      const company = await getCompanyById(companyId);

      return company;
    },
  });

export const useGetCompanyList = (params: ICompanyListParams) => {
  const { start, size, order, field } = params;
  let { filter } = params;
  const queryKey = [queriesKeys.companyList, start, size, order, field];

  if (filter) {
    if (isNotEmailOrName(filter)) {
      filter = removeCpfCnpjMask(filter);
      queryKey.push(filter);
    } else {
      queryKey.push(filter);
    }
  }

  return useQuery({
    queryKey,
    queryFn: async () => {
      const company = await listCompany({ ...params, filter });

      return company;
    },
    placeholderData: (previousData) => previousData,
  });
};

export const useGetCompanyGroupList = (params: ICompanyGroupListParams) => {
  const { companyId, groupId, status, order, field } = params;
  const queryKey = [queriesKeys.companyGroupList, companyId, groupId, status, order, field];

  return useQuery({
    queryKey,
    queryFn: async () => {
      const company = await listGroup(params);

      return company;
    },
    placeholderData: (previousData) => previousData,
  });
};

export const useGetCompanyExpenseList = (params: IExpenseListParams) => {
  const { start, size, order, field, companyId, description, dateInit, dateFinal } = params;

  const queryKey = [
    queriesKeys.companyExpenseList,
    companyId,
    start,
    size,
    order,
    field,
    description,
    dateInit,
    dateFinal,
  ];

  return useQuery({
    queryKey,
    queryFn: async () => {
      const expensesList = await listCompanyExpenses(params);

      return expensesList;
    },
    placeholderData: (previousData) => previousData,
  });
};

export const useGetCompanyLogList = (params: ILogListParams) => {
  const { start, size, order, field, companyId, filter, dateInit, dateFinal } = params;

  const queryKey = [
    queriesKeys.logList,
    companyId,
    start,
    size,
    order,
    field,
    filter,
    dateInit,
    dateFinal,
  ];

  return useQuery({
    queryKey,
    queryFn: async () => {
      const logList = await listCompayLogs(params);

      return logList;
    },
    placeholderData: (previousData) => previousData,
  });
};

export const useGetCompanyLogDetails = (params: ILogDetailsParams) => {
  const { logId } = params;

  const queryKey = [
    queriesKeys.logDetails,
    logId,
  ];

  return useQuery({
    queryKey,
    queryFn: async () => {
      const logDetails = await listLogsDetails(params);

      return logDetails;
    },
    placeholderData: (previousData) => previousData,
  });
};

export const useGetCompanyTypes = () => {
  const queryKey = [queriesKeys.companyTypes];

  return useQuery({
    queryKey,
    queryFn: async () => {
      const company = await listTypes();

      return company;
    },
    placeholderData: (previousData) => previousData,
  });
};

type QueryKeyName = keyof typeof queriesKeys;
export const useGetExpenseList = (
  companyId: number,
  groupId: string,
  queryKeyName: QueryKeyName
) => {
  const queryKey = [queriesKeys[queryKeyName], companyId, groupId];

  return useQuery({
    queryKey,
    queryFn: async () => {
      const listTypes = await listExpensesTypes(companyId, groupId);

      return listTypes;
    },
    placeholderData: (previousData) => previousData,
  });
};

export const useGetCompanyPendingSalesList = (params: ICompanyPendingSalesListParams) => {
  const { start, size, order, field, filter, companyId, clientId, dateInit, dateFinal,  } = params;

  const queryKey = [
    queriesKeys.companyPendingSaleslList,
    companyId,
    start,
    size,
    order,
    field,
    filter,
    clientId,
    dateInit,
    dateFinal,
  ];

  return useQuery({
    queryKey,
    queryFn: async () => {
      const sales = await listCompanyPendingSales({ ...params });

      return sales;
    },
    placeholderData: (previousData) => previousData,
  });
};

export const useGetCompanySalesList = (params: ICompanySalesListParams) => {
  const { start, size, order, field, filter, dateInit, dateFinal, companyId } = params;

  const queryKey = [
    queriesKeys.companySaleList,
    companyId,
    start,
    size,
    order,
    field,
    filter,
    dateInit,
    dateFinal,
  ];

  return useQuery({
    queryKey,
    queryFn: async () => {
      const sales = await listCompanySales({ ...params });

      return sales;
    },
    placeholderData: (previousData) => previousData,
  });
};

export const useGetPayments = (params: ICompanySalesListParams) => {
  const { start, size, order, field, filter, dateInit, dateFinal, companyId } = params;

  const queryKey = [
    queriesKeys.paymentList,
    companyId,
    start,
    size,
    order,
    field,
    filter,
    dateInit,
    dateFinal,
  ];

  return useQuery({
    queryKey,
    queryFn: async () => {
      const payments = await listCompanyPayments({ ...params });

      return payments;
    },
    placeholderData: (previousData) => previousData,
  });
};

export const useGetSalesId = (companyId: number | string, saleId: number | string) => {
  const queryKey = [queriesKeys.saleById, companyId, saleId];
  return useQuery({
    queryKey,
    queryFn: async () => {
      const sale: ISaleById = await getSaleById(companyId, saleId);

      return sale;
    },
    placeholderData: (previousData) => previousData,
  });
};

export const useExportReports = (params: IExportReportsParams) => {
  const { start, size, filter, initialDate, endDate, companyId, type, report, clientId } = params;

  const queryKey = [
    queriesKeys.exportReports,
    companyId,
    start,
    size,
    filter,
    initialDate,
    endDate,
    type,
    report,
    clientId
  ];

  return useQuery({
    queryKey,
    enabled: false,
    queryFn: async () => {
      const report = await exportReports({ ...params });

      return report;
    },
    placeholderData: (previousData) => previousData,
  });
};

export const useGetExpenseId = (companyId: number | string, expenseId: number | string) => {
  const queryKey = [queriesKeys.saleById, companyId, expenseId];
  return useQuery({
    queryKey,
    queryFn: async () => {
      const expense: IExpense = await getExpenseById(companyId, expenseId);

      return expense;
    },
    placeholderData: (previousData) => previousData,
  });
};

export const useGetDashboardData = (
  companyId: number | string,
  params: { initialDate: string; finalDate: string; clientId?: number },
  options?: { requestIdEnabled?: boolean }
) => {
  const queryKey = [
    queriesKeys.dashboardData,
    companyId,
    params.initialDate,
    params.finalDate,
    params.clientId,
  ];

  return useQuery({
    queryKey,
    queryFn: async () => {
      const dashboardData = await getDashboardData(companyId, params);

      return dashboardData;
    },
    enabled: options?.requestIdEnabled ?? true,
    placeholderData: (previousData) => previousData,
  });
};

export const useGetSummaryData = (
  companyId: number | string,
  params: { initialDate: string; finalDate: string },
  options?: { requestIdEnabled?: boolean }
) => {
  const queryKey = [queriesKeys.summaryData, companyId, params.initialDate, params.finalDate];

  return useQuery({
    queryKey,
    queryFn: async () => {
      const dashboardData = await getSummaryData(companyId, params);

      return dashboardData;
    },
    enabled: options?.requestIdEnabled ?? true,
    placeholderData: (previousData) => previousData,
  });
};
