import IconTextTitle, { IIconTextTitleProps } from '../IconTextTitle';
import TotalValueDisplayText, { ITotalValueDisplayText } from '../TotalValueDiplayText';
import TotalValueDisplay, { ITotalValueDisplay } from '../TotalValueDisplay';

interface IDisplayTotalValue {
  displayProps: ITotalValueDisplay;
  textProps: ITotalValueDisplayText;
}

interface IProps {
  iconTextTitleProps: IIconTextTitleProps;
  totalValueDisplayProps?: IDisplayTotalValue[];
}

function PageHeader({ iconTextTitleProps, totalValueDisplayProps }: IProps) {
  return (
    <div className="flex xl:flex-row flex-col justify-start items-center xl:pb-[0.5rem] pb-0 xl:gap-[1.25rem] w-full">
      <IconTextTitle {...iconTextTitleProps} />
      <h1 
        className="flex xl:flex-row flex-col xl:justify-end justify-items-center w-full my-[20px] gap-y-[20px] gap-x-[20px] "        
        >
        {totalValueDisplayProps?.map((e, i) => {
          return (
            <div
              key={i}
              className="flex flex-col justify-center xl:items-start xl:self-start gap-x-[8px] text-xl font-semibold text-gray-600 mb-[24px] bg-[#f6f9fd] !m-0 p-[30px] rounded-md"              
            >
              <TotalValueDisplayText {...e.textProps} />
              <TotalValueDisplay {...e.displayProps} />
            </div>
          );
        })}
      </h1>
    </div>
  );
}

export default PageHeader;
