//Third-party
import classNames from 'classnames';
import { ReactNode, useEffect, useRef } from 'react';

// App

type ModalProps = {
  content: ReactNode;
  onClose?: () => unknown;
  disableCloseOnOutsideClick?: boolean;
  css?: {
    modal?: classNames.ArgumentArray[0] | Record<string, classNames.ArgumentArray[0]>;
  };
};

function Modal({
  // title,
  content,
  onClose,
  disableCloseOnOutsideClick,
  css,
}: ModalProps) {
  /*
  **** Component organization ****

   └── Declaration of generic hooks (e.g., useNavigate)
   └── State declaration
   └── Side effects (e.g., useEffect)
   └── Memoization (e.g., useMemo)
   └── Handlers (e.g., useCallback)
   └── TSX
   */

  // └── Declaration of generic hooks (e.g., useNavigate)
  const modalRef = useRef<HTMLDivElement>(null);

  // └── Side effects (e.g., useEffect)
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      if (
        disableCloseOnOutsideClick ||
        !modalRef.current ||
        modalRef.current.contains(event.target as Node)
      ) {
        return;
      }
      onClose?.();
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [modalRef, onClose, disableCloseOnOutsideClick]);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className={classNames(
        'fixed inset-0 w-full md:h-screen h-full bg-black bg-opacity-40 flex items-center justify-center md:p-8 pt-[68px]  z-[99]',
        {
          'cursor-pointer': !!onClose && !disableCloseOnOutsideClick,
        }
      )}
    >
      <div
        className={classNames(
          'bg-white rounded-md md:max-w-[323px] shadow-2xl cursor-default p-6 ',
          css?.modal
        )}
        ref={modalRef}
      >
        {content}
      </div>
    </div>
  );
}

export default Modal;
