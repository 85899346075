interface IOptions {
  useGrouping?: boolean;
}

export const convertToBrazilianCurrencyFormat = (
  numericString: string,
  options?: IOptions
): string => {
  const numericValue = parseFloat(numericString);

  const formattedValue = numericValue.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    useGrouping: options?.useGrouping ?? true,
    minimumFractionDigits: 2,
  });
  return formattedValue;
};


export const toFloat = (number:any): number => {
  if(!number) {
      return 0 ;
  }

  if (typeof number === "string") {
      return Number.parseFloat(number);
  }

  return number;
}



export const convertToCurrencyFormat = (
  numericString: string,
  options?: IOptions
): string => {
  const numericValue = numericString.replace('R$', '').trim();
  
  const usValue = numericValue.replace(/\./g, '').replace(',', '.');
  
  return parseFloat(usValue).toFixed(2);
};
