// Third-party
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { AxiosError } from 'axios';

// App
import { formSchema } from './schemas';
import Input from 'components/Input';
import { changePassword } from 'services/api/user';
import { PadlockIcon, PasswordKeyIcon } from 'assets/icons';
import useLoadingStore from 'store/client/loading/useLoadingStore';
import { selectLoading, selectSetLoading } from 'store/client/loading/selectors';
import { selectAddNotification } from 'store/client/notification/selectors';
import useNotificationStore from 'store/client/notification/useNotificationStore';
import { useGetUser } from 'store/server/user/queries';

type FormValues = {
  currentPassword: string;
  newPassword: string;
  repeatNewPassword: string;
};

function ChangePassword() {
  /*
  **** Component organization ****

   └── Declaration of generic hooks (e.g., useNavigate)
   └── State declaration
   └── Side effects (e.g., useEffect)
   └── Memoization (e.g., useMemo)
   └── Handlers (e.g., useCallback)
   └── JSX
   */

  // └── Declaration of generic hooks (e.g., useNavigate)
  const { data: user } = useGetUser();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setError,
    reset,
  } = useForm<FormValues>({
    resolver: yupResolver(formSchema),
  });
  const dispatch = useDispatch();
  const isLoading = useLoadingStore(selectLoading);
  const setLoading = useLoadingStore(selectSetLoading);
  const addNotification = useNotificationStore(selectAddNotification);

  // └── Side effects (e.g., useEffect)

  // └── Handlers (e.g., useCallback)
  const onSubmit = async ({ currentPassword, newPassword, repeatNewPassword }: FormValues) => {
    setLoading(true);
    try {
      if (newPassword !== repeatNewPassword) {
        // Set an error message to the user
        setError('newPassword', { message: 'As senhas devem ser iguais' });
        setError('repeatNewPassword', { message: 'As senhas devem ser iguais' });
        addNotification({ type: 'error', message: 'As senhas devem ser iguais' });
        return;
      }

      await changePassword({
        email: user?.email || '',
        currentPassword,
        newPassword,
      });

      addNotification({ type: 'success', message: 'Senha alterada com sucesso!' });
      reset();
    } catch (error) {
      if (error instanceof AxiosError) {
        if (
          error.response?.status === 400 &&
          error.response.data.message.includes('Incorrect current password or email')
        ) {
          setError('currentPassword', { message: 'Senha incorreta.' });
          addNotification({
            type: 'error',
            message: 'Senha atual ou e-mail incorretos.<br/> Tente novamente mais tarde.',
          });
          return;
        }

        addNotification({
          type: 'error',
          message: 'Erro com o serviço de troca de senha. Tente novamente mais tarde.',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full h-screen flex flex-col gap-y-16 items-start pt-[23px] md:pt-[43px] p-[27px] md:p-8">
      <div>
        <h1 className="flex items-center gap-2.5 text-2xl font-semibold text-[#595959]">
          <PadlockIcon className="w-[22px] h-[24px] !text-secondary" /> Trocar Senha
        </h1>
      </div>
      <div className="w-full h-screen flex justify-center items-start">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="max-w-[400px] w-full flex flex-col justify-center items-center  gap-y-4"
        >
          <Input
            labelText="Senha atual"
            name="currentPassword"
            type="password"
            register={register}
            displayWarning={!!errors.currentPassword}
            warningMessage={errors.currentPassword?.message}
            canShowPassword
            showErrorOutline
            disablePassworPatternTip
          />
          <Input
            labelText="Nova senha"
            name="newPassword"
            type="password"
            register={register}
            displayWarning={!!errors.newPassword}
            warningMessage={errors.newPassword?.message}
            canShowPassword
            showErrorOutline
          />
          <Input
            labelText="Re-digite sua nova senha"
            name="repeatNewPassword"
            type="password"
            register={register}
            displayWarning={!!errors.repeatNewPassword}
            warningMessage={errors.repeatNewPassword?.message}
            canShowPassword
            showErrorOutline
          />
          <div className="flex justify-between mt-2">
            <button
              type="submit"
              className="bg-primary text-xs font-bold leading-4 disabled:bg-[#D1D5DB] text-buttontextcolor py-2 pl-4 pr-[18px] rounded-md flex justify-center items-center h-[42px]"
              disabled={!isValid || isLoading}
            >
              TROCAR SENHA
              <PasswordKeyIcon className="!text-buttontextcolor" />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ChangePassword;
