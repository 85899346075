export default {
  companyList: 'companyList',
  companyDetails: 'companyDetails',
  companyGroupList: 'companyGroupList',
  companyTypes: 'companyTypes',
  categoryList: 'categoryList',
  paymentMethod: 'paymentMethod',
  paymentType: 'paymentType',
  companyExpenseList: 'companyExpenseList',
  companySaleList: 'companySaleList',
  paymentList: 'paymentList',
  companyPendingSaleslList: 'companyPendingSaleslList',   
  saleById: 'saleById',
  dashboardData: 'dashboardData',
  summaryData: 'summaryData',
  exportReports: 'exportReports',
  logList: 'logList',
  logDetails: 'logDetails',

} as const;
