// Third-party
import { create } from 'zustand';

// App
import { IGlobalParamsState } from './interfaces';
import { setFirstRendered, setIsCheckingToken, setLockComponentsRender } from './actions';

const useGlobalParamsStore = create<IGlobalParamsState>((set) => ({
  firstRender: true,
  isCheckingToken: false,
  lockComponentsRender: 0,
  setFirstRendered: setFirstRendered(set),
  setIsCheckingToken: setIsCheckingToken(set),
  setLockComponentsRender: setLockComponentsRender(set),
}));

export default useGlobalParamsStore;
