// Third-party
import { create } from 'zustand';

// App
import { IDashboardState } from './interfaces';
import { setSelectedClient, setSelectedMonth, setSelectedYear } from './actions';
import dayjs from 'dayjs';

const useDashboardStore = create<IDashboardState>((set) => ({
  selectedMonth: dayjs().month() + 1,
  selectedYear: dayjs().year(),
  selectedClientId: undefined,
  setSelectedMonth: setSelectedMonth(set),
  setSelectedYear: setSelectedYear(set),
  setSelectedClient: setSelectedClient(set),
}));

export default useDashboardStore;
