// Third-party
import { object, string } from 'yup';

// App
import { ICreateCompanyPayload } from 'interfaces/companyTypes';

const REQUIRED_FIELD_MESSAGE = 'Este campo é obrigatório';
const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])+)+$/;

export const formSchema = object<ICreateCompanyPayload>({
  cnpj: string().min(18).max(18).required(REQUIRED_FIELD_MESSAGE),
  fantasyName: string().required(REQUIRED_FIELD_MESSAGE),
  name: string().required(REQUIRED_FIELD_MESSAGE),
  zipCode: string().required(REQUIRED_FIELD_MESSAGE),
  address: string().required(REQUIRED_FIELD_MESSAGE),
  addressNumber: string().required(REQUIRED_FIELD_MESSAGE),
  complement: string().nullable(),
  district: string().required(REQUIRED_FIELD_MESSAGE),
  city: string().required(REQUIRED_FIELD_MESSAGE),
  state: string().required(REQUIRED_FIELD_MESSAGE),
  phone: string().min(14).required(REQUIRED_FIELD_MESSAGE),
  givenName: string().required(REQUIRED_FIELD_MESSAGE),
  familyName: string().required(REQUIRED_FIELD_MESSAGE),
  email: string().matches(emailRegex, 'Formato inválido').required(REQUIRED_FIELD_MESSAGE),
  companyType: string().required(REQUIRED_FIELD_MESSAGE),
  monthlyInstallments: string()
    .test(
      'Valor minímo 0,01',
      (value) => parseFloat(value?.replace(/R\$\s/g, '').replace(',', '.') ?? '0') >= 0.01
    )
    .required(REQUIRED_FIELD_MESSAGE),
  typePlan: string().required(REQUIRED_FIELD_MESSAGE),
  dateRegister: string().required(REQUIRED_FIELD_MESSAGE),
  monthlyFeeDay: string().required(REQUIRED_FIELD_MESSAGE),
  comment: string().nullable(),
});
