import classNames from 'classnames';

function CustomerIcon({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="28"
      viewBox="0 0 487 334"
      fill="none"
      className={classNames('text-primary', className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M335.765 134.021C341.277 137.177 343.186 144.204 340.029 149.715L305.289 210.373H329.911C330.013 210.372 330.115 210.372 330.217 210.373H396.783C396.885 210.372 396.987 210.372 397.089 210.373H421.711L386.971 149.715C383.814 144.204 385.723 137.177 391.235 134.021C396.746 130.864 403.773 132.773 406.929 138.285L448.216 210.373H459.139C459.299 210.37 459.46 210.37 459.621 210.373H475C481.351 210.373 486.5 215.522 486.5 221.873C486.5 228.224 481.351 233.373 475 233.373H468.543L451.65 306.742C448.202 322.113 433.416 333.495 418.135 333.495H385.968C385.866 333.497 385.763 333.497 385.661 333.495H341.339C341.237 333.497 341.134 333.497 341.032 333.495H308.968C301.113 333.624 293.453 331.016 287.311 326.112C281.153 321.198 276.907 314.287 275.313 306.572L259.45 233.373H252C245.649 233.373 240.5 228.224 240.5 221.873C240.5 215.522 245.649 210.373 252 210.373H268.502C268.658 210.37 268.815 210.37 268.971 210.373H278.784L320.071 138.285C323.227 132.773 330.254 130.864 335.765 134.021ZM282.984 233.373L288.848 260.434H322.773L319.76 233.373H285.651C285.51 233.376 285.369 233.376 285.227 233.373H282.984ZM342.902 233.373L345.915 260.434H381.085L384.098 233.373H342.902ZM407.24 233.373L404.227 260.434H438.71L444.941 233.373H441.773C441.631 233.376 441.49 233.376 441.349 233.373H407.24ZM433.414 283.434H401.665L398.652 310.495H418.135C422.908 310.495 428.179 306.327 429.21 301.696L429.228 301.616L433.414 283.434ZM375.51 310.495L378.523 283.434H348.477L351.49 310.495H375.51ZM328.348 310.495L325.335 283.434H293.833L297.804 301.76C297.814 301.805 297.823 301.849 297.832 301.893C298.335 304.352 299.686 306.562 301.659 308.137C303.632 309.711 306.1 310.549 308.634 310.498C308.687 310.497 308.74 310.496 308.794 310.496C308.818 310.496 308.841 310.495 308.865 310.495H328.348Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M228.805 199.478C226.378 198.367 223.923 197.308 221.439 196.304C239.814 182.569 253.386 163.423 260.235 141.574C267.082 119.727 266.859 96.285 259.596 74.5705C252.333 52.8558 238.398 33.969 219.766 20.5854C201.133 7.20192 178.748 0 155.781 0C132.813 0 110.428 7.20192 91.7953 20.5854C73.1629 33.969 59.2284 52.8558 51.9653 74.5705C44.7024 96.285 44.4792 119.727 51.3273 141.574C58.1755 163.423 71.7481 182.569 90.1221 196.304C53.1736 211.252 22.3475 238.157 2.60608 272.686C1.38273 274.647 0.567499 276.832 0.208635 279.112C-0.150229 281.393 -0.045337 283.722 0.517074 285.961C1.07944 288.2 2.08796 290.305 3.48248 292.147C4.87701 293.991 6.62909 295.536 8.63477 296.691C10.6403 297.846 12.8585 298.586 15.1573 298.87C17.4559 299.153 19.7885 298.973 22.0159 298.341C24.2435 297.707 26.3205 296.635 28.1238 295.186C29.9269 293.737 31.4194 291.941 32.5125 289.906C58.6031 244.963 104.679 218.158 155.781 218.158C183.009 218.158 208.811 225.773 230.923 239.684L227.591 199.579L228.805 199.478ZM93.5254 67.6449C85.2982 79.9157 80.9068 94.3423 80.9068 109.1C80.9298 128.884 88.8256 147.85 102.862 161.839C116.899 175.828 135.931 183.696 155.781 183.718C170.589 183.718 185.066 179.342 197.378 171.143C209.691 162.944 219.288 151.29 224.956 137.655C230.622 124.021 232.105 109.018 229.215 94.5432C226.327 80.0687 219.197 66.7729 208.725 56.3374C198.254 45.9018 184.912 38.7952 170.388 35.916C155.864 33.0368 140.81 34.5145 127.128 40.1621C113.446 45.8099 101.753 55.3739 93.5254 67.6449Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default CustomerIcon;
