import { StoreApi } from 'zustand';
import { IAuthState } from './interfaces';

export const setIsRefreshToken =
  (set: StoreApi<IAuthState>['setState']): IAuthState['setIsRefreshToken'] =>
  (value) =>
    set((state) => {
      if (value) {
        return { isRefreshToken: state.isRefreshToken + 1 };
      }

      if (state.isRefreshToken > 0) return { isRefreshToken: state.isRefreshToken - 1 };

      return { isRefreshToken: 0 };
    });

export const setTokenTimeoutId =
  (set: StoreApi<IAuthState>['setState']): IAuthState['setTokenTimeoutId'] =>
  (newTimeoutId) =>
    set({ tokenTimeoutId: newTimeoutId });

export const cleanTokenTimeoutId =
  (set: StoreApi<IAuthState>['setState']): IAuthState['cleanTokenTimeoutId'] =>
  () =>
    set({ tokenTimeoutId: null });
