// Third-party
import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';

// App
import configVars from 'config/config';
import { PAGES_ROUTES } from 'constants/routes';
import { refreshTokenService, signOutService } from 'services/auth';

// Interface to add the auth property in AxiosInstance
interface CustomAxiosInstance extends AxiosInstance {
  auth: AxiosInstance;
}

const apiRefreshToken = async () => {
  try {
    await refreshTokenService();
  } catch (error) {
    signOutService();
    throw new Error('');
  }
};

// Helper function for creating Axios custom instances
export const createApiInstance = (baseURL: string, publicBaseUrl?: string) => {
  // Axios instance that will not gain token interceptor
  // TODO mudar nome de instance para publicInstance
  const publicInstance = axios.create({
    baseURL: publicBaseUrl || baseURL,
  }) as CustomAxiosInstance;

  publicInstance.interceptors.request.use(
    async (config) => {
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  publicInstance.interceptors.response.use(
    (config) => {
      return Promise.resolve(config);
    },
    async (error) => {
      if (error?.response?.status >= 500) {
        window.history.pushState(null, '', PAGES_ROUTES.unavailable);
        window.dispatchEvent(new Event('routeChanged'));
      }
      return Promise.reject(error);
    }
  );

  // Axios instance that will gain token interceptor
  const authInstance = axios.create({ baseURL });

  // Interceptor to add the access token to the headers
  authInstance.interceptors.request.use(
    async (config: InternalAxiosRequestConfig) => {
      const token = localStorage.getItem('access_token');

      if (token) {
        // Decode token to get expiration time
        const decodedToken = JSON.parse(atob(token.split('.')[1]));
        // Check if token is expired or within the refresh threshold (e.g., 5 minutes before expiration)
        const tokenExpiration = Math.floor(decodedToken.exp * 1000) - Date.now() - 300000;

        if (tokenExpiration > 0) {
          config.headers.Authorization = `${token}`;
        } else {
          try {
            await apiRefreshToken();
          } catch (err) {
            throw new axios.Cancel();
          }
        }
      } else {
        throw new axios.Cancel();
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  authInstance.interceptors.response.use(
    (config) => {
      return Promise.resolve(config);
    },
    async (error) => {
      if (error?.response?.status === 401) {
        const token = localStorage.getItem('access_token');

        if (token && !error.config.url.includes('/v1/users/user/permission')) {
          await apiRefreshToken();
        } else {
          signOutService();
        }
      }

      if (error?.response?.status === 403) {
        window.history.pushState(null, '', PAGES_ROUTES.notfound);
        window.dispatchEvent(new Event('routeChanged'));
      }

      if (error?.response?.status >= 500) {
        window.history.pushState(null, '', PAGES_ROUTES.unavailable);
        window.dispatchEvent(new Event('routeChanged'));
      }

      return Promise.reject(error);
    }
  );

  // Adds the axios instance with the interceptor that gets the token
  publicInstance.auth = authInstance;

  return publicInstance as CustomAxiosInstance;
};

const baseURL = configVars.PRIVATE_API_URL;
const publicBaseURL = configVars.PUBLIC_API_URL;

/**
  Axios instance with another special instance with an interceptor
  configured to fetch the access token from localStorage before any request
  and add it to the headers

  To access this modified instance, simply access the `auth` property
  @instance CustomAxiosInstance
  @authInstanceKey auth
*/
const api = createApiInstance(baseURL, publicBaseURL);

export default api;
