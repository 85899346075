/* eslint-disable react-hooks/rules-of-hooks */
import { yupResolver } from '@hookform/resolvers/yup';
import { CloseIcon } from 'assets/icons';
import classNames from 'classnames';
import InputTextArea from 'components/InputTextArea';
import Modal from 'components/Modal';
import {
  IListElementExpense,
  IExpense,
  ICreateWriteOffPaymentPayload,
  ICompanyGroup,
} from 'interfaces/companyTypes';
import { Controller, useForm } from 'react-hook-form';
import { formSchema } from './schemas';

import { selectSetLoading } from 'store/client/loading/selectors';
import useLoadingStore from 'store/client/loading/useLoadingStore';
import { selectAddNotification } from 'store/client/notification/selectors';
import useNotificationStore from 'store/client/notification/useNotificationStore';
import { useCreateWriteOffPaymentMutation } from 'store/server/company/mutations';
import { PAGES_ROUTES } from 'constants/routes';
import { Navigate } from 'react-router-dom';
import useCompanySelectedStore from 'store/client/companySelected/useCompanySelectedStore';
import { selectCompanySelected } from 'store/client/companySelected/selectors';
import Select from 'components/Select';
import Skeleton from 'react-loading-skeleton';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { AxiosError } from 'axios';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { dateUtils } from '../../utils/formatDate';
import {  ORDER_ATTRIBUTTES } from 'constants/orderAttributtes';
import { useGetCompanyGroupList } from 'store/server/company/queries';
import { DEFAULT_VALUES } from 'constants/textAreaInput';
import { handleChangeMonthlyInstallments } from 'utils/handlers';
import InputText from 'components/InputText';
import { convertToBrazilianCurrencyFormat, convertToCurrencyFormat, toFloat } from 'utils/currency.utils';


const { ASC } = ORDER_ATTRIBUTTES;
const { DATE_TIME_DISPLAY_FORMAT } = dateUtils.constants;
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/Sao_Paulo');
interface IProps {
  setOpenModal: (modalState: { display: boolean; expense: IExpense | null }) => void;
  openModal: { display: boolean; expense: IExpense | null };
}

function WriteOffModal({ setOpenModal, openModal }: IProps) {
  const { register, handleSubmit, watch, control, reset, formState: { isValid } } = useForm<ICreateWriteOffPaymentPayload>({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
  });
  const setLoading = useLoadingStore(selectSetLoading);
  const addNotification = useNotificationStore(selectAddNotification);
  const companySelected = useCompanySelectedStore(selectCompanySelected);

  const { expense } = openModal;

  if (!companySelected || !expense || !expense.id)
    return <Navigate to={PAGES_ROUTES.authenticated.commonInitialRoute} replace />;

  const createWriteOffMutation = useCreateWriteOffPaymentMutation(companySelected?.id, expense?.id);

  
  const { data: paymentMethodListData, isLoading: paymentMethodListIsLoading } = useGetCompanyGroupList(
    {
      companyId: companySelected.id,
      groupId: '2',
      status: true,
      field: 'description',
      order: ASC,
    }
  );
  const watchPaymentMethodId = watch('paymentMethodId');
  
  // └── Handlers (e.g., useCallback)
  const onSubmit = async ({ comment, dataEvent, amount }: ICreateWriteOffPaymentPayload) => {
    setLoading(true);
    try {
      await createWriteOffMutation.mutateAsync({
        comment,
        amount:parseFloat(
          amount && typeof amount === 'string'
            ? convertToCurrencyFormat(amount).toString()
            : '0'
        ), 
        dataEvent: dayjs(dataEvent).format(),
        paymentMethodId: watchPaymentMethodId,
      });

      addNotification({
        type: 'success',
        message: 'Cadastro realizado com sucesso!',
      });
      reset();
    } catch (error) {
      if (error instanceof AxiosError) {
        if (
          error.response?.status &&
          error.response?.status >= 400 &&
          error.response?.status < 500
        ) {
          addNotification({
            type: 'error',
            message: error.response?.data.message,
          });
          return;
        }
        addNotification({
          type: 'error',
          message: 'Erro com o serviço. Tente novamente mais tarde.',
        });
      }
    } finally {
      setLoading(false);
      setOpenModal({ display: false, expense: null })
    }
  };
  
  return (
    <Modal
      content={
        <>
          <button
            className="absolute top-[15px] right-4"
            onClick={() => setOpenModal({ display: false, expense: null })}
          >
            <CloseIcon />
          </button>
          <div className="flex flex-col justify-center items-center gap-y-[30px] pr-2 pb-[41px]">
            <h2 className="text-xl text-primary font-semibold">Registrar Baixa</h2>
            <form
              noValidate
              className="flex-row items-start w-full max-w-[600px] gap-x-[10px] xl:gap-x-[20px]"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="flex flex-col">
                <div className="w-full flex sm:gap-x-[16px] flex-col sm:flex-row">
                  <InputText
                          labelText="Valor da Despesa"
                          name="Valor da Despesa"
                          disabled
                          cssClasses={{
                            label: 'sm:max-w-[300px] h-[44px] mt-[10px]',
                            input: 'w-full sm:max-w-[300px]',
                          }}
                          type="text"
                          value={convertToBrazilianCurrencyFormat(expense.amount ?? '0')}
                        />
                    
                    <InputText
                          labelText="Valor Pago"
                          name="Valor Pago"
                          disabled
                          cssClasses={{
                            label: 'sm:max-w-[300px] h-[44px] mt-[10px]',
                            input: 'w-full sm:max-w-[300px]',
                          }}
                          type="text"
                          value={convertToBrazilianCurrencyFormat(expense.amountPay ?? '0')}
                        />
                </div>
                  
                  
                <div className="w-full flex sm:gap-x-[16px] flex-col sm:flex-row">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Controller
                    name="dataEvent"
                    control={control}
                    defaultValue={dayjs()} 
                    render={({ field }) => {
                      return (
                        <label
                          htmlFor="dataEvent"
                          className="font-inter text-primary text-sm font-medium flex flex-col justify-start w-full m-0 truncate !overflow-visible relative cursor-pointer gap-y-1.5  max-w-[250px]"
                        >
                          Data do Pagamento*
                          <DateTimePicker
                            ampm={false}
                            format={DATE_TIME_DISPLAY_FORMAT}
                            onChange={field.onChange}
                            value={dayjs(field.value) || dayjs()}
                            slotProps={{
                              textField: {
                                size: "small"
                              }
                            }
                            }
                          />
                        </label>
                      );
                    }}
                  />
                  </LocalizationProvider>
                  {/* FORMA DE PAGAMENTO */}
                  {paymentMethodListIsLoading ? (
                    <Skeleton height={44} containerClassName="w-full mt-[20px]" />
                  ) : (
                    <Select
                      labelText="Forma de Pagamento*"
                      cssClasses={{
                        label: 'text-primary',
                        select: classNames({
                          '!text-[#a9b1bc] h-[44px] ':
                            watchPaymentMethodId === undefined ||
                            watchPaymentMethodId.toString() === `-1`,
                        }),
                      }}
                      {...register('paymentMethodId')}
                      placeholder="Lista de Formas de Pagamentos"
                      defaultValue={-1}
                    >
                      <option
                        value={-1}
                        className="font-normal font-inter text-sm leading-5 !text-[#a9b1bc] "
                      >
                        Lista de Formas de Pagamentos
                      </option>
                      {paymentMethodListData?.data.map(
                        (paymentMethod: ICompanyGroup, paymentMethodIndex: number) =>
                          paymentMethod.description && (
                            <option
                              value={Number(paymentMethod.id)}
                              key={`client-type-${paymentMethod.id}-${paymentMethod.description}-${paymentMethodIndex}`}
                              className="text-textcolor max-w-[300px]"
                              title={paymentMethod.description}
                            >
                              {paymentMethod.description}
                            </option>
                          )
                      )}
                    </Select>
                  )}
                </div>
                {/* Valor baixa */}
                <div className="w-full flex sm:gap-x-[16px] flex-col sm:flex-row">
                  <Controller
                    name='amount'
                    control={control}
                    defaultValue={'R$ 0,00'}
                    render={({ field }) => (
                      <InputText
                        {...field}
                        labelText="Valor*"
                        cssClasses={{
                          label: ' sm:max-w-[300px] h-[44px] mt-[10px]',
                          input: 'w-full  sm:max-w-[300px]',
                        }}
                        type="text"
                        onChange={handleChangeMonthlyInstallments(field.onChange)}
                      />
                    )}
                  />

                    <InputText
                          labelText="Valor Pendente"
                          name="Valor Pendente"
                          disabled
                          cssClasses={{
                            label: 'sm:max-w-[300px] h-[44px] mt-[10px]',
                            input: 'w-full sm:max-w-[300px]',
                          }}
                          type="text"
                          value={convertToBrazilianCurrencyFormat((toFloat(expense.amount) - toFloat(expense.amountPay)).toString() ?? '0')}
                        />

                </div>
                

                {/* Observacoes */}
                <Controller
                  name='comment'
                  control={control}
                  defaultValue={""}
                  render={({field}) => (
                    <InputTextArea
                      {...field}
                      labelText="Observações"
                      cssClasses={{
                        label:'mt-[10px]',
                        textarea: 'h-full min-h-[100px] max-h-[250px]',
                      }}
                      maxLength={DEFAULT_VALUES.MAX_250_CHAR}                      
                    />
                  )}
                />
              </div>
              <div className="w-full flex flex-col justify-end items-end py-[30px]">
                <button
                  disabled={!isValid}
                  type="submit"
                  className="bg-primary text-xs font-bold leading-4 disabled:bg-[#D1D5DB] text-buttontextcolor py-2 pl-4 pr-[18px] rounded-lg flex justify-center items-center h-[42px] max-w-[200px] tracking-[0.6px]"
                >
                  SALVAR BAIXA
                </button>
              </div>
            </form>
          </div>
        </>
      }
      css={{
        modal: 'relative md:max-h-[600px] md:max-w-[600px] h-full w-full md:my-auto overflow-y-auto' ,

      }}
    />
  );
}

export default WriteOffModal;
