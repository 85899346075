/* eslint-disable react-hooks/rules-of-hooks */
// Third-party
import { Navigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import classNames from 'classnames';
import Skeleton from 'react-loading-skeleton';
import { formSchema } from './schemas';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useMemo } from 'react';
import dayjs from 'dayjs';
// App
//  └── Components
import { InputText, Select } from 'components';
import InputTextArea from 'components/InputTextArea';
import ReturnButton from 'components/ReturnButton';

//  └── Interfaces and Types
import { ICompanyGroup, ICreateExpensePayload, IListElementExpense } from 'interfaces/companyTypes';

//  └── Store
import useNotificationStore from 'store/client/notification/useNotificationStore';
import { selectAddNotification } from 'store/client/notification/selectors';
import useLoadingStore from 'store/client/loading/useLoadingStore';
import { selectLoading, selectSetLoading } from 'store/client/loading/selectors';
import { useGetUser } from 'store/server/user/queries';
import { useGetCompanyGroupList, useGetExpenseList } from 'store/server/company/queries';
import { useCreateExepenseMutation } from 'store/server/company/mutations';
import useCompanySelectedStore from 'store/client/companySelected/useCompanySelectedStore';
import { selectCompanySelected } from 'store/client/companySelected/selectors';
import {  ORDER_ATTRIBUTTES } from 'constants/orderAttributtes';



//  └── Assets
import { RegistrationIcon, CheapIcon } from 'assets/icons';

//  └── Constants
import { PAGES_ROUTES } from 'constants/routes';
import { handleChangeMonthlyInstallments } from 'utils/handlers';
import { dateUtils } from 'utils/formatDate';
import { DEFAULT_VALUES } from 'constants/textAreaInput';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { convertToCurrencyFormat } from 'utils/currency.utils';
const { DATE_TIME_DISPLAY_FORMAT } = dateUtils.constants;
const { ASC } = ORDER_ATTRIBUTTES;

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/Sao_Paulo');
function Create() {
  /*
  **** Component organization ****

   └── Declaration of generic hooks (e.g., useNavigate)
   └── State declaration
   └── Side effects (e.g., useEffect)
   └── Memoization (e.g., useMemo)
   └── Handlers (e.g., useCallback)
   └── JSX
   */

  // └── Declaration of generic hooks (e.g., useNavigate)
  const {
    register,
    handleSubmit,
    formState: { isValid },
    watch,
    reset,
    control,
  } = useForm<ICreateExpensePayload>({
    resolver: yupResolver(formSchema),
  });
  const isLoading = useLoadingStore(selectLoading);
  const setLoading = useLoadingStore(selectSetLoading);
  const addNotification = useNotificationStore(selectAddNotification);
  const companySelected = useCompanySelectedStore(selectCompanySelected);
  
  const { data: user } = useGetUser();
  if (!companySelected)
    return (
      <Navigate to={user?.initialRoute ?? PAGES_ROUTES.authenticated.adminInitialRoute} replace />
    );

  const createExpenseMutation = useCreateExepenseMutation(companySelected.id);

  const { data: categoryListData, isLoading: categoryListIsLoading } = useGetExpenseList(
    companySelected.id,
    '8',
    'categoryList',
    
  );
  const watchCategory = watch('categoryId');

  const { data: paymentMethodListData, isLoading: paymentMethodListIsLoading } = useGetCompanyGroupList(
    {
      companyId: companySelected.id,
      groupId: '1',
      status: true,
      field: 'description',
      order: ASC,
    }
  );
  const watchpaymentMethod = watch('paymentMethodId');


  const isAnyListLoading = useMemo(() => {
    return categoryListIsLoading || paymentMethodListIsLoading ;
  }, [categoryListIsLoading, paymentMethodListIsLoading ]);

  // └── State declaration
  // └── Side effects (e.g., useEffect)
  // └── Handlers (e.g., useCallback)
  const onSubmit = async ({
    description,
    categoryId,
    paymentMethodId,
    dataEvent,
    amount,
  }: ICreateExpensePayload) => {
    setLoading(true);
    try {

      await createExpenseMutation.mutateAsync({
        description,
        categoryId,
        paymentMethodId,    
        dataEvent: dayjs(dataEvent).toISOString(),
        amount: convertToCurrencyFormat(amount).toString(),
      });

      addNotification({
        type: 'success',
        message: 'Cadastro realizado com sucesso!',
      });
      reset({
        description: '', 
        categoryId: '-1', 
        paymentMethodId: '-1',
        dataEvent: dayjs(),
        amount: 'R$ 0,00',
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        if (
          error.response?.status &&
          error.response?.status >= 400 &&
          error.response?.status < 500
        ) {
          addNotification({
            type: 'error',
            message: error.response?.data.message,
          });
          return;
        }
        addNotification({
          type: 'error',
          message: 'Erro com o serviço. Tente novamente mais tarde.',
        });
      }
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <div className="w-full min-h-screen h-full flex flex-col justify-start gap-y-[19px] items-start pt-[23px] md:pt-[42px] p-[27px] md:p-8">
      <div className="flex flex-col gap-2">
        <h1 className="flex items-center gap-2.5 text-2xl font-semibold text-[#595959]">
          <RegistrationIcon className="w-[22px] h-[24px] !text-secondary" />
          Registrar Despesa
        </h1>
        <h3 className="text-xs text-[#374151]">Campos obrigatórios *</h3>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        className="md:items-start items-center w-full max-w-[600px] gap-x-[10px] xl:gap-x-[20px]  flex flex-col"
      >
        {/* MAIN CONTENT */}
        <div className="w-[90%] mx-10">
          <div className="flex flex-col w-full gap-y-5">
            {/* CATEGORIAS DE DESPESAS*/}
            {isAnyListLoading ? (
              <Skeleton height={40.5} containerClassName="w-full" />
            ) : (
              <Select
                labelText="Categoria*"
                cssClasses={{
                  select: classNames({
                    '!text-[#a9b1bc] h-[44px]':
                      watchCategory === '-1' || watchCategory === undefined,
                  }),
                }}
                {...register('categoryId')}
                defaultValue={-1}
              >
                <option
                  value={-1}
                  className="font-normal font-inter text-sm leading-5 !text-[#a9b1bc]"
                >
                  Lista de categorias
                </option>
                {categoryListData?.map(
                  (category: IListElementExpense, categoryIndex: number) =>
                    category.description && (
                      <option
                        value={category.id}
                        key={`client-type-${category.id}-${category.description}-${categoryIndex}`}
                        className="text-textcolor"
                        title={category.description}
                      >
                        {category.description}
                      </option>
                    )
                )}
              </Select>
            )}
            {/* FORMA DE PAGAMENTO */}
            {isAnyListLoading ? (
              <Skeleton height={40.5} containerClassName="w-full" />
            ) : (
              <Select
                labelText="Pagamento*"
                cssClasses={{
                  select: classNames({
                    '!text-[#a9b1bc] h-[44px]':
                      watchpaymentMethod === '-1' || watchpaymentMethod === undefined,
                  }),
                }}
                {...register('paymentMethodId')}
                defaultValue={-1}
              >
                <option
                  value={-1}
                  className="font-normal font-inter text-sm leading-5 !text-[#a9b1bc]"
                >
                  Lista de Pagamentos
                </option>
                {paymentMethodListData?.data.map(
                  (paymentMethod: ICompanyGroup, paymentMethodIndex: number) =>
                    paymentMethod.description && (
                      <option
                        value={paymentMethod.id}
                        key={`client-type-${paymentMethod.id}-${paymentMethod.description}-${paymentMethodIndex}`}
                        className="text-textcolor"
                        title={paymentMethod.description}
                      >
                        {paymentMethod.description}
                      </option>
                    )
                )}
              </Select>
            )}
            {/* Data do Evento */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Controller
                name="dataEvent"
                control={control}
                defaultValue={dayjs()}
                render={({ field }) => {
                  return (
                    <label
                      htmlFor="dataEvent"
                      className="font-inter text-[#374151] text-sm font-medium flex flex-col justify-start w-full m-0 truncate !overflow-visible relative cursor-pointer gap-y-1  max-w-[250px]"
                    >
                      Data da Despesa*
                      <DateTimePicker  
                        ampm={false}
                        format={DATE_TIME_DISPLAY_FORMAT}                                                                            
                        onChange={field.onChange}    
                        value={dayjs(field.value) || dayjs()}
                        slotProps={{
                          textField: {
                            size: "small"
                          }
                        }
                        }
                      />
                    </label>
                  );
                }}
              />
            </LocalizationProvider>

            {/* Valor */}
            <Controller
              name="amount"
              control={control}
              defaultValue="R$ 0,00"
              render={({ field }) => (
                <InputText
                  {...field}
                  labelText="Valor*"
                  cssClasses={{
                    label: 'max-w-[200px] h-[44px]',
                    input: 'w-full max-w-[200px]',
                  }}
                  type="text"
                  onChange={handleChangeMonthlyInstallments(field.onChange)}
                />
              )}
            />

            {/* Descrição da Despesa */}
            <Controller
              name="description"
              control={control}                
              render={({ field }) => (
                <InputTextArea
                  {...field}
                  labelText="Descrição"
                  cssClasses={{
                    textarea: 'h-full min-h-[100px] max-h-[250px]',
                  }}
                  maxLength={DEFAULT_VALUES.MAX_250_CHAR}
                />
              )}
            />
          </div>
          <div className="flex justify-between md:justify-end items-center gap-x-7 lg:gap-x-11 md:row-start-[9] !w-full md:col-start-8 md:col-end-13 mt-[30px]">           
            <ReturnButton 
              returnTo={PAGES_ROUTES.authenticated.transactions.expenses.list}
            />
            <button
              type="submit"
              disabled={
                isLoading ||
                !isValid ||
                categoryListIsLoading ||
                paymentMethodListIsLoading 
              }
              className="bg-primary text-xs font-bold leading-4 disabled:bg-[#D1D5DB] text-buttontextcolor py-2 pl-4 pr-[18px] rounded-lg flex justify-center items-center h-[42px] max-w-[147px] tracking-[0.6px] gap-x-2"
            >
              CADASTRAR
              <CheapIcon className="!text-buttontextcolor" />
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Create;
