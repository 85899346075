import axios from "axios";

interface IAddress {
  bairro: string;
  cep: string;
  logradouro: string;
  complemento: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
}

export async function getAddressByCep(cep: string): Promise<IAddress> {
  const result = await axios.get<IAddress>(
    `https://viacep.com.br/ws/${cep}/json/`
  );

  return result.data;
}

export default {
  getAddressByCep,
};
