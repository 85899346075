import classNames from 'classnames';
import { SummaryChart } from '../..';

interface ISummarySectionProps {
  toExport?: boolean;
}

function SummarySection({ toExport }: ISummarySectionProps) {
  return (
    <div
      className={classNames(
        'flex flex-col gap-y-[40px] gap-x-[20px] min-h-[500px] max-w-[100%] bg-[#f6f9fd] rounded-2xl px-[10px] md:px-[50px] py-[50px]',
        {
          '!bg-white': toExport,
        }
      )}
    >
      <SummaryChart toExport={toExport} />
    </div>
  );
}

export default SummarySection;
