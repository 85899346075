// App
import { IDashboardState } from './interfaces';

export const selectDashboardStore = (state: IDashboardState) => state;

export const selectSelectedMonth = (state: IDashboardState) => {
  return state.selectedMonth;
};

export const selectSetSelectedMonth = (state: IDashboardState) => {
  return state.setSelectedMonth;
};
export const selectSelectedYear = (state: IDashboardState) => {
  return state.selectedYear;
};

export const selectSetSelectedYear = (state: IDashboardState) => {
  return state.setSelectedYear;
};

export const selectSelectedClient = (state: IDashboardState) => {
  return state.selectedClient;
};

export const selectSetSelectedClient = (state: IDashboardState) => {
  return state.setSelectedClient;
};
