import classNames from 'classnames';

function ProfileIcon({ className }: { className?: string }) {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames('text-primary', className)}
      fill="currentColor"
    >
      <path d="M21.8887 19.5078C20.5498 17.1579 18.4591 15.3269 15.9531 14.3096C17.1993 13.3749 18.1198 12.0719 18.5843 10.585C19.0487 9.09817 19.0336 7.50285 18.541 6.02507C18.0484 4.54728 17.1033 3.26195 15.8396 2.35113C14.5759 1.44032 13.0577 0.950195 11.5 0.950195C9.94227 0.950195 8.42403 1.44032 7.16034 2.35113C5.89665 3.26195 4.95158 4.54728 4.45898 6.02507C3.96639 7.50285 3.95125 9.09817 4.41571 10.585C4.88017 12.0719 5.80069 13.3749 7.04686 14.3096C4.54093 15.3269 2.45023 17.1579 1.11132 19.5078C1.02835 19.6412 0.973059 19.7899 0.94872 19.9451C0.924381 20.1003 0.931495 20.2588 0.969639 20.4112C1.00778 20.5636 1.07618 20.7068 1.17076 20.8322C1.26534 20.9577 1.38417 21.0628 1.5202 21.1414C1.65622 21.22 1.80666 21.2704 1.96257 21.2897C2.11847 21.309 2.27667 21.2967 2.42774 21.2537C2.57882 21.2106 2.71969 21.1376 2.84199 21.039C2.96428 20.9404 3.06551 20.8182 3.13964 20.6797C4.90917 17.6211 8.03417 15.7969 11.5 15.7969C14.9658 15.7969 18.0908 17.6221 19.8603 20.6797C20.021 20.938 20.2758 21.1237 20.5709 21.1976C20.866 21.2715 21.1783 21.2277 21.4417 21.0755C21.7051 20.9233 21.899 20.6747 21.9824 20.3821C22.0658 20.0896 22.0322 19.776 21.8887 19.5078ZM6.42186 8.375C6.42186 7.37064 6.71969 6.38884 7.27768 5.55375C7.83567 4.71865 8.62877 4.06778 9.55668 3.68342C10.4846 3.29907 11.5056 3.19851 12.4907 3.39445C13.4757 3.59039 14.3806 4.07403 15.0908 4.78422C15.801 5.49441 16.2846 6.39925 16.4805 7.38431C16.6765 8.36937 16.5759 9.39041 16.1916 10.3183C15.8072 11.2462 15.1563 12.0393 14.3212 12.5973C13.4862 13.1553 12.5043 13.4531 11.5 13.4531C10.1537 13.4516 8.86292 12.9161 7.91093 11.9641C6.95893 11.0121 6.42342 9.72133 6.42186 8.375Z" />
    </svg>
  );
}

export default ProfileIcon;
