// Third-party
import { useState, ChangeEvent } from 'react';
import { UseFormRegister } from 'react-hook-form';
import classNames from 'classnames';
import { Tooltip } from 'react-tooltip';

// App
import './style.css';
import { OpenEyeIcon, ClosedEyeIcon, UploadIcon } from './assets/icons';

type InputType = 'text' | 'password' | 'email' | 'file'; // Input type (e.g., "text", "password", etc.)

type InputProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>; // Form register provided by react-hook-form
  labelText?: string; // Label text
  name: string; // Input field name
  displayWarning?: boolean; // Flag to display or hide the warning
  warningMessage?: string; // Displayed warning message
  placeholder?: string; // Placeholder text
  minLength?: number; // Minimum input length
  maxLength?: number; // Maximum input length
  className?: string; // CSS classes
  labelClassName?: string; // CSS classes
  readOnly?: boolean; // Flag to make input readonly
  disabled?: boolean; // Flag to make input disabled
  /*
   **** Flags to show or hide error message and outline ****
   */
  showError?: boolean; // Flag to show or hide error message and outline
  showErrorMessage?: boolean; // Flag to show or hide error message
  showErrorOutline?: boolean; // Flag to show or hide error outline
};

/**
 * Conditional types are used to validate props based on the type of the input.
 * So when an incorrect prop is passed in or a prop that is required for a particular type is missing,
 * TypeScript will throw an error.

 * Conditional Types doc -> https://www.typescriptlang.org/docs/handbook/2/conditional-types.html
*/
type TypeFileProps =
  | { type: 'file'; image: string }
  | { type: Exclude<InputType, 'file'>; image?: never };

type TypePasswordProps =
  | {
      type: 'password';
      canShowPassword?: boolean; // Flag to show checkbox to show password: string
      disablePassworPatternTip?: boolean;
    }
  | {
      type: Exclude<InputType, 'password'>;
      canShowPassword?: never;
      disablePassworPatternTip?: boolean;
    };

function Input({
  labelText,
  name,
  type,
  displayWarning,
  warningMessage,
  register,
  placeholder,
  minLength,
  maxLength,
  canShowPassword,
  className,
  labelClassName,
  showError,
  showErrorMessage,
  showErrorOutline,
  readOnly,
  disabled,
  image,
  disablePassworPatternTip,
}: InputProps & TypeFileProps & TypePasswordProps) {
  /*
  **** Component organization ****

   └── Declaration of generic hooks (e.g., useNavigate)
   └── State declaration
   └── Side effects (e.g., useEffect)
   └── Memoization (e.g., useMemo)
   └── Handlers (e.g., useCallback)
   └── JSX
   */

  // └── Declaration of generic hooks (e.g., useNavigate)
  const { onChange: onChangeOfHookForm, ...restOfReqister } = register(name);

  // State to control password visibility
  const [showPassword, setShowPassword] = useState<boolean>(false);
  // States for password validations
  const [minLengthValidation, setMinLengthValidation] = useState<boolean>(false);
  const [upperCaseValidation, setUpperCaseValidation] = useState<boolean>(false);
  const [lowerCaseValidation, setLowerCaseValidation] = useState<boolean>(false);
  const [numberValidation, setNumberValidation] = useState<boolean>(false);
  const [symbolValidation, setSymbolValidation] = useState<boolean>(false);

  // Handle onChange password input
  const handleChangeWithValidation = (e: ChangeEvent<HTMLInputElement>) => {
    if (type === 'password') {
      const inputValue = e.target.value;

      const isMinLengthValid = inputValue.length >= 8;
      const hasUppercaseLetter = /[A-Z]/.test(inputValue);
      const hasLowercaseLetter = /[a-z]/.test(inputValue);
      const hasNumber = /\d/.test(inputValue);
      const hasSymbol = /[!@#$%^&*()\-=_+[\]{};':"\\|,.<>/?~`¨´]/.test(inputValue);

      setMinLengthValidation(isMinLengthValid);
      setUpperCaseValidation(hasUppercaseLetter);
      setLowerCaseValidation(hasLowercaseLetter);
      setNumberValidation(hasNumber);
      setSymbolValidation(hasSymbol);
    }

    // Call to the onChange of the reactHook form coming from the return of the register function
    onChangeOfHookForm(e);
  };

  return (
    <label
      className={classNames(
        'font-inter flex flex-col justify-start text-sm font-medium relative h-fit text-primary overflow-visible w-full',
        {
          'relative !w-[106px] !h-[106px] rounded-full bg-[#1F2937] bg-opacity-50 text-white flex items-center !justify-center cursor-pointer !overflow-hidden group mb-5':
            type === 'file',
        },
        labelClassName
      )}
    >
      <div className="flex gap-2 align-center min-w-full min-h-full">
        {type === 'file' ? (
          image ? (
            <div className="relative w-full h-full">
              <img alt="profile" src={image} className="w-full h-full object-cover z-10" />
              <div className="hidden group-hover:flex absolute inset-0 justify-center items-center">
                <p className="relative text-sm text-white z-50" style={{ textAlign: 'center' }}>
                  Alterar Foto
                </p>
                <div className="absolute inset-0 bg-opacity-50 bg-[#1F2937] z-20" />
              </div>
            </div>
          ) : (
            <div className="flex flex-col justify-center items-center gap-1 min-w-full min-h-full">
              <UploadIcon className="text-red" />
              {labelText}
            </div>
          )
        ) : (
          <span className="truncate">{labelText}</span>
        )}

        {/* Password Questionmark Tooltip */}
        {type === 'password' && !disablePassworPatternTip && (
          <div>
            <div
              className="bg-secondary w-5 h-5 rounded-full text-white flex justify-center align-center cursor-pointer"
              data-tooltip-id={`my-tooltip-multiline-${name}`}
            >
              ?
            </div>
            {/* Multi-line tooltip father div */}
            <Tooltip id={`my-tooltip-multiline-${name}`} float style={{ zIndex: '2000' }}>
              {/* tooltip line */}
              <p className={minLengthValidation ? 'text-[#4CAF50] font-bold' : 'text-gray-400'}>
                Mínimo de 8 caracteres{' '}
                <svg
                  className={minLengthValidation ? 'inline' : 'hidden'}
                  xmlns="http://www.w3.org/2000/svg"
                  height="16px"
                  width="16px"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="none"
                >
                  <circle cx="12" cy="12" r="10" fill="#4CAF50" />
                  <path
                    d="M8 12L10 14L16 8"
                    stroke="#FFFFFF"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </p>
              {/* tooltip line */}
              <p className={upperCaseValidation ? 'text-[#4CAF50] font-bold' : 'text-gray-400'}>
                Requer letra maiúscula{' '}
                <svg
                  className={upperCaseValidation ? 'inline' : 'hidden'}
                  xmlns="http://www.w3.org/2000/svg"
                  height="16px"
                  width="16px"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="none"
                >
                  <circle cx="12" cy="12" r="10" fill="#4CAF50" />
                  <path
                    d="M8 12L10 14L16 8"
                    stroke="#FFFFFF"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </p>
              {/* tooltip line */}
              <p className={lowerCaseValidation ? 'text-[#4CAF50] font-bold' : 'text-gray-400'}>
                Requer letra minúscula{' '}
                <svg
                  className={lowerCaseValidation ? 'inline' : 'hidden'}
                  xmlns="http://www.w3.org/2000/svg"
                  height="16px"
                  width="16px"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="none"
                >
                  <circle cx="12" cy="12" r="10" fill="#4CAF50" />
                  <path
                    d="M8 12L10 14L16 8"
                    stroke="#FFFFFF"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </p>
              {/* tooltip line */}
              <p className={numberValidation ? 'text-[#4CAF50] font-bold' : 'text-gray-400'}>
                Requer número{' '}
                <svg
                  className={numberValidation ? 'inline' : 'hidden'}
                  xmlns="http://www.w3.org/2000/svg"
                  height="16px"
                  width="16px"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="none"
                >
                  <circle cx="12" cy="12" r="10" fill="#4CAF50" />
                  <path
                    d="M8 12L10 14L16 8"
                    stroke="#FFFFFF"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </p>
              {/* tooltip line */}
              <p className={symbolValidation ? 'text-[#4CAF50] font-bold' : 'text-gray-400'}>
                Requer símbolo{' '}
                <svg
                  className={symbolValidation ? 'inline' : 'hidden'}
                  xmlns="http://www.w3.org/2000/svg"
                  height="16px"
                  width="16px"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="none"
                >
                  <circle cx="12" cy="12" r="10" fill="#4CAF50" />
                  <path
                    d="M8 12L10 14L16 8"
                    stroke="#FFFFFF"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </p>
            </Tooltip>
          </div>
        )}
      </div>

      <input
        type={(showPassword && 'text') || type} // Input type (can be text or the provided type)
        placeholder={placeholder} // Placeholder
        minLength={minLength} // Minimum length
        maxLength={maxLength} // Maximum length
        className={classNames(
          'bg-[#FBFCFD] border border-[#D1D5DB] text-textcolor text-sm rounded-lg focus:outline outline-1 outline-secondary w-full p-2.5 mt-1 h-11 disabled:text-[#D1D5DB]  read-only:text-[#D1D5DB] disabled:border-[#EEE] read-only:border-[#EEE]',
          className,
          {
            'shadow-red-400 shadow-[0_0_0_2px] border-opacity-0 !outline-0':
              (showError || showErrorOutline) && displayWarning,
            '!hidden': type === 'file',
          }
        )} // CSS classes for styling
        readOnly={readOnly}
        disabled={disabled}
        onChange={handleChangeWithValidation}
        autoComplete="on"
        {...restOfReqister}
      />
      {type === 'password' && canShowPassword && (
        <button
          type="button"
          onClick={() => setShowPassword(!showPassword)}
          className="absolute top-11 right-3 transform -translate-y-1/2 bg-transparent border-none cursor-pointer"
        >
          {showPassword ? <OpenEyeIcon /> : <ClosedEyeIcon />}
        </button>
      )}
      {(showError || showErrorMessage) && (
        <div className="flex justify-between">
          {(displayWarning && (
            <span className="text-sm text-orange h-6">{warningMessage}</span>
          )) || <span className="h-[24px]" />}
        </div>
      )}
    </label>
  );
}

export default Input;
